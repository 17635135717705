/*
 * 업무구분: 지급스케쥴관리
 * 화 면 명: MSPTS301P
 * 화면설명: 지급스케쥴관리
 * 작 성 일: 2023.03.02
 * 작 성 자: 김경태
 */
<template>
  <mo-modal class="fts-modal large wide" ref="modal" title="지급 스케쥴 관리" id="idMspts301">
    <template>
      <div class="wrap-modalcontents" id="dv301P">
        <div class="wrap-button right gap6 m6col">
          <mo-button class="btn-pop-plus"  :disabled="disEnabled" @click="fn_AddRow()">+</mo-button>
          <mo-button class="btn-pop-minus" :disabled="disEnabled" @click="fn_DelRow()">-</mo-button>
        </div>
        <div class="right-bg-white">
          <div class="wrap-table table-sticky sticky-colspan3">
            <table class="table col-type num-type th-row-gray">
              <thead>
                <tr>
                  <th> 선택 </th>
                  <th> 등급 </th>
                  <th colspan="3"> 원금수익자 </th>
                  <th> 약정이체계좌 </th>
                  <th colspan="3"> 이익수익자 </th>
                  <th> 약정이체계좌 </th>
                  <th> 정기지급여부 </th>
                  <th> 정률/정액 </th>
                  <th> 방법 </th>
                  <th> 주기 </th>
                  <th> 일자 </th>
                  <th> 금액 </th>
                  <th> 비율 </th>
                  <th> 지급시작월 </th>
                  <th> 지급종료월 </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in dsList01" :key="idx"  :value="idx" @click="fn_RowSelected(idx)">
                  <td> <mo-radio v-model='lv_SelectedItem' :disabled="disEnabled" :value="idx.toString()" /> </td>
                  <td> <mo-dropdown class="w120" :items="bnfcRatingTcItems" v-model="item.bnfcRatingTc" :disabled="disEnabled" ref="dropdown1" dropdown301="dropdown1" /> </td>
                  <td class="wsN"> <mo-text-field class="w80" v-model="item.prinBnfcRcno" :disabled="true"/> </td>
                  <td> <mo-button class="btn-pop-download" @click="fn_OpenMSPTS107P('A', idx)"> </mo-button> </td>
                  <td> <mo-text-field class="w74 aC" v-model="item.prinBnfcCnm" disabled/> </td>
                  <td> <mo-dropdown class="w225" @input="fn_Change(idx,'prinBnfcBnkSseq')" :items="item.prinBnfcBnkSseqItems" v-model="item.prinBnfcBnkSseq" :disabled="disEnabled" ref="dropdown2" dropdown301="dropdown2" /> </td>
                  <td class="wsN"> <mo-text-field class="w80" v-model="item.prftBnfcRcno" :disabled="true"/> </td>
                  <td> <mo-button class="btn-pop-download" @click="fn_OpenMSPTS107P('B', idx)" :disabled="disEnabled"> </mo-button> </td>
                  <td> <mo-text-field class="w74 aC" v-model="item.prftBnfcCnm" disabled/> </td>
                  <td> <mo-dropdown class="w225" @input="fn_Change(idx,'prftBnfcBnkSseq')" :items="item.prftBnfcBnkSseqItems" v-model="item.prftBnfcBnkSseq" :disabled="disEnabled" ref="dropdown3" dropdown301="dropdown3" /> </td>
                  <td> <mo-dropdown class="w80" @input="fn_FxppYnChange(idx)" :items="fxppYnItems" v-model="item.fxppYn" :disabled="disEnabled" ref="dropdown4" dropdown301="dropdown4" /> </td>
                  <td> <mo-dropdown class="w80" @input="fn_FxrtFamtTcChange(idx)" :items="fxrtFamtTcItems" v-model="item.fxrtFamtTc" :disabled="disEnabled" ref="dropdown5" dropdown301="dropdown5" /> </td>
                  <td> <mo-dropdown class="w80" @input="fn_FxppMethCChange(idx)" :items="fxppMethCItems" v-model="item.fxppMethC" :disabled="disEnabled" ref="dropdown6" dropdown301="dropdown6" /> </td>
                  <td> <mo-dropdown class="w80" :items="ftrmPymCyclItems" v-model="item.ftrmPymCycl" :disabled="disEnabled" ref="dropdown7" dropdown301="dropdown7" /> </td>
                  <td> <mo-dropdown class="w80" :items="ftrmPymDdTcItems" v-model="item.ftrmPymDdTc" :disabled="disEnabled" ref="dropdown8" dropdown301="dropdown8" /> </td>
                  <td> <mo-decimal-field class="aR w150" numeric mask="number" v-model="item.pymAmt" :disabled="item.fxrtFamtTc!='' && item.fxrtFamtTc=='2' && item.fxppMethC!='03'?false:true"/> </td>
                  <td> <mo-text-field class="w80" v-model="item.pymRto" :disabled="item.fxrtFamtTc!='' && item.fxrtFamtTc=='1'?false:true" :maxlength='3' /> </td>
                  <td> <mo-month-picker class="w150" v-model="item.fxppStrtDateFrm" :disabled="disEnabled" :bottom="false" @input="fn_ymChg(idx,1)" /> </td>
                  <td> <mo-month-picker class="w150" v-model="item.fxppEndDateFrm" :disabled="disEnabled" :bottom="false" @input="fn_ymChg(idx,2)" /> </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_Close">닫기</mo-button>
        <mo-button primary size="large" @click="fn_Confirm">확인</mo-button>
      </div>
    </template>
    <ts-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
      :style="alertZindex"
    ></ts-alert-popup>
    <msp-ts-107p
    ref="popup107"
    :popup107Obj="pPopup107Obj"
    @ts-popup107-callback="fn_Popup107Back"
  ></msp-ts-107p>
  </mo-modal>
</template>

<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'
import MSPTS107P from '@/ui/ts/MSPTS107P' //고객조회

/************************************************************************************************
* DataSet 영역
************************************************************************************************/
  const dsList01_301 = {
    'tacno' : '',
    'bnkbSeq' : '',
    'accSeq' : '',
    'ctno' : '',
    'bnfcSeq' : '',
    'bnfcRatingTc' : '1',
    'fxrtFamtTc' : '',
    'fxppYn' : '',
    'fxppMethC' : '',
    'fxppStrtDate' : '',
    'fxppEndDate' : '',
    'ftrmPymCycl' : '',
    'ftrmPymDdTc' : '',
    'pymRto' : '',
    'pymAmt' : '',
    'prinBnfcCsId' : '',
    'prinBnfcRcno' : '',
    'prinBnfcRcnoGb' : '',
    'prinBnfcCnm' : '',
    'prinBnfcBnkSseq' : '',
    'prinBnfcBnkSseqNm' : '',
    'prftBnfcCsId' : '',
    'prftBnfcRcno' : '',
    'prftBnfcRcnoGb' : '',
    'prftBnfcCnm' : '',
    'prftBnfcBnkSseq' : '',
    'prftBnfcBnkSseqNm' : ''
  };

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPTS301P',
  // 현재 화면 ID
  screenId: 'MSPTS301P',
  // 컴포넌트 선언
  components: {
    'ts-alert-popup': TSAlertPopup,
    'msp-ts-107p': MSPTS107P
  },
  //화면명
  props: {
    popup301Obj: {type:Object, default:null},
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      //style
      alertZindex: {zIndex:9999},
      // 공통 변수
      rowIdx: '',
      rowType: '',
      dsDetail: [],  //tfac20000Rst
      dsList01: [],
      dsList00: {},  //tpup95320Rst{}
      pAlertPopupObj: {},
      lv_SelectedItem: '',
      lv_Params:{},
      lv_ReturnVal: [], // 리턴값
      lv_ReturnStr: [], // 베이스 화면으로 리턴할 조회조건 텍스트
      bnfcRatingTcItems: [
        {value: '1', text: '원 수익자'},
        {value: '2', text: '1차 수익자'},
      ],
      disEnabled:false,
      fxppYnItems: [],
      fxrtFamtTcItems: [],
      fxppMethCItems: [],
      ftrmPymCyclItems: [],
      ftrmPymDdTcItems: [],
      popup107: {}, // MSPTS107P
      pPopup107Obj: {},
      eaiCommObj: {
        lv_vm: '',
        trnstId: '',
        auth: '',
        commHeaderVO: {
          eaiId: '',
          fahrTrnId: 'S',
          requestSystemCode: 'F1391',
          reqSrvcNm: '',
          reqMthdNm: '',
          targetSystemCode: '',
          resVONm: '',
          reqVONm: '',
        },
        params: {},
        response: [],
        error_msg           : '',      // nexacro 메시지코드(length: 6)
        mapAcct: {},
      },
      acctRslt: {},
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    modal() {
      return this.$refs.modal
    },
    
  },
  
  /** watch 정의 영역 */
  watch: {
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    // 공통코드 조회
    //this.fn_ComCode()
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPTS301P')
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    fn_display(mode){
      //mode: true(display), false(none display)
      let el = document.getElementById("dv301P");
      if (el == null) return;
      if(mode){
        el.style.display = "";
      }else{
        el.style.display = "none";
      }
    },
    fn_ymChg(rw,mode){
      if (mode==1){
        let st1 = this.dsList01[rw]["fxppStrtDateFrm"];
        let st1m = st1[1]>10?st1[1]+"":"0"+st1[1];
        this.dsList01[rw]["fxppStrtDate"] = st1[0]+st1m;
      }else{
        let ed1 = this.dsList01[rw]["fxppEndDateFrm"];
        let ed1m = ed1[1]>10?ed1[1]+"":"0"+ed1[1];
        this.dsList01[rw]["fxppEndDate"] = ed1[0]+ed1m;
      }
    },
    fn_Change(idx,mod){
      let cnt = this.dsList01.length;
      let val = this.dsList01[idx][mod];
      //soptTc
      let soptTc = this.dsDetail[0]["soptTc"];
      if (soptTc != "10"){
        if (mod == "prftBnfcBnkSseq"){
          this.dsList01[idx]["prftBnfcBnkSseq"] = val;
        }else{
          this.dsList01[idx]["prinBnfcBnkSseq"] = val;
          if (this.dsList01[idx]["prftBnfcBnkSseq"]==null || this.dsList01[idx]["prftBnfcBnkSseq"] == ""){
            this.dsList01[idx]["prftBnfcBnkSseq"] = val;
          }
        }
        return;
      }
      for(let i=0;i<cnt;i++){
        this.dsList01[i]["prinBnfcBnkSseq"] = val;
        this.dsList01[i]["prftBnfcBnkSseq"] = val;
      }
    },
    /***************************************************************************************
      code        : 호출할 공통코드
      dsName      : 호출된 공통코드를 담을 Dataset
      selecttype  : ComboBox 최상단에 추가할 문구( A: -전체-, S:-선택-, N: 빈칸)
      
      selecttype은 추후 변경될 수 있음.
      this.fn_commonAfterOnload를 화면에 선언하면 gfn_setCommonCode처리후 호출됨.
    *****************************************************************************************/
    fn_ComCode (){
      if (this.fxppYnItems != null && this.fxppYnItems.length > 0) return;

      this.fn_display(false);
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.trnstId = 'txTSSTS90S1' // selectTFCP11000A
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600046_S'
      this.eaiCommObj.params = {'TUKEMK':[
                                  {cId:"502904"},
                                  {cId:"5101"},
                                  {cId:"5019"},
                                  {cId:"5035"},
                                  {cId:"5055"}
                              ]}
      //TSServiceUtil.invoke(this.eaiCommObj, this.fn_ComCodeCallBack, this.fn_error)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_ComCodeCallBack, this.fn_error)
    }, 
    fn_ComCodeCallBack ( res ) {
      for ( let index in res.data.tukemk ) {
          let item = res.data.tukemk[index]
          switch (item.cid) 
          {
            case "5101":
              this.fxppYnItems.push({value: item.c , text: item.cnm})
              break
            case "5019":
              this.fxrtFamtTcItems.push({value: item.c , text: item.cnm})
              break
            case "5029":
              this.fxppMethCItems.push({value: item.c , text: item.cnm})
              break
            case "5035":
              this.ftrmPymCyclItems.push({value: item.c , text: item.cnm})
              break
            case "5055":
              this.ftrmPymDdTcItems.push({value: item.c , text: item.cnm})
              break
          }
      }
      this.fn_display(true);
    },
    /******************************************************************************
     * Function명 : fn_Open
     * 설명       : open
     ******************************************************************************/
    async fn_Open(properties){
      this.fn_ComCode();
      
      TSCommUtil.gfn_setArrDDBoxHide(this.$refs, "idMspts301", "dropdown301");

      this.lv_Params.pPgmId = properties.pgmId;
      this.lv_Params.processGb = properties.processGb;

      this.dsList01 = [];
      this.dsDetail = _.cloneDeep(properties.dsDetail);

      this.dsList00 = _.cloneDeep(properties.dsList00);

      let tmpDsList01 = TSCommUtil.gfn_getArrFrBase(dsList01_301, properties.dsList01);
      tmpDsList01 = await this.fn_setAcct(tmpDsList01);  //add prinBnfcBnkSseqItems,prftBnfcBnkSseqItems
      for(let i=0;i<tmpDsList01.length;i++){
        tmpDsList01[i]["fxppYnItems"] = [];
        tmpDsList01[i]["fxrtFamtTcItems"] = [];
        tmpDsList01[i]["fxppMethCItems"] = [];
        tmpDsList01[i]["ftrmPymCyclItems"] = [];
        tmpDsList01[i]["ftrmPymDdTcItems"] = [];
        let st1 = tmpDsList01[i]["fxppStrtDate"];
        if (_.isEmpty(st1)) st1 = "200001";
        let ed1 = tmpDsList01[i]["fxppEndDate"];
        if (_.isEmpty(ed1)) ed1 = "200001";
        tmpDsList01[i]["fxppStrtDateFrm"] = [st1.substr(0,4),st1.substr(4,2)];
        tmpDsList01[i]["fxppEndDateFrm"] = [ed1.substr(0,4),ed1.substr(4,2)];
        tmpDsList01[i]["prinBnfcBnkSseq"] = properties.dsList01[i]["prinBnfcBnkSseq"]+"";
        tmpDsList01[i]["prftBnfcBnkSseq"] = properties.dsList01[i]["prftBnfcBnkSseq"]+"";
      }
      this.dsList01 = _.cloneDeep(tmpDsList01);

      this.modal.open()
    },
    /******************************************************************************
     * Function명 : fn_Close
     * 설명       : 닫기
     ******************************************************************************/
    fn_Close(){
      this.modal.close()
      TSCommUtil.gfn_closeArrDDBox(this.$refs, "idMspts301", "dropdown301");
    },
    /******************************************************************************
     * Function명 : fn_Clear
     * 설명       : 입력값 모두 초기화 (초기 값으로 셋팅)
     ******************************************************************************/
    fn_Clear () {
     
    },
    /******************************************************************************
     * Function명 : fn_FxppYnChange
     * 설명       : 정기지급여부
    ******************************************************************************/
    fn_FxppYnChange(idx){
      if(this.dsList01[idx].fxppYn === 'Y'){
        this.dsList01[idx].fxrtFamtTc = '2'
        this.dsList01[idx].fxppMethC = ''
        this.dsList01[idx].ftrmPymCycl = ''
        this.dsList01[idx].ftrmPymDdTc = ''
        this.dsList01[idx].pymAmt = 0
        this.dsList01[idx].pymRto = 0
        this.dsList01[idx].fxppStrtDate = ''
        this.dsList01[idx].fxppEndDate = ''
      }else{
        this.dsList01[idx].fxrtFamtTc = '1'
        this.dsList01[idx].fxppMethC = ''
        this.dsList01[idx].ftrmPymCycl = ''
        this.dsList01[idx].ftrmPymDdTc = ''
        this.dsList01[idx].pymAmt = 0
        this.dsList01[idx].pymRto = 0
        this.dsList01[idx].fxppStrtDate = ''
        this.dsList01[idx].fxppEndDate = ''
      }
    },
    /******************************************************************************
     * Function명 : fn_FxrtFamtTcChange
     * 설명       : 정률/정액 변경
    ******************************************************************************/
    fn_FxrtFamtTcChange(idx){
      if(this.dsList01[idx].fxppYn === 'Y'){
        this.dsList01[idx].fxrtFamtTc = '2'
        this.pAlertPopupObj.content = '정기지급은 정액만 가능합니다.'
        this.pAlertPopupObj.confirm = false
        this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
      }else{
        this.dsList01[idx].fxrtFamtTc = '1'
        this.pAlertPopupObj.content = '정기지급이 아닌 경우는 정률만 가능합니다.'
        this.pAlertPopupObj.confirm = false
        this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
      }
    },
    /******************************************************************************
     * Function명 : fn_FxppMethCChange
     * 설명       : 방법 변경
    ******************************************************************************/
    fn_FxppMethCChange(idx){
      if(this.dsList01[idx].fxppMethC === '3' ||  this.dsList01[idx].fxppMethC=== '003'){
        this.dsList01[idx].pymAmt = 0
      }
    },
    /******************************************************************************
     * Function명 : fn_getAccList
     * 설명       : 계좌목록
     ******************************************************************************/
    async fn_getAccList(csId){
      try {
        if (_.isEmpty(csId)){
          let initArr = new Array();
          initArr.push({"value":"0","text":" - 선택안함(브릿지계좌로 송금) - "})
          return initArr;
        }
        if (this.acctRslt[csId]!=null){
          return this.acctRslt[csId];
        }

        let pParams = {
          csId: csId
        }
        this.eaiCommObjS = TSCommUtil.gfn_eaiCommObj()
        this.eaiCommObjS.lv_vm = this
        this.eaiCommObjS.trnstId = 'txTSSTS92S7'
        this.eaiCommObjS.auth = 'S'
        this.eaiCommObjS.params = pParams
        this.eaiCommObjS.commHeaderVO.eaiId = 'C392_F10600164_S'
        let resData = null;

        await TSServiceUtil.invokeInDirect(this.eaiCommObjS, function (response) {
          if (response && response.data) {
            resData = _.cloneDeep(response.data);
          }
        },function (error) {
          console.log("error..........."+error)
        },'UF10600164')
        if (_.isEmpty(resData) || TSCommUtil.gfn_trim(resData.errorCode) != ''){
          let accLst = []
          let acc = {value:"0",text:" - 선택안함(브릿지계좌로 송금) - "}
          accLst.push(acc)
          return accLst;
        }
        //set account list data(key(csId), value(account list))
        let accLst = []
        const tfcbsl = resData.tfcbsl
        let acc = {value:"0",text:" - 선택안함(브릿지계좌로 송금) - "}
        accLst.push(acc)
        
        if(tfcbsl.length > 0){
          for(let i=0;i<tfcbsl.length; i++){
            let acc = {}
            acc.value = String(tfcbsl[i].stipSeq)
            acc.text = tfcbsl[i].trBkcdNmBnkAcno
            accLst.push(acc)
          }
        }

        this.acctRslt[csId] = accLst;

        return accLst;
      } catch (error) {
        console.log("error......................................................."+error)
        return null;
      }
    },
    /******************************************************************************
     * Function명 : fn_setAcct
     * 설명       : 계좌목록 적용(open시)
     ******************************************************************************/
    async fn_setAcct(tmpVal){
      if (_.isEmpty(tmpVal) || tmpVal.length < 1) return tmpVal;
      for(let i=0;i<tmpVal.length; i++){
        let csId = tmpVal[i].prinBnfcCsId;
        let bnkArr = await this.fn_getAccList(csId);
        tmpVal[i].prinBnfcBnkSseqItems = bnkArr;

        let csId2 = tmpVal[i].prftBnfcCsId;
        let bnkArr2 = await this.fn_getAccList(csId2);
        tmpVal[i].prftBnfcBnkSseqItems = bnkArr2
      }
      return tmpVal;
    },
    /******************************************************************************
     * Function명 : fn_AddRow
     * 설명       : 행 추가
     ******************************************************************************/
    async fn_AddRow(){
      //추가 입금은 변경없음
      if (this.lv_Params.processGb == "2") return;

      let bnfcSeq = 0;
      let rCnt = this.dsList01.length;
      if (rCnt > 0){
        for(let item of this.dsList01){
          if (item[bnfcSeq] > bnfcSeq) bnfcSeq = item[bnfcSeq];
        }
        bnfcSeq++;
      }
      let addTftbni = TSCommUtil.gfn_getMapFrBase(dsList01_301);
      addTftbni["bnfcSeq"] = bnfcSeq;
      addTftbni["bnfcRatingTc"] = "1";
      addTftbni["fxppYnItems"] = [];
      addTftbni["fxrtFamtTcItems"] = [];
      addTftbni["fxppMethCItems"] = [];
      addTftbni["ftrmPymCyclItems"] = [];
      addTftbni["ftrmPymDdTcItems"] = [];
      addTftbni["fxppStrtDateFrm"] = ["2000","01"];
      addTftbni["fxppEndDateFrm"] = ["2000","01"];
      
      if (!_.isEmpty(this.dsDetail) && this.dsDetail[0].soptTc == '10'){
        let csId = this.dsList00["csId"];
        let arrBnk = await this.fn_getAccList(csId);
        
        addTftbni["prinBnfcCsId"] = csId
        
        addTftbni["prinBnfcRcno"] = this.dsList00.rcno;
        addTftbni["prinBnfcRcnoGb"] = this.dsList00.rcnoKindTc;
        addTftbni["prinBnfcCnm"] = this.dsList00.cnm;
        addTftbni["prinBnfcBnkSseq"] = "0";
        addTftbni["prinBnfcBnkSseqNm"] = " - 선택안함(브릿지계좌로 송금) - ";
        
        addTftbni["prinBnfcBnkSseqItems"] = arrBnk;
        
        addTftbni["prftBnfcCsId"] = csId;
        addTftbni["prftBnfcRcno"] = this.dsList00.rcno;
        addTftbni["prftBnfcRcnoGb"] = this.dsList00.rcnoKindTc;
        addTftbni["prftBnfcCnm"] = this.dsList00.cnm;
        addTftbni["prftBnfcBnkSseq"] = "0";
        addTftbni["prftBnfcBnkSseqNm"] = " - 선택안함(브릿지계좌로 송금) - ";
        
        addTftbni["prftBnfcBnkSseqItems"] = arrBnk;
      }
      else{
        let arrBnk2 = await this.fn_getAccList(null);
        addTftbni["prinBnfcBnkSseqItems"] = arrBnk2
        addTftbni["prftBnfcBnkSseqItems"] = arrBnk2
      }
      this.dsList01.push(_.cloneDeep(addTftbni));
    },
    /******************************************************************************
     * Function명 : fn_DelRow
     * 설명       : 행 삭제
     ******************************************************************************/
    fn_DelRow(){
      //추가 입금은 변경없음
      if (this.lv_Params.processGb == "2") return;
      
      if(!_.isEmpty(this.lv_SelectedItem)){
        this.dsList01.splice(this.lv_SelectedItem, 1)
      }
    },
    /******************************************************************************
     * Function명 : fn_csGbChange
     * 설명       : 개인/법인 선택
     ******************************************************************************/
    fn_csGbChange(type){

    },
    /******************************************************************************
     * Function명 : fn_Search
     * 설명       : 조회
     ******************************************************************************/
    fn_Search () {
    },
    /******************************************************************************
     * Function명 : fn_RowSelected
     * 설명       : 조회내역 선택
     ******************************************************************************/
    fn_RowSelected(idx){
      this.lv_SelectedItem = idx+"";
    },
    /******************************************************************************
     * Function명 : fn_Confirm
     * 설명       : 확인
     ******************************************************************************/
    fn_Confirm () {
      if (this.dsList01.length < 1){
        this.pAlertPopupObj.content = '원수익자를 입력해 주십시오.'
        this.pAlertPopupObj.confirm = false
        this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
        return;
      }
      if ( this.dsList01[0].bnfcRatingTc != "1" ){
        this.pAlertPopupObj.content = '첫번째 행은 원수익자를 입력하십시오.'
        this.pAlertPopupObj.confirm = false
        this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
        return
      }

      for(let i=0; i<this.dsList01.length; i++){
        let row = i + 1
        if(this.lv_Params.pPgmId !== '상품'){
          if(_.isEmpty(this.dsList01[i].prinBnfcCsId)){
            this.pAlertPopupObj.content = row+"번째 행의 원금수익자를 선택해 주세요."
            this.pAlertPopupObj.confirm = false
            this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
            return
          }else if(this.dsList01[i].soptTc === '10' && (this.dsList01[i].prinBnfcCsId !== this.dsDetail.csId)){
            this.pAlertPopupObj.content = row+"번째 행의 자익 수익자의 경우 본인만 선택 가능합니다."
            this.pAlertPopupObj.confirm = false
            this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
            return
          }
          if(_.isEmpty(this.dsList01[i].prftBnfcCsId)){
            this.pAlertPopupObj.content = row+"번째 행의 이익수익자를 선택해 주세요."
            this.pAlertPopupObj.confirm = false
            this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
            return
          }else if(this.dsList01[i].soptTc === '10' && (this.dsList01[i].prftBnfcCsId !== this.dsDetail.csId)){
            this.pAlertPopupObj.content = row+"번째 행의 자익 수익자의 경우 본인만 선택 가능합니다."
            this.pAlertPopupObj.confirm = false
            this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
            return
          }
          if(_.isEmpty(this.dsList01[i].bnfcRatingTc)){
            this.pAlertPopupObj.content = row+"번째 행의 수익자 등급을 선택해 주세요."
            this.pAlertPopupObj.confirm = false
            this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
            return
          }
        }
        
        if(_.isEmpty(this.dsList01[i].fxppYn)){
          this.pAlertPopupObj.content = row+"번째 행의 정기지급 여부를 선택해 주세요."
          this.pAlertPopupObj.confirm = false
          this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
          return
        }
        if(this.dsList01[i].fxppYn === 'Y'){
          if(_.isEmpty(this.dsList01[i].fxrtFamtTc)){
            this.pAlertPopupObj.content = row+"번째 행의 정률정액구분을 선택해 주세요."
            this.pAlertPopupObj.confirm = false
            this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
            return
          }
          if(_.isEmpty(this.dsList01[i].fxppMethC)){
            this.pAlertPopupObj.content = row+"번째 행의 지급방법을 선택해 주세요."
            this.pAlertPopupObj.confirm = false
            this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
            return
          }
          if(_.isEmpty(this.dsList01[i].fxppStrtDate)){
            this.pAlertPopupObj.content = row+"번째 행의 시작일자를 선택해 주세요."
            this.pAlertPopupObj.confirm = false
            this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
            return
          }
          if(_.isEmpty(this.dsList01[i].fxppEndDate)){
            this.pAlertPopupObj.content = row+"번째 행의 종료일자를 선택해 주세요."
            this.pAlertPopupObj.confirm = false
            this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
            return
          }
          if(_.isEmpty(this.dsList01[i].ftrmPymCycl)){
            this.pAlertPopupObj.content = row+"번째 행의 지급주기를 선택해 주세요."
            this.pAlertPopupObj.confirm = false
            this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
            return
          }
          if(_.isEmpty(this.dsList01[i].ftrmPymDdTc)){
            this.pAlertPopupObj.content = row+"번째 행의 지급일자를 선택해 주세요."
            this.pAlertPopupObj.confirm = false
            this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
            return
          }

          if(this.dsList01[i].fxrtFamtTc === '1'){
            if(_.isEmpty(this.dsList01[i].pymRto)){
              this.pAlertPopupObj.content = row+"번째 행의 비율을 입력해 주세요."
              this.pAlertPopupObj.confirm = false
              this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
              return
            }
          }else if(this.dsList01[i].fxrtFamtTc === '2' && this.dsList01[i].fxppMethC !== '03' && this.dsList01[i].fxppMethC !== '04'){
            if(this.dsList01[i].pymAmt === 0 && _.isEmpty(this.dsList01[i].pymAmt)){
              this.pAlertPopupObj.content = row+"번째 행의 금액을 입력해 주세요."
              this.pAlertPopupObj.confirm = false
              this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
              return
            }
          }
        }
        if(Number(this.dsList01[i].fxppEndDate) < Number(this.dsList01[i].fxppStrtDate)){
          this.pAlertPopupObj.content = row+"번째 행의 지급종료월은 지급시작일 이후로 입력해 주시기 바랍니다."
          this.pAlertPopupObj.confirm = false
          this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
          return
        }
        if(this.dsList01[i].fxrtFamtTc === '1' && Number(this.dsList01[i].pymRto) <= 0){
          this.pAlertPopupObj.content = row+"번째 행의 정률수익자 비율을 0이상 입력해 주세요."
          this.pAlertPopupObj.confirm = false
          this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
          return
        }
      }
      if(this.lv_Params.pPgmId == '상품'){
        //1차수익자 체크
        let tc1 = 0
        let tc11 = 0
        let tc1sum = 0
        for(let i=0; i<this.dsList01.length; i++){
          if(this.dsList01[i].bnfcRatingTc === '1'){
            tc1++
          }
          if(this.dsList01[i].bnfcRatingTc === '1' && this.dsList01[i].fxrtFamtTc === '1'){
            tc11++
            if(this.dsList01[i].pymRto == null || isNaN(this.dsList01[i].pymRto)){
              this.dsList01[i].pymRto = 0
            }
            tc1sum = Number(this.dsList01[i].pymRto) + tc1sum
          }
        }
        if(tc1 >= 1){
          if(tc11 >= 1){
            if(tc1sum !== 100){
              this.pAlertPopupObj.content = '원수익자 정률 수익자 비율의 합은 100이어야 합니다.'
              this.pAlertPopupObj.confirm = false
              this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
              return
            }
          }
        }
      }
      if(this.lv_Params.pPgmId != '상품'){
        //원수익자 체크
        let tc1 = 0
        let tc11 = 0
        let tc1sum = 0
        for(let i=0; i<this.dsList01.length; i++){
          if(this.dsList01[i].bnfcRatingTc === '1'){
            tc1++
          }
          if(this.dsList01[i].bnfcRatingTc === '1' && this.dsList01[i].fxrtFamtTc === '1'){
            tc11++
            if(this.dsList01[i].pymRto == null || isNaN(this.dsList01[i].pymRto)){
              this.dsList01[i].pymRto = 0
            }
            tc1sum = Number(this.dsList01[i].pymRto) + tc1sum
          }
        }
        if(tc1 >= 1){
          if(tc11 >= 1){
            if(tc1sum !== 100){
              this.pAlertPopupObj.content = '원수익자 정률 수익자 비율의 합은 100이어야 합니다3.'
              this.pAlertPopupObj.confirm = false
              this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
              return
            }
          }else{
            let rtn = {}
            rtn.bnfcRatingTc = '1'
            rtn.fxppYn = 'N'
            rtn.fxrtFamtTc = '1'
            rtn.pymRto = '100'
            rtn.prinBnfcCsId = this.dsList00["csId"]
            rtn.prinBnfcRcno = this.dsList00["rcno"]
            rtn.prinBnfcRcnoGb = this.dsList00["rcnoKindTc"]
            rtn.prinBnfcCnm = this.dsList00["cnm"]
            rtn.prinBnfcBnkSseq = '0'
            rtn.prinBnfcBnkSseqNm = ' - 선택안함(브릿지계좌로 송금) - '
            rtn.prftBnfcCsId = this.dsList00["csId"]
            rtn.prftBnfcRcno = this.dsList00["rcno"]
            rtn.prftBnfcRcnoGb = this.dsList00["rcnoKindTc"]
            rtn.prftBnfcCnm = this.dsList00["cnm"]
            rtn.prftBnfcBnkSseq = '0'
            rtn.prftBnfcBnkSseqNm = ' - 선택안함(브릿지계좌로 송금) - '
            this.dsList01.push(rtn)
          }
        }else{
          this.pAlertPopupObj.content = '원수익자를 입력해 주세요.'
          this.pAlertPopupObj.confirm = false
          this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
          return
        }
      }
      //1차수익자
      let tc2 = 0   //bnfcRatingTc == '2'
      let tc21 = 0  //bnfcRatingTc == '1'
      let tc22 = 0  //fxrtFamtTc == '1'&& bnfcRatingTc == '2'
      let tc2sum = 0  //fxrtFamtTc == '1' && bnfcRatingTc == '2' ==> pymRto sum
      for(let i=0; i<this.dsList01.length; i++){
        if(this.dsList01[i].bnfcRatingTc === '2'){
          tc2++
        }
        if(this.dsList01[i].bnfcRatingTc === '1'){
          tc21++
        }
        if(this.dsList01[i].bnfcRatingTc === '2' && this.dsList01[i].fxrtFamtTc === '1'){
          tc22++
          if(this.dsList01[i].pymRto == null || isNaN(this.dsList01[i].pymRto)){
            this.dsList01[i].pymRto = 0
          }
          tc2sum = Number(this.dsList01[i].pymRto) + tc2sum
        }
      }
      if(tc2 >= 1){
        if(tc21 < 1){
          this.pAlertPopupObj.content = '원수익자를 입력하세요.'
          this.pAlertPopupObj.confirm = false
          this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
        }
        if(tc22 >= 1){
          if(tc2sum !== 100){
            this.pAlertPopupObj.content = '1차 정률 수익자 비율의 합은 100이어야 합니다1.'
            this.pAlertPopupObj.confirm = false
            this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
          }
        }
      }
      this.$emit('ts-popup301-callback', this.dsList01)
      this.fn_Close()
    },
    /*********************************************************
    * Function명: fn_OpenMSPTS107P
    * 설명: 고객조회 팝업호출
    *********************************************************/
    fn_OpenMSPTS107P (type, index) {
      this.rowIdx = index
      this.rowType = type
      let soptTc = _.isEmpty(this.dsDetail.soptTc)?"":this.dsDetail.soptTc
      if(soptTc === '10'){
        return
      }
      let properties = {
        pPage : 'MSPTS301P',
        pRow: index,
        pType: type
      }
      this.popup107 = this.$refs.popup107.fn_Open(properties)
    },
    async fn_Popup107Back(rtnData){
      //원금수익자
      if(this.rowType == 'A'){
        this.dsList01[this.rowIdx].prinBnfcCsId = rtnData.csId
        this.dsList01[this.rowIdx].prinBnfcRcno = rtnData.rcno
        this.dsList01[this.rowIdx].prinBnfcCnm = rtnData.cnm
        let arrBnk = await this.fn_getAccList(rtnData.csId);
        this.dsList01[this.rowIdx].prinBnfcBnkSseqItems = arrBnk
        this.dsList01[this.rowIdx].prinBnfcBnkSseq = "0"

        if (this.dsList01[this.rowIdx].prftBnfcCsId == null || this.dsList01[this.rowIdx].prftBnfcCsId == ""){
          this.dsList01[this.rowIdx].prftBnfcBnkSseqItems = arrBnk
          this.dsList01[this.rowIdx].prftBnfcCsId = rtnData.csId
          this.dsList01[this.rowIdx].prftBnfcRcno = rtnData.rcno
          this.dsList01[this.rowIdx].prftBnfcCnm = rtnData.cnm
          this.dsList01[this.rowIdx].prftBnfcBnkSseq = "0"
        }
      //이익수익자
      }else{
        this.dsList01[this.rowIdx].prftBnfcCsId = rtnData.csId
        this.dsList01[this.rowIdx].prftBnfcRcno = rtnData.rcno
        this.dsList01[this.rowIdx].prftBnfcCnm = rtnData.cnm
        let arrBnk = await this.fn_getAccList(rtnData.csId);
        this.dsList01[this.rowIdx].prftBnfcBnkSseqItems = arrBnk
        this.dsList01[this.rowIdx].prftBnfcBnkSseq = "0"
      }
      this.$forceUpdate()
    },

    /*********************************************************************************************
     * Function   : gfn_firstDate(sDate) 
     * Parameter  : sDate - yyyyMMdd형태의 날짜 (예 : "20151122")
     * Return     : 성공 = yyyyMMdd형태의 시작 날짜 (예: "20151122" )
     *              실패 = ""
     *
     * 해당월의 시작 날짜를 yyyyMMdd형태로 구하기
    *********************************************************************************************/
    gfn_firstDate(sDate)
    { 
      if (this.gfn_isNull(sDate)){
        return "";
      }
      
      return sDate.substr(0, 6) + "01";
    },

    /*********************************************************************************************
     * Function   : gfn_lastDate(sDate) 
     * Parameter  : sDate - yyyyMMdd형태의 날짜 (예 : "20151122")
     * Return     : 성공 = yyyyMMdd형태의 마지막날짜 (예: "20151122" )
     *              실패 = ""
     *
     * 해당월의 마지막 날짜를 yyyyMMdd형태로 구하기
    *********************************************************************************************/
    gfn_lastDate(sDate)
    {
      if (this.gfn_isNull(sDate)){
        return "";
      }
      
      let nLastDate = this.gfn_lastDateNum(sDate);
      return sDate.substr(0, 6) + nLastDate.toString();
    },
    /*********************************************************************************************
     * Function   : gfn_lastDate(sDate) 
     * Parameter  : sDate - yyyyMMdd형태의 날짜 (예 : "20151122")
     * Return     : 성공 = 마지막 날짜 숫자값 (예 : 30)
     *              실패 = -1
     *
     * 해당월의 마지막 날짜를 숫자로 구하기
    *********************************************************************************************/
    gfn_lastDateNum(sDate)
    {
      let nMonth,nLastDate;

      if (this.gfn_isNull(sDate)){
        return -1;
      }

      nMonth = Number(sDate.substr(4, 2), 10);
      if (nMonth == 1 || nMonth == 3 || nMonth == 5 || nMonth == 7 || nMonth == 8 || nMonth == 10 || nMonth == 12){
        nLastDate = 31;
      } else if (nMonth == 2){
        if (this.gfn_isLeapYear(sDate) == true){
          nLastDate = 29;
        } else {
          nLastDate = 28;
        }
      } else {
        nLastDate = 30;
      }

      return nLastDate;
    }
  }
}
</script>