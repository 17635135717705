/* 
* 업무구분: 고객 
* 화 면 명: MSPTS200M 
* 화면설명: 계약 신규/추가입금/변경신청  1020 
* 수 정 일: 2024-04-17 정수진 사유 : ASR240300857 / [사랑on신탁]신규입력화면 해피콜서비스값 생성
* 수 정 일: 2024-08-26 정수진 사유 : ASR240800403_[사랑On신탁] 개인고객확인서 서식변경
*/

<template>
  <ur-page-container
    class="fts"
    :show-title="false"
    title="계약 신규/추가입금/변경신청"
    :topButton="true"
  >
    <!-- header start -->
    <ts-header id="hd200M" ref="tsHeader" :propObj="pHeaderObj"></ts-header>
    <!-- header end -->
    <div class="fts-main" id="dv200M" ref="rfDv200M">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="left column">
            <div class="row w100p gap30">
              <div class="wrap-input row">
                <label> 업무구분 </label>
                <mo-radio-wrapper
                  :items="radProcessGbItems"
                  @input="fn_RadProcess()"
                  v-model="radProcessGb"
                  class="row"
                />
              </div>
            </div>
            <div class="row w100p gap30" v-if="visDivAcno">
              <div class="wrap-input row">
                <label> 계좌번호 </label>
                <div class="wrap-input row">
                  <mo-decimal-field
                    numeric
                    mask="#######"
                    v-model="edtAcno1"
                    ref="rfEdtAcno1"
                    class="w120"
                    :class="{ comp: edtAcno1.length > 6 }"
                    maxlength="7"
                    @keyup="edtAcno1_onkeyup($event)"
                  />
                  <mo-button
                    class="btn-pop-download"
                    @click="Button11_onclick()"
                  >
                  </mo-button>
                  <mo-text-field class="w130" v-model="edtCnm1" disabled />
                </div>
              </div>
              <div class="wrap-input row">
                <label> 통장번호 </label>
                <mo-dropdown
                  :items="dsList08Acno"
                  v-model="cboAcno"
                  value=""
                  ref="rfCboAcno"
                  placeholder=" "
                  @input="Div02_cbo_acno_onitemchanged()"
                />
              </div>
              <div class="wrap-input row">
                <label> 계약번호 </label>
                <mo-dropdown
                  class="w70"
                  :items="dsList"
                  v-model="cboCtno"
                  value=""
                  ref="rfCboCtno"
                  placeholder=" "
                  @input="Div02_cbo_ctno_onitemchanged()"
                />
              </div>
            </div>
            <div class="row w100p gap30" v-if="visDivAccno">
              <div class="wrap-input row">
                <label> 계좌번호 </label>
                <div class="wrap-input row">
                  <mo-decimal-field
                    v-model="edtAcno2"
                    ref="rfEdtAcno2"
                    numeric
                    mask="#######-###"
                    maxlength="11"
                    class="w130 input-account"
                    :class="{ comp: edtAcno2.length > 9 }"
                    clearable
                    @keyup="edtAcno2_onkeyup($event)"
                  />
                  <mo-dropdown
                    :items="dsListS2Seq"
                    v-model="cboAccSeq"
                    ref="rfCboAccSeq"
                    class="w200"
                    placeholder=""
                    @input="Cbo_accSeq_onitemchanged()"
                  />
                  <!-- 계좌일련번호 -->
                  <mo-button
                    class="btn-pop-download"
                    @click="btn_popupTPUP95350_onclick()"
                  >
                  </mo-button>
                  <mo-text-field class="w130" v-model="edtCnm2" disabled />
                </div>
              </div>
              <div class="wrap-input row">
                <label> 일련번호 </label>
                <mo-dropdown
                  :items="dsList09"
                  @input="Div02_cbo_chngSeq_onitemchanged()"
                  v-model="cboChngSeq"
                  class="w90"
                  placeholder=" "
                />
              </div>
            </div>
          </div>
          <div class="right self-end">
            <div class="wrap-button row">
              <mo-button
                :disabled="btnInit"
                class="btn-clear"
                @click="fn_init()"
              >
                초기화
              </mo-button>
              <mo-button
                :disabled="btnSearchList"
                primary
                class="btn-inquiry"
                @click="fn_searchList()"
              >
                조회
              </mo-button>
              <!-- <mo-button @click="fn_checkProcess()" primary> 전자문서 조회 </mo-button> -->
            </div>
          </div>
        </div>
      </ur-box-container>
      <ur-box-container direction="column" alignV="start">
        <div class="wrap-button result">
          <mo-button
            @click="Div02_Btn_reprInfoConfirm_onclick()"
            :disabled="disReprInfo"
            v-if="visReprInfo"
          >
            대리인등록
          </mo-button>
          &nbsp;&nbsp;&nbsp;
          <mo-button @click="Mspts108_onclick()" v-if="visBtnGoodsInfo">
            신탁상품 정보조회
          </mo-button>
          &nbsp;&nbsp;&nbsp;
          <!-- <mo-button @click="Div02_Btn_ivorInfoConfirmation00_onclick()"> 송금계좌등록 </mo-button> &nbsp;&nbsp;&nbsp; -->
          <mo-button @click="btn_admission_onclick()" v-if="visBtnAdmission">
            {{ btnAdmissionText }}
          </mo-button>
          <mo-button @click="fn_btnReAmlCheck()" :disabled="btnAml">
            AML
          </mo-button>
        </div>
        <div class="wrap-table">
          <table class="table col-type num-type w50p">
            <colgroup>
              <col width="20%" />
              <col width="30%" />
              <col width="20%" />
              <col width="30%" />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <span class="emphasis"> 신탁유형 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      :items="dsCmb5014"
                      v-model="trstTypC"
                      dsdtl="trstTypC"
                      ref="rfTrstTypC"
                      @input="cbo_trstTypC_onitemchanged()"
                      :disabled="disTrstTypC"
                      placeholder="선택하세요"
                    />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 자타익구분 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      :items="dsCmb5026"
                      v-model="soptTc"
                      dsdtl="soptTc"
                      ref="rfSoptTc"
                      @input="cbo_soptTc_onitemchanged()"
                      :disabled="disSoptTc"
                      placeholder="선택하세요"
                    />
                    <span class="emphasis" v-if="visMoCtno"> 모상품번호 </span>
                    <mo-text-field v-if="visMoCtno" v-model="moCtno" disabled />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 상품유형 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      :items="cmbGdTypDtlC"
                      v-model="gdTypDtlC"
                      dsdtl="gdTypDtlC"
                      ref="rfGdTypDtlC"
                      @input="cbo_gdTypDtlC_onitemchanged()"
                      :disabled="disGdTypDtlC"
                      placeholder="선택하세요"
                    />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 원금수익자 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      mask="######-#******"
                      v-model="otxtBnfcRcno"
                      :disabled="true"
                    />
                    <mo-button
                      class="btn-pop-download"
                      @click="btn_custInfo00_onclick()"
                      :disabled="btnCustInfo00"
                    >
                    </mo-button>
                    <mo-text-field
                      v-model="otxtBnfcNm"
                      :disabled="disOtxtBnfcNm"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 신탁상품 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      @keyup="edt_gdC_onchar($event)"
                      v-model="gdC"
                      dsdtl="gdC"
                      ref="rfGdC"
                      :disabled="disGdC"
                    />
                    <mo-button
                      class="btn-pop-download"
                      @click="btn_popupGdC_onclick()"
                      :disabled="disGdC"
                    >
                    </mo-button>
                  </div>
                </td>
                <th>
                  <span> 원금지급계좌 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      :items="dsList11"
                      @input="cbo_trBkcdNmbnkAcno00_onitemchanged()"
                      v-model="trBkcdNmbnkAcno00"
                      :disabled="disTrBkcdNmbnkAcno00"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 상품명 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      v-model="gdNm"
                      dsdtl="gdNm"
                      :disabled="disGdNm"
                    />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 이익수익자 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      mask="######-#******"
                      v-model="prftBnfcRcno"
                      :disabled="true"
                    />
                    <mo-button
                      class="btn-pop-download"
                      @click="btn_custInfo01_onclick()"
                      :disabled="btnCustInfo01"
                    >
                    </mo-button>
                    <mo-text-field
                      v-model="prftBnfcNm"
                      :disabled="disPrftBnfcNm"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> {{ trstAmtNm }} </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field
                      class="input-long"
                      v-model="trstAmt"
                      dsdtl="trstAmt"
                      ref="rfTrstAmt"
                      :disabled="disTrstAmt"
                      numeric
                      mask="number"
                      @change="fn_OnColumnChanged('dsDetail', 'trstAmt')"
                    />
                    <mo-button class="btn-input" :disabled="true">
                      KRW
                    </mo-button>
                  </div>
                </td>
                <th>
                  <span> 이익지급계좌 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      :items="dsList12"
                      @input="cbo_trBkcdNmbnkAcno01_onitemchanged()"
                      v-model="trBkcdNmbnkAcno01"
                      :disabled="disTrBkcdNmbnkAcno01"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 승인상태 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      class="input-long"
                      :items="dsCmb5085"
                      v-model="aprvBusnTc1"
                      dsdtl="aprvBusnTc1"
                      :disabled="disAprvBusnTc1"
                      placeholder=""
                    />
                    <mo-dropdown
                      class="input-long w120"
                      :items="dsCmb5023"
                      v-model="aprvStatTc"
                      dsdtl="aprvStatTc"
                      :disabled="disAprvStatTc"
                      placeholder=""
                    />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 상품등급 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      v-model="ivRskRatingC"
                      dsdtl="ivRskRatingC"
                      :items="dsCmb5071"
                      :disabled="disivRskRatingC"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 고객성향 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      v-model="csIvtdRatingC"
                      dsdtl="csIvtdRatingC"
                      :items="dsCmb5072"
                      :disabled="disCsIvtdRatingC"
                    />
                    <mo-badge
                      v-if="static83 == '적합상품'"
                      class="blue ml0"
                      :text="static83"
                      shape="status"
                      >{{ static83 }}</mo-badge
                    >
                    <mo-badge
                      v-if="static83 != '적합상품' && static83 != ''"
                      class="red ml0"
                      :text="static83"
                      shape="status"
                      >{{ static83 }}</mo-badge
                    >
                  </div>
                </td>
                <th>
                  <span ref="rfStatic79"> 계약관계자 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-button
                      @click="btn_related_onclick()"
                      class="btn-input"
                      ref="rfRelated"
                      :disabled="disRelated"
                    >
                      계약 관계자
                    </mo-button>
                  </div>
                </td>
              </tr>

              <tr>
                <th>
                  <span class="emphasis"> 만기일 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-date-picker
                      class="input-long"
                      v-model="mtrtDate"
                      dsdtl="mtrtDate"
                      ref="rfMtrtDate"
                      :disabled="disMtrtDate"
                      :bottom="false"
                    />
                  </div>
                </td>
                <th>
                  <span> 지급스케쥴 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-button
                      @click="btn_custInfoReg_onclick()"
                      class="btn-input"
                      :disabled="btnCustInfoReg"
                    >
                      스케쥴 관리
                    </mo-button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 만기후보수율 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      v-model="mtrtAfTfrt"
                      dsdtl="mtrtAfTfrt"
                      :disabled="disMtrtAfTfrt"
                      mask="number"
                    />
                    <span>%</span>
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 보수구분 </span>
                </th>
                <td>
                  <div class="row justify-between">
                    <div class="wrap-input row  w185">
                      <mo-dropdown
                        :items="dsCmb5017"
                        v-model="poaoTc"
                        dsdtl="poaoTc"
                        ref="rfPoaoTc"
                        :disabled="disPoaoTc"
                        @input="cbo_poaoTc_onitemchanged()"
                      />
                    </div>
                    <div class="wrap-checkbox row">
                      <mo-checkbox v-model="rufeYn" dsdtl="rufeYn" disabled />
                      성과보수
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 해지소요일수 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      v-model="tmtlNeedDds"
                      dsdtl="tmtlNeedDds"
                      :disabled="disTmtlNeedDds"
                      mask="number"
                    />
                    <span>일</span>
                  </div>
                </td>
                <th>
                  <span> (후취)기본보수율 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      class="input-long"
                      v-model="basTfrt"
                      dsdtl="basTfrt"
                      ref="rfBasTfrt"
                      :disabled="disBasTfrt"
                      mask="number"
                    />
                    <!-- 기본수수료율 -->
                    <span>%</span>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 운용내역통보 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      :items="dsCmb5025"
                      v-model="opdtNtcTc"
                      dsdtl="opdtNtcTc"
                      ref="rfOpdtNtcTc"
                      :disabled="disOpdtNtcTc"
                    />
                  </div>
                </td>
                <th>
                  <span v-if="visIsueYn"> 발행여부 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      v-if="visIsueYn"
                      :items="dsCmb5101"
                      v-model="isueYn"
                      dsdtl="isueYn"
                      :disabled="disIsueYn"
                    />
                  </div>
                </td>
              </tr>
              <tr v-if="visAocpRgfeYn || visFrBondCpTc">
                <th>
                  <span v-if="visAocpRgfeYn"> 정기보수여부 </span>
                </th>
                <td>
                  <div class="wrap-input row" v-if="visAocpRgfeYn">
                    <mo-dropdown
                      :items="dsCmb5101"
                      @input="cbo_aocpRgfeYn_onitemchanged()"
                      v-model="aocpRgfeYn"
                      dsdtl="aocpRgfeYn"
                      :disabled="disAocpRgfeYn"
                    />
                  </div>
                </td>
                <th>
                  <span v-if="visFrBondCpTc" class="emphasis">
                    해외채권 쿠폰처리여부
                  </span>
                </th>
                <td>
                  <div class="wrap-input row" v-if="visFrBondCpTc">
                    <mo-dropdown
                      :items="dsList15"
                      v-model="frBondCpTc"
                      dsdtl="frBondCpTc"
                      ref="rfFrBondCpTc"
                      :disabled="disFrBondCpTc"
                    />
                  </div>
                </td>
              </tr>
              <tr v-if="visComsTamt || visPrfe || visPrfeRt || visAocpRgfe">
                <th>
                  <span v-if="visComsTamt"> 총수수료 </span>
                  <span v-if="visPrfe"> 선취수수료 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field
                      v-if="visComsTamt"
                      class="input-long"
                      v-model="comsTamt"
                      dsdtl="comsTamt"
                      numeric
                      mask="number"
                      :disabled="true"
                    />
                    <mo-text-field
                      v-if="visPrfe"
                      class="input-long"
                      v-model="prfe"
                      dsdtl="prfe"
                      mask="number"
                      :disabled="disPrfe"
                      @change="fn_OnColumnChanged('dsDetail', 'prfe')"
                    />
                  </div>
                </td>
                <th>
                  <span v-if="visPrfeRt"> 선취수수료율 </span>
                  <span v-if="visAocpRgfe"> 후취정기보수 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      v-if="visPrfeRt"
                      class="input-long"
                      v-model="prfeRt"
                      dsdtl="prfeRt"
                      mask="number"
                      :disabled="disPrfeRt"
                      @change="fn_OnColumnChanged('dsDetail', 'prfeRt')"
                    />
                    <span v-if="visPrfeRt">%</span>
                    <mo-text-field
                      v-if="visAocpRgfe"
                      class="input-long"
                      v-model="aocpRgfe"
                      dsdtl="aocpRgfe"
                      mask="number"
                      :disabled="disAocpRgfe"
                    />
                  </div>
                </td>
              </tr>
              <tr v-if="visEpayPoaoTc || visAddValue">
                <th>
                  <span v-if="visEpayPoaoTc"> 별납보수구분 </span>
                </th>
                <td>
                  <div class="wrap-input row" v-if="visEpayPoaoTc">
                    <mo-dropdown
                      v-model="epayPoaoTc"
                      dsdtl="epayPoaoTc"
                      :items="dsCmb5018"
                      @input="cbo_epayPoaoTc_onitemchanged()"
                      placeholder="선택하세요"
                      :disabled="disEpayPoaoTc"
                    />
                  </div>
                </td>
                <th>
                  <span v-if="visAddValue"> 부가가치세 </span>
                </th>
                <td>
                  <div class="wrap-input row" v-if="visAddValue">
                    <mo-decimal-field
                      v-model="addValue"
                      dsdtl="addValue"
                      numeric
                      mask="number"
                      class="input-long"
                      :disabled="true"
                    />
                  </div>
                </td>
              </tr>
              <tr v-if="visEpayTfrt || visEpayCyclTc">
                <th>
                  <span v-if="visEpayTfrt"> 별납보수률 </span>
                </th>
                <td>
                  <div class="wrap-input row" v-if="visEpayTfrt">
                    <mo-text-field
                      v-model="epayTfrt"
                      dsdtl="epayTfrt"
                      mask="number"
                      class="input-long"
                      :disabled="disEpayTfrt"
                      @change="fn_OnColumnChanged('dsDetail', 'epayTfrt')"
                    />
                    <span>%</span>
                  </div>
                </td>
                <th>
                  <span v-if="visEpayCyclTc"> 주기 </span>
                </th>
                <td>
                  <div class="wrap-input row" v-if="visEpayCyclTc">
                    <mo-dropdown
                      :items="dsCmb5099"
                      v-model="epayCyclTc"
                      dsdtl="epayCyclTc"
                      placeholder="선택하세요"
                      :disabled="disEpayCyclTc"
                    />
                  </div>
                </td>
              </tr>
              <tr
                v-if="visEpayFxrtFamtTc || visAocpRgfeFamtTc || visEpayFeeAmt"
              >
                <th>
                  <span v-if="visEpayFxrtFamtTc"> 정률정액구분 </span>
                  <span v-if="visAocpRgfeFamtTc"> 정률정액구분 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      v-model="epayFxrtFamtTc"
                      dsdtl="epayFxrtFamtTc"
                      v-if="visEpayFxrtFamtTc"
                      :items="dsCmb5019"
                      @input="cbo_epayFxrtFamtTc_onitemchanged()"
                      placeholder="선택하세요"
                      :disabled="disEpayFxrtFamtTc"
                    />
                    <mo-dropdown
                      v-model="aocpRgfeFamtTc"
                      dsdtl="aocpRgfeFamtTc"
                      v-if="visAocpRgfeFamtTc"
                      :items="dsCmb5019"
                      @input="cbo_aocpRgfeFamtTc_onitemchanged()"
                      placeholder="선택하세요"
                      :disabled="disAocpRgfeFamtTc"
                    />
                    <mo-dropdown
                      v-if="visAocpRgfeCyclTc"
                      :items="dsCmb5100"
                      v-model="aocpRgfeCyclTc"
                      dsdtl="aocpRgfeCyclTc"
                      :disabled="disAocpRgfeCyclTc"
                    />
                  </div>
                </td>
                <th>
                  <span v-if="visEpayFeeAmt"> 금액 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field
                      v-if="visEpayFeeAmt"
                      v-model="epayFeeAmt"
                      dsdtl="epayFeeAmt"
                      class="input-long"
                      numeric
                      mask="number"
                      :disabled="disEpayFeeAmt"
                      @change="fn_OnColumnChanged('dsDetail', 'epayFeeAmt')"
                    />
                  </div>
                </td>
              </tr>
              <!-- <tr v-if="visAgntCsId">
                <th>
                  <span> 대리인 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      v-model="agntCsId"
                      dsdtl="agntCsId"
                      class="input-long"
                      :disabled="disAgntCsId"
                    />
                    <mo-button
                      class="btn-pop-download"
                      @click="btn_agntCsId_onclick()"
                      :disabled="disAgntCsId"
                    >
                    </mo-button>
                    <mo-text-field
                      v-model="agntCsIdNm"
                      dsdtl="agntCsIdNm"
                      class="input-long"
                      :disabled="disAgntCsIdNm"
                    />
                  </div>
                </td>
                <td colspan="2"></td>
              </tr> ASR240800403_[사랑On신탁] 개인고객확인서 서식변경-->
              <tr v-if="visBnfcDethYn">
                <th>
                  <span> 사망여부 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      :items="dsCmb5101"
                      v-model="bnfcDethYn"
                      dsdtl="bnfcDethYn"
                      placeholder="선택하세요"
                      :disabled="disBnfcDethYn"
                    />
                  </div>
                </td>
                <th>
                  <span> 사망일자 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-date-picker
                      v-model="bnfcDethDate"
                      dsdtl="bnfcDethDate"
                      class="input-long"
                      :bottom="false"
                      :disabled="disBnfcDethDate"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis" @click="fn_realAlert()"> 권유자 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field
                      class="input-long"
                      v-model="invtMnEmno"
                      numeric
                      mask="#####"
                      dsdtl="invtMnEmno"
                      ref="rfInvtMnEmno"
                      @input="edt_onkeyupToGetName('invtMnEmno')"
                      maxlength="5"
                      :disabled="disInvtMnEmno"
                    />
                    <mo-button
                      class="btn-pop-download"
                      @click="btn_empInfo00_onclick()"
                      :disabled="disInvtMnEmno"
                    >
                    </mo-button>
                    <mo-text-field
                      class="input-long"
                      v-model="invtMnEmnoNm"
                      dsdtl="invtMnEmnoNm"
                      :disabled="disInvtMnEmnoNm"
                    />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 판매직원 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field
                      class="input-long"
                      v-model="saleEmno"
                      numeric
                      mask="#####"
                      dsdtl="saleEmno"
                      ref="rfSaleEmno"
                      @input="edt_onkeyupToGetName('saleEmno')"
                      maxlength="5"
                      :disabled="disSaleEmno"
                    />
                    <mo-button
                      class="btn-pop-download"
                      @click="btn_empInfo03_onclick()"
                      :disabled="disSaleEmno"
                    >
                    </mo-button>
                    <mo-text-field
                      class="input-long"
                      v-model="saleEmnoNm"
                      dsdtl="saleEmnoNm"
                      :disabled="disSaleEmnoNm"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis" @click="fn_emno()"> 관리자 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field
                      class="input-long"
                      v-model="mngrEmno"
                      numeric
                      mask="#####"
                      dsdtl="mngrEmno"
                      ref="rfMngrEmno"
                      @input="edt_onkeyupToGetName('mngrEmno')"
                      maxlength="5"
                      :disabled="disMngrEmno"
                    />
                    <mo-button
                      class="btn-pop-download"
                      @click="btn_empInfo01_onclick()"
                      :disabled="disMngrEmno"
                    >
                    </mo-button>
                    <mo-text-field
                      class="input-long"
                      v-model="mngrEmnoNm"
                      dsdtl="mngrEmnoNm"
                      :disabled="disMngrEmnoNm"
                    />
                  </div>
                </td>
                <th>
                  <span> 권유대행FC </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field
                      class="input-long"
                      v-model="fnlDsgnEmno"
                      numeric
                      mask="########"
                      @input="edt_onkeyupToGetName('fnlDsgnEmno')"
                      dsdtl="fnlDsgnEmno"
                      maxlength="8"
                      :disabled="disFnlDsgnEmno"
                    />

                    <mo-button
                      class="btn-pop-download"
                      @click="btn_empInfo02_onclick()"
                      :disabled="disFnlDsgnEmno"
                    >
                    </mo-button>
                    <mo-text-field
                      class="input-long"
                      v-model="fnlDsgnEmnoNm"
                      dsdtl="fnlDsgnEmnoNm"
                      :disabled="disFnlDsgnEmnoNm"
                    />
                  </div>
                </td>
              </tr>
              <tr v-if="visTrBkcdNmBnkAcno || visHpcTc">
                <th>
                  <span v-if="visTrBkcdNmBnkAcno"> 부동산환급계좌 </span>
                </th>
                <td>
                  <div class="wrap-input row" v-if="visTrBkcdNmBnkAcno">
                    <mo-dropdown
                      :items="dsList10"
                      v-model="trBkcdNmBnkAcno"
                      dsdtl="trBkcdNmBnkAcno"
                      :disabled="disTrBkcdNmbnkAcno"
                    />
                  </div>
                </td>
                <th>
                  <span v-if="visHpcTc" class="emphasis"> 해피콜구분 </span>
                </th>
                <td>
                  <div class="wrap-radio row" v-if="visHpcTc">
                    <mo-radio-wrapper
                      :items="hpcTcItems"
                      v-model="hpcTc"
                      class="row"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>
      <ur-box-container v-if="visStatic92" direction="column" alignV="start">
        <!-- 수익보수율 / 중도해지수수료율 -->
      </ur-box-container>

      <ur-box-container
        v-if="visGrdList02"
        :disabled="disGrdList02"
        direction="column"
        alignV="start"
      >
        <div class="wrap-table-title row justify-between">
          <div class="row">
            <h2 class="table-title">운용지시내역</h2>
            <div class="wrap-checkbox row spS">
              <mo-checkbox
                v-model="blktAppnIndcYn"
                :disabled="disBlktAppnIndcYn"
              >
                포괄운용지시
              </mo-checkbox>
              <mo-checkbox v-model="parseng" disabled> 파생여부 </mo-checkbox>
            </div>
          </div>
          <div class="wrap-button right gap6" v-if="visInsertRow">
            <mo-button class="btn-pop-plus" @click="insertRow_onclick()">
            </mo-button>
            <mo-button class="btn-pop-minus" @click="delRow_onclick()">
            </mo-button>
          </div>
        </div>
        <div class="wrap-table">
          <table class="table row-type">
            <thead>
              <tr>
                <th class="w40"></th>
                <th colspan="3">운용방법</th>
                <th>운용비율</th>
                <th colspan="3">KR코드</th>
                <th>운용지시내역</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(data, idx) in dsList02"
                :key="idx"
                :class="{ checked: lv_SelectedItem02 == idx.toString() }"
                @click="fn_selectedDsList02(idx)"
              >
                <td>
                  <mo-radio
                    v-model="lv_SelectedItem02"
                    :value="idx.toString()"
                    :disabled="disGrdList02"
                  ></mo-radio>
                </td>
                <td>{{ data.appnMethC }}</td>
                <td>
                  <mo-button
                    @click="grd_list_oncellclick(1, idx)"
                    :disabled="disGrdList02"
                    class="btn-pop-download"
                  >
                  </mo-button>
                </td>
                <td class="aL">{{ data.appnMethCNm }}</td>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      mask="number"
                      class="w40"
                      v-model="data.appnStrtRto"
                      :disabled="disGrdList02"
                    />
                    이상
                    <mo-text-field
                      mask="number"
                      class="w40 ml-2"
                      v-model="data.appnEndRto"
                      :disabled="disGrdList02"
                    />
                    이하
                  </div>
                </td>
                <td>{{ data.krItemC }}</td>
                <td>
                  <mo-button
                    @click="grd_list_oncellclick(8, idx)"
                    :disabled="disGrdList02"
                    class="btn-pop-download"
                  >
                  </mo-button>
                </td>
                <td>{{ data.krItemNm }}</td>
                <td>
                  <div class="wrap-input tbl">
                    <mo-text-area
                      rows="2"
                      class="row"
                      v-model="data.opdtExpl"
                      :disabled="disGrdList02"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container
        v-if="visGrdList03"
        :disabled="disGrdList03"
        direction="column"
        alignV="start"
      >
        <div class="wrap-table-title row justify-between">
          <h2 class="table-title">유가증권내역</h2>
          <div class="wrap-button right gap6" v-if="visInsertRow00">
            <mo-button class="btn-pop-plus" @click="insertRow00_onclick()">
            </mo-button>
            <mo-button class="btn-pop-minus" @click="delRow00_onclick()">
            </mo-button>
          </div>
        </div>
        <div class="wrap-table">
          <table class="table row-type">
            <thead>
              <tr>
                <th class="w40"></th>
                <th>구분</th>
                <th colspan="3">증권번호</th>
                <th>권종</th>
                <th>수량(매,주수)</th>
                <th>평가단가</th>
                <th>신탁가액</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(data, idx3) in dsList03"
                :key="idx3"
                :class="{ checked: lv_SelectedItem03 == idx3.toString() }"
                @click="fn_selectedDsList03(idx3)"
              >
                <td>
                  <mo-radio
                    v-model="lv_SelectedItem03"
                    :value="idx3.toString()"
                    :disabled="disGrdList03"
                  ></mo-radio>
                </td>
                <td class="aL">
                  <mo-dropdown
                    :items="dsCmb5030"
                    v-model="data.astsType"
                    class="w120"
                  />
                </td>
                <td>{{ data.secusNo }}</td>
                <td>
                  <mo-button
                    @click="grd_list03_oncellclick(2, idx3)"
                    :disabled="disGrdList03"
                    class="btn-pop-download"
                  >
                  </mo-button>
                </td>
                <td>{{ data.itemNm }}</td>
                <td>
                  <mo-decimal-field
                    v-model="data.dnmn"
                    numeric
                    mask="number"
                    class="w90"
                    @change="fn_OnColumnChanged('dsList03', 'dnmn', idx3)"
                  />
                </td>
                <td>
                  <mo-decimal-field
                    v-model="data.snbQty"
                    numeric
                    mask="number"
                    class="w90"
                    @change="fn_OnColumnChanged('dsList03', 'snbQty', idx3)"
                  />
                </td>
                <td>
                  <mo-decimal-field
                    v-model="data.snbFaceAmt"
                    numeric
                    mask="number"
                    class="w90"
                    @change="fn_OnColumnChanged('dsList03', 'snbFaceAmt', idx3)"
                  />
                </td>
                <td>
                  <mo-decimal-field
                    v-model="data.trstPric"
                    numeric
                    mask="number"
                    class="w120"
                    @change="fn_OnColumnChanged('dsList03', 'trstPric', idx3)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container
        v-if="visGrdList04"
        :disabled="disGrdList04"
        direction="column"
        alignV="start"
      >
        <div class="wrap-table-title row justify-between">
          <h2 class="table-title">부동산재산목록</h2>
          <div class="wrap-button right gap6" v-if="visInsertRow00">
            <mo-button class="btn-pop-plus" @click="insertRow00_onclick()">
            </mo-button>
            <mo-button class="btn-pop-minus" @click="delRow00_onclick()">
            </mo-button>
          </div>
        </div>
        <div class="wrap-table">
          <table class="table row-type">
            <thead>
              <tr>
                <th class="w40"></th>
                <th>구분</th>
                <th>소재지</th>
                <th>지번</th>
                <th>지목</th>
                <th>현황</th>
                <th>연면적(㎡)</th>
                <th>신탁대상면적(㎡)</th>
                <th>신탁가액</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(data, idx4) in dsList04"
                :key="idx4"
                :class="{ checked: lv_SelectedItem04 == idx4.toString() }"
                @click="fn_selectedDsList04(idx4)"
              >
                <td>
                  <mo-radio
                    v-model="lv_SelectedItem04"
                    :value="idx4.toString()"
                    :disabled="disGrdList04"
                  ></mo-radio>
                </td>
                <td class="aL">
                  <mo-dropdown
                    :items="dsCmb5031"
                    v-model="data.realEsttTc"
                    class="w100"
                  />
                </td>
                <td>
                  <mo-text-field v-model="data.realEsttLoct" class="w130" />
                </td>
                <td><mo-text-field v-model="data.plmk" class="w40" /></td>
                <td><mo-text-field v-model="data.lcat" class="w40" /></td>
                <td><mo-text-field v-model="data.pnstaCntn" class="w140" /></td>
                <td>
                  <mo-decimal-field
                    v-model="data.landLdar"
                    numeric
                    mask="number"
                    class="w70"
                  />
                </td>
                <td>
                  <mo-decimal-field
                    v-model="data.flrByLdar"
                    numeric
                    mask="number"
                    class="w70"
                  />
                </td>
                <td>
                  <mo-decimal-field
                    v-model="data.trstPric"
                    numeric
                    mask="number"
                    class="w120"
                    @change="fn_OnColumnChanged('dsList04', 'trstPric', idx4)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container
        v-if="visGrdList05"
        :disabled="disGrdList05"
        direction="column"
        alignV="start"
      >
        <div class="wrap-table-title row justify-between">
          <h2 class="table-title">금전채권신탁</h2>
          <div class="wrap-button right gap6" v-if="visInsertRow00">
            <mo-button class="btn-pop-plus" @click="insertRow00_onclick()">
            </mo-button>
            <mo-button class="btn-pop-minus" @click="delRow00_onclick()">
            </mo-button>
          </div>
        </div>
        <div class="wrap-table">
          <table class="table row-type">
            <thead>
              <tr>
                <th class="w40"></th>
                <th>구분</th>
                <th>채권종류</th>
                <th>채무자명</th>
                <th>채권액면금액</th>
                <th>신탁가액</th>
                <th>비고</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(data, idx5) in dsList05"
                :key="idx5"
                :class="{ checked: lv_SelectedItem05 == idx5.toString() }"
                @click="fn_RowSelected(idx5)"
              >
                <td>
                  <mo-radio
                    v-model="lv_SelectedItem05"
                    :value="idx5.toString()"
                    :disabled="disGrdList05"
                  ></mo-radio>
                </td>
                <td><mo-text-field v-model="data.bondKind" class="w150" /></td>
                <td><mo-text-field v-model="data.debtMnNm" class="w80" /></td>
                <td>
                  <mo-decimal-field
                    v-model="data.bondFaceAmt"
                    numeric
                    mask="number"
                    class="w120"
                  />
                </td>
                <td>
                  <mo-decimal-field
                    v-model="data.trstPric"
                    numeric
                    mask="number"
                    class="w120"
                    @change="fn_OnColumnChanged('dsList05', 'trstPric', idx5)"
                  />
                </td>
                <td><mo-text-field v-model="data.note" class="w140" /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <mo-button
            :disabled="btnCancel"
            @click="fn_Cancel()"
            point
            size="large"
          >
            취소
          </mo-button>
          <mo-button
            :disabled="btnDelete"
            @click="fn_Delete()"
            point
            size="large"
          >
            삭제
          </mo-button>
          <mo-button
            :disabled="btnUpdate"
            @click="fn_Update()"
            point
            size="large"
          >
            수정
          </mo-button>
          <mo-button
            :disabled="btnConfirm"
            @click="fn_Confirm()"
            primary
            size="large"
          >
            확인
          </mo-button>
        </div>
      </ur-box-container>
    </div>

    <ts-alert-popup
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></ts-alert-popup>

    <msp-ts-103p
      ref="popup103"
      :popup103Obj="pPopup103Obj"
      @ts-popup103-callback="fn_popupAfter"
    ></msp-ts-103p>
    <msp-ts-110p
      ref="popup110"
      :popup110Obj="pPopup110Obj"
      @ts-popup110-callback="fn_popupAfter"
    ></msp-ts-110p>
    <!-- <msp-ts-105p
    ref="popupDeputy"
    :popupObj="pPopupDeputyObj"
  ></msp-ts-105p> -->
    <msp-ts-106p
      ref="popup106"
      :popup106Obj="pPopup106Obj"
      @ts-popup106-callback="fn_popupAfter"
    ></msp-ts-106p>
    <msp-ts-106m
      ref="popup106m"
      :popupObj="pPopup106mObj"
      @ts-popup106m-callback="fn_popupAfter"
    ></msp-ts-106m>
    <msp-ts-107p
      ref="popup107"
      :popup107Obj="pPopup107Obj"
      @ts-popup107-callback="fn_popupAfter"
    ></msp-ts-107p>
    <msp-ts-322p
      ref="popup322p"
      :popupObj="pPopup322pObj"
      @ts-popup322p-callback="fn_popupAfter"
    ></msp-ts-322p>
    <msp-ts-108p ref="popup108" :popup108Obj="pPopup108Obj"></msp-ts-108p>
    <msp-ts-204p
      ref="popup204"
      :popup204Obj="pPopup204Obj"
      @ts-popup204-callback="fn_popupAfter"
    ></msp-ts-204p>
    <msp-ts-206p
      ref="popup206"
      :popup206Obj="pPopup206Obj"
      @ts-popup206-callback="fn_popupAfter"
    ></msp-ts-206p>
    <msp-ts-301p
      ref="popup301"
      :popup301Obj="pPopup301Obj"
      @ts-popup301-callback="fn_popupAfter"
    ></msp-ts-301p>
    <msp-ts-302p
      ref="popup302"
      :popup302Obj="pPopup302Obj"
      @ts-popup302-callback="fn_popupAfter"
    ></msp-ts-302p>
    <msp-ts-303p
      ref="popup303"
      :popup303Obj="pPopup303Obj"
      @ts-popup303-callback="fn_popupAfter"
    ></msp-ts-303p>
    <msp-ts-311p
      ref="popup311"
      :popup311Obj="pPopup311Obj"
      @ts-popup311-callback="fn_popupAfter"
    ></msp-ts-311p>
    <msp-ts-312p
      ref="popup312"
      :popup312Obj="pPopup312Obj"
      @ts-popup312-callback="fn_popupAfter"
    ></msp-ts-312p>
    <msp-ts-313p
      ref="popup313"
      :popup313Obj="pPopup313Obj"
      @ts-popup313-callback="fn_popupAfter"
    ></msp-ts-313p>
    <!-- <msp-ts-315p
    ref="popup315"
    :popup315Obj="pPopup315Obj"
    @ts-popup315-callback="fn_Popup315Back"
  ></msp-ts-315p> -->
    <msp-ts-316p
      ref="popup316"
      :popup316Obj="pPopup316Obj"
      @ts-popup316-callback="fn_popupAfter"
    ></msp-ts-316p>
    <msp-ts-319p
      ref="popup319"
      :popup319Obj="pPopup319Obj"
      @ts-popup319-callback="fn_popupAfter"
    ></msp-ts-319p>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                    *
 ***********************************************************************************/
import moment from "moment";
import TSAlertPopup from "~/src/ui/ts/comm/TSAlertPopup";
import TSHeader from "~/src/ui/ts/comm/TSHeader"; // header 영역 (공통)
import TSServiceUtil from "~/src/ui/ts/comm/TSServiceUtil";
import MSPTS110P from "@/ui/ts/MSPTS110P"; //상품검색
import MSPTS103P from "@/ui/ts/MSPTS103P"; //사원조회
// import MSPTS105P from '@/ui/ts/MSPTS105P' // 대리인
import MSPTS106P from "@/ui/ts/MSPTS106P"; //책임자승인요청
import MSPTS106M from "@/ui/ts/MSPTS106M"; //신탁계약 대리인/지급청구대리인 등록
import MSPTS107P from "@/ui/ts/MSPTS107P"; //고객조회
import MSPTS322P from "@/ui/ts/MSPTS322P"; // 조력자지정
import MSPTS108P from "@/ui/ts/MSPTS108P"; //신탁상품 정보조회
import MSPTS204P from "@/ui/ts/MSPTS204P"; //송금계좌등록/해지
import MSPTS206P from "@/ui/ts/MSPTS206P"; //계좌번호 조회
import MSPTS301P from "@/ui/ts/MSPTS301P"; //지급스케줄관리
import MSPTS302P from "@/ui/ts/MSPTS302P"; //계약관계자
import MSPTS303P from "@/ui/ts/MSPTS303P"; //AML
import MSPTS311P from "@/ui/ts/MSPTS311P"; //조력자등록대상
import MSPTS312P from "@/ui/ts/MSPTS312P"; //운용방법
import MSPTS313P from "@/ui/ts/MSPTS313P"; //종목정보검색
import MSPTS316P from "@/ui/ts/MSPTS316P"; //FC조회
import MSPTS319P from "@/ui/ts/MSPTS319P"; //계약승인알림
import TSCommUtil from "~/src/ui/ts/comm/TSCommUtil";
import TSComDate from "~/src/ui/ts/comm/TSComDate";
import TSInfoUtil from "~/src/ui/ts/comm/TSInfoUtil"; // Report 영역 (공통)
import DateUtil from "@/ui/sp/common/exDateUtils";

/************************************************************************************************
 * DataSet 영역
 ************************************************************************************************/
//only[0]{}(dsLoan,dsDetail_1:{})amlRstC,drvtInvtPosbYn,frBondGdYn,gdC1,gdNm1,invtRsRdo,issueDate,ivRskRatingC1
//only[0](dsDetail02)intMtrtYn,intPymYn
const dsDetail_b = {
  accOpngBrcd: "",
  accOpngOffrDate: "",
  accSeq: "",
  addmIamtPosbYn: "",
  agntCsId: "",
  agntCsIdNm: "",
  amlAccept: "",
  amlSeq: "",
  aocpRgfe: "",
  aocpRgfeCyclTc: "",
  aocpRgfeDd: "",
  aocpRgfeFamtTc: "",
  aocpRgfeYn: "",
  apC: "",
  aprvBusnTc1: "",
  aprvNo: "",
  aprvReqTc: "",
  aprvRsn: "",
  aprvStatTc: "",
  awilEtorCsId: "",
  awilEtorRcno: "",
  awilEtorRcnoNm: "",
  basTfrt: "",
  bffeBaseC: "",
  bkcd: "",
  blktAppnIndcYn: "",
  bnfcAporCsId: "",
  bnfcAporRcno: "",
  bnfcAporRcnoNm: "",
  bnfcDethDate: "",
  bnfcDethYn: "",
  bnkbSeq: "",
  brigAccYn: "",
  busyDate: "",
  careYn: "",
  chngBaseDate: "",
  chngSeq: "",
  cnclDate: "",
  comsTamt: "",
  contDate: "",
  contPrntNo: "",
  contStatTc: "",
  csId: "",
  csIvtdRatingC: "",
  csIvtdRatingCNm: "",
  ctno: "",
  curC: "KRW",
  dethAlmnCsId: "",
  dethAlmnRcno: "",
  dethAlmnRcnoNm: "",
  dignAftLvgctPymLmt: "",
  dmntDignYmd: "",
  dmntDignYn: "",
  domnNm: "",
  drvtGdClasC: "",
  emailAddr: "",
  entLmtAmt: "",
  epayCyclTc: "",
  epayFeeAmt: "",
  epayFxrtFamtTc: "",
  epayPoaoTc: "",
  epayTfrt: "0",
  feeCalcBaseTc: "",
  feeCpstAcno: "",
  fnlDsgnEmno: "",
  fnlDsgnEmnoNm: "",
  frBondCpTc: "",
  fssGdClasC: "",
  ftrmPymAmt: "",
  fundStactYn: "",
  fxppTc: "",
  fxppYn: "",
  gdC: "",
  gdNm: "",
  gdTypDtlC: "",
  genTaxtTc: "", //과세구분
  hpcTc: "", //ASR240300857 / [사랑on신탁]신규입력화면 해피콜서비스값 생성
  hpcYn: "",
  intPymAcno: "",
  intPymDdTc: "",
  intPymTc: "",
  invtMnBrcd: "",
  invtMnEmno: "",
  invtMnEmnoNm: "",
  invtRs: "",
  isueYn: "",
  ivRskRatingC: "",
  ivRskRatingNm: "",
  lastChngDt: "",
  lastChngMnTmno: "",
  lastChngMnUsid: "",
  lmtAmt: "",
  loanYn: "",
  mdtmEndDds01: "",
  mdtmEndDds02: "",
  mdtmEndDds03: "",
  mdtmFert01: "",
  mdtmFert02: "",
  mdtmFert03: "",
  mdtmPosbYn: "",
  mdtmStrtDds01: "",
  mdtmStrtDds02: "",
  mdtmStrtDds03: "",
  mngBrcd: "",
  mngrEmno: "",
  mngrEmnoNm: "",
  moContCoicYn: "",
  moContSeq: "",
  moGdC: "",
  moGdCNm: "",
  moGdCYn: "",
  mtrtAfTfrt: "",
  mtrtDate: "",
  mtrtPymYn: "",
  oldExclYn: "",
  opdtNtcTc: "",
  opdtNtcZone: "",
  optgYn: "",
  padNmts: "",
  padTc: "",
  poaoTc: "",
  prfe: "",
  prfeRt: "",
  procBrcd: "",
  procDate: "",
  procType: "",
  pymCyclTc: "",
  recYn: "",
  retuBnkStipSeq: "",
  retuCtrmCsId: "",
  retuCtrmRcno: "",
  retuCtrmRcnoGb: "",
  retuCtrmRcnoNm: "",
  rufeYn: "",
  saleEmno: "",
  saleEmnoNm: "",
  sconMtr: "",
  sectFeeCrtYn: "",
  signYn: "",
  soptTc: "",
  stopPymStrtYymm: "",
  sumTamt: "",
  tacno: "",
  taxPrimYn: "",
  teleVssTc: "",
  tmtlDate: "",
  tmtlNeedDds: "",
  trsrLawcAgntCsId: "",
  trsrLawcAgntRcno: "",
  trsrLawcAgntRcnoGb: "",
  trsrLawcAgntRcnoNm: "",
  trsrRelExpl01: "",
  trsrRelExpl02: "",
  trstAmt: "",
  trstMngpCsId1: "",
  trstMngpCsId2: "",
  trstMngpCsId3: "",
  trstMngpRcno1: "",
  trstMngpRcno1Gb: "",
  trstMngpRcno1Nm: "",
  trstMngpRcno2: "",
  trstMngpRcno2Gb: "",
  trstMngpRcno2Nm: "",
  trstMngpRcno3: "",
  trstMngpRcno3Gb: "",
  trstMngpRcno3Nm: "",
  trstOtxtAmt: "",
  trstOtxtGiveDate: "",
  trstPrptDnrCsId: "",
  trstPrptDnrRcno: "",
  trstPrptDnrRcnoGb: "",
  trstPrptDnrRcnoNm: "",
  trstTypC: "",
  vlntrGudCsId: "",
  vlntrGudRcno: "",
  vlntrGudRcnoGb: "",
  vlntrGudRcnoNm: "",
  vlntrGudRel: "",
  bnfTfrt: "",
  fxppBnkStipSeq: "",
  mngrBrcd: "",
  overPrrt: "",
  stipSeq: "",
  drvtInvtPosbYn: "", //except
  frBondGdYn: "",
  appnTrmUnitC: "",
  appnTrm: "",
  fcInvtPosbYn: "",
};
const dsList_b = {
  ctno: "",
};
//accSeq
const dsList01_b = {
  bnfcRatingTc: "",
  bnfcSeq: "",
  bnkbSeq: "",
  accSeq: "",
  ctno: "",
  ftrmPymCycl: "",
  ftrmPymDdTc: "",
  fxppEndDate: "",
  fxppMethC: "",
  fxppStrtDate: "",
  fxppYn: "",
  fxrtFamtTc: "",
  prftBnfcBnkSseq: "",
  prftBnfcBnkSseqNm: "",
  prftBnfcCnm: "",
  prftBnfcCsId: "",
  prftBnfcRcno: "",
  prftBnfcRcnoGb: "",
  prinBnfcBnkSseq: "",
  prinBnfcBnkSseqNm: "",
  prinBnfcCnm: "",
  prinBnfcCsId: "",
  prinBnfcRcno: "",
  prinBnfcRcnoGb: "",
  pymAmt: "",
  pymRto: "",
  tacno: "",
};
//recYn(dsList02_1)
const dsList02_b = {
  appnMethC: "",
  drtsOtxtLossRtTc: "",
  appnEndRto: "",
  appnStrtRto: "",
  opdtExpl: "",
  appnMethCNm: "",
  krItemC: "",
  krItemNm: "",
};
const dsList03_b = {
  astsType: "",
  bnkbSeq: "",
  accSeq: "",
  ctno: "",
  dnmn: "",
  itemNm: "",
  opratIndcNo: "",
  secusNo: "",
  snbFaceAmt: "",
  snbQty: "",
  tacno: "",
  trstPric: "",
};
//accSeq
const dsList04_b = {
  bnkbSeq: "",
  accSeq: "",
  ctno: "",
  flrByLdar: "",
  landLdar: "",
  lcat: "",
  opratIndcNo: "",
  plmk: "",
  pnstaCntn: "",
  realEsttLoct: "",
  realEsttTc: "",
  tacno: "",
  trstPric: "",
  trstTypC: "",
};
//accSeq
const dsList05_b = {
  astsType: "",
  bnkbSeq: "",
  accSeq: "",
  bondFaceAmt: "",
  bondKind: "",
  ctno: "",
  debtMnNm: "",
  eaiNote: "",
  lastChngDt: "",
  lastChngMnTmno: "",
  lastChngMnUsid: "",
  note: "",
  opratIndcNo: "",
  tacno: "",
  trstPric: "",
  trstTypC: "",
};
//accSeq
const dsList06_b = {
  bnfTfrt: "",
  bnkbSeq: "",
  accSeq: "",
  ctno: "",
  lastChngDt: "",
  lastChngMnTmno: "",
  lastChngMnUsid: "",
  overPrrt: "",
  sectSeq: "",
  tacno: "",
};
const dsList07_b = {
  slipSeq: "",
  procDate: "",
};
//(dsListIsaAmt{})
const dsListIsa_b = {
  accSeq: "",
  bnkbSeq: "",
  incomeChk: "",
  isaLmtAmt: "",
  tacno: "",
  taxPrimeAmt: "",
  txsmGdTc: "",
  trAmt: "",
};
const dsList08_b = {
  bnkbSeq: "",
  bnkbStatC: "",
  contCnt: "",
  psmtRcvTc: "",
  tacno: "",
  tacnoBnkbSeq: "",
};
const dsList09_b = {
  chngSeq: "",
};
//{}
const dsList00_b = {
  age: "",
  cnm: "",
  cno: "",
  cono: "",
  corpYn: "",
  csId: "",
  csIvtdRatingC: "",
  genSpivrTc: "",
  genTaxtTc: "",
  persnBusiMnBsno: "",
  procDt: "",
  qtnSeq: "",
  ques10: "",
  rcno: "",
  rcnoKindTc: "",
  regDate: "",
  tacno: "",
  tacnoBnkbSeq: "",
  tassIssncAccYn: "",
  valdTrmYys: "",
  valdYn: "",
};
//(dsList11,dsList12)
const dsList10_b = {
  bnkAccNm: "",
  bnkAcno: "",
  regDate: "",
  stipSeq: "",
  trBkcd: "",
  trBkcdNm: "",
  trBkcdNmBnkAcno: "",
  csId: "",
};
//{}
const dsDetailFatca_b = {
  clctnDate: "",
  clctnMedTyp: "",
  clctnSeq: "",
  corpYn: "",
  csId: "",
  lastChngDt: "",
  lastChngMnTmno: "",
  lastChngMnUsid: "",
  sbmtYn: "",
  trgpTc: "",
};
//tfcpfiLst, tfarfiLst(tacno,bnkbSeq,ctno,accSeq)
const dsList06_1_b = {
  sectSeq: "",
  lastChngDt: "",
  lastChngMnTmno: "",
  lastChngMnUsid: "",
  bnfTfrt: "",
  overPrrt: "",
};
//ctno
const dsList01_1_b = {
  tacno: "",
  bnkbSeq: "",
  accSeq: "",
  bnfcSeq: "",
  bnfcRatingTc: "",
  fxrtFamtTc: "",
  fxppYn: "",
  fxppMethC: "",
  fxppStrtDate: "",
  fxppEndDate: "",
  ftrmPymCycl: "",
  ftrmPymDdTc: "",
  pymRto: "",
  pymAmt: "",
  prinBnfcCsId: "",
  prinBnfcRcno: "",
  prinBnfcRcnoGb: "",
  prinBnfcCnm: "",
  prinBnfcBnkSseq: "",
  prinBnfcBnkSseqNm: "",
  prftBnfcCsId: "",
  prftBnfcRcno: "",
  prftBnfcRcnoGb: "",
  prftBnfcCnm: "",
  prftBnfcBnkSseq: "",
  prftBnfcBnkSseqNm: "",
};
//ctno
const dsList03_1_b = {
  tacno: "",
  bnkbSeq: "",
  accSeq: "",
  opratIndcNo: "",
  astsType: "",
  secusNo: "",
  dnmn: "",
  snbQty: "",
  snbFaceAmt: "",
  trstPric: "",
  itemNm: "",
};
//ctno,trstTypC
const dsList04_1_b = {
  tacno: "",
  bnkbSeq: "",
  accSeq: "",
  opratIndcNo: "",
  realEsttTc: "",
  realEsttLoct: "",
  plmk: "",
  lcat: "",
  pnstaCntn: "",
  landLdar: "",
  flrByLdar: "",
  trstPric: "",
};
//ctno,trstTypC
const dsList05_1_b = {
  tacno: "",
  bnkbSeq: "",
  accSeq: "",
  opratIndcNo: "",
  lastChngDt: "",
  lastChngMnTmno: "",
  lastChngMnUsid: "",
  astsType: "",
  bondKind: "",
  debtMnNm: "",
  bondFaceAmt: "",
  trstPric: "",
  note: "",
  eaiNote: "",
};
//plzaNm
const dsList14_b = {
  fcEmno: "",
  fcNm: "",
  rcno: "",
  etpsDpcd: "",
  invPosYn: "",
};
const dsList15_b = {
  frBondCpCd: "",
  frBondCpNm: "",
};
const dsList20_b = {
  refNo: "",
  csId: "",
  amlRstC: "",
  tacno: "",
  bnkbSeq: "",
  ctno: "",
  procSeq: "",
  amlPrcsPfrmType: "",
};
const dsDetail03_b = {
  ctnoCnt: "",
  bnkbSeq: "", // 추가 - ASR240101094 / 무통장(999) 최대 계약 계좌수 확대
};
const dsTfmdli_b = {
  usid: "",
  userNm: "",
  brcd: "",
  brcdNm: "",
  lastChngMnUsid: "",
  lastChngDt: "",
  regYn: "",
  regDate: "",
  fctStopDate: "",
  feeEdDate: "",
  ersrDate: "",
  ersrRsnC: "",
  ersrRsnCNm: "",
};
const dsCori_b = {
  ctno: "",
  coriYn: "",
};

//window 객체
var thisWin = null;

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPTS200M",
  screenId: "MSPTS200M",
  props: ["isShow", "isEnabled"],
  components: {
    "ts-header": TSHeader,
    "ts-alert-popup": TSAlertPopup,
    "msp-ts-110p": MSPTS110P,
    "msp-ts-103p": MSPTS103P,
    // 'msp-ts-105p': MSPTS105P,
    "msp-ts-106p": MSPTS106P,
    "msp-ts-106m": MSPTS106M,
    "msp-ts-107p": MSPTS107P,
    "msp-ts-322p": MSPTS322P,
    "msp-ts-108p": MSPTS108P,
    "msp-ts-204p": MSPTS204P,
    "msp-ts-206p": MSPTS206P,
    "msp-ts-301p": MSPTS301P,
    "msp-ts-302p": MSPTS302P,
    "msp-ts-303p": MSPTS303P,
    "msp-ts-311p": MSPTS311P,
    "msp-ts-312p": MSPTS312P,
    "msp-ts-313p": MSPTS313P,
    "msp-ts-316p": MSPTS316P,
    "msp-ts-319p": MSPTS319P,
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      sSvcID: "",
      sPopupId: "",
      emnoPopupYn: "N",
      sSvcIDSub: "",
      pHeaderObj: {
        title: "계약 신규/추가입금/변경",
      },
      //pop
      pAlertPopupObj: {},
      pPopup110Obj: {},
      pPopup103Obj: {},
      //pPopupDeputyObj: {},  // 대리인
      pPopup106Obj: {},
      pPopup106mObj: {},
      pPopup107Obj: {},
      pPopup322pObj: {},
      pPopup108Obj: {},
      pPopup204Obj: {},
      pPopup206Obj: {},
      pPopup301Obj: {},
      pPopup302Obj: {},
      pPopup303Obj: {},
      pPopup311Obj: {},
      pPopup312Obj: {},
      pPopup313Obj: {},
      //pPopup315Obj: {},
      pPopup316Obj: {},
      pPopup319Obj: {},
      //pop end.
      //search
      radProcessGbItems: [],
      _P_PARAMS: null,
      radProcessGb: "1",
      tacno: "",
      vwDivAcno: true,
      vwDivAccno: false,
      edtAcno1: "",
      edtCnm1: "",
      cboAcno: "",
      cboCtno: "",
      edtAcno2: "",
      edtCnm2: "",
      cboAccSeq: "",
      cboChngSeq: "",

      btnSearchList: false,
      btnInit: false,
      btnUpdate: true,
      btnDelete: true,
      btnConfirm: true,
      btnCancel: true,
      dsListS2Seq: [], //계좌일련번호
      dsList08Acno: [], //통장번호
      dsListS1: {}, //search 고객정보
      dsListS1Base: {}, //search 고객정보
      dsListS2: [], //search 계좌정보
      //search end.
      //button
      btnAml: true,
      //button end.
      // combo
      dsCmb5056: [], //일반과세구분코드(none,genTaxtTc)
      dsCmb5017: [], //선후취구분코드
      dsCmb5025: [], //운용내역통보구분코드
      dsCmb5101: [], //가능여부
      dsCmb5021: [], //수익보수기준코드
      dsCmb5026: [], //자타익구분코드
      dsCmb5020: [], //보수계산기준구분코드
      dsCmb5023: [], //승인상태구분코드
      dsCmb5030: [], //자산구분코드
      dsCmb5031: [], //부동산구분코드
      dsCmb5024: [], //운용방법코드
      dsCmb5014: [], //신탁유형코드
      dsCmb5085: [], //승인업무유형코드
      dsCmb5073: [], //납입구분코드
      dsCmb5029: [], //정기지급구분코드
      dsCmb5035: [], //지급주기구분코드
      dsCmb5071: [], //투자위험등급코드
      dsCmb5072: [], //고객투자성향등급코드
      dsCmb5010: [], //상품유형상세코드
      dsCmb5011: [], //유가증권신탁유형코드
      dsCmb5012: [], //부동산신탁유형코드
      dsCmb5013: [], //금전채권신탁유형코드
      dsCmb5018: [], //별납선후취구분코드
      dsCmb5019: [], //후취정기보수정액구분코드
      dsCmb5016: [], //이자지급구분코드
      //      dsCmb5052: [],  //유선내방구분
      dsCmb5055: [], //지급일구분코드
      dsCmb5139: [], //종합재산신탁유형코드
      dsCmb5100: [], //_후취정기보수주기구분코드
      dsCmb5099: [], //별납주기구분코드
      dsCmb5186: [], //고령기준연령
      dsCmb5187: [], //초고령기준연령
      dsCmb5188: [], //상품위험등급코드
      dsCmb5059: [], //치매신탁 상품유형코드
      dsCmb5173: [], //유언대용신탁(금전)상품유형코드
      dsCmb5174: [], //유언대용신탁(유가증권)상품유형코드
      dsCmb5175: [], //유언대용신탁(부동산)상품유형코드
      oldAge: "",
      superAge: "",
      // combo end.
      //dataset
      dsDetail: [], //[0], selectTFAC20000B->{}
      dsList: [],
      dsList01: [],
      dsList02: [],
      dsList03: [],
      dsList04: [],
      dsList05: [],
      dsList06: [],
      dsList07: [],
      dsListIsa: [],
      dsList08: [],
      dsList09: [],
      dsDetail02: [], //[0]
      dsList00: {},
      dsList10: [],
      dsDetailFatca: {},
      dsList14: [],
      dsList15: [],
      dsList20: [],
      dsDetail03: [],//객체 형식 변경 - ASR240101094 / 무통장(999) 최대 계약 계좌수 확대
      dsTfmdli: {},
      dsCori: [],
      dsLoan: {}, //[0]:dsDetail, [1]:dsLoan(only loanYn)
      dsListIsaAmt: {},
      dsList11: [], //원지급금계좌
      dsList12: [], //수익급금계좌
      //dataset end.

      //visible
      visBtnAdmission: false,
      visFrBondCpTc: false, //해외채권 쿠폰처리
      visMoCtno: false, //모상품번호
      visAocpRgfeYn: true, //정기보수
      visComsTamt: false, //선취수수료
      visPrfe: false, //선취수수료
      visPrfeRt: false, //선취수수료율
      visEpayPoaoTc: false, //별납보수구분
      visAddValue: false, //부가세
      visEpayTfrt: false, //별납보수률
      visEpayCyclTc: false, //주기
      visEpayFxrtFamtTc: false, //정률정액구분
      visAocpRgfeFamtTc: false, //정액정률(후취)
      visEpayFeeAmt: false, //금액
      visAgntCsId: true, //대리인
      visBnfcDethYn: false, //사망
      visTrBkcdNmBnkAcno: false, //부동산환급계좌
      visHpcTc: false, //해피콜구분
      visAocpRgfeCyclTc: true, //기본수수료율
      visAocpRgfe: true, //후취정기보수
      visIsueYn: false, //발행여부
      visDivAcno: true, //seach 1
      visDivAccno: false, //seach 2
      visStatic92: true, //수익보수율
      visGrdList02: true, //grid
      visGrdList03: false, //grid
      visGrdList04: false, //grid
      visGrdList05: false, //grid
      visInsertRow: false,
      visInsertRow00: false,

      visReprInfo: true, //대리인
      visBtnGoodsInfo: true, //신탁상품조회
      //visible end.
      //disabled
      disIsueYn: true, //발행여부
      disHpcTc: true, //해피콜구분
      disGdTypDtlC: true, //상품유형
      disGdC: true, //신탁상품
      disTrstAmt: true, //신탁금액
      disMtrtDate: true, //만기일
      disMtrtAfTfrt: true, //만기보수율
      disTmtlNeedDds: true, //해지소요일수
      disOpdtNtcTc: true, //운영내역통보
      disSoptTc: true, //자탁익구분
      btnCustInfo00: true, //원금수익자
      disTrBkcdNmbnkAcno00: true, //원금지급계좌
      disTrBkcdNmbnkAcno01: true, //이익지급계좌
      disTrstTypC: true, //신탁유형
      disGdNm: true, //상품명
      btnCustInfo01: true, //이익수익자
      disAprvBusnTc1: true, //승인상태
      disAprvStatTc: true, //승인상태
      disivRskRatingC: true, //상품등급
      disCsIvtdRatingC: true, //고객성향
      disPoaoTc: true, //보수구분
      disBasTfrt: true, //(후취)기본보수율
      disInvtMnEmno: true, //권유자
      disSaleEmno: true, //판매직원
      disMngrEmno: true, //관리자
      disFnlDsgnEmno: true, //권유대행FC
      btnCustInfoReg: false, //스케쥴관리
      disRelated: false, //계약 관계자
      disOtxtBnfcNm: true, //원금수익자명
      disPrftBnfcNm: true, //이익수익자명
      disPrfeRt: true, //선취수수료율
      disPrfe: true, //선취수수료
      disAocpRgfe: true, //후취정기보수
      disEpayPoaoTc: true, //별납보수구분
      disEpayCyclTc: true, //주기
      disAocpRgfeYn: true, //정기보수여부
      disAocpRgfeCyclTc: true, //후취정기보수주기구분코드
      disEpayFxrtFamtTc: true, //정률정액구분
      disAocpRgfeFamtTc: true, //후취정률정액구분
      disEpayTfrt: true, //별납보수률
      disEpayFeeAmt: true, //정액정률금액
      disInvtMnEmnoNm: true, //권유자명
      disSaleEmnoNm: true, //판매직원명
      disMngrEmnoNm: true, //관리자명
      disFnlDsgnEmnoNm: true, //판매FC명
      disAgntCsId: true, //대리인
      disAgntCsIdNm: true, //대리인명
      disBnfcDethYn: true, //사망여부
      disBnfcDethDate: true, //사망일자
      disTrBkcdNmbnkAcno: true, //송금계좌(환급계좌)
      disBlktAppnIndcYn: true, //포괄운용지시
      disFrBondCpTc: true, //해외채권 쿠폰처리여부
      disGrdList02: true,
      disGrdList03: true,
      disGrdList04: true,
      disGrdList05: true,

      disReprInfo: true, //대리인 등록버튼
      //disabled end.
      //title
      trstAmtNm: "신탁금액", //신탁금액, 추가금액(static49)
      //title end.
      //detail
      trstTypC: "1",
      soptTc: "",
      gdTypDtlC: "",
      gdC: "",
      gdNm: "",
      trstAmt: "",
      aprvBusnTc1: "",
      aprvStatTc: "",
      ivRskRatingC: "",
      csIvtdRatingC: "",
      mtrtDate: "",
      mtrtAfTfrt: "",
      poaoTc: "",
      rufeYn: "",
      tmtlNeedDds: "",
      aocpRgfeCyclTc: "",
      basTfrt: "",
      opdtNtcTc: "",
      isueYn: "",
      aocpRgfeYn: "",
      frBondCpTc: "",
      comsTamt: "",
      prfe: "",
      aocpRgfe: "",
      prfeRt: "",
      epayPoaoTc: "",
      addValue: "",
      epayTfrt: "0",
      epayCyclTc: "",
      epayFxrtFamtTc: "",
      aocpRgfeFamtTc: "",
      epayFeeAmt: "",
      agntCsId: "",
      agntCsIdNm: "",
      bnfcDethYn: "",
      bnfcDethDate: "",
      invtMnEmno: "",
      invtMnEmnoNm: "",
      saleEmno: "",
      saleEmnoNm: "",
      mngrEmno: "",
      mngrEmnoNm: "",
      fnlDsgnEmno: "",
      fnlDsgnEmnoNm: "",
      trBkcdNmBnkAcno: "",
      moCtno: "", //모상품번호(add detail)
      otxtBnfcRcno: "", //원금수익자(add detail)
      otxtBnfcNm: "", //원금수익자명(add detail)
      trBkcdNmbnkAcno00: "", //원금지급계좌(add detail)
      prftBnfcRcno: "", //이익수익자(add detail)
      prftBnfcNm: "", //이익수익자명(add detail)
      trBkcdNmbnkAcno01: "", //이익지급계좌(add detail)
      hpcTc: "", //(add detail) //ASR240300857 / [사랑on신탁]신규입력화면 해피콜서비스값 생성
      //detail end.
      //temp
      timerId: null, //timer
      preGdC: "",
      busyDate: "", //현영업일
      procDt: "", //설문등록일
      valdYn: "", //설문유효여부
      nextCnto: "", //계약번호(신규채번)
      coriYn: "N", //치매신탁 지급청구대리인 검증 여부
      amlCtno: "",
      fxppYn: "", // 정기지급 여부
      fxppStrtDate: "", //정기지급개시년월
      fxppEndDate: "", //정기지급개시년월
      fxppTc: "", //방법
      intPymDdTc: "", //일자
      pymCyclTc: "", //주기
      ftrmPymAmt: "", //금액
      teleVssTc: "O", //유선내방구분  : 1.방문 2.내방 O.태블릿방문 T.태블릿내방
      opdtNtcTc: "2", // 운영내역통보 : 2 미통보
      static83: "", //적합/등급 확인
      parseng: false, //파생여부
      frBondCp: "", //해외채권 쿠폰처리(사용없음)
      blktAppnIndcYn: "", //포괄운용지시
      cmbGdTypDtlC: [], //상품유형
      //grid checked
      lv_SelectedItem02: "",
      lv_SelectedItem03: "",
      lv_SelectedItem04: "",
      lv_SelectedItem05: "",
      // 로그인정보
      lv_basInfo: {},
      formKey: [
        "trstTypC",
        "soptTc",
        "gdTypDtlC",
        "gdC",
        "gdNm",
        "trstAmt",
        "aprvBusnTc1",
        "aprvStatTc",
        "ivRskRatingC",
        "csIvtdRatingC",
        "mtrtDate",
        "mtrtAfTfrt",
        "poaoTc",
        "rufeYn",
        "tmtlNeedDds",
        "aocpRgfeCyclTc",
        "basTfrt",
        "opdtNtcTc",
        "isueYn",
        "aocpRgfeYn",
        "frBondCpTc",
        "comsTamt",
        "prfe",
        "prfeRt",
        "aocpRgfe",
        "epayPoaoTc",
        "addValue",
        "epayTfrt",
        "epayCyclTc",
        "epayFxrtFamtTc",
        "aocpRgfeFamtTc",
        "epayFeeAmt",
        "agntCsId",
        "agntCsIdNm",
        "bnfcDethYn",
        "bnfcDethDate",
        "invtMnEmno",
        "invtMnEmnoNm",
        "saleEmno",
        "saleEmnoNm",
        "mngrEmno",
        "mngrEmnoNm",
        "fnlDsgnEmno",
        "fnlDsgnEmnoNm",
        "trBkcdNmBnkAcno",
      ],
      //temp end.
      eaiCommObj: TSCommUtil.gfn_eaiCommObj(),

      ds_searchReportList: [], // 보고서 출력조회
      ds_resultReportList: [], // 보고서 출력조회
      ds_searchReport: {}, // 보고서 출력조회

      routeId: "", //다음화면 route screenId
      routeParams: {},

      oldAgeConfirm: false,
      dsList00Svd: {}, //dsList00 정보 저장용
      tacnoBase: "", //진행중인 tacno
      popAccSeq: "", //계좌조회 popup accSeq temp
    };
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    thisWin = this; //set window 객체
    this.fn_ComCode();

    this.lv_basInfo = _.cloneDeep(
      window.vue.getStore("tsStore").getters.getBasInfo.data
    );
    if (
      this.lv_basInfo == undefined ||
      this.lv_basInfo == null ||
      this.lv_basInfo.brcd == undefined ||
      this.lv_basInfo.brcd == null ||
      this.lv_basInfo.brcd == ""
    ) {
      this.fn_alert("로그인 후 작업하세요.");
      this.$router.push({ name: "MSPBC002M" });
    }

    this.busyDate = this.lv_basInfo.busyDate;
  },
  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount() {}, //beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted() {
    // thisWin.$GnbManager.fn_SetGnbShow(false);
    if (!_.isEmpty(this.$route.params)) {
      //this._P_PARAMS = this.$route.params;
    }
    // if(!_.isEmpty(this._P_PARAMS)){
    //   this.radProcessGb = this._P_PARAMS.processGb;
    //   this.Div02_rad_processGb_onitemchanged();

    //   if ( this.radProcessGb == '1' ) {
    //     this.edtAcno1 = this._P_PARAMS.tacno;
    //   }else{
    //     this.edtAcno2 = this._P_PARAMS.tacno + this._P_PARAMS.bnkbSeq;
    //     this.cboAccSeq = this._P_PARAMS.accSeq;
    //     this._P_PARAMS = null ;
    //   }
    // }else{
    //   this.Div02_rad_processGb_onitemchanged();
    // }
    this.$bizUtil.insSrnLog("MSPTS200M");
    this.$nextTick(() => {
      //menu 선택으로 신규/추가입금,변경이 구분되도록 함.
      //processGb : 1.신규/2.추가입금,3.변경
      //tacno : 영업/개인고객으로 부터 시작됨.
      let processGb = thisWin.fn_getValue(thisWin.$route.params.processGb);
      let tacno = thisWin.fn_getValue(thisWin.$route.params.tacno);
      let bnkbSeq = thisWin.fn_getValue(thisWin.$route.params.bnkbSeq);
      let accSeq = thisWin.fn_getValue(thisWin.$route.params.accSeq);
      if (processGb == "") {
        if (tacno != "") {
          thisWin.radProcessGbItems = [];
          thisWin.radProcessGbItems.push({ value: "1", text: "신규" });
          thisWin.radProcessGb = "1";
          thisWin.getStore("tsStore").getters.getState.isProcess = true;

          let tacno = thisWin.$route.params.tacno;
          thisWin.edtAcno1 = tacno;
          // 자동조회
          this.edtAcno1_onkeyup();
        } else {
          thisWin.radProcessGbItems = [];
          thisWin.radProcessGbItems.push({ value: "2", text: "추가입금" });
          thisWin.radProcessGbItems.push({ value: "3", text: "변경" });
          thisWin.radProcessGb = "2";
          thisWin.getStore("tsStore").getters.getState.isProcess = true;
        }
      } else {
        if (processGb == "1") {
          thisWin.radProcessGbItems = [];
          thisWin.radProcessGbItems.push({ value: "1", text: "신규" });
          thisWin.radProcessGb = "1";
          thisWin.getStore("tsStore").getters.getState.isProcess = true;
          if (tacno != "") {
            thisWin.edtAcno1 = tacno;
            // 자동조회
            this.edtAcno1_onkeyup();
          }
        } else {
          thisWin.radProcessGbItems = [];
          thisWin.radProcessGbItems.push({ value: "2", text: "추가입금" });
          thisWin.radProcessGbItems.push({ value: "3", text: "변경" });
          thisWin.radProcessGb = processGb;
          if (processGb == "2") {
            thisWin.getStore("tsStore").getters.getState.isProcess = true;
          } else {
            thisWin.getStore("tsStore").getters.getState.isProcess = false;
          }
          if (tacno != "" && bnkbSeq != "" && accSeq != "") {
            thisWin.cboAccSeq = accSeq;
          }
          if (tacno != "" && bnkbSeq != "") {
            thisWin.edtAcno2 = tacno + bnkbSeq;
            thisWin.edtAcno2_onkeyup();
          }
        }
      }
      if (thisWin.radProcessGb != "1") {
        //추가입금 / 변경신청
        thisWin.visReprInfo = false;
        thisWin.visBtnGoodsInfo = false;
      }
      let elH1 = document.querySelector("#hd200M h1");
      if (elH1 != null) {
        if (thisWin.radProcessGb == "1") {
          elH1.innerText = "계약 신규신청";
        } else {
          elH1.innerText = "추가입금 / 변경신청";
        }
      }
      thisWin.fn_RadProcess();
    });
  }, //mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate() {}, //beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated() {}, //updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy() {
    this.fn_clearTimerApprvState();
  }, //beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed() {
    //this.fn_clearTimerApprvState();
  }, //destroyed

  watch: {}, //watch

  computed: {
    //radProcessGbItems() { //업무구분
    //  let rtn = []
    //  rtn.push({value: '1', text: '신규'})
    //  rtn.push({value: '2', text: '추가입금'})
    //  rtn.push({value: '3', text: '변경'})
    //  return rtn
    //},
    hpcTcItems() {
      //해피콜
      let rtn = [];
      rtn.push({ value: "1", text: "온라인" });
      rtn.push({ value: "2", text: "유선" });
      rtn.push({ value: "3", text: "거부" });//ASR240300857 / [사랑on신탁]신규입력화면 해피콜서비스값 생성
      return rtn;
    },
  },

  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_getValue(val) {
      if (val == undefined || val == null) return "";
      return val;
    },
    fn_getNumber(val, defaultVal) {
      //mode:return value (not null).return defaultVal, (0 or null).return 0
      if (defaultVal == undefined || defaultVal == null) defaultVal = 0;
      if (val == undefined || val == null || val == "") return defaultVal;
      return Number(val);
    },
    fn_getComma(n) {
      if (n == undefined || n == null) return n;
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    fn_alert(msg, fn_yes, fn_cls, real) {
      //msg:message, fn_yes:confirm function, fn_cls:close or no confirm function
      if (real == undefined || real == null) real = "";
      let t_popupObj = {
        confirm: false,
        content: msg,
      };
      if (real != "") {
        t_popupObj["realCheck"] = true;
        t_popupObj["confirm"] = true;
        return this.$refs.alertPopup.fn_OpenA(t_popupObj);
      }
      let lRtn = fn_yes == undefined || fn_yes == null ? 0 : 1;
      let lEnd = fn_cls == undefined || fn_cls == null ? 0 : 1;
      if (lRtn == 0 && lEnd == 0) {
        this.pAlertPopupObj = t_popupObj;
        this.$refs.alertPopup.fn_OpenA(t_popupObj);
        return;
      }
      if (lRtn == 1) {
        t_popupObj["confirm"] = true;
        t_popupObj["confirmFunc"] = fn_yes;
      }
      if (lEnd == 1) t_popupObj["closeFunc"] = fn_cls;
      this.pAlertPopupObj = t_popupObj;
      this.$refs.alertPopup.fn_OpenA(t_popupObj);
    },
    async fn_wait() {
      //thisWin.fn_alert("Message...",null,null,true);
      //let rtn = await this.fn_wait(); => rtn=Y.Yes N.No
      let self = this;
      const wait = (timeToDelay) =>
        new Promise((resolve) => setTimeout(resolve, timeToDelay));
      let closeType = "";
      while (true) {
        closeType = self.$refs.alertPopup.$data["closeType"];
        if (closeType != "") {
          break;
        }
        await wait(200);
      }
      return closeType;
    },
    fn_copyKeyArrMap(arr, frMap, toMap) {
      if (_.isEmpty(toMap)) toMap = {};
      if (_.isEmpty(arr) || arr.length < 1) return {};
      for (let i = 0; i < arr.length; i++) {
        toMap[arr[i]] =
          frMap[arr[i]] == undefined || frMap[arr[i]] == null
            ? ""
            : frMap[arr[i]];
      }
      return toMap;
    },
    // map to form
    fn_copyDataArrMap(arr, frMap) {
      if (_.isEmpty(frMap)) return;
      if (_.isEmpty(arr) || arr.length < 1) return;
      for (let i = 0; i < arr.length; i++) {
        let keycp = arr[i];
        let valcp =
          frMap[keycp] == undefined || frMap[keycp] == null ? "" : frMap[keycp];
        thisWin.$data[keycp] = valcp;
      }
    },
    fn_setCalendar(id, ymd) {
      try {
        this.$refs[id].$data.from["textInput"] = ymd;
        this.$refs[id].$data.from["selected"] = ymd;
        this.$refs[id].onUpdate();
      } catch (e) {}
    },
    addDateYmd(baseDate, days) {
      var d1 = new Date();
      var year = "";
      var month = "";
      var day = "";
      if (baseDate == "") {
        return baseDate;
      }
      if (baseDate.length == 6) {
        year = baseDate.substr(0, 4);
        month = baseDate.substr(4, 2);
      }
      if (baseDate.length == 8) {
        year = baseDate.substr(0, 4);
        month = baseDate.substr(4, 2);
        day = baseDate.substr(6, 2);
      }

      d1.setFullYear(Number(year), Number(month) - 1, Number(day));
      var date = d1.getDate();
      d1.setDate(date + days);
      var v1 = d1.getFullYear();
      var sv1 = d1.getMonth() + 1;
      if (Number(sv1) < 10) sv1 = "0" + sv1;
      v1 += "" + sv1;
      sv1 = d1.getDate();
      if (Number(sv1) < 10) sv1 = "0" + sv1;
      v1 += "" + sv1;
      return v1;
    },
    /*********************************************************
     * 설명: get 계좌 조회조건 정보
     *********************************************************/
    fn_getAcnoCondInfo() {
      let edtAcno1 = thisWin.edtAcno1;
      let cboAcno = thisWin.cboAcno.replaceAll("-", "");
      let cboCtno = thisWin.cboCtno;

      let edtAcno2 = thisWin.edtAcno2;
      let cboAccSeq = thisWin.cboAccSeq;
      let cboChngSeq = thisWin.cboChngSeq;

      let acnoInfo = {};
      if (cboAccSeq == null || cboAccSeq == "") {
        acnoInfo["tacno"] = edtAcno1;
        acnoInfo["bnkbSeq"] = cboAcno.substr(7, 3);
        acnoInfo["accSeq"] = "";
        acnoInfo["ctno"] = cboCtno;
        acnoInfo["chngSeq"] = "";
      } else {
        acnoInfo["tacno"] = edtAcno2.substr(0, 7);
        acnoInfo["bnkbSeq"] = edtAcno2.substr(7, 3);
        acnoInfo["accSeq"] = cboAccSeq;
        acnoInfo["procType"] =
          thisWin.fn_getNumber(thisWin.radProcessGb) - 1 + "";
        acnoInfo["ctno"] = "";
        acnoInfo["chngSeq"] = cboChngSeq;
      }
      return acnoInfo;
    },
    /*********************************************************
     * 설명: get user name
     *********************************************************/
    fn_getUserName(usid, userNm) {
      let pParam = {
        usid: usid, // 사원번호
      };
      // let res = await TSServiceUtil.gfn_getUrlData(this, "F10600268", "S", pParam);
      // if (res && res.data) {
      //   let uLst = _.cloneDeep(res.data.tuuser);
      //   if (uLst != null && uLst.length > 0){
      //     if (userNm == "invtMnEmnoNm"){
      //       thisWin.invtMnEmnoNm = uLst[0]["userNm"];
      //     }
      //     if (userNm == "mngrEmnoNm"){
      //       thisWin.mngrEmnoNm = uLst[0]["userNm"];
      //     }
      //     if (userNm == "saleEmnoNm"){
      //       thisWin.saleEmnoNm = uLst[0]["userNm"];
      //     }
      //   }
      // }

      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600268_S";
      this.eaiCommObj.params = pParam;
      TSServiceUtil.invokeInDirect(
        this.eaiCommObj,
        function(res) {
          if (res && res.data) {
            let uLst = _.cloneDeep(res.data.tuuser);
            if (uLst != null && uLst.length > 0) {
              if (userNm == "invtMnEmnoNm") {
                thisWin.invtMnEmnoNm = uLst[0]["userNm"];
              }
              if (userNm == "mngrEmnoNm") {
                thisWin.mngrEmnoNm = uLst[0]["userNm"];
              }
              if (userNm == "saleEmnoNm") {
                thisWin.saleEmnoNm = uLst[0]["userNm"];
              }
              //thisWin.$data[userNm] = uLst[0]["userNm"];
            }
          }
        },
        function(error) {
          return;
        },
        "UF10600268"
      );
    },
    /*********************************************************
     * 설명: 상품조회 결과 적용
     *********************************************************/
    fn_copyGoodsResult(ds02) {
      let soptTc = thisWin.soptTc;
      if (soptTc != null && soptTc != "") return;

      //form set(except:"trstTypC","ivRskRatingC","mtrtDate",)
      let dfArr = [
        "gdTypDtlC",
        "gdC",
        "gdNm",
        "mtrtAfTfrt",
        "poaoTc",
        "rufeYn",
        "tmtlNeedDds",
        "aocpRgfeCyclTc",
        "basTfrt",
        "aocpRgfeYn",
        "aocpRgfe",
        "prfe",
        "prfeRt",
        "epayPoaoTc",
        "epayTfrt",
        "epayCyclTc",
        "epayFxrtFamtTc",
        "aocpRgfeFamtTc",
        "epayFeeAmt",
        "ivRskRatingC",
      ];
      thisWin.fn_copyDataArrMap(dfArr, ds02[0]);
      //dsDetail set
      let dDtArr = [
        "genTaxtTc",
        "feeCalcBaseTc",
        "bffeBaseC",
        "mdtmPosbYn",
        "mdtmFert01",
        "mdtmFert02",
        "mdtmFert03",
        "mdtmStrtDds01",
        "mdtmEndDds01",
        "mdtmStrtDds02",
        "mdtmEndDds02",
        "mdtmStrtDds03",
        "mdtmEndDds03",
        "trstTypC",
        "lmtAmt",
        "fundStactYn",
        "fxppYn",
        "appnTrm",
        "appnTrmUnitC",
        "blktAppnIndcYn",
        "aocpRgfeDd",
        "sectFeeCrtYn",
        "fssGdClasC",
        "moContCoicYn",
        "curC",
        "drvtInvtPosbYn",
        "saleTagtTc",
        "moGdC",
        "addmIamtPosbYn",
        "apC",
        "sconMtr",
        "drvtGdClasC",
        "countDrvtGdClasC",
        "hpcYn",
        "careYn",
        "frBondGdYn",
        "gdTypDtlC",
        "gdC",
        "gdNm",
        "mtrtAfTfrt",
        "poaoTc",
        "rufeYn",
        "tmtlNeedDds",
        "aocpRgfeCyclTc",
        "basTfrt",
        "aocpRgfeYn",
        "aocpRgfe",
        "prfe",
        "prfeRt",
        "epayPoaoTc",
        "epayTfrt",
        "epayCyclTc",
        "epayFxrtFamtTc",
        "aocpRgfeFamtTc",
        "epayFeeAmt",
        "ivRskRatingC",
      ];
      if (thisWin.dsDetail == null || thisWin.dsDetail.length < 1)
        thisWin.dsDetail[0] = _.cloneDeep(dsDetail_b);
      thisWin.dsDetail[0] = thisWin.fn_copyKeyArrMap(
        dDtArr,
        ds02[0],
        thisWin.dsDetail[0]
      );
    },

    /*********************************************************
     * 설명: dsDetail to form
     *********************************************************/
    fn_dsDetailToForm() {
      if (_.isEmpty(thisWin.dsDetail) || thisWin.dsDetail.length < 1) return;
      let cnt = this.formKey.length;
      for (let i = 0; i < cnt; i++) {
        let fKey = this.formKey[i];
        let val1DF =
          thisWin.dsDetail[0][fKey] == undefined ||
          thisWin.dsDetail[0][fKey] == null
            ? ""
            : thisWin.dsDetail[0][fKey];
        try {
          thisWin.$data[fKey] = String(val1DF);
          if (fKey == "mtrtDate") {
            thisWin.fn_setCalendar("rfMtrtDate", val1DF);
          }
        } catch (e) {}
      }
    },
    /*********************************************************
     * 설명: form to dsDetail
     *********************************************************/
    fn_formTodsDetail() {
      if (_.isEmpty(thisWin.dsDetail) || thisWin.dsDetail.length < 1) {
        thisWin.dsDetail[0] = _.cloneDeep(dsDetail_b);
      }
      let cnt = this.formKey.length;
      for (let i = 0; i < cnt; i++) {
        let fKey = this.formKey[i];
        let val1 =
          thisWin.$data[fKey] == undefined || thisWin.$data[fKey] == null
            ? ""
            : thisWin.$data[fKey];
        if (fKey == "mtrtDate" || fKey == "bnfcDethDate") {
          thisWin.dsDetail[0][fKey] = val1.replaceAll("-", "");
        } else {
          thisWin.dsDetail[0][fKey] = val1;
        }
      }
      //유선/내방
      let teleVssTc = "T"; // 내방(vss)
      if (this.lv_basInfo.vstTyp == "vst") {
        teleVssTc = "O"; // 방판(vst)
      }
      thisWin.dsDetail[0]["teleVssTc"] = teleVssTc; //내방
    },
    /*********************************************************
     * 설명: form clear
     *********************************************************/
    fn_formClear() {
      let cnt = this.formKey.length;
      for (let i = 0; i < cnt; i++) {
        let fKey = this.formKey[i];
        thisWin.$data[fKey] = "";
      }
      this.moCtno = "";
      this.otxtBnfcRcno = "";
      this.otxtBnfcNm = "";
      this.trBkcdNmbnkAcno00 = "";
      this.prftBnfcRcno = "";
      this.prftBnfcNm = "";
      this.trBkcdNmbnkAcno01 = "";
      this.hpcTc = "2";
      this.preGdC = "";
      this.oldAgeConfirm = false;
    },
    //grid row checked
    fn_selectedDsList02(idx) {
      this.lv_SelectedItem02 = idx + "";
    },

    fn_selectedDsList03(idx) {
      this.lv_SelectedItem03 = idx + "";
    },

    fn_selectedDsList04(idx) {
      this.lv_SelectedItem04 = idx + "";
    },

    fn_selectedDsList05(idx) {
      this.lv_SelectedItem05 = idx + "";
    },
    //grid row checked end.
    /***************************************************************************************
      code        : 호출할 공통코드
      dsName      : 호출된 공통코드를 담을 Dataset
      selecttype  : ComboBox 최상단에 추가할 문구( A: -전체-, S:-선택-, N: 빈칸)

      selecttype은 추후 변경될 수 있음.
      this.fn_commonAfterOnload를 화면에 선언하면 gfn_setCommonCode처리후 호출됨.
    *****************************************************************************************/
    fn_ComCode() {
      this.eaiCommObj.lv_vm = this;
      // this.eaiCommObj.trnstId = 'txTSSTS90S1' // selectTFCP11000A
      this.eaiCommObj.auth = "S";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600046_S";
      this.eaiCommObj.params = {
        TUKEMK: [
          { cId: "5056" },
          { cId: "5017" },
          { cId: "5025" },
          { cId: "5101" },
          { cId: "5021" },
          { cId: "5026" },
          { cId: "5020" },
          { cId: "5023" },
          { cId: "503002" },
          { cId: "5031" },
          { cId: "5024" },
          { cId: "501402" },
          { cId: "5085" },
          { cId: "5073" },
          { cId: "502904" },
          { cId: "5035" },
          { cId: "5071" },
          { cId: "5072" },
          { cId: "5010" },
          { cId: "5011" },
          { cId: "5012" },
          { cId: "5013" },
          { cId: "5018" },
          { cId: "5019" },
          { cId: "5016" },
          //{cId:"505202"},
          //{cId:"505203"},
          { cId: "5055" },
          { cId: "5139" },
          { cId: "5100" },
          { cId: "5099" },
          { cId: "5186" },
          { cId: "5187" },
          { cId: "5188" },
          { cId: "5059" },
          { cId: "5173" },
          { cId: "5174" },
          { cId: "5175" },
        ],
      };
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_comCodeCallBack);
    },
    fn_comCodeCallBack(res) {
      if (res && res.data) {
        for (let index in res.data.tukemk) {
          let item = res.data.tukemk[index];
          let dsNm = "dsCmb" + item.cid;
          //dsCmb + code name
          thisWin.$data[dsNm].push({ value: item.c, text: item.cnm });
        }
        if (this.dsCmb5186.length > 0) {
          thisWin.oldAge = thisWin.dsCmb5186[0]["text"];
        }
        if (this.dsCmb5187.length > 0) {
          thisWin.superAge = thisWin.dsCmb5187[0]["text"];
        }
        //신탁유형 : dsCmb5014
        let idx = TSCommUtil.gfn_getIdxArrMapKeyVal(
          thisWin.dsCmb5014,
          "value",
          "007"
        ); //치매
        if (idx > -1) {
          thisWin.dsCmb5014.splice(idx, 1);
        }
        this.cmbGdTypDtlC = _.cloneDeep(thisWin.dsCmb5010);
      } else {
        console.log("code error.....");
      }
    },
    /**********************************************************************************
     * 버튼 활성,비활성 처리
     **********************************************************************************/
    fn_enableComponent(strVal) {
      if (_.isEmpty(strVal)) return;
      TSCommUtil.gfn_setDisableArr(this, ["btnInit"], false);
      TSCommUtil.gfn_setDisableArr(
        this,
        ["btnCancel", "btnDelete", "btnUpdate", "btnConfirm", "btnSearchList"],
        true
      );
      let arrVal = strVal.split(",");
      TSCommUtil.gfn_setDisableArr(this, arrVal, false);
    },
    /**********************************************************************************
     * 초기화  : 조회영역을 제외하고 초기화한다.
     **********************************************************************************/
    fn_initBody(b) {
      this.fn_clearTimerApprvState();
      if (_.isEmpty(b)) {
        this.fn_enableComponent("btnSearchList");
      }
      //this.fn_custRcnoMask();          //개인정보 마스킹

      let a00Info = this.fn_getAcnoCondInfo();
      let tacno = a00Info["tacno"];
      if (tacno.length != 7 || _.isEmpty(thisWin.dsList00)) {
        this.tacnoBase = "";
        this.procDt = ""; //설문등록일 //2021-04-06 add  2021-08-05 위치변경
        this.valdYn = ""; //설문유효여부 //2021-04-06 add  2021-08-05 위치변경
        this.nextCnto = ""; // 2021-08-31 add 계약번호(신규채번)
        this.coriYn = "N"; // 2021-08-31 add 치매신탁 지급청구대리인 검증 여부
        this.oldAgeConfirm = false; //고령자 조력자 확인 여부

        // 계좌번호 조회
        this.dsList00 = {}; // 데이터 셋 초기화
      }

      if (tacno.length == 7 && this.tacnoBase != tacno) {
        //selectTPUP95320
        let pParam = { tacno: tacno };

        this.eaiCommObj.lv_vm = this;
        this.eaiCommObj.auth = "S";
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600215_S";
        this.eaiCommObj.params = pParam;
        TSServiceUtil.invokeInDirect(
          this.eaiCommObj,
          function(res) {
            if (res && res.data) {
              let dsL00 = TSCommUtil.gfn_getMapFrBase(dsList00_b, res.data); //{}
              if (
                !_.isEmpty(dsL00) &&
                thisWin.fn_getValue(dsL00["csId"]) != ""
              ) {
                thisWin.dsList00 = _.cloneDeep(dsL00);
                thisWin.dsList00Svd = _.cloneDeep(dsL00); //기본 setting
                let dt = thisWin.dsList00["procDt"];
                if (dt == null) dt = "";
                thisWin.procDt = dt; //설문등록일
                thisWin.valdYn = thisWin.dsList00["valdYn"]; //설문유효여부
                thisWin.tacnoBase = thisWin.dsList00["tacno"];
              }
            }
          },
          function(error) {
            return;
          },
          "UF10600215"
        );
      }

      this.btnAml = true;

      //데이터셋 row 초기화후 row추가 : row 없으면 입력 불가능.
      //this.dsDetail = [];
      this.fn_formClear();

      //데이터셋 row 초기화후
      this.dsList01 = [];
      this.dsList02 = [];
      this.dsList03 = [];
      this.dsList04 = [];
      this.dsList05 = [];
      this.dsList06 = [];
      this.dsList07 = [];

      this.dsList10 = [];
      this.dsList15 = [];

      this.dsList11 = [];
      this.dsList12 = [];
      this.dsList11.push({
        value: "0",
        text: " - 선택안함(브릿지계좌로 송금) - ",
      });
      this.dsList12.push({
        value: "0",
        text: " - 선택안함(브릿지계좌로 송금) - ",
      });

      this.amlCtno = "";

      this.fxppYn = ""; // 정기지급 여부
      this.fxppStrtDate = ""; //정기지급개시년월
      this.fxppEndDate = ""; //정기지급개시년월
      this.fxppTc = ""; //방법
      this.intPymDdTc = ""; //일자
      this.pymCyclTc = ""; //주기
      this.ftrmPymAmt = ""; //금액
      this.teleVssTc = ""; //유선내방구분  : 2 내방
      this.opdtNtcTc = "2"; // 운영내역통보 : 2 미통보
      this.static83 = ""; //적합/등급

      this.parseng = false;
      this.addValue = 0;
      this.frBondCpTc = "";
      this.isueYn = "";

      this.epayFeeAmt = 0;

      //visBtnAdmission(btnAdmissionText), visFrBondCpTc.해외채권 쿠폰처리여부
      TSCommUtil.gfn_setVisibleArr(
        this,
        ["visBtnAdmission", "visFrBondCpTc"],
        false
      );

      //disIsueYn.발행여부(visible), disHpcTc.해피콜구분
      TSCommUtil.gfn_setDisableArr(this, ["disIsueYn", "disHpcTc"], true);

      //치매없음

      this.fn_Init_Btn_Enable();
    },
    /**********************************************************************************
     * 취소 : WFtitleCRUDbtn 의 callback
     **********************************************************************************/
    fn_Cancel() {
      this.fn_clearTimerApprvState();
      this.fn_initBody();
    },
    Div02_rad_processGb_onitemchanged() {
      if (this.radProcessGb == "1") {
        this.vwDivAcno = true;
        this.vwDivAccno = false;

        //visible true(vwDivAcno(신규),FATCA/CRS확인 없음,징구/미징구 없음)
        TSCommUtil.gfn_setVisibleArr(this, ["visDivAcno"], true);

        //visible false(vwDivAccno(추가입금/변경), vwAgntCsId(대리인), 치매없음)
        TSCommUtil.gfn_setVisibleArr(
          this,
          ["visDivAccno", "visAgntCsId"],
          false
        );
      } else if (this.radProcessGb == "2" || this.radProcessGb == "3") {
        this.vwDivAcno = false;
        this.vwDivAccno = true;

        //visible false(vwDivAcno(신규),FATCA/CRS확인 없음,징구/미징구 없음)
        TSCommUtil.gfn_setVisibleArr(this, ["visDivAcno"], false);

        //visible true(vwDivAccno(추가입금/변경), vwAgntCsId(대리인), 치매없음)
        TSCommUtil.gfn_setVisibleArr(
          this,
          ["visDivAccno", "visAgntCsId"],
          true
        );
        if (this.radProcessGb == "2") {
          //사망여부: true
          TSCommUtil.gfn_setVisibleArr(this, ["visBnfcDethYn"], true);
        } else {
          //사망여부: false
          TSCommUtil.gfn_setVisibleArr(this, ["visBnfcDethYn"], false);
        }
      }
    },
    /**********************************************************************************
     * 개인정보 마스킹
     **********************************************************************************/
    fn_custRcnoMask() {
      //개인정보 마스킹
      //본문 컨트롤(수익자)  초기화
      //this.otxtBnfcRcno = "";
      //this.prftBnfcRcno = "";
      //this.otxtBnfcNm = "";
      //this.prftBnfcNm = "";
      let nRow = TSCommUtil.gfn_getIdxArrMapKeyVal(
        this.dsList01,
        "fxrtFamtTc",
        "1"
      );
      if (nRow > 0) {
        // 정률다수
        //원금지급계좌, 이익지급계좌, 원금수익자, 이익수익자
        TSCommUtil.gfn_setDisableArr(
          this,
          [
            "disTrBkcdNmbnkAcno00",
            "disTrBkcdNmbnkAcno01",
            "btnCustInfo00",
            "btnCustInfo01",
          ],
          true
        );
        //스케쥴관리
        this.btnCustInfoReg = false;
        //return;
      }

      //20211217 - 치매신탁, 타익, 사망여부 "Y'인 경우 정률 수익자 없을 수 있음 첫번째 정액수익자 보여줌
      if (
        this.radProcessGb == 3 &&
        this.trstTypC == "007" &&
        this.soptTc == "20" &&
        this.bnfcDethYn == "Y"
      ) {
        nRow = 0;
      }

      if (this.dsList11.length < 1) {
        this.dsList11.push({
          value: "0",
          text: " - 선택안함(브릿지계좌로 송금) - ",
        });
      }
      if (this.dsList12.length < 1) {
        this.dsList12.push({
          value: "0",
          text: " - 선택안함(브릿지계좌로 송금) - ",
        });
      }
      //20211217
      if (nRow < 0) {
        return;
      }

      if (
        !_.isEmpty(this.dsList01[nRow]["prinBnfcCsId"]) &&
        this.dsList01[nRow]["prinBnfcCsId"].length == 9
      ) {
        let vRcno = this.dsList01[nRow]["prinBnfcRcno"];
        let vRnameGb = this.dsList01[nRow]["prinBnfcRcnoGb"];
        let vName = this.dsList01[nRow]["prinBnfcCnm"];

        if (
          vRnameGb == "01" ||
          vRnameGb == "03" ||
          vRnameGb == "04" ||
          vRnameGb == "05"
        ) {
          this.otxtBnfcRcno =
            vRcno.substr(0, 6) + "-" + vRcno.substr(6, 1) + "******";
        } else if (vRnameGb == "06") {
          this.otxtBnfcRcno = "*********" + "-" + vRcno.substr(4);
        } else {
          this.otxtBnfcRcno = vRcno;
        }
        this.otxtBnfcNm = vName;
        this.otxtBnfcRcno = vRcno;
        //서버에 호출 할 정보를 셋팅해준다.(selectTFIO42100CList)
        let pParam = {
          csId: this.dsList01[nRow]["prinBnfcCsId"],
        };
        this.eaiCommObj.lv_vm = this;
        this.eaiCommObj.auth = "S";
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600164_S";
        this.eaiCommObj.params = pParam;
        TSServiceUtil.invokeInDirect(
          this.eaiCommObj,
          function(res) {
            if (res && res.data) {
              thisWin.fn_callBackOtxt(res);
            }
          },
          function(error) {
            return;
          },
          "UF10600164"
        );
      }

      if (
        !_.isEmpty(this.dsList01[nRow]["prftBnfcCsId"]) &&
        this.dsList01[nRow]["prftBnfcCsId"].length == 9
      ) {
        let vRcno = this.dsList01[nRow]["prftBnfcRcno"];
        let vRnameGb = this.dsList01[nRow]["prftBnfcRcnoGb"];
        let vName = this.dsList01[nRow]["prftBnfcCnm"];

        if (
          vRnameGb == "01" ||
          vRnameGb == "03" ||
          vRnameGb == "04" ||
          vRnameGb == "05"
        ) {
          this.prftBnfcRcno =
            vRcno.substr(0, 6) + "-" + vRcno.substr(6, 1) + "******";
        } else if (vRnameGb == "06") {
          this.prftBnfcRcno = "*********" + "-" + vRcno.substr(4);
        } else {
          this.prftBnfcRcno = vRcno;
        }
        this.prftBnfcNm = vName;
        this.prftBnfcRcno = vRcno;

        //조회용 dataset에 조회할 항목.(selectTFIO42100CList)
        let pParams = {
          csId: this.dsList01[nRow]["prftBnfcCsId"],
        };

        this.eaiCommObj.lv_vm = this;
        this.eaiCommObj.auth = "S";
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600164_S";
        this.eaiCommObj.params = pParams;
        TSServiceUtil.invokeInDirect(
          this.eaiCommObj,
          function(res) {
            if (res && res.data) {
              thisWin.fn_callBackPrft(res);
            }
          },
          function(error) {
            return;
          },
          "UF10600164"
        );
      }
    },
    cbo_poaoTc_onitemchanged() {
      if (this.poaoTc == null || this.poaoTc == "") {
        this.disBasTfrt = true; //기본보수율료율
        //선취수수료율,선취수수료,총수수료,별납보수구분,정기보수여부,정률정액구분
        TSCommUtil.gfn_setVisibleArr(
          this,
          [
            "visPrfeRt",
            "visPrfe",
            "visComsTamt",
            "visEpayPoaoTc",
            "visAocpRgfeYn",
            "visEpayFxrtFamtTc",
          ],
          false
        );
      } else if (this.poaoTc == "1") {
        //별납
        this.disBasTfrt = true; //후취 기본 보수율
        //선취수수료율,선취수수료,총수수료,정기보수여부
        TSCommUtil.gfn_setVisibleArr(
          this,
          ["visPrfeRt", "visPrfe", "visComsTamt", "visAocpRgfeYn"],
          false
        );
        //별납보수구분,정률정액구분
        TSCommUtil.gfn_setVisibleArr(
          this,
          ["visEpayPoaoTc", "visEpayFxrtFamtTc"],
          true
        );
      } else if (this.poaoTc == "2") {
        //후취
        this.disBasTfrt = false; //후취보수율료율
        //선취수수료율,선취수수료,총수수료,별납보수구분,정률정액구분
        TSCommUtil.gfn_setVisibleArr(
          this,
          [
            "visPrfeRt",
            "visPrfe",
            "visComsTamt",
            "visEpayPoaoTc",
            "visEpayFxrtFamtTc",
          ],
          false
        );
        //정기보수여부
        TSCommUtil.gfn_setVisibleArr(this, ["visAocpRgfeYn"], true);
      } else if (this.poaoTc == "3") {
        //선취+후취
        this.disBasTfrt = false; //후취보수율료율
        //선취수수료율,선취수수료
        TSCommUtil.gfn_setVisibleArr(this, ["visPrfeRt", "visPrfe"], true);
        if (this.fn_getNumber(this.comsTamt) > 0 && this.radProcessGb != "1") {
          TSCommUtil.gfn_setVisibleArr(this, ["visComsTamt"], true); //총수수료
          TSCommUtil.gfn_setVisibleArr(this, ["visPrfe"], false); //선취수수료
        } else {
          TSCommUtil.gfn_setVisibleArr(this, ["visComsTamt"], false); //총수수료
          TSCommUtil.gfn_setVisibleArr(this, ["visPrfe"], true); //선취수수료
        }

        //별납보수구분,정률정액구분
        TSCommUtil.gfn_setVisibleArr(
          this,
          ["visEpayPoaoTc", "visEpayFxrtFamtTc"],
          false
        );
        //정기보수여부
        TSCommUtil.gfn_setVisibleArr(this, ["visAocpRgfeYn"], true);
      } else {
        //관리
        this.disBasTfrt = true; //후취보수율료율
        //선취수수료율,선취수수료,별납보수구분,정기보수여부,정률정액구분
        TSCommUtil.gfn_setVisibleArr(
          this,
          [
            "visPrfeRt",
            "visPrfe",
            "visEpayPoaoTc",
            "visAocpRgfeYn",
            "visEpayFxrtFamtTc",
          ],
          false
        );
      }
    },
    /**********************************************************************************
     * 원금수익자 송금계좌 조회
     **********************************************************************************/
    fn_callBackOtxt(res) {
      let ds11 = TSCommUtil.gfn_getArrFrBase(dsList10_b, res.data.tfcbsl);
      for (let i = 0; i < ds11.length; i++) {
        let acno = ds11[i]["trBkcdNmBnkAcno"];
        if (acno.indexOf("선택안함") >= 0) continue;
        let arr1 = acno.split(" ");
        if (arr1.length < 2) continue;
        let s1 = arr1[1]; //계좌
        if (s1.length >= 8) {
          s1 = s1.substring(0, 3) + "*****" + s1.substring(8);
          arr1[1] = s1;
          ds11[i]["trBkcdNmBnkAcno"] = arr1.join(" ");
        }
      }
      let ds11_1 = [];
      //value key : stipSeq, text key : trBkcdNmBnkAcno
      if (
        ds11.length < 1 ||
        this.fn_getValue(ds11[0]["trBkcdNmBnkAcno"]) !=
          " - 선택안함(브릿지계좌로 송금) - "
      ) {
        let m1 = { value: "0", text: " - 선택안함(브릿지계좌로 송금) - " };
        ds11_1.push(m1);
      }
      this.dsList11 = TSCommUtil.gfn_getDropdownFrArr(
        ds11,
        "stipSeq",
        "trBkcdNmBnkAcno",
        ds11_1
      );
      let pseq = this.dsList01[0]["prinBnfcBnkSseq"];
      //find value (stipSeq)
      let idx = TSCommUtil.gfn_getIdxArrMapKeyVal(this.dsList11, "value", pseq);
      if (idx < 0) idx = 0;
      this.trBkcdNmbnkAcno00 = this.dsList11[idx]["value"];
    },
    /**********************************************************************************
     * 이자수익자 송금계좌 조회
     **********************************************************************************/
    fn_callBackPrft(res) {
      let ds12 = TSCommUtil.gfn_getArrFrBase(dsList10_b, res.data.tfcbsl);
      for (let i = 0; i < ds12.length; i++) {
        let acno = ds12[i]["trBkcdNmBnkAcno"];
        if (acno.indexOf("선택안함") >= 0) continue;
        let arr1 = acno.split(" ");
        if (arr1.length < 2) continue;
        let s1 = arr1[1]; //계좌
        if (s1.length >= 8) {
          s1 = s1.substring(0, 3) + "*****" + s1.substring(8);
          arr1[1] = s1;
          ds12[i]["trBkcdNmBnkAcno"] = arr1.join(" ");
        }
      }
      let ds12_1 = [];
      //value key : stipSeq, text key : trBkcdNmBnkAcno
      if (
        ds12.length < 1 ||
        this.fn_getValue(ds12[0]["trBkcdNmBnkAcno"]) !=
          " - 선택안함(브릿지계좌로 송금) - "
      ) {
        let m1 = { value: "0", text: " - 선택안함(브릿지계좌로 송금) - " };
        ds12_1.push(m1);
      }
      this.dsList12 = TSCommUtil.gfn_getDropdownFrArr(
        ds12,
        "stipSeq",
        "trBkcdNmBnkAcno",
        ds12_1
      );

      let pseq = this.dsList01[0]["prftBnfcBnkSseq"];
      //find value (stipSeq)
      let idx = TSCommUtil.gfn_getIdxArrMapKeyVal(this.dsList12, "value", pseq);
      if (idx < 0) idx = 0;
      this.trBkcdNmbnkAcno01 = this.dsList12[idx]["value"];
    },
    /**********************************************************************************
     * 별납보수구분
     **********************************************************************************/
    cbo_epayPoaoTc_onitemchanged() {
      if (
        !_.isEmpty(this.epayPoaoTc) &&
        this.visEpayPoaoTc &&
        (this.epayPoaoTc == "1" || this.epayPoaoTc == "2")
      ) {
        //주기
        TSCommUtil.gfn_setVisibleArr(this, ["visEpayCyclTc"], true);
      } else {
        //주기
        TSCommUtil.gfn_setVisibleArr(this, ["visEpayCyclTc"], false);
      }
    },
    /**********************************************************************************
     * 정기지급구분 변경시
     **********************************************************************************/
    cbo_aocpRgfeYn_onitemchanged() {
      if (
        !this.visAocpRgfeYn ||
        _.isEmpty(this.aocpRgfeYn) ||
        this.aocpRgfeYn == "N"
      ) {
        //주기,정률정액
        TSCommUtil.gfn_setVisibleArr(
          this,
          ["visAocpRgfeCyclTc", "visAocpRgfeFamtTc"],
          false
        );
      } else {
        //주기,정률정액
        TSCommUtil.gfn_setVisibleArr(
          this,
          ["visAocpRgfeCyclTc", "visAocpRgfeFamtTc"],
          true
        );
        //정률정액구분, 주기
        TSCommUtil.gfn_setDisableArr(
          this,
          ["disAocpRgfeFamtTc", "disAocpRgfeCyclTc"],
          true
        );
        this.aocpRgfeFamtTc = "1";
        this.aocpRgfeCyclTc = "12";
      }
    },
    /**********************************************************************************
     * 별납정률정액구분 변경시
     **********************************************************************************/
    cbo_epayFxrtFamtTc_onitemchanged() {
      if (this.trstTypC == "003" || this.trstTypC == "010") {
        //부가세
        TSCommUtil.gfn_setVisibleArr(this, ["visAddValue"], true);
      }
      if (
        !_.isEmpty(this.epayFxrtFamtTc) &&
        this.visEpayFxrtFamtTc == true &&
        this.epayFxrtFamtTc == "2"
      ) {
        // 정액
        TSCommUtil.gfn_setVisibleArr(
          this,
          ["visEpayTfrt", "visEpayFeeAmt"],
          true
        );
        TSCommUtil.gfn_setVisibleArr(this, ["visAddValue"], true);
        //별납보수율,별납보수금액2
        TSCommUtil.gfn_setDisableArr(this, ["disEpayTfrt"], true);
        //금액
        TSCommUtil.gfn_setDisableArr(this, ["disEpayFeeAmt"], false);
      } else if (
        !_.isEmpty(this.epayFxrtFamtTc) &&
        this.visEpayFxrtFamtTc &&
        this.epayFxrtFamtTc == "1"
      ) {
        //정률
        TSCommUtil.gfn_setVisibleArr(
          this,
          ["visEpayTfrt", "visEpayFeeAmt"],
          true
        );
        TSCommUtil.gfn_setVisibleArr(this, ["visAddValue"], true);
        //별납보수율,별납보수금액2
        TSCommUtil.gfn_setDisableArr(this, ["disEpayTfrt"], false);
        //금액
        TSCommUtil.gfn_setDisableArr(this, ["disEpayFeeAmt"], true);
      } else {
        //별납보수율,별납보수금액2,금액,부가세
        TSCommUtil.gfn_setVisibleArr(
          this,
          ["visEpayTfrt", "visEpayFeeAmt", "visAddValue"],
          false
        );
        TSCommUtil.gfn_setVisibleArr(this, ["visAddValue"], false);
      }
      if (this.epayFxrtFamtTc == "2") {
        //정액
        TSCommUtil.gfn_setVisibleArr(this, ["visEpayTfrt"], false);
      }
    },
    /**********************************************************************************
     * 정기지급 정률정액구분  변경시
     **********************************************************************************/
    cbo_aocpRgfeFamtTc_onitemchanged() {
      //thisWin.cbo_epayFxrtFamtTc_onitemchanged();
      if (
        !_.isEmpty(this.aocpRgfeFamtTc) &&
        this.visAocpRgfeFamtTc &&
        this.aocpRgfeFamtTc == "2"
      ) {
        // 정액
        //후취정기보수금액
        TSCommUtil.gfn_setVisibleArr(this, ["visAocpRgfe"], true);
      } else if (
        !_.isEmpty(this.aocpRgfeFamtTc) &&
        this.visAocpRgfeFamtTc &&
        this.aocpRgfeFamtTc == "2"
      ) {
        //정률
        //후취정기보수금액
        TSCommUtil.gfn_setVisibleArr(this, ["visAocpRgfe"], false);
      } else {
        //후취정기보수금액
        TSCommUtil.gfn_setVisibleArr(this, ["visAocpRgfe"], false);
      }
    },
    /**********************************************************************************
     * 상품에 따라 보여지는 그리드 설정
     **********************************************************************************/
    fn_visibleGrid() {
      let cbo_trstTypC = this.trstTypC;
      let radProcessGb = this.radProcessGb;
      let addTop = 0;
      if (cbo_trstTypC == "002" || cbo_trstTypC == "009") {
        //20230307 유언대용신탁(유가증권)추가
        //운용지시,유가증권내역
        TSCommUtil.gfn_setVisibleArr(
          this,
          ["visGrdList02", "visGrdList03"],
          true
        );
        if (radProcessGb == "3") {
          TSCommUtil.gfn_setVisibleArr(
            this,
            ["visInsertRow", "visInsertRow00"],
            true
          );
        }
        //부동산재산,금전채권신탁
        TSCommUtil.gfn_setVisibleArr(
          this,
          ["visGrdList04", "visGrdList05"],
          false
        );
      } else if (cbo_trstTypC == "003" || cbo_trstTypC == "010") {
        //20230307 유언대용신탁(부동산)추가
        //운용지시,부동산재산
        TSCommUtil.gfn_setVisibleArr(
          this,
          ["visGrdList02", "visGrdList04"],
          true
        );
        if (radProcessGb == "3") {
          TSCommUtil.gfn_setVisibleArr(
            this,
            ["visInsertRow", "visInsertRow00"],
            true
          );
        }
        //유가증권내역,금전채권신탁
        TSCommUtil.gfn_setVisibleArr(
          this,
          ["visGrdList03", "visGrdList05"],
          false
        );

        //조회용 dataset에 조회할 항목을 넣어준다.
        //위치가 애매하긴하나...
        if (_.isEmpty(thisWin.dsList00Svd)) {
          return;
        }

        if (_.isEmpty(this.dsList10) || this.dsList10.length < 1) {
          this.fn_getData164();
          // //서버에 호출 할 정보를 셋팅(selectTFIO42100CList)
          // let pParam = {
          //   csId: thisWin.dsList00Svd["csId"]
          // }

          // this.eaiCommObj.lv_vm = this
          // this.eaiCommObj.auth = 'S'
          // this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600164_S'
          // this.eaiCommObj.params = pParam
          // TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBackRetu,function (error) {console.log(error)},'UF10600164')
        }
      } else if (cbo_trstTypC == "004") {
        //운용지시,금전채권신탁
        TSCommUtil.gfn_setVisibleArr(
          this,
          ["visGrdList02", "visGrdList05"],
          true
        );
        if (radProcessGb == "3") {
          TSCommUtil.gfn_setVisibleArr(
            this,
            ["visInsertRow", "visInsertRow00"],
            true
          );
        }
        //유가증권내역,부동산재산
        TSCommUtil.gfn_setVisibleArr(
          this,
          ["visGrdList03", "visGrdList04"],
          false
        );
      } else {
        //운용지시
        TSCommUtil.gfn_setVisibleArr(this, ["visGrdList02"], true);
        if (radProcessGb == "3") {
          TSCommUtil.gfn_setVisibleArr(this, ["visInsertRow"], true);
        }
        //유가증권내역,부동산재산,금전채권신탁
        TSCommUtil.gfn_setVisibleArr(
          this,
          ["visGrdList03", "visGrdList04", "visGrdList05"],
          false
        );

        //view 행추가, 행삭제
        TSCommUtil.gfn_setVisibleArr(this, ["visInsertRow00"], false);
      }
    },
    /**********************************************************************************
     * 부동산 환급계좌
     **********************************************************************************/
    fn_getData164() {
      let pParam = {
        csId: thisWin.dsList00Svd["csId"],
      };
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600164_S";
      this.eaiCommObj.params = pParam;
      TSServiceUtil.invokeInDirect(
        this.eaiCommObj,
        function(res) {
          if (res && res.data) {
            let ds10 = TSCommUtil.gfn_getArrFrBase(dsList10_b, res.data.tfcbsl);
            //value key : stipSeq, text key : trBkcdNmBnkAcno
            if (
              thisWin.dsList10.length < 1 ||
              thisWin.dsList10[0]["trBkcdNmBnkAcno"] !=
                " - 선택안함(브릿지계좌로 송금) - "
            ) {
              let m1 = {
                value: "0",
                text: " - 선택안함(브릿지계좌로 송금) - ",
              };
              thisWin.dsList10.push(m1);
            }
            thisWin.dsList10 = TSCommUtil.gfn_getDropdownFrArr(
              ds10,
              "stipSeq",
              "trBkcdNmBnkAcno",
              thisWin.dsList10
            );
            if (
              thisWin.trBkcdNmBnkAcno == null ||
              thisWin.trBkcdNmBnkAcno == ""
            ) {
              thisWin.trBkcdNmBnkAcno = "0";
            }
          }
        },
        function(error) {
          return;
        },
        "UF10600164"
      );
    },
    fn_callBackRetu(res) {
      // let ds10 = TSCommUtil.gfn_getArrFrBase(dsList10_b, res.data.tfcbsl);
      // //value key : stipSeq, text key : trBkcdNmBnkAcno
      // if (thisWin.dsList10.length < 1 || thisWin.dsList10[0]["trBkcdNmBnkAcno"] != " - 선택안함(브릿지계좌로 송금) - " ){
      //   let m1 = {"value":"0", "text":" - 선택안함(브릿지계좌로 송금) - "};
      //   thisWin.dsList10.push(m1);
      // }
      // thisWin.dsList10 = TSCommUtil.gfn_getDropdownFrArr(ds10,"stipSeq","trBkcdNmBnkAcno", thisWin.dsList10);
    },
    /**********************************************************************************
     *초기 모드로 컨트롤 활성화
     **********************************************************************************/
    fn_Init_Btn_Enable() {
      this.trstAmtNm = "신탁금액";
      //신탁유형,상품유형,신탁상품코드/신탁상품팝업,신탁금액
      //만기일,만기후보수율,해지소요일수,운영내역통보, 자타익구분
      TSCommUtil.gfn_setDisableArr(
        this,
        [
          "disTrstTypC",
          "disGdTypDtlC",
          "disGdC",
          "disTrstAmt",
          "disMtrtDate",
          "disMtrtAfTfrt",
          "disTmtlNeedDds",
          "disOpdtNtcTc",
          "disSoptTc",
        ],
        true
      );

      //원금수익자 팝업,원금수익자명,원금수익자계좌선택
      //이익수익자 팝업,이익수익자명,이익수익자계좌선택
      TSCommUtil.gfn_setDisableArr(
        this,
        [
          "btnCustInfo00",
          "disOtxtBnfcNm",
          "disTrBkcdNmbnkAcno00",
          "btnCustInfo01",
          "disPrftBnfcNm",
          "disTrBkcdNmbnkAcno01",
        ],
        true
      );
      this.fn_custRcnoMask(); //개인정보 마스킹
      //대리인등록버튼
      let radProcessGb = this.radProcessGb;
      let cboCtno = this.cboCtno == null ? "" : this.cboCtno;
      if (radProcessGb == "1" && cboCtno != "") {
        TSCommUtil.gfn_setDisableArr(this, ["disReprInfo"], false);
      } else {
        TSCommUtil.gfn_setDisableArr(this, ["disReprInfo"], true);
      }
      //보수구분
      TSCommUtil.gfn_setDisableArr(this, ["disPoaoTc"], true);

      this.cbo_poaoTc_onitemchanged(); // 포함컨트롤 visible

      //선취수수료율,선취수수료,(후취)기본보수율
      TSCommUtil.gfn_setDisableArr(
        this,
        ["disPrfeRt", "disPrfe", "disBasTfrt"],
        true
      );

      //선후취구분
      TSCommUtil.gfn_setDisableArr(this, ["disEpayPoaoTc"], true);

      this.cbo_epayPoaoTc_onitemchanged(); // visible
      //주기
      TSCommUtil.gfn_setDisableArr(this, ["disEpayCyclTc"], true);
      //정기보수여부
      TSCommUtil.gfn_setDisableArr(this, ["disAocpRgfeYn"], true);

      this.cbo_aocpRgfeYn_onitemchanged(); // visible
      //주기,별납정률정액구분
      TSCommUtil.gfn_setDisableArr(
        this,
        ["disAocpRgfeCyclTc", "disEpayFxrtFamtTc"],
        true
      );

      this.cbo_epayFxrtFamtTc_onitemchanged(); //visible
      //별납보수율,별납보수금액,별납보수금액2( 정률용 )
      TSCommUtil.gfn_setDisableArr(
        this,
        ["disEpayTfrt", "disEpayFeeAmt"],
        true
      );
      //후취정기보수
      TSCommUtil.gfn_setDisableArr(this, ["disAocpRgfe"], true);

      this.cbo_aocpRgfeYn_onitemchanged(); // visible
      //정률정액구분(후취)
      TSCommUtil.gfn_setDisableArr(this, ["disAocpRgfeFamtTc"], true);

      this.cbo_aocpRgfeFamtTc_onitemchanged(); //visible
      //권유자,권유자명,관리자,관리자명,재무설계FP,재무설계FP명,판매직원,판매직원명
      TSCommUtil.gfn_setDisableArr(
        this,
        [
          "disInvtMnEmno",
          "disInvtMnEmnoNm",
          "disMngrEmno",
          "disMngrEmnoNm",
          "disFnlDsgnEmno",
          "disFnlDsgnEmnoNm",
          "disSaleEmno",
          "disSaleEmnoNm",
        ],
        true
      );

      //teleVssTc.유선/내방 없음,승인업무구분코드1,승인상태,메모 없음,상품등급,고객성향
      TSCommUtil.gfn_setDisableArr(
        this,
        [
          "disAocpRgfeFamtTc",
          "disAprvBusnTc1",
          "disAprvStatTc",
          "disivRskRatingC",
          "disCsIvtdRatingC",
        ],
        true
      );

      //대리인, 대리인명,사망여부,사망일자,송금계좌
      TSCommUtil.gfn_setDisableArr(
        this,
        [
          "disAgntCsId",
          "disAgntCsIdNm",
          "disBnfcDethYn",
          "disBnfcDethDate",
          "disTrBkcdNmbnkAcno",
        ],
        true
      );
      //기준금리, 수익보수율 없음

      //포괄운용지시
      TSCommUtil.gfn_setDisableArr(this, ["disBlktAppnIndcYn"], true);

      //view 행추가, 행삭제
      TSCommUtil.gfn_setVisibleArr(
        this,
        ["visInsertRow", "visInsertRow00"],
        false
      );

      //운용지시,유가증권내역,부동산재산,금전채권신탁
      TSCommUtil.gfn_setDisableArr(
        this,
        ["disGrdList02", "disGrdList03", "disGrdList04", "disGrdList05"],
        true
      );

      this.fn_visibleGrid();

      //해외채권 쿠폰처리여부, 발생여부, rad_hpcGb.온라인/유선 없음
      TSCommUtil.gfn_setDisableArr(this, ["disFrBondCpTc", "disIsueYn"], true);

      //치매 없음


      if (this.trstTypC == "003" || this.trstTypC == "010") {
        //20230307
        //부동산환급계좌,부가가치세,발행여부(2018.12.04)
        TSCommUtil.gfn_setVisibleArr(
          this,
          ["visTrBkcdNmBnkAcno", "visAddValue", "visIsueYn"],
          true
        );
        //부동산환급계좌
        TSCommUtil.gfn_setDisableArr(this, ["disTrBkcdNmbnkAcno"], true);
      } else {
        //부동산환급계좌,부가가치세,발행여부(2018.12.04)
        TSCommUtil.gfn_setVisibleArr(
          this,
          ["visTrBkcdNmBnkAcno", "visAddValue", "visIsueYn"],
          false
        );
      }

      let cboChngSeq = this.cboChngSeq; //변경일련번호
      if (
        this.radProcessGb == "2" ||
        (cboChngSeq != null && cboChngSeq != "")
      ) {
        //계약 관계자, 스케쥴 관리
        TSCommUtil.gfn_setDisableArr(
          this,
          ["disRelated", "btnCustInfoReg"],
          true
        );
      }
      if (this.btnConfirm == true) {
        TSCommUtil.gfn_setDisableArr(
          this,
          ["disRelated", "btnCustInfoReg"],
          true
        );
      }
    },
    /************************************************************************************************
     * 데이터 초기화
     ************************************************************************************************/
    fn_userInit() {
      this.edtAcno1 = "";
      this.edtCnm1 = "";
      this.cboAcno = "";
      this.cboCtno = "";
      this.dsListS1 = {};
      this.dsList08Acno = [];
      this.dsList08 = [];
      this.dsList = [];
    },
    _fn_dataInit() {
      this.edtAcno2 = "";
      this.edtCnm2 = "";
      this.cboAccSeq = "";
      this.cboChngSeq = "";
      this.dsListS2 = [];
      this.dsListS2Seq = [];
      this.dsList09 = [];
    },
    /**********************************************************************************
     * 초기화  : WFtitleCRUDbtn 의 callback : 폼로드시 호출, 초기화버튼 클릭시 호출
     **********************************************************************************/
    fn_init() {
      this.fn_userInit();
      this._fn_dataInit();

      this.dsList = [];
      this.dsList08 = [];
      this.dsList09 = [];
      this.fn_initBody();
    },
    /**********************************************************************************
     * 종합계좌변경 콜백
     **********************************************************************************/
    acnoClearCallBackFunc() {
      this.fn_initBody();
    },
    /**********************************************************************************
     * 해외채권 재투자 쿠폰목록 조회 : 2018.06.28 add
     **********************************************************************************/
    frBondReInvCallBackFunc() {
      //해외채권 쿠폰처리여부
      TSCommUtil.gfn_setVisibleArr(thisWin, ["visFrBondCpTc"], true);

      thisWin.dsList15 = [];
      thisWin.frBondCpTc = "";

      // 계좌번호 조회(selectTFAC20000DList)
      let pParam = {
        gdC: thisWin.gdC,
      };
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600086_S";
      this.eaiCommObj.params = pParam;
      TSServiceUtil.invokeInDirect(
        this.eaiCommObj,
        function(res) {
          if (res && res.data) {
            let ds15 = TSCommUtil.gfn_getArrFrBase(dsList15_b, res.data.tfcprb);
            //value key : frBondCpCd, text key : frBondCpNm
            thisWin.dsList15 = TSCommUtil.gfn_getDropdownFrArr(
              ds15,
              "frBondCpCd",
              "frBondCpNm"
            );
          }
        },
        function(error) {
          return;
        },
        "UF10600086"
      );
    },
    /**********************************************************************************
     * 신탁유형 코드 에 따른 상품 유형 변경
     **********************************************************************************/
    cbo_trstTypCTogdTypDtlC() {
      let trstTypC = this.trstTypC;
      if (trstTypC == "001") {
        //금전신탁일때
        this.cmbGdTypDtlC = _.cloneDeep(this.dsCmb5010);
      } else if (trstTypC == "002") {
        this.cmbGdTypDtlC = _.cloneDeep(this.dsCmb5011);
      } else if (trstTypC == "003") {
        this.cmbGdTypDtlC = _.cloneDeep(this.dsCmb5012);
      } else if (trstTypC == "004") {
        this.cmbGdTypDtlC = _.cloneDeep(this.dsCmb5013);
      } else if (trstTypC == "007") {
        //치매신탁 20210805
        this.cmbGdTypDtlC = _.cloneDeep(this.dsCmb5059);
      } else if (trstTypC == "008") {
        //유언대용신탁(금전)유형코드 20230126
        this.cmbGdTypDtlC = _.cloneDeep(this.dsCmb5173);
      } else if (trstTypC == "009") {
        //유언대용신탁(유가증권)유형코드  20230126
        this.cmbGdTypDtlC = _.cloneDeep(this.dsCmb5174);
      } else if (trstTypC == "010") {
        //유언대용신탁(부동산)유형코드  20230126
        this.cmbGdTypDtlC = _.cloneDeep(this.dsCmb5175);
      }

      if (trstTypC == "003" || trstTypC == "010") {
        //20230307
        //부동산환급계좌,부가가치세,발행여부
        TSCommUtil.gfn_setVisibleArr(
          this,
          ["visTrBkcdNmBnkAcno", "visAddValue", "visIsueYn"],
          true
        );
        //부동산환급계좌
        TSCommUtil.gfn_setDisableArr(this, ["disTrBkcdNmbnkAcno"], true);
      } else {
        //부동산환급계좌,부가가치세,발행여부
        TSCommUtil.gfn_setVisibleArr(
          this,
          ["visTrBkcdNmBnkAcno", "visAddValue", "visIsueYn"],
          false
        );
      }
    },
    /**********************************************************************************
     * 신규조회시 수정 항목 표시 (신규승인화면에서의 참조)
     **********************************************************************************/
    fn_callBackNewColor() {
      let appnTrm = thisWin.dsDetail02[0]["appnTrm"]; //운용기간
      let appnTrmUnitC = thisWin.dsDetail02[0]["appnTrmUnitC"]; //운용기간단위
      let mtrtDate = thisWin.dsDetail02[0]["mtrtDate"]; //만기일
      let vMtrtDate = "";
      if (
        !_.isEmpty(appnTrmUnitC) &&
        thisWin.fn_getNumber(appnTrmUnitC.trim()) == 1
      ) {
        vMtrtDate = thisWin.addDateYmd(
          thisWin.dsDetail[0]["contDate"],
          appnTrm
        );
      } else if (
        !_.isEmpty(appnTrmUnitC) &&
        thisWin.fn_getNumber(appnTrmUnitC.trim()) == 2
      ) {
        vMtrtDate = DateUtil.fn_AddMonth(
          thisWin.dsDetail[0]["contDate"],
          appnTrm
        );
      } else if (
        !_.isEmpty(appnTrmUnitC) &&
        thisWin.fn_getNumber(appnTrmUnitC.trim()) == 3
      ) {
        //hyh 2008.12.22
        vMtrtDate = DateUtil.fn_AddMonth(
          thisWin.dsDetail[0]["contDate"],
          appnTrm * 12
        );
      } else {
        vMtrtDate = thisWin.dsDetail02[0]["mtrtDate"];
      }
      thisWin.dsDetail02[0]["mtrtDate"] = vMtrtDate;

      //만기일
      if (thisWin.mtrtDate != thisWin.dsDetail02[0]["mtrtDate"]) {
        //thisWin.cal_mtrtDate.style.set_color( "red");
      }
      //보수구분
      if (thisWin.poaoTc != thisWin.dsDetail02[0]["poaoTc"]) {
        //thisWin.cbo_poaoTc.style.set_color( "red");
      }
      //선취수수료율
      if (thisWin.prfeRt != thisWin.dsDetail02[0]["prfeRt"]) {
        //thisWin.mae_prfeRt.style.set_color( "red");
      }
      if (thisWin.basTfrt != thisWin.dsDetail02[0]["basTfrt"]) {
        //thisWin.mae_basTfrt.style.set_color( "red");
      }
      //선후취구분
      if (thisWin.epayPoaoTc != thisWin.dsDetail02[0]["epayPoaoTc"]) {
        //thisWin.cbo_epayPoaoTc.style.set_color( "red");
      }
      //정기보수여부
      if (thisWin.aocpRgfeYn != thisWin.dsDetail02[0]["aocpRgfeYn"]) {
        //thisWin.cbo_aocpRgfeYn.style.set_color( "red");
      }
      //별납정률정액구분
      if (thisWin.epayFxrtFamtTc != thisWin.dsDetail02[0]["epayFxrtFamtTc"]) {
        //thisWin.cbo_epayFxrtFamtTc.style.set_color( "red");
      }
      //정기정률정액구분
      if (thisWin.aocpRgfeFamtTc != thisWin.dsDetail02[0]["aocpRgfeFamtTc"]) {
        //thisWin.cbo_aocpRgfeFamtTc.style.set_color( "red");
      }
      //금액
      if (thisWin.aocpRgfe != thisWin.dsDetail02[0]["aocpRgfe"]) {
        //thisWin.mae_aocpRgfe.style.set_color( "red");
      }
      //별납보수율
      if (thisWin.epayTfrt != thisWin.dsDetail02[0]["epayTfrt"]) {
        //thisWin.mae_epayTfrt.style.set_color( "red");
      }
      //금액
      if (thisWin.epayFeeAmt != thisWin.dsDetail02[0]["epayFeeAmt"]) {
        //thisWin.mae_epayFeeAmt.style.set_color( "red");
      }

      for (let i = 0; i < thisWin.dsList02.length; i++) {
        if (
          thisWin.dsList02.length != this.dsList02_1.length ||
          thisWin.fn_getNumber(thisWin.dsList02[i]["appnEndRto"]) !=
            thisWin.fn_getNumber(this.dsList02_1[i]["appnEndRto"]) ||
          thisWin.dsList02[i]["opdtExpl"] != this.dsList02_1[i]["opdtExpl"] ||
          thisWin.dsList02[i]["krItemC"] != this.dsList02_1[i]["krItemC"] ||
          thisWin.fn_getNumber(thisWin.dsList02[i]["appnStrtRto"]) !=
            thisWin.fn_getNumber(this.dsList02_1[i]["appnStrtRto"]) ||
          thisWin.dsList02[i]["appnMethC"] != this.dsList02_1[i]["appnMethC"]
        ) {
          //for( let i1 =0 ; i1 <= thisWin.grd_list02.getCellCount("body") ; i1++ ){
          //    thisWin.grd_list02.setCellProperty( "Body", i1 , "color", "red" );
          //    thisWin.grd_list02.setCellProperty( "Body", i1 , "color2", "red" );
          //}
          break;
        }
      }

      for (let i = 0; i < thisWin.dsList06.length; i++) {
        if (
          thisWin.dsList06.length != this.dsList06_1.length ||
          thisWin.fn_getNumber(thisWin.dsList06[i]["sectSeq"]) !=
            thisWin.fn_getNumber(this.dsList06_1[i]["sectSeq"]) ||
          thisWin.fn_getNumber(thisWin.dsList06[i]["bnfTfrt"]) !=
            thisWin.fn_getNumber(this.dsList06_1[i]["bnfTfrt"]) ||
          thisWin.fn_getNumber(thisWin.dsList06[i]["overPrrt"]) !=
            thisWin.fn_getNumber(this.dsList06_1[i]["overPrrt"])
        ) {
          //for( let i1 =0 ; i1 <= thisWin.grd_list06.getCellCount("body") ; i1++ ){
          //    thisWin.grd_list06.setCellProperty( "Body", i1 , "color", "red" );
          //    thisWin.grd_list06.setCellProperty( "Body", i1 , "color2", "red" );
          //}
          break;
        }
      }

      thisWin.fn_Init_Btn_Enable();
    },
    /**********************************************************************************
     * 수정 항목 표시
     **********************************************************************************/
    fn_callBackChanColor(res) {
      let dsDetail_1 = TSCommUtil.gfn_getMapFrBase(dsDetail_b, res.data); //{}
      let dsList01_1 = TSCommUtil.gfn_getArrFrBase(
        dsList01_1_b,
        res.data.tfabni
      ); //[]
      let dsList02_1 = TSCommUtil.gfn_getArrFrBase(dsList02_b, res.data.tfaoil); //[]
      let dsList03_1 = TSCommUtil.gfn_getArrFrBase(
        dsList03_1_b,
        res.data.tfaivd
      ); //[]
      let dsList04_1 = TSCommUtil.gfn_getArrFrBase(
        dsList04_1_b,
        res.data.tfared
      ); //[]
      let dsList05_1 = TSCommUtil.gfn_getArrFrBase(
        dsList05_1_b,
        res.data.tfambd
      ); //[]
      let dsList06_1 = TSCommUtil.gfn_getArrFrBase(
        dsList06_1_b,
        res.data.tfarfi
      ); //[]

      if (thisWin.mtrtDate != dsDetail_1["mtrtDate"]) {
        //thisWin.cal_mtrtDate.style.set_color( "red");
      }
      if (thisWin.mtrtAfTfrt != dsDetail_1["mtrtAfTfrt"]) {
        //thisWin.mae_mtrtAfTfrt.style.set_color( "red");
      }

      if (thisWin.trstAmt != dsDetail_1["trstAmt"]) {
        //thisWin.mae_trstAmt.style.set_color( "red");
      }

      if (thisWin.agntCsId != dsDetail_1["agntCsId"]) {
        //thisWin.edt_agntCsId.style.set_color( "red");
        //thisWin.edt_agntCsIdNm.style.set_color( "red");
      }

      if (thisWin.basTfrt != dsDetail_1["basTfrt"]) {
        //thisWin.mae_basTfrt.style.set_color( "red");
      }

      if (thisWin.opdtNtcTc != dsDetail_1["opdtNtcTc"]) {
        //thisWin.cbo_opdtNtcTc.style.set_color( "red");
        //thisWin.cbo_opdtNtcTc.style.set_itemcolor( "red");
      }

      if (thisWin.soptTc != dsDetail_1["soptTc"]) {
        //thisWin.cbo_soptTc.style.set_color( "red");
      }
      ////
      if (thisWin.tmtlNeedDds != dsDetail_1["tmtlNeedDds"]) {
        //thisWin.mae_tmtlNeedDds.style.set_color( "red");
      }
      //정기보수여부
      if (thisWin.aocpRgfeYn != dsDetail_1["aocpRgfeYn"]) {
        //thisWin.cbo_aocpRgfeYn.style.set_color( "red");
      }
      //별납보수구분
      if (thisWin.epayPoaoTc != dsDetail_1["epayPoaoTc"]) {
        //thisWin.cbo_epayPoaoTc.style.set_color( "red");
      }
      //별납정률정액구분
      if (thisWin.epayFxrtFamtTc != dsDetail_1["epayFxrtFamtTc"]) {
        //thisWin.cbo_epayFxrtFamtTc.style.set_color( "red");
      }
      //별납주기
      if (thisWin.epayCyclTc != dsDetail_1["epayCyclTc"]) {
        //thisWin.cbo_epayCyclTc.style.set_color( "red");
      }
      //별납보수율
      if (thisWin.epayTfrt != dsDetail_1["epayTfrt"]) {
        //thisWin.mae_epayTfrt.style.set_color( "red");
      }
      //금액
      if (thisWin.epayFeeAmt != dsDetail_1["epayFeeAmt"]) {
        //thisWin.mae_epayFeeAmt.style.set_color( "red");
      }
      ////
      if (thisWin.bnfcDethYn != dsDetail_1["bnfcDethYn"]) {
        //thisWin.cbo_bnfcDethYn.style.set_color( "red");
      }
      if (thisWin.bnfcDethDate != dsDetail_1["bnfcDethDate"]) {
        //thisWin.cal_bnfcDethDate.style.set_color( "red");
      }

      //2019.03.19 add
      if (thisWin.frBondCpTc != dsDetail_1["frBondCpTc"]) {
        //thisWin.cbo_frBondCp.style.set_color( "red");
      }

      //2019.11.19 add : 판매직원 변경 승인 시, 변경내역 빨간색 표시

      if (thisWin.saleEmno != dsDetail_1["saleEmno"]) {
        //thisWin.edt_saleEmno.style.set_color( "red");
      }
      if (thisWin.saleEmnoNm != dsDetail_1["saleEmnoNm"]) {
        //thisWin.edt_saleEmnoNm.style.set_color( "red");
      }

      /*
      dsDetail :: 변경 후 데이터
      dsDetail_1 :: 변경 전 데이터
      */
      for (let i = 0; i < thisWin.dsList01.length; i++) {
        if (
          thisWin.dsList01.length != dsList01_1.length ||
          thisWin.dsList01[i]["bnfcSeq"] != dsList01_1[i]["bnfcSeq"] ||
          thisWin.dsList01[i]["bnfcRatingTc"] !=
            dsList01_1[i]["bnfcRatingTc"] ||
          thisWin.dsList01[i]["fxrtFamtTc"] != dsList01_1[i]["fxrtFamtTc"] ||
          thisWin.dsList01[i]["fxppYn"] != dsList01_1[i]["fxppYn"] ||
          thisWin.dsList01[i]["fxppMethC"] != dsList01_1[i]["fxppMethC"] ||
          thisWin.dsList01[i]["fxppStrtDate"] !=
            dsList01_1[i]["fxppStrtDate"] ||
          thisWin.dsList01[i]["fxppEndDate"] != dsList01_1[i]["fxppEndDate"] ||
          thisWin.dsList01[i]["ftrmPymCycl"] != dsList01_1[i]["ftrmPymCycl"] ||
          thisWin.dsList01[i]["ftrmPymDdTc"] != dsList01_1[i]["ftrmPymDdTc"] ||
          thisWin.dsList01[i]["pymRto"] != dsList01_1[i]["pymRto"] ||
          thisWin.dsList01[i]["pymAmt"] != dsList01_1[i]["pymAmt"] ||
          thisWin.dsList01[i]["prinBnfcCsId"] !=
            dsList01_1[i]["prinBnfcCsId"] ||
          thisWin.dsList01[i]["prinBnfcBnkSseq"] !=
            dsList01_1[i]["prinBnfcBnkSseq"] ||
          thisWin.dsList01[i]["prftBnfcCsId"] !=
            dsList01_1[i]["prftBnfcCsId"] ||
          thisWin.dsList01[i]["prftBnfcBnkSseq"] !=
            dsList01_1[i]["prftBnfcBnkSseq"]
        ) {
          //thisWin.cbo_trBkcdNmbnkAcno00.style.set_color( "red");
          //thisWin.edt_prftBnfcRcno_mask.style.set_color( "red");
          //thisWin.cbo_trBkcdNmbnkAcno01.style.set_color( "red");

          break;
        }
      }
      for (let i = 0; i < thisWin.dsList02.length; i++) {
        if (
          thisWin.dsList02.length != dsList02_1.length ||
          thisWin.fn_getNumber(thisWin.dsList02[i]["appnEndRto"]) !=
            thisWin.fn_getNumber(dsList02_1[i]["appnEndRto"]) ||
          thisWin.dsList02[i]["drtsOtxtLossRtTc"] !=
            dsList02_1[i]["drtsOtxtLossRtTc"] ||
          thisWin.dsList02[i]["opdtExpl"] != dsList02_1[i]["opdtExpl"] ||
          thisWin.fn_getNumber(thisWin.dsList02[i]["appnStrtRto"]) !=
            thisWin.fn_getNumber(dsList02_1[i]["appnStrtRto"]) ||
          thisWin.dsList02[i]["appnMethC"] != dsList02_1[i]["appnMethC"]
        ) {
          for (
            let i1 = 0;
            i1 <= thisWin.grd_list02.getCellCount("body");
            i1++
          ) {
            //thisWin.grd_list02.setCellProperty( "Body", i1 , "color", "red" );
            //thisWin.grd_list02.setCellProperty( "Body", i1 , "color2", "red" );
          }

          break;
        }
      }
      for (let i = 0; i < thisWin.dsList03.length; i++) {
        if (
          thisWin.dsList03.length != dsList03_1.length ||
          thisWin.dsList03[i]["opratIndcNo"] != dsList03_1[i]["opratIndcNo"] ||
          thisWin.dsList03[i]["astsType"] != dsList03_1[i]["astsType"] ||
          thisWin.dsList03[i]["secusNo"] != dsList03_1[i]["secusNo"] ||
          thisWin.dsList03[i]["dnmn"] != dsList03_1[i]["dnmn"] ||
          thisWin.dsList03[i]["snbQty"] != dsList03_1[i]["snbQty"] ||
          thisWin.dsList03[i]["snbFaceAmt"] != dsList03_1[i]["snbFaceAmt"] ||
          thisWin.dsList03[i]["trstPric"] != dsList03_1[i]["trstPric"] ||
          thisWin.dsList03[i]["itemNm"] != dsList03_1[i]["itemNm"]
        ) {
          for (
            let i1 = 0;
            i1 <= thisWin.grd_list03.getCellCount("body");
            i1++
          ) {
            //thisWin.grd_list03.setCellProperty( "Body", i1 , "color", "red" );
            //thisWin.grd_list03.setCellProperty( "Body", i1 , "color2", "red" );
          }

          break;
        }
      }

      for (let i = 0; i < thisWin.dsList04.length; i++) {
        if (
          thisWin.dsList04.length != dsList04_1.length ||
          thisWin.dsList04[i]["opratIndcNo"] != dsList04_1[i]["opratIndcNo"] ||
          thisWin.dsList04[i]["realEsttTc"] != dsList04_1[i]["realEsttTc"] ||
          thisWin.dsList04[i]["realEsttLoct"] !=
            dsList04_1[i]["realEsttLoct"] ||
          thisWin.dsList04[i]["plmk"] != dsList04_1[i]["plmk"] ||
          thisWin.dsList04[i]["lcat"] != dsList04_1[i]["lcat"] ||
          thisWin.dsList04[i]["pnstaCntn"] != dsList04_1[i]["pnstaCntn"] ||
          thisWin.dsList04[i]["flrByLdar"] != dsList04_1[i]["flrByLdar"] ||
          thisWin.dsList04[i]["trstPric"] != dsList04_1[i]["trstPric"]
        ) {
          for (
            let i1 = 0;
            i1 <= thisWin.grd_list04.getCellCount("body");
            i1++
          ) {
            //thisWin.grd_list04.setCellProperty( "Body", i1 , "color", "red" );
            //thisWin.grd_list04.setCellProperty( "Body", i1 , "color2", "red" );
          }
          break;
        }
      }

      for (let i = 0; i < thisWin.dsList05.length; i++) {
        if (
          thisWin.dsList05.length != dsList05_1.length ||
          thisWin.dsList05[i]["opratIndcNo"] != dsList05_1[i]["opratIndcNo"] ||
          thisWin.dsList05[i]["astsType"] != dsList05_1[i]["astsType"] ||
          thisWin.dsList05[i]["bondKind"] != dsList05_1[i]["bondKind"] ||
          thisWin.dsList05[i]["debtMnNm"] != dsList05_1[i]["debtMnNm"] ||
          thisWin.dsList05[i]["bondFaceAmt"] != dsList05_1[i]["bondFaceAmt"] ||
          thisWin.dsList05[i]["trstPric"] != dsList05_1[i]["trstPric"] ||
          thisWin.dsList05[i]["note"] != dsList05_1[i]["note"] ||
          thisWin.dsList05[i]["eaiNote"] != dsList05_1[i]["eaiNote"]
        ) {
          for (
            let i1 = 0;
            i1 <= thisWin.grd_list05.getCellCount("body");
            i1++
          ) {
            //thisWin.grd_list05.setCellProperty( "Body", i1 , "color", "red" );
            //thisWin.grd_list05.setCellProperty( "Body", i1 , "color2", "red" );
          }
          break;
        }
      }
      for (let i = 0; i < thisWin.dsList06.length; i++) {
        if (
          thisWin.dsList06.length != dsList06_1.length ||
          thisWin.fn_getNumber(thisWin.dsList06[i]["sectSeq"]) !=
            thisWin.fn_getNumber(dsList06_1[i]["sectSeq"]) ||
          thisWin.fn_getNumber(thisWin.dsList06[i]["bnfTfrt"]) !=
            thisWin.fn_getNumber(dsList06_1[i]["bnfTfrt"]) ||
          thisWin.fn_getNumber(thisWin.dsList06[i]["overPrrt"]) !=
            thisWin.fn_getNumber(dsList06_1[i]["overPrrt"])
        ) {
          for (
            let i1 = 0;
            i1 <= thisWin.grd_list06.getCellCount("body");
            i1++
          ) {
            //thisWin.grd_list06.setCellProperty( "Body", i1 , "color", "red" );
            //thisWin.grd_list06.setCellProperty( "Body", i1 , "color2", "red" );
          }

          break;
        }
      }
      thisWin.fn_Init_Btn_Enable();
    },
    /**********************************************************************************
     *추가입금 모드로 컨트롤 활성화
     **********************************************************************************/
    fn_insertEnableCompAdd() {
      this.fn_setTotActive();

      // this.fn_Init_Btn_Enable();
      // this.fn_enableComponent("btnConfirm,btnCancel" );
      // this.sSvcID  =  "updateTFAC20000B"
      // let trstTypC = this.trstTypC;
      // if ( (trstTypC != "001" && trstTypC != "080") && this.poaoTc == "1" && this.epayFxrtFamtTc == "1" ){// 별납정률 20230321 유언대용신탁(금전)추가
      //   //행추가/삭제
      //   TSCommUtil.gfn_setVisibleArr(this,["visInsertRow00"],true);
      //   //운용지시,유가증권내역,부동산재산,금전채권신탁
      //   TSCommUtil.gfn_setDisableArr(this,["disGrdList02","disGrdList03","disGrdList04","disGrdList05"],false);
      //   this.fn_visibleGrid();
      // }else{
      //   //신탁금액
      //   TSCommUtil.gfn_setDisableArr(this,["disTrstAmt"],false);
      // }
      // this.trstAmtNm = "추가금액";
      // let trstAmt = this.trstAmt; //추가 금액
      // let trstOtxtAmt = this.dsDetail[0]["trstOtxtAmt"]; // 원래 신탁금액

      // if ( this.fn_getNumber( trstOtxtAmt)  ==  this.fn_getNumber( trstAmt ) ){ //초기 추가입금
      //   this.trstAmt = "0";
      // }else{ // 추가입금 수정
      //   this.trstAmt =  (this.fn_getNumber( trstAmt )  - this.fn_getNumber( trstOtxtAmt  ))+"";
      // }
      // //유선구분 없음
      // //판매직원/판매직원팝업
      // TSCommUtil.gfn_setDisableArr(this,["disIsueYn","disSaleEmno"],true);

      // //해외채권 쿠폰처리여부(추가/변경 비활성화)
      // TSCommUtil.gfn_setDisableArr(this,["disFrBondCpTc"],false);

      // //정액정률,계약관계자
      // TSCommUtil.gfn_setDisableArr(this,["disEpayFxrtFamtTc","disAocpRgfeFamtTc","disRelated"],false);
      // if (this.epayFxrtFamtTc == "2"){
      //   //정액
      //   TSCommUtil.gfn_setVisibleArr(this, ["visEpayTfrt"], false);
      // }
    },
    /**********************************************************************************
     *변경 모드로 컨트롤 활성화
     **********************************************************************************/
    fn_insertEnableCompChan() {
      this.fn_setTotActive();

      //       this.fn_Init_Btn_Enable();
      //       this.fn_enableComponent("btnConfirm,btnCancel" );
      //       this.sSvcID  =  "updateTFAC20000B"

      //       let trstTypC = this.trstTypC;
      //       let cboChngSeq = this.cboChngSeq;
      //       //만기일,만기후보수율,운영내역통보,자타익구분
      //       TSCommUtil.gfn_setDisableArr(this,["disMtrtDate","disMtrtAfTfrt","disOpdtNtcTc","disSoptTc"],false);
      //       //원금수익자,이익수익자,스케쥴 관리
      //       if (cboChngSeq==null || cboChngSeq==""){
      //         TSCommUtil.gfn_setDisableArr(this,["btnCustInfo00","btnCustInfo01","btnCustInfoReg"],false);
      //       }else{
      //         TSCommUtil.gfn_setDisableArr(this,["btnCustInfo00","btnCustInfo01","btnCustInfoReg"],true);
      //       }
      //       //원금수익자계좌선택,이익수익자 계좌 팝업
      //       if (cboChngSeq==null || cboChngSeq==""){
      //         TSCommUtil.gfn_setDisableArr(this,["disTrBkcdNmbnkAcno00","disTrBkcdNmbnkAcno01"],false);
      //       }else{
      //         TSCommUtil.gfn_setDisableArr(this,["disTrBkcdNmbnkAcno00","disTrBkcdNmbnkAcno01"],true);
      //       }

      // //      this.fn_custRcnoMask();      //개인정보 마스킹

      //       //(후취) 기본보수율,선후취구분,주기
      //       TSCommUtil.gfn_setDisableArr(this,["disBasTfrt","disEpayPoaoTc","disEpayCyclTc"],false);

      //       this.cbo_epayPoaoTc_onitemchanged   () ; // visible

      //       //정기보수여부
      //       TSCommUtil.gfn_setDisableArr(this,["disAocpRgfeYn"],false);
      //       this.cbo_aocpRgfeYn_onitemchanged   () ; // visible
      //       //주기,별납정률정액구분
      //       TSCommUtil.gfn_setDisableArr(this,["disAocpRgfeCyclTc","disEpayFxrtFamtTc"],false);
      //       //별납보수율,별납보수 금액,별납보수 금액2 ( 정률용 )
      //       TSCommUtil.gfn_setDisableArr(this,["disEpayTfrt"],false);
      //       TSCommUtil.gfn_setDisableArr(this,["disEpayFeeAmt"],true);
      //       //금액cbo_epayFxrtFamtTc
      //       TSCommUtil.gfn_setDisableArr(this,["disAocpRgfe"],false);
      //       this.cbo_aocpRgfeYn_onitemchanged(); // visible
      //       //정률정액구분
      //       TSCommUtil.gfn_setDisableArr(this,["disAocpRgfeFamtTc"],false);
      //       this.cbo_poaoTc_onitemchanged();         // 포함컨트롤 visible
      //       //해지소요일수,유선구분(없음),대리인팝업,사망여부,사망일자
      //       TSCommUtil.gfn_setDisableArr(this,["disTmtlNeedDds","disAgntCsId","disBnfcDethYn","disBnfcDethDate"],false);
      //       //행추가/삭제
      //       TSCommUtil.gfn_setVisibleArr(this,["visInsertRow"],true);
      //       //수익보수율 없음
      //       //if (this.visStatic92){
      //         TSCommUtil.gfn_setVisibleArr(this,["visInsertRow00"],true);
      //       //}

      //       //운용지시,유가증권내역,부동산재산,금전채권신탁
      //       TSCommUtil.gfn_setDisableArr(this,["disGrdList02","disGrdList03","disGrdList04","disGrdList05"],false);

      //       //해외채권 쿠폰처리여부,발행여부,판매직원/판매직원팝업
      //       TSCommUtil.gfn_setDisableArr(this,["disFrBondCpTc","disIsueYn","disSaleEmno"],false);
      //       //해피콜 없음
      //       //부동산 운용
      //       if (thisWin.trstTypC == "002" || thisWin.trstTypC == "003" || thisWin.trstTypC == "004"){
      //         TSCommUtil.gfn_setDisableArr(this,["disGrdList02"],true);
      //       }

      //       if (this.radProcessGb == "3"){
      //         //정액정률,계약관계자
      //         TSCommUtil.gfn_setDisableArr(this,["disEpayFxrtFamtTc","disAocpRgfeFamtTc","disRelated"],false);
      //       }

      //       if (this.epayFxrtFamtTc == "2"){
      //         //정액
      //         TSCommUtil.gfn_setVisibleArr(this, ["visEpayTfrt"], false);
      //       }
    },
    /**********************************************************************************
     * AML 확인
     **********************************************************************************/
    fn_AML_Logic_2000() {
      let acnoInfo = this.fn_getAcnoCondInfo();
      if (this.radProcessGb == "1") {
        if (this.cboCtno.length < 1) {
          return false;
        }
        //let idx = TSCommUtil.gfn_getIdxArrMapKeyVal(this.dsList08Acno,"value",this.cboAcno);   //선택된 통장번호
        //let msgCntn = aprvBusnTc1 + this.dsList08[idx]["tacnoBnkbSeq"].replace("-","") + TSCommUtil.gfn_lpad( this.cboCtno , 3  , "0" ) + this.dsList00["cnm"];
        //;this.amlCtno = this.dsList08[idx]["tacno"] + this.dsList08[idx]["bnkbSeq"] + TSCommUtil.gfn_lpad(this.cboCtno , 3 , "0" );
        this.amlCtno =
          acnoInfo["tacno"] +
          acnoInfo["bnkbSeq"] +
          TSCommUtil.gfn_lpad(this.cboCtno, 3, "0");
      } else {
        if (this.cboChngSeq.length < 1) {
          return false;
        }
        this.amlCtno = acnoInfo["tacno"] + acnoInfo["bnkbSeq"] + this.cboAccSeq;
      }
      if (this.amlCtno.length == 13) {
        let vNowGb = this.fn_getNumber(this.dsDetail[0]["amlAccept"]);
        if (vNowGb == 1) {
          //aml button
          TSCommUtil.gfn_setDisableArr(this, ["btnAml"], false);

          this.btnAdmissionText = "";
          TSCommUtil.gfn_setVisibleArr(this, ["visBtnAdmission"], false);
          thisWin.$ToastManager.fn_ShowToast({
            text: "수정가능합니다. (승인요청은 AML고객인증 후 가능)",
          });
        } else if (vNowGb == 2) {
          TSCommUtil.gfn_setDisableArr(this, ["btnAml"], false);
          thisWin.$ToastManager.fn_ShowToast({
            text:
              "AML고객인증을 완료 하십시오. (승인요청은 AML고객인증 후 가능)",
          });
          this.btnAdmissionText = "";
          TSCommUtil.gfn_setVisibleArr(this, ["visBtnAdmission"], false);
        } else if (vNowGb == 3) {
          TSCommUtil.gfn_setDisableArr(this, ["btnAml"], false);
          thisWin.$ToastManager.fn_ShowToast({
            text:
              "해당 고객님은 당사와 거래할 수 없는 거래거절 고객입니다. 진행이 불가하오니 신규입력 계약건을 삭제하시기 바랍니다.",
          });
          this.btnAdmissionText = "";
          TSCommUtil.gfn_setVisibleArr(this, ["visBtnAdmission"], false);
        } else {
          //승인요청
          TSCommUtil.gfn_setDisableArr(this, ["btnAml"], true);
          this.btnAdmissionText = "승인요청";
          TSCommUtil.gfn_setVisibleArr(this, ["visBtnAdmission"], true);
        }
        this.fn_enableComponent("btnSearchList,btnUpdate,btnDelete");
      } else {
        this.btnAdmissionText = "";
        TSCommUtil.gfn_setDisableArr(this, ["btnAml"], true);
        TSCommUtil.gfn_setVisibleArr(this, ["visBtnAdmission"], false);
        //this.Div02.Btn_AML.set_enable( false );   //???
      }
    },
    /**********************************************************************************
     * 입력 : WFtitleCRUDbtn 의 callback
     **********************************************************************************/
    fn_insert() {
      //서버에 호출 할 정보를 셋팅해준다.
      this.sSvcID = "insertTFAC20000";
      this.fn_enableComponent("btnConfirm,btnCancel");

      if (this.dsList08.length == 0) {
        this.fn_alert("등록된 계좌가 없습니다.");
        return false; //dsList08 값 없을 시
      }

      let idx = TSCommUtil.gfn_getIdxArrMapKeyVal(
        this.dsList08Acno,
        "value",
        this.cboAcno
      ); //선택된 통장번호
      if (this.dsList08[idx]["bnkbStatC"] == "14") {
        this.fn_alert("폐쇄 통장입니다.");
      }

      if (thisWin.radProcessGb == "1") {
        if (
          thisWin.dsDetail03 !== null &&
          thisWin.fn_getNumber(thisWin.dsDetail03["ctnoCnt"]) >= 9 &&
          thisWin.fn_getNumber(thisWin.dsDetail03["bnkbSeq"]) !== 0 //추가 - ASR240101094 / 무통장(999) 최대 계약 계좌수 확대
        ) {
                thisWin.fn_alert([
                  "통장에 거래가 다 찼습니다.",
                  "다른 통장을 이용하십시오.",
                ]);
        }
      }

      if (this.edtAcno1.length != 7) {
        this.fn_alert("고객번호를 확인하십시오.");
        //this.$refs.rfEdtAcno1.focus();
      }

      //this.dsDetail = [];
      this.fn_formClear();
      //신탁유형,상품유형,신탁상품/신탁상품팝업
      TSCommUtil.gfn_setDisableArr(
        this,
        ["disTrstTypC", "disGdTypDtlC", "disGdC"],
        false
      );
    },
    /**********************************************************************************
     * call back
     **********************************************************************************/
    fn_callBackSub(svcID, res) {
      //confirm 처리
      if (svcID == "selectTFAC20000A") {
        let gdTypDtlC2 = thisWin.gdTypDtlC;
        let parseng = false;
        // 파생여부 확인
        if (thisWin.trstTypC == "001" && gdTypDtlC2 == "5") {
          parseng = true;
        } else {
          for (let i = 0; i < thisWin.dsList02.length; i++) {
            if (
              thisWin.dsList02[i]["appnMethC"] == "10" ||
              thisWin.dsList02[i]["appnMethC"] == "13" ||
              thisWin.dsList02[i]["appnMethC"] == "14"
            ) {
              // 파생결합증권
              parseng = true;
            }
          }
        }
        let vCareYn = thisWin.dsDetail[0]["careYn"];
        //계좌개설일자 확인 후 투자숙려제도 대상이 보여야 한다.
        //신탁유형:특정금전신탁, //20230307 유언대용신탁(금전)추가
        if (
          thisWin.trstTypC == "001" ||
          thisWin.trstTypC == "007" ||
          thisWin.trstTypC == "008"
        ) {
          //2018.05.24 : 기존 투자자숙려제도 팝업 주석처리 (팝업 발생기준 변경)
          if (thisWin.fn_lifeAge() < thisWin.oldAge) {
            //2019.08.20
            //파생상품&숙려제도여부"Y"인 경우, 계약승인신청일은 최소 발행일자의 2영업일 전 이어야 한다
            if (
              parseng &&
              vCareYn == "Y" &&
              thisWin.gfn_minusDate(thisWin.dsDetail[0]["issueDate"], 2) <
                thisWin.busyDate
            ) {
              let wArr = [];
              wArr.push(
                "투자자숙려제도 대상은 계약승인신청일이 최소 발행일자의 2영업일 전 이어야 합니다."
              );
              wArr.push("해당 상품의 발행일자를 확인해 주시기 바랍니다.");
              thisWin.fn_alert(
                wArr,
                function() {
                  return true;
                },
                function() {
                  thisWin.fn_initBody();
                  return false;
                }
              );
              return;
            }
          }
        }
      }
      thisWin.fn_callBackSubJob(svcID, res);
    },
    fn_callBackSubJob(svcID, res) {
      try {
        if (svcID == "selectTFAC20000" || svcID == "selectTFAC20000B") {
          thisWin.fn_Init_Btn_Enable();
          if (
            thisWin.dsDetail.length > 0 &&
            !_.isEmpty(thisWin.dsDetail[0]["tacno"])
          ) {
            //2018.06.28 add
            let frBondCpTc = thisWin.frBondCpTc;
            if (!_.isEmpty(frBondCpTc)) {
              //해외채권쿠폰값이 존재하면
              thisWin.frBondReInvCallBackFunc();
              thisWin.frBondCpTc = frBondCpTc;
            }
            let aprvStatTc = thisWin.aprvStatTc;

            let aprvBusnTc1 = thisWin.aprvBusnTc1;
            //let gdC      =  thisWin.gdC;        //상품코드
            //let gdNm      =  thisWin.gdNm;        //상품명
            let slipSeq =
              _.isEmpty(thisWin.dsList07) || thisWin.dsList07.length < 1
                ? ""
                : thisWin.dsList07[0]["slipSeq"];
            let procDate =
              _.isEmpty(thisWin.dsList07) || thisWin.dsList07.length < 1
                ? ""
                : thisWin.dsList07[0]["procDate"]; // 처리일

            if (!_.isEmpty(thisWin.dsDetail[0]["moGdC"])) {
              //모계약변호
              TSCommUtil.gfn_setVisibleArr(this, ["visMoCtno"], true);
            } else {
              //모계약변호
              TSCommUtil.gfn_setVisibleArr(this, ["visMoCtno"], false);
            }
            //20230307 유언대용신탁(금전)추가
            let ivRskRatingC = thisWin.ivRskRatingC;
            let csIvtdRatingC = thisWin.csIvtdRatingC;
            if (
              _.isEmpty(ivRskRatingC) ||
              (thisWin.trstTypC != "001" &&
                thisWin.trstTypC != "007" &&
                thisWin.trstTypC != "008")
            ) {
              // 2021-08-31 add 치매신탁 추가:: 특정금전신탁과 같은 로직에서 처리
              thisWin.ivRskRatingC = ""; //"- 해당 없음 -"
            } else if (thisWin.fn_getNumber(ivRskRatingC) == 0) {
              thisWin.static83 = "상품등급확인불가";
            } else if (
              _.isEmpty(csIvtdRatingC) ||
              thisWin.fn_getNumber(csIvtdRatingC) == 0
            ) {
              thisWin.static83 = "고객등급확인불가";
            } else if (
              thisWin.fn_getNumber(ivRskRatingC) <
              thisWin.fn_getNumber(csIvtdRatingC)
            ) {
              thisWin.static83 = "비적합상품";
            } else {
              thisWin.static83 = "적합상품";
            }

            let gdTypDtlC = thisWin.gdTypDtlC;
            if (
              (thisWin.trstTypC == "001" && gdTypDtlC == "A") ||
              (thisWin.trstTypC == "001" && gdTypDtlC == "B") ||
              (thisWin.trstTypC == "002" && gdTypDtlC == "A") ||
              (thisWin.trstTypC == "002" && gdTypDtlC == "B") ||
              thisWin.trstTypC == "003" ||
              thisWin.trstTypC == "010" //유언대용신탁(부동산)추가 20230321
            ) {
              //계약관계자
              //thisWin.$refs.rfRelated  //class: "btn_WFSA_Search"
            } else {
              //thisWin.$refs.rfRelated  //class: ""
            }

            // 2021-12-17 add 치매신탁보완사항 :: 치매신탁 시 계약관계자 - 신탁관리인 입력 필수로 필수입력 표기
            if (thisWin.trstTypC == "007") {
              //thisWin.$refs.rfStatic79 //class:sta_WF_Label_P
              //thisWin.$refs.rfRelated  //class: "btn_WFSA_Search"
            } else {
              //thisWin.$refs.rfStatic79 //class:sta_WF_LabelLtLine
              //thisWin.$refs.rfRelated  //class: ""
            }

            //신탁유형코드 == "특정금전신탁" && 상품유형상세코드 == "파생상품"
            if (thisWin.trstTypC == "001" && gdTypDtlC == "5") {
              thisWin.parseng = true;
            } else {
              for (let i = 0; i < thisWin.dsList02.length; i++) {
                if (
                  thisWin.dsList02[i]["appnMethC"] == "10" ||
                  thisWin.dsList02[i]["appnMethC"] == "13" ||
                  thisWin.dsList02[i]["appnMethC"] == "14"
                ) {
                  // 파생결합증권
                  thisWin.parseng = true;
                }
              }
            }

            thisWin.cbo_trstTypCTogdTypDtlC();

            thisWin.fn_dsDetailToForm();

            if (thisWin.dsDetail[0]["contDate"] > "20150701") {
              //유언대용신탁(부동산)추가 20230321
              thisWin.addValue = Math.round(
                thisWin.fn_getNumber(thisWin.epayFeeAmt) *
                  0.1 *
                  (thisWin.trstTypC == "003" || thisWin.trstTypC == "010"
                    ? 1
                    : 0)
              );
            } else {
              thisWin.addValue = 0;
            }

            if (
              thisWin.trstTypC == "001" &&
              gdTypDtlC == "E" &&
              thisWin.radProcessGb == "2"
            ) {
              //추가입금이고, ISA 경우

              //추가계약 ISA 한도금액 조회
              let openDt = thisWin.dsDetail[0]["contDate"];
              let expDt1 = thisWin.addDateYmd(
                DateUtil.fn_AddMonth(openDt, 1 * 12),
                -1
              ); //가입 1년뒤 만기일자
              let expDt2 = thisWin.addDateYmd(
                DateUtil.fn_AddMonth(openDt, 2 * 12),
                -1
              ); //가입 2년뒤 만기일자
              let expDt3 = thisWin.addDateYmd(
                DateUtil.fn_AddMonth(openDt, 3 * 12),
                -1
              ); //가입 3년뒤 만기일자
              let expDt4 = thisWin.addDateYmd(
                DateUtil.fn_AddMonth(openDt, 4 * 12),
                -1
              ); //가입 4년뒤 만기일자
              let expDt5 = thisWin.addDateYmd(
                DateUtil.fn_AddMonth(openDt, 5 * 12),
                -1
              ); //가입 5년뒤 만기일자

              let start_dt;
              let end_dt;
              let gDate = thisWin.busyDate;

              if (gDate <= expDt1) {
                start_dt = openDt;
                end_dt = gDate;
              } else if (gDate > expDt1 && gDate <= expDt2) {
                start_dt = thisWin.addDateYmd(expDt1, 1);
                end_dt = gDate;
              } else if (gDate > expDt2 && gDate <= expDt3) {
                start_dt = thisWin.addDateYmd(expDt2, 1);
                end_dt = gDate;
              } else if (gDate > expDt3 && gDate <= expDt4) {
                start_dt = thisWin.addDateYmd(expDt3, 1);
                end_dt = gDate;
              } else if (gDate > expDt4 && gDate <= expDt5) {
                start_dt = thisWin.addDateYmd(expDt4, 1);
                end_dt = gDate;
              }

              //selectTFAC25100C
              let a23Info = thisWin.fn_getAcnoCondInfo();
              let pParam = {
                tacno: a23Info["tacno"],
                bnkbSeq: a23Info["bnkbSeq"],
                accSeq: a23Info["accSeq"],
                startTrDate: start_dt,
                endTrDate: end_dt,
              };
              this.eaiCommObj.lv_vm = this;
              this.eaiCommObj.auth = "S";
              this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600108_S";
              this.eaiCommObj.params = pParam;
              TSServiceUtil.invokeInDirect(
                this.eaiCommObj,
                function(res) {
                  if (res && res.data) {
                    thisWin.dsListIsaAmt = TSCommUtil.gfn_getMapFrBase(
                      dsListIsa_b,
                      res.data
                    );
                  }
                },
                function(error) {
                  return;
                },
                "UF10600108"
              );
            }
            let pPage = "";
            // if (!_.isEmpty(thisWin.$router.params) && !_.isEmpty(thisWin.$router.params.screenId)){
            //   pPage = this.fn_getValue(thisWin.$router.params.screenId);
            // }
            if (pPage == "TFAC20601") {
              //신탁계약신규 내역 팝업

              thisWin.fn_Init_Btn_Enable();
              return;
            } else if (pPage == "TFAC20201") {
              //신규승인화면에서의 참조

              //selectTFAC20000A
              if (thisWin.dsDetail02.length < 1) {
                let pParam = {
                  gdC: thisWin.gdC,
                };
                this.eaiCommObj.lv_vm = this;
                this.eaiCommObj.auth = "S";
                this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600080_S";
                this.eaiCommObj.params = pParam;
                TSServiceUtil.invokeInDirect(
                  this.eaiCommObj,
                  function(res) {
                    if (res && res.data) {
                      thisWin.dsDetail02 = TSCommUtil.gfn_getArrFrBase(
                        dsDetail_b,
                        res.data.tfcprb
                      );
                      thisWin.fn_copyGoodsResult(thisWin.dsDetail02);
                      thisWin.dsList02_1 = TSCommUtil.gfn_getArrFrBase(
                        dsList02_b,
                        res.data.tfcpil
                      );
                      thisWin.dsList06_1 = TSCommUtil.gfn_getArrFrBase(
                        dsList06_1_b,
                        res.data.tfcpfi
                      );
                      //thisWin.fn_callBackNewColor();
                    }
                  },
                  function(error) {
                    return;
                  },
                  "UF10600080"
                );
              }

              try {
                // 법인고객의 경우에는 나이가 표지되지않도록 변경(신규시에만)
                if (thisWin.dsListS1["corpYn"] == "Y") {
                  thisWin.$refs.popup106.fn_adminRadio(
                    aprvStatTc,
                    aprvBusnTc1,
                    gdTypDtlC
                  );
                  //thisWin.lookupFunc("popup106").call(aprvStatTc, aprvBusnTc1 , gdTypDtlC  );
                } else {
                  thisWin.$refs.popup106.fn_adminRadio(
                    aprvStatTc,
                    aprvBusnTc1,
                    gdTypDtlC,
                    thisWin.fn_lifeAge()
                  );
                  //thisWin.lookupFunc("fn_adminRadio").call(aprvStatTc, aprvBusnTc1 , gdTypDtlC , thisWin.fn_lifeAge() );
                }
                return;
              } catch (e) {
                return;
              }
            } else if (pPage == "TFAC20203") {
              //변경
              //dataset초기화
              //selectTFAC20000B
              let a25Info = thisWin.fn_getAcnoCondInfo();
              let pParam = {
                tacno: a25Info["tacno"],
                bnkbSeq: a25Info["bnkbSeq"],
                accSeq: a25Info["accSeq"],
                procType: thisWin.fn_getNumber(thisWin.radProcessGb) - 1 + "",
                chngSeq: a25Info["chngSeq"],
              };
              this.eaiCommObj.lv_vm = this;
              this.eaiCommObj.auth = "S";
              this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600082_S";
              this.eaiCommObj.params = pParam;
              TSServiceUtil.invokeInDirect(
                this.eaiCommObj,
                function(res) {
                  if (res && res.data) {
                    //thisWin.fn_callBackChanColor(res);
                  }
                },
                function(error) {
                  return;
                },
                "UF10600082"
              );
              let hpcYn1 = thisWin.dsDetail02[0]["hpcYn"];//ASR240300857_[사랑on신탁]신규입력화면 해피콜서비스값 생성
              if( hpcYn1 == "Y" ) {
                ////해피콜
                TSCommUtil.gfn_setVisibleArr(this, ["visHpcTc"], true);
                TSCommUtil.gfn_setDisableArr(this, ["disHpcTc"], true);
                }

              //transaction을 호출하는 공통함수
              //thisWin.gfn_callService(thisWin.sSvcIDSub, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc, true, false);

              try {
                thisWin.$refs.popup106.fn_adminRadio(
                  aprvStatTc,
                  aprvBusnTc1,
                  gdTypDtlC,
                  thisWin.fn_lifeAge()
                );
                //thisWin.lookupFunc("fn_adminRadio").call(aprvStatTc, aprvBusnTc1 , gdTypDtlC , thisWin.fn_lifeAge() ); //2021-05-06 add
                return;
              } catch (e) {
                return;
              }
            } else {
              if (
                thisWin.radProcessGb == "2" &&
                thisWin.dsDetail[0]["addmIamtPosbYn"] == "N"
              ) {
                thisWin.fn_alert("해당계좌는 추가입금이 불가능합니다.");
                thisWin.fn_initBody();
                return;
              }
            }

            if (aprvStatTc == "11") {
              thisWin.$ToastManager.fn_ShowToast({
                text: "승인 요청중 입니다.",
              });
              thisWin.btnAdmissionText = "승인요청취소";
              TSCommUtil.gfn_setVisibleArr(this, ["visBtnAdmission"], true);
            } else if (aprvStatTc == "12" && thisWin.radProcessGb == "1") {
              thisWin.$ToastManager.fn_ShowToast({
                text: "승인 확정 되었습니다.",
              });

              if (
                aprvBusnTc1 != "1012" &&
                slipSeq == "0" &&
                procDate == thisWin.busyDate &&
                thisWin.dsDetail[0]["brigAccYn"] != "Y"
              ) {
                //&& thisWin.dsDetail[0]["aprvReqTc"] !="2" )  // 20100413
                thisWin.btnAdmissionText = "승인취소요청";
                TSCommUtil.gfn_setVisibleArr(this, ["visBtnAdmission"], true);
              }

              if (slipSeq > 0) {
                //적합성보고서 없음
              }
              //자동승인 상품일때
              if (
                aprvStatTc == "12" &&
                aprvBusnTc1 == "1011" &&
                slipSeq == "0"
              ) {
                //let idx = TSCommUtil.gfn_getIdxArrMapKeyVal(thisWin.dsList08Acno,"value",thisWin.cboAcno);   //선택된 통장번호
                //let msgCntn = aprvBusnTc1 + thisWin.dsList08[idx]["tacnoBnkbSeq"].replace("-","") + TSCommUtil.gfn_lpad( thisWin.cboCtno , 3  , "0" ) + thisWin.dsList00["cnm"];
                //let oArg       = { msgCntn: msgCntn};  //팝업화면으로 보낼 파라미터.
                //let sOption    = "autosize=true,title=true";       //팝업열때 옵션
                //let sPopupCallBack = "";            //팝업화면에서 보낸 파라미터 받는곳
                //popup 없음
              }
            } else if (aprvStatTc == "12" && thisWin.radProcessGb != "1") {
              thisWin.$ToastManager.fn_ShowToast({
                text: "승인 확정 되었습니다.",
              });

              if (
                aprvBusnTc1 == "1031" &&
                thisWin.dsDetail[0]["chngBaseDate"] == thisWin.busyDate
              ) {
                // 20100413
                //let edtAcno2 = thisWin.edtAcno2;
                //let cboAccSeq = thisWin.cboAccSeq;
                //let msgCntn = aprvBusnTc1 + edtAcno2.substr(0,7) +  edtAcno2.substr(7,3) + cboAccSeq + thisWin.dsList00["cnm"];
                //let oArg       = { msgCntn: msgCntn};  //팝업화면으로 보낼 파라미터.
                //let sOption    = "autosize=true,title=true";       //팝업열때 옵션
                //let sPopupCallBack = "";            //팝업화면에서 보낸 파라미터 받는곳
                //  thisWin.gfn_openPopup("TPUP96100","popup::TPUP96100.xfdl",oArg,sOption,sPopupCallBack);
                thisWin.btnAdmissionText = "승인취소요청";

                TSCommUtil.gfn_setVisibleArr(this, ["visBtnAdmission"], true);
              } else if (aprvBusnTc1 == "1021") {
                thisWin.btnAdmissionText = "승인취소요청";
                TSCommUtil.gfn_setVisibleArr(this, ["visBtnAdmission"], true);
              }
            } else if (aprvStatTc == "13" || aprvStatTc == "16") {
              //메모없음
            } else if (aprvStatTc == "19") {
              //적합성보고서 없음
            } else {
              if (thisWin.radProcessGb == "2") {
                if (thisWin.cboChngSeq == null || thisWin.cboChngSeq == "") {
                  thisWin.fn_insertEnableCompAdd();
                }
              } else if (thisWin.radProcessGb == "3") {
                if (thisWin.cboChngSeq == null || thisWin.cboChngSeq == "") {
                  thisWin.fn_insertEnableCompChan();
                }
              }
              thisWin.fn_AML_Logic_2000();
              //thisWin.fn_alert("승인요청 가능합니다.");
              if (this.btnAdmissionText == "승인요청") {
                thisWin.$ToastManager.fn_ShowToast({
                  text: "승인요청 가능합니다.",
                });
              }
            }
          } else {
            let brcd = thisWin.lv_basInfo.brcd;
            if (brcd == "916") {
              thisWin.fn_alert("신탁지원부는 처리 할 수 없습니다.");
              return;
            }
            //thisWin.$ToastManager.fn_ShowToast({ text: '등록된 내용이 없습니다.등록거래가 가능합니다.'})
            //thisWin.fn_alert("등록된 내용이 없습니다.등록거래가 가능합니다.");   //2021-04-13 불필요한 팝업 삭제요청 :: 조상준프로
            thisWin.fn_insert();
            return;
          }
          //if( thisWin.dsDetail[0]["aprvReqTc"] == "2" && aprvStatTc != "12"){
          //  //let rtn = thisWin.gfn_getMessage("CONFIRM","자동승인 상품입니다" );
          //}
        }
        if (svcID == "selectTFAC20000A") {
          if (thisWin.dsDetail02.length < 1) {
            thisWin.fn_initBody(false);
          }
          //2021.05.06 add
          if (thisWin.$options.screenId == "MSPTS200M") {
            //#1020화면에서만 체크
            //2021-05-07 add
            let sRcnoKindTc = thisWin.dsListS1["rcnoKindTc"]; // 실명번호구분 코드 (01:주민등록번호 04:외국인등록번호 05:국내거소신고번호)
            if (sRcnoKindTc == "") {
              sRcnoKindTc = thisWin.dsListS2[0]["rcnoKindTc"];
            }
            if (
              thisWin.fn_lifeAge() >= thisWin.fn_getNumber(thisWin.superAge) &&
              (sRcnoKindTc == "01" ||
                sRcnoKindTc == "04" ||
                sRcnoKindTc == "05" ||
                sRcnoKindTc == "06") &&
              thisWin.dsDetail02[0]["trstTypC"] == "001"
            ) {
              //2021.05.06
              thisWin.fn_alert(
                "만 " +
                  thisWin.superAge +
                  "세 이상 초고령자의 경우 유언대용신탁, 부동산신탁, 유가증권신탁, 치매신탁 외 상품가입이 불가합니다."
              );
              vTrstTypC = "";
              thisWin.trstTypC = "";
              thisWin.opdtNtcTc = ""; //운영내역통보
              return;
            }

            if (
              thisWin.fn_lifeAge() < thisWin.oldAge &&
              thisWin.dsDetail02[0]["oldExclYn"] == "Y" &&
              thisWin.radProcessGb == "1"
            ) {
              thisWin.fn_alert(
                "만" +
                  thisWin.oldAge +
                  "세 이상 고령투자자 전용상품 입니다. 상품코드를 확인하시기 바랍니다"
              );
              return;
            }
          } //#1020화면에서만 체크

          let gdC1 = thisWin.dsDetail02[0]["gdC"]; //상품코드
          let gdNm1 = thisWin.dsDetail02[0]["gdNm"]; //상품명
          //브릿지 여부확인
          if (gdC1 == "A1A001") {
            //브릿지
            thisWin.fn_alert("해당상품은 브릿지 상품입니다(등록불가).");
            thisWin.fn_initBody();
            return;
          } else if (TSCommUtil.gfn_indexOf(gdNm1, "브릿지") > -1) {
            thisWin.fn_alert(
              "해당상품은 브릿지 상품입니다. 확인후 등록하십시오."
            );
          }
          //d운용기간으로 설정시 만기일자 계산
          let appnTrmUnitC = thisWin.dsDetail02[0]["appnTrmUnitC"]; //운용기간단위
          let appnTrm = thisWin.fn_getNumber(thisWin.dsDetail02[0]["appnTrm"]);
          let vMtrtDate = "";
          if (
            !_.isEmpty(appnTrmUnitC) &&
            thisWin.fn_getNumber(appnTrmUnitC.trim()) == 1
          ) {
            vMtrtDate = thisWin.addDateYmd(thisWin.busyDate, appnTrm);
          } else if (
            !_.isEmpty(appnTrmUnitC) &&
            thisWin.fn_getNumber(appnTrmUnitC.trim()) == 2
          ) {
            vMtrtDate = DateUtil.fn_AddMonth(thisWin.busyDate, appnTrm);
          } else if (
            !_.isEmpty(appnTrmUnitC) &&
            thisWin.fn_getNumber(appnTrmUnitC.trim()) == 3
          ) {
            //hyh 2008.12.22
            vMtrtDate = DateUtil.fn_AddMonth(thisWin.busyDate, appnTrm * 12);
          } else {
            vMtrtDate = thisWin.dsDetail02[0]["mtrtDate"];
          }
          this.mtrtDate = vMtrtDate;
          this.fn_setCalendar("rfMtrtDate", this.mtrtDate);

          //기존 source detail02 -> detail ==> thisWin.fn_copyGoodsResult로 대처(조회 할 때)

          this.csIvtdRatingC = this.fn_getValue(
            thisWin.dsList00Svd["csIvtdRatingC"]
          );
          this.dsDetail[0]["contDate"] = thisWin.busyDate;
          this.dsDetail[0]["cnclDate"] = thisWin.busyDate;

          thisWin.cbo_trstTypCTogdTypDtlC();

          let gdTypDtlC2 = thisWin.gdTypDtlC;
          if (
            (thisWin.trstTypC == "001" && gdTypDtlC2 == "A") ||
            (thisWin.trstTypC == "001" && gdTypDtlC2 == "B") ||
            (thisWin.trstTypC == "002" && gdTypDtlC2 == "A") ||
            (thisWin.trstTypC == "002" && gdTypDtlC2 == "B") ||
            thisWin.trstTypC == "003" ||
            thisWin.trstTypC == "010" //유언대용신탁(부동산)추가 20230321
          ) {
            //thisWin.btn_related.set_cssclass( "btn_WFSA_Search" );
          } else {
            //thisWin.btn_related.set_cssclass( "" );
          }

          // 2021-12-17 add 치매신탁보완사항 :: 치매신탁 시 계약관계자 - 신탁관리인 입력 필수로 필수입력 표기
          if (thisWin.trstTypC == "007") {
            //thisWin.Static79.set_cssclass("sta_WF_Label_P");
            //thisWin.btn_related.set_cssclass( "btn_WFSA_Search" );
          } else {
            //thisWin.Static79.set_cssclass("sta_WF_LabelLtLine");
            //thisWin.btn_related.set_cssclass( "" );
          }
          let saleTagtTc = this.fn_getValue(
            thisWin.dsDetail02[0]["saleTagtTc"]
          );
          if (saleTagtTc != "1") {
            if (saleTagtTc == "2" && thisWin.dsList00Svd["corpYn"] == "Y") {
              thisWin.fn_alert(
                "개인고객 상품으로 법인고객은 가입할수 없습니다."
              );
              thisWin.fn_initBody();
              return;
            }

            if (saleTagtTc == "3" && thisWin.dsList00Svd["corpYn"] != "Y") {
              thisWin.fn_alert(
                "법인고객 상품으로 개인고객은 가입할수 없습니다."
              );
              thisWin.fn_initBody();
              return;
            }
          }
          let ivRskRatingC1 = thisWin.ivRskRatingC;
          let csIvtdRatingC1 = thisWin.csIvtdRatingC;
          //고객등급 & 상품등급 정보 표기
          if (
            _.isEmpty(ivRskRatingC1) ||
            (thisWin.trstTypC != "001" &&
              thisWin.trstTypC != "007" &&
              thisWin.trstTypC != "008")
          ) {
            // 2021-08-31 add 치매신탁 추가:: 특정금전신탁과 같은 로직에서 처리, //20230307 유언대용신탁(금전)추가
            let i5071 = TSCommUtil.gfn_getIdxArrMapKeyVal(
              thisWin.dsCmb5071,
              "text",
              "- 해당 없음 -"
            );
            if (i5071 > -1) {
              thisWin.ivRskRatingC = thisWin.dsCmb5071[i5071]["value"];
            }
          } else if (
            _.isEmpty(csIvtdRatingC1) ||
            thisWin.fn_getNumber(csIvtdRatingC1) == 0
          ) {
            thisWin.static83 = "고객등급확인불가";
            //thisWin.static83.style.set_color( "red");
          } else if (
            thisWin.fn_getNumber(ivRskRatingC1) <
            thisWin.fn_getNumber(csIvtdRatingC1)
          ) {
            thisWin.static83 = "비적합상품";
            //thisWin.static83.style.set_color( "red");
          } else {
            thisWin.static83 = "적합상품";
            //thisWin.static83.style.set_color( "blue");
          }

          // 정기지급일자 세팅
          let aocpRgfeYn = this.aocpRgfeYn;
          if (!_.isEmpty(aocpRgfeYn) && aocpRgfeYn == "Y") {
            thisWin.dsDetail[0]["aocpRgfeDd"] = thisWin.busyDate.substr(6, 2);
          }
          //유언대용신탁(부동산)추가 20230321
          thisWin.addValue = Math.round(
            thisWin.fn_getNumber(thisWin.epayFeeAmt) *
              0.1 *
              (thisWin.trstTypC == "003" || thisWin.trstTypC == "010" ? 1 : 0)
          );

          let parseng = false;
          let blktAppnIndcYn = false;
          // 파생여부 확인
          if (thisWin.trstTypC == "001" && gdTypDtlC2 == "5") {
            parseng = true;
          } else {
            for (let i = 0; i < thisWin.dsList02.length; i++) {
              if (
                thisWin.dsList02[i]["appnMethC"] == "10" ||
                thisWin.dsList02[i]["appnMethC"] == "13" ||
                thisWin.dsList02[i]["appnMethC"] == "14"
              ) {
                // 파생결합증권
                parseng = true;
              }
            }
          }

          //숙려제도여부 확인. 2018.05.08 add
          let vCareYn = thisWin.dsDetail[0]["careYn"];

          //파생녹취의무여부. 2019.08.20 add
          let vDrvtGdClasC = thisWin.dsDetail[0]["drvtGdClasC"];

          //해외채권 상품여부 확인. 2018.06.28 add
          let vFrBondGdYn = thisWin.dsDetail[0]["frBondGdYn"];
          //신탁유형:특정금전신탁, //20230307 유언대용신탁(금전)추가
          if (
            thisWin.trstTypC == "001" ||
            thisWin.trstTypC == "007" ||
            thisWin.trstTypC == "008"
          ) {
            // 2021-08-31 add  thisWin.trstTypC == "007" (치매신탁) 추가 :: 특정금전신탁과 같은 로직
            //포괄운용지시확인
            if (thisWin.dsDetail02[0]["blktAppnIndcYn"] == "Y") {
              blktAppnIndcYn = true;
            }
            //개인고객확인용 실명번호구분
            let sRcnoKindTc = thisWin.dsList00Svd["rcnoKindTc"];

            //해외채권 상품인 경우, 쿠폰목록 조회 : 2018.06.28 add
            if (vFrBondGdYn == "Y") {
              thisWin.frBondReInvCallBackFunc();
            }
            ////////////////////////////////////////////////////////////////////////////////////////

            //2021.05.06 add
            //신규가입시 고령자가 고령전용상품 가입시  녹취대상여부 Y (반드시 녹취해야함)
            if (
              thisWin.fn_lifeAge() >= thisWin.oldAge &&
              thisWin.dsDetail02[0]["oldExclYn"] == "Y" &&
              thisWin.radProcessGb == "1"
            ) {
              thisWin.dsDetail[0]["recYn"] = "Y";
            }

            //2019.08.20
            // 1. 파생녹취의무여부'N'인 상품을 선택 -> 녹취비대상
            // 2. 파생녹취의무여부'Y'인 상품 선택 AND ("고령자" OR "투자성향부적합") -> 녹취대상
            if (
              (sRcnoKindTc == "01" ||
                sRcnoKindTc == "04" ||
                sRcnoKindTc == "05" ||
                sRcnoKindTc == "06") &&
              vDrvtGdClasC == "01" &&
              parseng &&
              //2021.02.09 update
              thisWin.fn_lifeAge() >= thisWin.oldAge
            ) {
              thisWin.dsDetail[0]["recYn"] = "Y";
            }
            if (
              (sRcnoKindTc == "01" ||
                sRcnoKindTc == "04" ||
                sRcnoKindTc == "05" ||
                sRcnoKindTc == "06") &&
              vDrvtGdClasC == "01" &&
              parseng &&
              (thisWin.fn_getNumber(thisWin.dsList00Svd["csIvtdRatingC"]) ==
                0 ||
                thisWin.fn_getNumber(thisWin.dsList00Svd["csIvtdRatingC"]) >
                  thisWin.fn_getNumber(thisWin.dsDetail02[0]["ivRskRatingC"]))
            ) {
              thisWin.dsDetail[0]["recYn"] = "Y";
            }

            if (
              (sRcnoKindTc == "07" || sRcnoKindTc == "09") &&
              vDrvtGdClasC == "01" &&
              parseng &&
              (thisWin.fn_getNumber(thisWin.dsList00Svd["csIvtdRatingC"]) ==
                0 ||
                thisWin.fn_getNumber(thisWin.dsList00Svd["csIvtdRatingC"]) >
                  thisWin.fn_getNumber(thisWin.dsDetail02[0]["ivRskRatingC"]))
            ) {
              thisWin.dsDetail[0]["recYn"] = "Y";
            }

            //2018.05.24 : 기존 투자자숙려제도 팝업 주석처리 (팝업 발생기준 변경)
            if (thisWin.fn_lifeAge() < thisWin.oldAge) {
              //if( thisWin.dsDetail[0]["recYn"] == "Y" ){
              //  let letRet;
              //  let objParentFrame = thisWin.getOwnerFrame();
              //  let newChild = new nexacro.ChildFrame;
              //  let oArg  = {  }  ;
              //  newChild.init("TPUP97000","absolute","300", "300", "750", "350", null, null,"popup::TPUP97000.xfdl"  );
              //  letRet = system.showModalSync(newChild,objParentFrame, oArg,this);
              //}
              // 1. 고령자 확인
              // 2018.05.24: 파생상품 판매 알림 팝업 발생 기준 변경 (숙려제도 체크)
              // 2019.08.20 : vCareYn -> vDrvtGdClasC 변경, 고령자체크로직 추가
              //if ( ( sRcnoKindTc == "01" ||sRcnoKindTc == "04" || sRcnoKindTc == "05" || sRcnoKindTc == "06") && vDrvtGdClasC == "01" && parseng ){
              //    //2021.02.09 update
              //    //2021.05.06 조상준프로요청  && thisWin.fn_lifeAge() >= thisWin.oldAge){    //주민등록번호, 외국인등록번호, 국내거소신고번호만 체크
              //    //ASR180201043, [Sub][신탁부] 파생결합증권 판매과정 녹취 의무화 관련 변경
              //      let letRet;
              //      let objParentFrame = thisWin.getOwnerFrame();
              //      let newChild = new nexacro.ChildFrame;
              //      let oArg  = { lifeAge: thisWin.fn_lifeAge() }  ;
              //
              //      newChild.init("TPUP97010","absolute","300", "300", "720", "320", null, null,"popup::TPUP97010.xfdl"  );
              //      letRet = system.showModalSync(newChild,objParentFrame, oArg,this);
              //
              //    //ASR180100650, [신탁부] 파생결합증권 판매과정 녹취 의무화 관련 변경
              //    //주석 삭제
              //  }
              //
            } //2021.05.06 고령자 전용상품은 띄우지 말것  -End
            let strWarning1 = "";
            // 2021-04-08 add thisWin.$options.screenId == "MSPTS200M"
            // 030010010은 #1020화면 코드번호. 1020에서 오픈할 때만 성향등록 체크
            if (thisWin.dsList00Svd["csIvtdRatingC"].length < 1) {
              let wArr = [];
              wArr.push("고객성향설문지등록을 하지 않은 고객입니다.");
              wArr.push("등록 하시겠습니까?");
              wArr.push("");
              wArr.push("확인 : 고객설문조사 등록");
              wArr.push("취소 : 닫기");
              thisWin.pAlertPopupObj.content = wArr;
              thisWin.pAlertPopupObj.confirm = true;
              thisWin.pAlertPopupObj.confirmFunc = thisWin.fn_popSurvey;
              thisWin.$refs.alertPopup.fn_Open(thisWin.pAlertPopupObj);

              thisWin.fn_init();
              thisWin.fn_initBody();
              return;
            }
            if (thisWin.$options.screenId == "MSPTS200M") {
              //#1020화면에서만 체크
              // if (thisWin.dsList00Svd["procDt"] != thisWin.busyDate) {
              //   let wArr = [];
              //   wArr.push("고객성향설문지등록일자와 계좌개설일자가");
              //   wArr.push("일치하지 않습니다. 이 경우 계좌개설");
              //   wArr.push("업무가 제한되오니 고객성향설문지를 ");
              //   wArr.push("재등록 하시기 바랍니다.");
              //   wArr.push("");
              //   wArr.push("확인 : 고객설문조사 등록");
              //   wArr.push("취소 : 닫기");
              //   thisWin.pAlertPopupObj.content = wArr;
              //   thisWin.pAlertPopupObj.confirm = true;
              //   thisWin.pAlertPopupObj.confirmFunc = thisWin.fn_popSurvey;
              //   thisWin.$refs.alertPopup.fn_Open(thisWin.pAlertPopupObj);
              //   return false;
              // }

              let genSpivrTc = thisWin.dsList00Svd["genSpivrTc"]; //genSpivrTc:일반전문투자가구분코드 1전문2일반
              if (
                genSpivrTc == "2" && //일반투자자限 //고객투자성향이 0이거나 상품등급이 고객투자성향을 상회
                (thisWin.fn_getNumber(thisWin.dsList00Svd["csIvtdRatingC"]) ==
                  0 ||
                  thisWin.fn_getNumber(thisWin.dsList00Svd["csIvtdRatingC"]) >
                    thisWin.fn_getNumber(thisWin.dsDetail02[0]["ivRskRatingC"]))
              ) {
                let wArr = [];
                wArr.push("해당 상품은 고객님의 투자성향보다 위험도가");
                wArr.push("높은 '부적합' 상품으로, 가입이 불가합니다.");
                wArr.push("고객님의 투자성향을 확인 후 이에 적합한");
                wArr.push("상품을 선택해 주시기 바랍니다.");
                wArr.push("");

                thisWin.fn_formClear();
                thisWin.fn_alert(wArr);
                return;
              }
            } //#1020화면에서만 체크

            //2019.08.20 위에서 처리
            //파생상품&숙려제도여부"Y"인 경우, 계약승인신청일은 최소 발행일자의 2영업일 전 이어야 한다
            //if( parseng && vCareYn == "Y" && ( thisWin.gfn_minusDate(thisWin.dsDetail[0]["issueDate"], 2) < thisWin.busyDate ) ) {
            //  let wArr = [];
            //  wArr.push("투자자숙려제도 대상은 계약승인신청일이 최소 발행일자의 2영업일 전 이어야 합니다.");
            //  wArr.push("해당 상품의 발행일자를 확인해 주시기 바랍니다.");
            //  if (!confirm(wArr.join("\n"))){
            //    thisWin.fn_initBody();
            //  }
            //  return false;
            //}
            //20210831 끝
          } // if( thisWin.trstTypC == "001" || thisWin.trstTypC == "007" ) 끝

          if (!_.isEmpty(thisWin.dsDetail[0]["moGdC"])) {
            //모계약번호
            TSCommUtil.gfn_setVisibleArr(this, ["visMoCtno"], true);
          } else {
            TSCommUtil.gfn_setVisibleArr(this, ["visMoCtno"], false);
          }

          thisWin.soptTc = "10"; //자익
          //원금수익자,이익수익자
          TSCommUtil.gfn_setDisableArr(
            this,
            ["btnCustInfo00", "btnCustInfo01"],
            true
          );

          let nRow = thisWin.dsList01.length;
          let sum01 = 0;
          for (let i = 0; i < thisWin.dsList01.length; i++) {
            if (
              thisWin.dsList01[i]["fxrtFamtTc"] != "1" &&
              thisWin.dsList01[i]["bnfcRatingTc"] != "1"
            )
              continue;
            sum01 += this.fn_getNumber(thisWin.dsList01[i]["pymRto"]);
          }
          if (nRow == 0 || sum01 != 100) {
            nRow = thisWin.dsList01.length;
            thisWin.dsList01.push({
              bnfcRatingTc: "1",
              fxppYn: "N",
              fxrtFamtTc: "1",
              pymRto: 100,
            });
          }
          //해피콜 없음
          let hpcYn1 = thisWin.dsDetail02[0]["hpcYn"]; //ASR240300857_[사랑on신탁]신규입력화면 해피콜서비스값 생성
          TSCommUtil.gfn_setVisibleArr(this, ["visHpcTc"], false);
          TSCommUtil.gfn_setDisableArr(this, ["disHpcTc"], true);
          if( hpcYn1 == "Y" ) {
          ////해피콜
            TSCommUtil.gfn_setVisibleArr(this, ["visHpcTc"], true);
            TSCommUtil.gfn_setDisableArr(this, ["disHpcTc"], true);
          }
          thisWin.cbo_soptTc_onitemchanged();
          thisWin.fn_insertEnableCompNew();
        }
        if (svcID == "selectTFAC20000AList") {
          if (thisWin.dsList.length < 1) {
            thisWin.dsList.push({ value: "", text: "" });
          }
          if (!_.isEmpty(thisWin._P_PARAMS)) {
            let ifr = TSCommUtil.gfn_getIdxArrMapKeyVal(
              thisWin.dsList,
              "value",
              thisWin._P_PARAMS.ctno
            );
            if (ifr > -1) {
              thisWin.cboCtno = thisWin._P_PARAMS.ctno;
              thisWin._P_PARAMS = {};
            }
            thisWin.fn_searchList();
          } else {
            if (_.isEmpty(this.$options.screenId)) {
              if (
                thisWin.dsDetail03["ctnoCnt"] >= 9 &&
                thisWin.dsDetail03["bnkbSeq"] !== 0 // 추가 - ASR240101094 / 무통장(999) 최대 계약 계좌수 확대
              ) {
                thisWin.$ToastManager.fn_ShowToast({
                  text: "통장에 거래가 다 찼습니다. 다른 통장을 이용하십시오.",
                });
              }
            }
          }
          //thisWin.rfCboCtno.focus();
        }
        if (svcID == "selectTFAC20000BList") {
          //dsList08Acno
          //value key : tacnoBnkbSeq, text key : tacnoBnkbSeq
          thisWin.dsList08Acno = TSCommUtil.gfn_getDropdownFrArr(
            thisWin.dsList08,
            "tacnoBnkbSeq",
            "tacnoBnkbSeq"
          );
          if (thisWin.dsList08Acno != null && thisWin.dsList08Acno.length > 0) {
            let ds08Acno = thisWin.dsList08Acno[0]["value"];
            this.cboAcno = ds08Acno;
          }

          if (!_.isEmpty(thisWin._P_PARAMS)) {
            let tmp = thisWin._P_PARAMS.tacno + "-" + thisWin._P_PARAMS.bnkbSeq;
            let idx08 = thisWin.gfn_getIdxArrMapKeyVal(
              thisWin.dsList08,
              "value",
              tmp
            );
            if (idx08 > -1) {
              thisWin.cboAcno =
                thisWin._P_PARAMS.tacno + "-" + thisWin._P_PARAMS.bnkbSeq;
              thisWin.fn_searchCtnoList();
            } else {
              thisWin.fn_searchList();
            }
          } else {
            thisWin.fn_searchCtnoList();
          }
        }
        if (svcID == "selectTPUP95320") {
          //2021-04-06 add
          thisWin.procDt = thisWin.dsList00Svd["procDt"]; //설문등록일
          thisWin.valdYn = thisWin.dsList00Svd["valdYn"]; //설문유효여부
        }
        if (svcID == "selectTFAC20000CList") {
          if (thisWin.dsList09.length < 1) {
            thisWin.dsList09.push({ value: "", text: "" });
          }
          if (
            !_.isEmpty(thisWin._P_PARAMS) &&
            !_.isEmpty(thisWin._P_PARAMS.chngSeq)
          ) {
            thisWin.cboChngSeq = thisWin._P_PARAMS.chngSeq;
            thisWin.fn_searchList();
          } else {
            thisWin.cboChngSeq = "";
          }
        }
        if (svcID == "selectTFAC20000D") {
          if (!_.isEmpty(thisWin.dsList14)) {
            if (thisWin.dsList14[0]["invPosYn"] == "N") {
              thisWin.fn_alert(
                "해당 직원은 신탁상품 권유자격이 불충분하여 권유자로 입력할 수 없습니다."
              );
              thisWin.fnlDsgnEmno = "";
              thisWin.fnlDsgnEmnoNm = "";
            } else {
              thisWin.fnlDsgnEmnoNm = thisWin.dsList14[0]["fcNm"];
            }
          } else {
            thisWin.fn_alert("직원번호를 바르게 입력하시오.");
            thisWin.fnlDsgnEmno = "";
            thisWin.fnlDsgnEmnoNm = "";
          }
        }
        if (
          svcID == "selectTFAC20000CList" ||
          svcID == "selectTFAC20000BList"
        ) {
          // 계좌번호 조회
          thisWin.dsList00 = {}; // 데이터 셋 초기화
          let a00Info = this.fn_getAcnoCondInfo();
          let tacno = a00Info["tacno"];
          if (tacno.length != 7) {
            thisWin.fn_init();
            return;
          }

          //selectTPUP95320
          let pParam = {
            tacno: tacno,
          };
          this.eaiCommObj.lv_vm = this;
          this.eaiCommObj.auth = "S";
          this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600215_S";
          this.eaiCommObj.params = pParam;
          TSServiceUtil.invokeInDirect(
            this.eaiCommObj,
            function(res) {
              if (res && res.data) {
                let dsL00 = TSCommUtil.gfn_getMapFrBase(dsList00_b, res.data); //{}
                if (
                  !_.isEmpty(dsL00) &&
                  thisWin.fn_getValue(dsL00["csId"]) != ""
                ) {
                  thisWin.dsList00 = _.cloneDeep(dsL00);

                  thisWin.tacnoBase = thisWin.dsList00["tacno"];

                  thisWin.fn_callBackSub("selectTPUP95320", res);
                }
              }
            },
            function(error) {
              return;
            },
            "UF10600215"
          );
        }
      } catch (e) {
      } finally {
      }
    },
    /******************************************************************************
     * Function명 : fn_popSurvey
     * 설명       : 설문등록
     ******************************************************************************/
    fn_popSurvey() {
      let rcno =
        this.dsList00Svd["rcno"] == null ? "" : this.dsList00Svd["rcno"];
      rcno = rcno.replaceAll("-", "");
      let aInfo = thisWin.fn_getAcnoCondInfo();
      let processGb = thisWin.radProcessGb;
      let tacno = aInfo["tacno"];
      let bnkbSeq = aInfo["bnkbSeq"];
      let accSeq = aInfo["accSeq"];
      let args = {
        rcnoKindTc: this.dsList00Svd["rcnoKindTc"],
        rcno: rcno,
        cnm: this.dsList00Svd["cnm"],
        csId: this.dsList00Svd["csId"],
        processGb: processGb,
        tacno: tacno,
        bnkbSeq: bnkbSeq,
        accSeq: accSeq,
      };
      this.$router.push({ name: "MSPTS104M", params: args });
    },
    /**********************************************************************************
     * 조회 : WFtitleCRUDbtn 의 callback
     **********************************************************************************/
    fn_searchList() {
      //2021-04-06 add
      let _procDt = this.procDt;
      let _valdYn = this.valdYn;
      this.fn_enableComponent("btnSearchList");
      this.fn_initBody();
      let pParam = {};
      if (this.radProcessGb == "1") {
        // 계약 신규
        /*dataset초기화*/
        //this.dsDetail = [];
        //this.fn_formClear();
        this.busyDate = this.busyDate; //현 영업일자
        if (!_.isEmpty(this._P_PARAMS)) {
          let tacno = this.fn_getValue(this._P_PARAMS.tacno);
          let bnkbSeq = this.fn_getValue(this._P_PARAMS.bnkbSeq);
          let ctno = this.fn_getValue(this._P_PARAMS.ctno);
          if (tacno.length != 7 || bnkbSeq.length != 3 || ctno.length < 1)
            return;

          pParam = {
            tacno: tacno, //종합계좌번호
            bnkbSeq: bnkbSeq, //통장일련번호
            ctno: ctno, //계약일련번호
            aprvNo: this.fn_getValue(this._P_PARAMS.aprvNo), //승인번호
            procDate: this.fn_getValue(this._P_PARAMS.procDate), //승인일자
            procBrcd: this.fn_getValue(this._P_PARAMS.procBrcd),
            csId: this.fn_getValue(thisWin.dsList00Svd["csId"]),
          };
        } else {
          //dsList08Acno : get tacno(this.cboAcno)
          let a2Info = this.fn_getAcnoCondInfo();
          if (a2Info["tacno"] == null || a2Info["tacno"] == "") {
            this.fn_alert("계좌번호를 확인해 주세요.");
            return;
          }
          //dsList08: 통장번호
          pParam = {
            tacno: a2Info["tacno"], //종합계좌번호
            bnkbSeq: a2Info["bnkbSeq"], //통장일련번호
            ctno: a2Info["ctno"], //계약일련번호
            aprvNo: "",
            csId: this.fn_getValue(thisWin.dsList00Svd["csId"]),
          };
        }
        //selectTFAC20000
        this.eaiCommObj.lv_vm = this;
        this.eaiCommObj.auth = "S";
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600079_S";
        this.eaiCommObj.params = pParam;
        TSServiceUtil.invokeInDirect(
          this.eaiCommObj,
          function(res) {
            if (res && res.data) {
              thisWin.dsDetail = TSCommUtil.gfn_getArrFrBase(dsDetail_b, [
                res.data.tfac20000[0],
              ]); //
              thisWin.fn_dsDetailToForm();
              thisWin.dsList01 = TSCommUtil.gfn_getArrFrBase(
                dsList01_b,
                res.data.tftbni
              );
              thisWin.fn_getDetail02();
              thisWin.dsList02 = TSCommUtil.gfn_getArrFrBase(
                dsList02_b,
                res.data.tftoil
              );
              thisWin.dsList03 = TSCommUtil.gfn_getArrFrBase(
                dsList03_b,
                res.data.tftivd
              );
              thisWin.dsList04 = TSCommUtil.gfn_getArrFrBase(
                dsList04_b,
                res.data.tftred
              );
              thisWin.dsList05 = TSCommUtil.gfn_getArrFrBase(
                dsList05_b,
                res.data.tftmbd
              );
              thisWin.dsList06 = TSCommUtil.gfn_getArrFrBase(
                dsList06_b,
                res.data.tftrfi
              );
              thisWin.dsList07 = TSCommUtil.gfn_getArrFrBase(
                dsList07_b,
                res.data.tftapl
              );
              thisWin.dsListIsa = TSCommUtil.gfn_getArrFrBase(
                dsListIsa_b,
                res.data.tfaill
              );
              thisWin.dsLoan = TSCommUtil.gfn_getMapFrBase(
                dsDetail_b,
                res.data.tfac20000[1]
              ); //{}
              thisWin.fn_callBackSub("selectTFAC20000");
            } else {
            }
            this.hpcTc = this.fn_getValue(thisWin.dsDetail[0].hpcTc)//ASR240300857 / [사랑on신탁]신규입력화면 해피콜서비스값 생성
          },
          function(error) {
            return;
          },
          "UF10600079"
        );
      } else if (this.radProcessGb == "2" || this.radProcessGb == "3") {
        // 계약 추가 변경
        if (this.cboAccSeq == "") {
          this.fn_alert("계좌번호를 확인해주세요.");
          return;
        }

        //#1020화면에서 추가가입인 경우만 체크 2021.05.20
        // if (this.$options.name == "MSPTS200M" && this.radProcessGb == "2") {
        //   if (_.isEmpty(_procDt) == true && this.$options.name == "MSPTS200M") {
        //     //설문등록이 없는 경우

        //     //let strWarning2 = "고객성향설문지등록을 하지 않은 고객입니다.<br/>등록 하시겠습니까?";
        //     //  strWarning2 += "<br/><br/>확인 : 고객설문조사 등록<br/>취소 : 닫기"
        //     let wArr = [];
        //     wArr.push("고객성향설문지등록을 하지 않은 고객입니다.");
        //     wArr.push("등록 하시겠습니까?");
        //     wArr.push("");
        //     wArr.push("확인 : 고객설문조사 등록");
        //     wArr.push("취소 : 닫기");
        //     this.pAlertPopupObj.content = wArr;
        //     this.pAlertPopupObj.confirm = true;
        //     this.pAlertPopupObj.confirmFunc = this.fn_popSurvey;
        //     this.$refs.alertPopup.fn_Open(this.pAlertPopupObj);

        //     this.fn_init();
        //     this.fn_initBody();
        //     return;
        //   }
        //   //2021-04-06 add
        //   if (_.isEmpty(_procDt) == false && _valdYn == "N") {
        //     //설문등록 유효기간이 지난 경우

        //     //let strWarning2 = "고객성향설문지등록 유효기간이 만료되었습니다. 새로 등록 하시겠습니까?";
        //     //  strWarning2 += "<br/><br/>확인 : 고객설문조사 등록<br/>취소 : 닫기"
        //     let wArr = [];
        //     wArr.push(
        //       "고객성향설문지등록 유효기간이 만료되었습니다. 새로 등록 하시겠습니까?"
        //     );
        //     wArr.push("");
        //     wArr.push("확인 : 고객설문조사 등록");
        //     wArr.push("취소 : 닫기");
        //     this.pAlertPopupObj.content = wArr;
        //     this.pAlertPopupObj.confirm = true;
        //     this.pAlertPopupObj.confirmFunc = this.fn_popSurvey;
        //     this.$refs.alertPopup.fn_Open(this.pAlertPopupObj);

        //     this.fn_init();
        //     this.fn_initBody();
        //     return;
        //   }
        // } //#1020화면에서 추가가입인 경우만 체크 2021.05.20

        let a22Info = this.fn_getAcnoCondInfo();

        let acno = a22Info["tacno"] + a22Info["bnkbSeq"] + a22Info["accSeq"];
        if (acno.length != 13) {
          this.fn_alert("계좌번호를 확인하십시오");
          return;
        }
        /*dataset초기화*/
        //this.dsDetail = [];
        this.fn_formClear();

        //계좌정보 기본조회
        if (_.isEmpty(this.dsListS1)) {
          this.dsListS1Base = {};
          this.fn_transactionS1_base(); //old age check
        }

        let pParam = {
          tacno: a22Info["tacno"], //종합계좌번호
          bnkbSeq: a22Info["bnkbSeq"], //통장일련번호
          accSeq: a22Info["accSeq"], //통장일련번호
          procType: String(this.fn_getNumber(this.radProcessGb) - 1), //처리구분
          ctno: a22Info["ctno"], //계약일련번호
          chngSeq: this.fn_getNumber(a22Info["chngSeq"]) + "",
          csId: thisWin.dsList00Svd["csId"],
        };
        if (!_.isEmpty(this._P_PARAMS)) {
          pParam["chngSeq"] = this._P_PARAMS.chngSeq; //계약변경일련번호
          pParam["aprvNo"] = this._P_PARAMS.aprvNo; //승인번호
          pParam["procDate"] = this._P_PARAMS.procDate; //승인번호
          pParam["procBrcd"] = this._P_PARAMS.procBrcd; //승인번호
        }

        //selectTFAC20000B
        this.eaiCommObj.lv_vm = this;
        this.eaiCommObj.auth = "S";
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600082_S";
        this.eaiCommObj.params = pParam;
        TSServiceUtil.invokeInDirect(
          this.eaiCommObj,
          function(res) {
            if (res && res.data) {
              thisWin.dsDetail = [
                TSCommUtil.gfn_getMapFrBase(dsDetail_b, res.data),
              ]; //
              thisWin.fn_dsDetailToForm();
              thisWin.dsList01 = TSCommUtil.gfn_getArrFrBase(
                dsList01_b,
                res.data.tfabni
              );
              thisWin.fn_getDetail02();
              thisWin.dsList02 = TSCommUtil.gfn_getArrFrBase(
                dsList02_b,
                res.data.tfaoil
              );
              thisWin.dsList03 = TSCommUtil.gfn_getArrFrBase(
                dsList03_b,
                res.data.tfaivd
              );
              thisWin.dsList04 = TSCommUtil.gfn_getArrFrBase(
                dsList04_b,
                res.data.tfared
              );
              thisWin.dsList05 = TSCommUtil.gfn_getArrFrBase(
                dsList05_b,
                res.data.tfambd
              );
              thisWin.dsList06 = TSCommUtil.gfn_getArrFrBase(
                dsList06_b,
                res.data.tfarfi
              );
              thisWin.dsListIsa = TSCommUtil.gfn_getArrFrBase(
                dsListIsa_b,
                res.data.tfaill
              );
              thisWin.fn_callBackSub("selectTFAC20000B");
            } else {
            } 
            this.hpcYn = this.fn_getValue(thisWin.dsDetail[0].hpcYn)
            this.hpcTc = this.fn_getValue(thisWin.dsDetail[0].hpcTc)//ASR240300857 / [사랑on신탁]신규입력화면 해피콜서비스값 생성
          if( this.hpcYn == "Y" ) {
          ////해피콜
            TSCommUtil.gfn_setVisibleArr(this, ["visHpcTc"], true);
            TSCommUtil.gfn_setDisableArr(this, ["disHpcTc"], true);
          }

          },
          function(error) {
            return;
          },
          "UF10600082"
        );
      }
      this._P_PARAMS = null;
    },
    /**********************************************************************************
     * 계약변경 일련번호 조회 : 계좌 일련번호 변경시
     **********************************************************************************/
    acctCallBackFunc(mode) {
      //업무구분 : 추가입금 or 변경 라디오버튼 클릭시 여기로 온다.
      thisWin.fn_initBody();

      let a24Info = this.fn_getAcnoCondInfo();

      if (
        a24Info["tacno"].length != 7 ||
        a24Info["bnkbSeq"].length != 3 ||
        a24Info["accSeq"].length != 3
      ) {
        return;
      }

      //selectTFAC20000CList ===> 계좌일련번호(dropdown)
      let pParam = {
        tacno: a24Info["tacno"],
        bnkbSeq: a24Info["bnkbSeq"],
        accSeq: a24Info["accSeq"],
        procType: String(thisWin.fn_getNumber(thisWin.radProcessGb) - 1),
      };
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600084_S";
      this.eaiCommObj.params = pParam;
      TSServiceUtil.invokeInDirect(
        this.eaiCommObj,
        function(res) {
          if (res && res.data) {
            let ds09 = TSCommUtil.gfn_getArrFrBase(dsList09_b, res.data.tftchh); //기존:ChngSeqtftchhList
            //value key : chngSeq, text key : chngSeq
            thisWin.dsList09 = [];
            if (ds09.length > 0) {
              thisWin.dsList09.push({ value: "", text: "" });
              thisWin.dsList09 = TSCommUtil.gfn_getDropdownFrArr(
                ds09,
                "chngSeq",
                "chngSeq",
                thisWin.dsList09
              );
            } else {
              thisWin.dsList09.push({ value: "", text: "" });
            }
            if (!_.isEmpty(thisWin.cboChngSeq)) {
              thisWin.fn_searchList();
            } else {
            }
          } else {
          }
        },
        function(error) {
          return;
        },
        "UF10600084"
      );
    },
    /**********************************************************************************
     * 계약번호조회 - 신규
     **********************************************************************************/
    fn_searchCtnoList() {
      // let procDt = this.dsList00Svd["procDt"];//설문등록일
      // let valdYn = this.dsList00Svd["valdYn"];//설문유효여부

      //2021-04-06 add
      // 030010010은 #1020화면 코드번호. 1020에서 오픈할 때만 성향등록 체크
      if (this.$options.name == "MSPTS200M") {
        //#1020화면에서만 체크

        if (
          _.isEmpty(this.procDt) == true &&
          this.$options.name == "MSPTS200M"
        ) {
          //설문등록이 없는 경우
          let strWarning2 =
            "고객성향설문지등록을 하지 않은 고객입니다.<br/>등록 하시겠습니까?";
          strWarning2 += "<br/><br/>확인 : 고객설문조사 등록<br/>취소 : 닫기";
          let wArr = [];
          wArr.push("고객성향설문지등록을 하지 않은 고객입니다.");
          wArr.push("등록 하시겠습니까?");
          wArr.push("");
          wArr.push("확인 : 고객설문조사 등록");
          wArr.push("취소 : 닫기");
          //          this.pAlertPopupObj.content = strWarning2
          this.pAlertPopupObj.content = wArr;
          this.pAlertPopupObj.confirm = true;
          this.pAlertPopupObj.confirmFunc = this.fn_popSurvey;
          this.$refs.alertPopup.fn_Open(this.pAlertPopupObj);

          //this.fn_init();
          this.fn_initBody();
          return;
        }
      } //#1020화면에서만 체크
      //2021-04-06 add
      if (this.$options.name == "MSPTS200M") {
        //#1020화면에서만 체크
        if (_.isEmpty(this.procDt) == false && this.valdYn == "N") {
          //설문등록 유효기간이 지난 경우

          let strWarning2 =
            "고객성향설문지등록 유효기간이 만료되었습니다.<br/>새로 등록 하시겠습니까?";
          strWarning2 += "<br/><br/>확인 : 고객설문조사 등록<br/>취소 : 닫기";
          let wArr = [];
          wArr.push("고객성향설문지등록 유효기간이 만료되었습니다.");
          wArr.push("새로 등록 하시겠습니까?");
          wArr.push("");
          wArr.push("확인 : 고객설문조사 등록");
          wArr.push("취소 : 닫기");
          //          this.pAlertPopupObj.content = strWarning2
          this.pAlertPopupObj.content = wArr;
          this.pAlertPopupObj.confirm = true;
          this.pAlertPopupObj.confirmFunc = this.fn_popSurvey;
          this.$refs.alertPopup.fn_Open(this.pAlertPopupObj);
          //this.fn_init();
          this.fn_initBody();
          return;
        }
      } //#1020화면에서만 체크
      this.fn_enableComponent("btnSearchList");
      let a5Info = this.fn_getAcnoCondInfo();
      if (a5Info["tacno"] == null || a5Info["tacno"] == "") return;
      if (a5Info["bnkbSeq"] == null || a5Info["bnkbSeq"] == "") return;

      /*dataset초기화*/
      this.dsDetail03 = []; // 추가 - ASR240101094 / 무통장(999) 최대 계약 계좌수 확대
      //this.dsDetail03 = {}; // 주석 - ASR240101094 / 무통장(999) 최대 계약 계좌수 확대
      this.cboCtno = ""; //콤보 초기화

      //selectTFAC20000AList
      let pParam = {
        tacno: a5Info["tacno"], //종합계좌번호
        bnkbSeq: a5Info["bnkbSeq"], //통장일련번호
      };
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600081_S";
      this.eaiCommObj.params = pParam;
      TSServiceUtil.invokeInDirect(
        this.eaiCommObj,
        function(res) {
          if (res && res.data) {
            thisWin.dsList = [];
            thisWin.dsDetail03 = TSCommUtil.gfn_getMapFrBase(
              dsDetail03_b,
              res.data
            );
            let dsL = TSCommUtil.gfn_getArrFrBase(dsList_b, res.data.tftcob);
            //value key : ctno, text key : ctno
            if (thisWin.dsList.length < 1) {
              thisWin.dsList.push({ value: "", text: "" });
            }
            thisWin.dsList = TSCommUtil.gfn_getDropdownFrArr(
              dsL,
              "ctno",
              "ctno",
              thisWin.dsList
            ); //space 필요

            if (!_.isEmpty(thisWin._P_PARAMS)) {
              let idx = TSCommUtil.gfn_getIdxArrMapKeyVal(
                thisWin.dsList,
                "value",
                thisWin._P_PARAMS.ctno
              );
              if (idx > -1) {
                thisWin.cboCtno = thisWin._P_PARAMS.ctno;
                thisWin._P_PARAMS = null;
              }
              thisWin.fn_searchList();
            } else {
              if (
                thisWin.fn_getNumber(thisWin.dsDetail03["ctnoCnt"]) >= 9 &&
                thisWin.fn_getNumber(pParam.bnkbSeq) < 999 // 추가 - ASR240101094 / 무통장(999) 최대 계약 계좌수 확대
              ) {
                thisWin.fn_alert([
                  "통장에 거래가 다 찼습니다.",
                  "다른 통장을 이용하십시오.",
                ]);
              }
            }

            //this.$refs.rfCboCtno.focus();
          }
        },
        function(error) {
          return;
        },
        "UF10600081"
      );
    },
    /**********************************************************************************
     * 종합계좌번호 선택 - 신규
     **********************************************************************************/
    Div02_cbo_acno_onitemchanged() {
      this.fn_initBody();
      this.fn_searchCtnoList();
    },
    /**********************************************************************************
     *등록모드로 컨트롤 활성화  fn_insertEnableCompNew
     **********************************************************************************/
    fn_insertEnableCompNew() {
      this.fn_setTotActive();

      // this.fn_Init_Btn_Enable();
      // this.fn_enableComponent("btnConfirm,btnCancel" );

      // let trstTypC = this.trstTypC;
      // if ( (trstTypC != "001" && trstTypC != "008") && this.poaoTc == "1" && this.epayFxrtFamtTc == "1" ){// 별납정률 20230321
      //   //신탁금액
      //   TSCommUtil.gfn_setDisableArr(this,["disTrstAmt"],true);
      // }else{
      //   TSCommUtil.gfn_setDisableArr(this,["disTrstAmt"],false);
      // }
      // //만기일
      // TSCommUtil.gfn_setDisableArr(this,["disMtrtDate"],false);
      // //만기후보수율
      // TSCommUtil.gfn_setDisableArr(this,["disMtrtAfTfrt"],true);
      // //운영내역통보,자타익구분,원금수익자,이익수익자,원금수익자계좌선택,이익수익자 계좌 팝업
      // TSCommUtil.gfn_setDisableArr(this,["disOpdtNtcTc","disSoptTc","btnCustInfo00","btnCustInfo01",
      //   "disTrBkcdNmbnkAcno00","disTrBkcdNmbnkAcno01"],false);

      // //this.fn_custRcnoMask();      //개인정보 마스킹
      // ////(후취) 기본보수율,선후취구분
      // TSCommUtil.gfn_setDisableArr(this,["disBasTfrt","disEpayPoaoTc"],false);
      // this.cbo_epayPoaoTc_onitemchanged   () ; // visible
      // TSCommUtil.gfn_setDisableArr(this,["disEpayCyclTc"],false);   //주기
      // this.cbo_aocpRgfeYn_onitemchanged   () ; // visible
      // TSCommUtil.gfn_setDisableArr(this,["disEpayFxrtFamtTc"],false);   //별납정률정액구분
      // this.cbo_epayFxrtFamtTc_onitemchanged();  //visible
      // //별납보수율,별납보수 금액,금액(후취정기)
      // TSCommUtil.gfn_setDisableArr(this,["disEpayTfrt","disAocpRgfe"],false);
      // TSCommUtil.gfn_setDisableArr(this,["disEpayFeeAmt"],false);
      // this.cbo_aocpRgfeYn_onitemchanged   (); // visible
      // TSCommUtil.gfn_setDisableArr(this,["disAocpRgfeFamtTc"],false); //정률정액구분
      // this.cbo_poaoTc_onitemchanged();         // 포함컨트롤 visible
      //  //권유자/권유자팝업,관리자/관리자팝업
      // TSCommUtil.gfn_setDisableArr(this,["disInvtMnEmno","disMngrEmno"],false);

      // //2019.11.19 add
      // //판매직원/판매직원팝업,유선구분(없음)
      // TSCommUtil.gfn_setDisableArr(this,["disSaleEmno"],false);
      // //행추가/삭제
      // TSCommUtil.gfn_setVisibleArr(this,["visInsertRow"],true);
      // //수익보수율 / 중도해지수수료율 없음
      // if( this.visGrdList03 || this.visGrdList04 || this.visGrdList05 ){
      //   // 행추가/삭제
      //   TSCommUtil.gfn_setVisibleArr(this,["visInsertRow00"],true);
      // }
      // //운용지시,유가증권내역,부동산재산,금전채권신탁
      // TSCommUtil.gfn_setDisableArr(this,["disGrdList02","disGrdList03","disGrdList04","disGrdList05"],false);
      // //송금계좌,해외채권 쿠폰처리여부,온라인/유선 없음
      // TSCommUtil.gfn_setDisableArr(this,["disTrBkcdNmbnkAcno","disFrBondCpTc"],false);
      // TSCommUtil.gfn_setDisableArr(this,["disIsueYn"],true);
      // if (trstTypC == "008"){   //유언대용신탁(금전)
      //   //정기보수여부
      //   TSCommUtil.gfn_setDisableArr(this,["disAocpRgfeYn"],true);
      //   //주기,별납정률정액구분
      //   TSCommUtil.gfn_setDisableArr(this,["disEpayFxrtFamtTc","disAocpRgfeFamtTc"],false);
      //   TSCommUtil.gfn_setVisibleArr(this, ["visEpayFxrtFamtTc"], true);
      // }
      // if (this.btnConfirm == true){
      //   //계약 관계자, 스케쥴 관리, 권유대행 FC
      //   TSCommUtil.gfn_setDisableArr(this,["disRelated","btnCustInfoReg","disFnlDsgnEmno"],true);
      // } else{
      //   TSCommUtil.gfn_setDisableArr(this,["disRelated","btnCustInfoReg","disFnlDsgnEmno"],false);
      // }
      // if (this.epayFxrtFamtTc == "2"){
      //   //정액
      //   TSCommUtil.gfn_setVisibleArr(this, ["visEpayTfrt"], false);
      // }
      // thisWin.cbo_epayFxrtFamtTc_onitemchanged();
    },
    /**********************************************************************************
     * 수정 : WFtitleCRUDbtn 의 callback
     **********************************************************************************/
    fn_Update() {
      //서버에 호출 할 정보를 셋팅해준다.
      let brcd = this.lv_basInfo.brcd;
      if (brcd == "916") {
        this.fn_alert("신탁지원부는 처리 할 수 없습니다.");
        return;
      }

      this.sSvcID = "updateTFAC20000";
      if (this.radProcessGb == "1") {
        if (this.edtAcno1.length != 7) {
          this.fn_alert("고객번호를 확인하십시오.");
        } else if (this.fn_getNumber(this.cboCtno) < 1) {
          this.fn_alert("계약번호를 확인하십시오.");
        } else {
          this.fn_insertEnableCompNew();
        }
      } else if (this.radProcessGb == "2") {
        let accno = this.edtAcno2 + this.cboAccSeq;
        if (accno.length != 13) {
          this.fn_alert("계좌번호를 확인하십시오");
        } else {
          this.fn_insertEnableCompAdd();
        }
      } else if (this.radProcessGb == "3") {
        let accno = this.edtAcno2 + this.cboAccSeq;
        if (accno.length != 13) {
          this.fn_alert("계좌번호를 확인하십시오");
        } else {
          this.fn_insertEnableCompChan();
        }
      }
    },
    /**********************************************************************************
     * 입력값 체크
     **********************************************************************************/
    fn_InDataCheck() {
      let rBoolean = true;
      let chk_cust_no = "";
      if (this.radProcessGb == "1") {
        chk_cust_no = this.edtAcno1;
      } else {
        chk_cust_no = this.edtAcno2.substr(0, 7);
      }
      let min = this.fn_getNumber(this.dsDetail02[0]["lwstIamtAmt"]); // 최저 입금액
      let max = this.fn_getNumber(this.dsDetail02[0]["histIamtAmt"]); // 최대 입금액
      let lim = this.fn_getNumber(this.dsDetail02[0]["lmtAmt"]); // 한도 금액
      let trstTypC = this.trstTypC;
      if (trstTypC == "001" && this.gdTypDtlC == "E") {
        lim = this.dsListIsa[0]["taxPrimeAmt"]; //ISA 한도 금액
        if (
          this.fn_getNumber(lim) < this.fn_getNumber(this.trstAmt) &&
          this.fn_getNumber(lim) != 0
        ) {
          this.fn_alert("ISA 한도금액을 넘을 수 없습니다.");
          //this.$refs.rfTrstAmt.focus();
          return false;
        }
      }

      if (this.dsList01.length < 0) {
        this.fn_alert("수익자를 입력하십시오");
        return false;
      }
      let bri = false;
      let sopt = false;

      for (let i = 0; i < this.dsList01.length; i++) {
        if (
          this.fn_getNumber(this.dsList01[i]["prftBnfcBnkSseq"]) == 0 ||
          this.fn_getNumber(this.dsList01[i]["prinBnfcBnkSseq"]) == 0
        ) {
          bri = true;
        }
        if (
          this.dsList01[i]["prinBnfcCsId"] != this.dsList00["csId"] ||
          this.dsList01[i]["prftBnfcCsId"] != this.dsList00["csId"]
        ) {
          sopt = true;
        }
        if (_.isEmpty(this.dsList01[i]["prinBnfcCsId"])) {
          this.fn_alert("미입력 원금 수익자가 있습니다.");
          return;
        }
        if (_.isEmpty(this.dsList01[i]["prftBnfcCsId"])) {
          this.fn_alert("미입력 이익 수익자가 있습니다.");
          return;
        }
        if (
          this.soptTc == "10" &&
          this.dsList01[i]["prinBnfcCsId"] != this.dsList00["csId"]
        ) {
          this.fn_alert(
            "자익 수익자의 경우 본인만 선택 가능합니다.(" + (i + 1) + "번째 행)"
          );
          return false;
        }
        if (
          this.soptTc == "10" &&
          this.dsList01[i]["prftBnfcCsId"] != this.dsList00["csId"]
        ) {
          this.fn_alert(
            "자익 수익자의 경우 본인만 선택 가능합니다.(" + (i + 1) + "번째 행)"
          );
          return false;
        }
        if (_.isEmpty(this.dsList01[i]["fxppYn"])) {
          this.dsList01[i]["fxppYn"] = "N";
        }

        if (
          this.dsList01[i]["fxrtFamtTc"] == "1" &&
          this.dsList01[i]["fxppYn"] == "Y"
        ) {
          this.fn_alert("정률로 정기지급은 불가능합니다.");
          return false;
        }

        if (
          this.dsList01[i]["fxrtFamtTc"] == "1" &&
          this.fn_getNumber(this.dsList01[i]["pymRto"]) <= 0
        ) {
          this.fn_alert("정률수익자 비율의 0 이상 입력해주시오.");
          return;
        }
        //20211217 }
        if (
          !_.isEmpty(this.dsList01[i]["fxppYn"]) &&
          this.dsList01[i]["fxppYn"] == "Y"
        ) {
          if (_.isEmpty(this.dsList01[i]["fxrtFamtTc"])) {
            this.fn_alert("정기지급 정률정액구분을 입력하십시오.");
            return false;
          }
          if (_.isEmpty(this.dsList01[i]["fxppMethC"])) {
            this.fn_alert("정기지급 지급방법을 선택하십시오.");
            return false;
          }
          if (_.isEmpty(this.dsList01[i]["fxppStrtDate"])) {
            this.fn_alert("정기지급 시작월를 입력하십시오.");
            return false;
          }
          if (_.isEmpty(this.dsList01[i]["fxppEndDate"])) {
            this.fn_alert("정기지급 종료월를 입력하십시오.");
            return false;
          }
          if (_.isEmpty(this.dsList01[i]["ftrmPymCycl"])) {
            this.fn_alert("정기지급 지급주기을 선택하십시오.");
            return false;
          }
          if (_.isEmpty(this.dsList01[i]["ftrmPymDdTc"])) {
            this.fn_alert("정기지급 지급일자을 선택하십시오.");
            return false;
          }
          if (
            _.isEmpty(this.dsList01[i]["fxrtFamtTc"]) &&
            this.dsList01[i]["fxrtFamtTc"] == "1"
          ) {
            //정률
            if (_.isEmpty(this.dsList01[i]["pymRto"])) {
              this.fn_alert("정기지급 비율을 입력하십시오.");
              return false;
            }
          } else if (
            this.dsList01[i]["fxrtFamtTc"] == "2" &&
            this.dsList01[i]["fxppMethC"] != "03" &&
            this.dsList01[i]["fxppMethC"] != "04"
          ) {
            if (
              _.isEmpty(this.dsList01[i]["pymAmt"]) ||
              this.fn_getNumber(this.dsList01[i]["pymAmt"]) == 0
            ) {
              this.fn_alert("정기지급 금액을 입력하십시오.");
              return false;
            }
          }
          if (
            this.dsList01[i]["fxppEndDate"] < this.dsList01[i]["fxppStrtDate"]
          ) {
            this.fn_alert(
              "지급종료월을 지급시작월 이후로 입력해 주시기 바랍니다."
            );
            return false;
          }

          if (this.radProcessGb == "1") {
            if (
              thisWin.addDateYmd(
                this.dsList01[i]["fxppStrtDate"].substr(0, 6) +
                  this.dsList01[i]["ftrmPymDdTc"],
                -this.fn_getNumber(this.tmtlNeedDds)
              ) <= this.busyDate
            ) {
              let message =
                "지급시작일(" +
                this.dsList01[i]["fxppStrtDate"].substr(0, 4) +
                "-";
              message += this.dsList01[i]["fxppStrtDate"].substr(4, 2) + "-";
              message += this.dsList01[i]["ftrmPymDdTc"] + ")";
              message +=
                "은 현재일 이후로만 등록 가능하오니 \n 확인하여 주시기 바랍니다. ( 해지소요일수:[" +
                this.fn_getNumber(this.tmtlNeedDds) +
                "] 포함)";

              this.fn_alert(message);
              return false;
            }
          }
        }
      }
      for (let i = 0; i < this.dsList01.length; i++) {
        if (
          this.radProcessGb == "3" &&
          trstTypC == "007" &&
          this.soptTc == "20" &&
          this.bnfcDethYn == "Y"
        ) {
          //변경처리,치매신탁, 타익, 사망

          if (
            this.dsList01[i]["fxrtFamtTc"] == "1" &&
            this.dsList01[i]["bnfcRatingTc"] == "1" &&
            this.dsList01[i]["prinBnfcCsId"] == this.dsList00["csId"] &&
            this.dsList01[i]["prftBnfcCsId"] == this.dsList00["csId"]
          ) {
            this.dsList01.splice(i, 1);
          }
        }
      }

      //강제추가
      if (
        this.radProcessGb == "3" &&
        trstTypC == "007" &&
        this.soptTc == "20" &&
        this.bnfcDethYn == "Y"
      ) {
        //변경처리,치매신탁, 타익, 사망
      } else {
        let nRow = this.dsList01.length;
        let iSum = 0;
        for (let item of this.dsList01) {
          if (item["fxrtFamtTc"] != "1" || item["bnfcRatingTc"] != "1")
            continue;
          iSum += this.fn_getNumber(item["pymRto"]);
        }
        if (nRow == 0 || iSum != 100) {
          this.dsList01.push({
            bnfcRatingTc: "1",
            fxppYn: "N",
            fxrtFamtTc: "1",
            pymRto: 100,
            pymRto: 100,
            prinBnfcCsId: this.dsList00["csId"],
            prinBnfcRcno: this.dsList00["rcno"],
            prinBnfcRcnoGb: this.dsList00["rcnoKindTc"],
            prinBnfcCnm: this.dsList00["cnm"],
            prinBnfcBnkSseq: "0",
            prinBnfcBnkSseqNm: " - 선택안함(브릿지계좌로 송금) - ",
            prftBnfcCsId: this.dsList00["csId"],
            prftBnfcRcno: this.dsList00["rcno"],
            prftBnfcRcnoGb: this.dsList00["rcnoKindTc"],
            prftBnfcCnm: this.dsList00["cnm"],
            prftBnfcBnkSseq: "0",
            prftBnfcBnkSseqNm: " - 선택안함(브릿지계좌로 송금) - ",
          });
        }
      }
      // 20211217 (fn_confirm에서 일괄작업)
      //if ( bri ){
      //  let wArr = [];
      //  wArr.push("지급받을 계좌를 별도로 지정하지 않은 경우, 해당 계약의 브릿지계좌로 지급됩니다.");
      //  wArr.push("계속 진행하시겠습니까?");
      //  if (!confirm(wArr.join("\n"))){
      //    return false;
      //  }
      //}
      if (!sopt && this.soptTc == "20") {
        this.fn_alert(
          "모든 수익자가 위탁자와 동일합니다. 자타익구분을 확인하세요."
        );
        return false;
      }
      ////유선내방구분 : 2.내방
      if (_.isEmpty(this.teleVssTc)) {
        this.teleVssTc = "2";
      }
      if (chk_cust_no.length != 7) {
        this.fn_alert("고객번호를 확인하십시오.");
        //this.$refs.rfEdtAcno1.focus();
        return false;
      }
      if (this.gdC.length != 6) {
        this.fn_alert("신탁상품을 확인하십시오.");
        //this.$refs.rfGdC.focus();
        return false;
      }
      if (
        this.fn_getNumber(lim) < this.fn_getNumber(this.trstAmt) &&
        this.fn_getNumber(lim) != 0
      ) {
        let t_lim = this.fn_getComma(lim);
        this.fn_alert("한도금액(" + t_lim + ")을 초과 하였습니다.");
        //this.$refs.rfTrstAmt.focus();
        return false;
      }
      let mtrtDate = this.mtrtDate.replaceAll("-", "");
      if (!TSComDate.gfn_isDate8(mtrtDate)) {
        this.fn_alert("만기일을 확인하십시오.(" + mtrtDate + ")");
        //this.$refs.frMtrtDate.focus();
        return false;
      }
      //과세구분 없음
      //if(this.fn_getNumber( this.genTaxtTc ) <= 0 ) {
      //  this.fn_alert("과세구분을 확인하십시오.");
      //  this.$refs.rfGenTaxtTc.focus();
      //  return false;
      //}
      if (this.fn_getNumber(this.poaoTc) <= 0) {
        this.fn_alert("선후취구분을 확인하십시오.");
        //this.$refs.rfPoaoTc.focus();
        return false;
      }
      if (this.fn_getNumber(this.opdtNtcTc) <= 0) {
        this.fn_alert("운영내역통보를 확인하십시오.");
        //this.$refs.rfOpdtNtcTc.focus();
        return false;
      }
      if (this.poaoTc == "1" && this.fn_getNumber(this.epayFeeAmt, 0) <= 0) {
        this.fn_alert("별납수수료를 확인하십시오."); //20130322 ljy, 별납-선취일 경우만 별납수수료 계산해서 세팅하도록 변경 (기존에는 별납일경우 무조건 체크)-
        return false;
      }
      if (this.poaoTc == "3" && this.fn_getNumber(this.prfe) <= 0) {
        this.fn_alert("선취수수료를 확인하십시오.(" + this.prfe + ")");
        return false;
      }
      if (
        this.poaoTc != "1" &&
        !_.isEmpty(this.basTfrt) &&
        this.basTfrt.trim().length < 1
      ) {
        //20120430 lbo, 보수 수수료 체계 변경의 건
        this.fn_alert("기본보수율을 확인하십시오.");
        //this.$refs.rfBasTfrt.focus();
        return false;
      }
      if (_.isEmpty(this.mngrEmno)) {
        this.fn_alert("관리자를 확인하십시오");
        //this.$refs.rfMngrEmno.focus();
        return false;
      }
      if (_.isEmpty(this.invtMnEmno)) {
        this.fn_alert("권유자를 확인하십시오");
        //this.$refs.rfInvtMnEmno.focus();
        return false;
      }
      if (_.isEmpty(this.saleEmno)) {
        //2019.11.19 add
        this.fn_alert("판매직원을 확인하십시오");
        //this.$refs.rfSaleEmno.focus();
        return false;
      }
      if (this.invtMnEmno == this.edtCnm1) {
        // 2014.06.13 add by KJH
        this.fn_alert(
          "동일명의로 수수료 50% 지급기준입니다, 해당 내용 인지해주시기 바랍니다"
        );
      }
      if (_.isEmpty(this.soptTc)) {
        this.fn_alert("자타익구분코드을 확인하십시오");
        //this.$refs.rfSoptTc.focus();
        return false;
      }
      //if(  !_.isEmpty(this.dsDetail[0]["invtRs"])  )  {  //2016-12-30 add
      //  let nVal = this.fn_getByteLength(this.dsDetail[0]["invtRs"]);
      //  if(nVal > 500){
      //    this.fn_alert("투자권유사유 글자수 제한(500byte)을 초과했습니다");
      //    return false;
      //  }
      //}

      // 2016.12.20 구태훈 추가:계약변경의 경우 금액체크 하지 않음
      if (
        this.radProcessGb != "3" &&
        this.fn_getNumber(this.trstAmt, -1) <= 0
      ) {
        this.fn_alert("신탁금액을 확인하십시오.");
        //this.$refs.rfTrstAmt.focus();
        return false;
      }

      // 2018.06.28 add
      if (this.dsDetail[0]["frBondGdYn"] == "Y" && _.isEmpty(this.frBondCpTc)) {
        this.fn_alert("해외채권 쿠폰처리여부를 확인하십시오.");
        //this.$refs.rfFrBondCpTc.focus();
        return false;
      }

      return rBoolean;
    },
    /**********************************************************************************
     * 입력값 체크
     **********************************************************************************/
    fn_gridDataCheck() {
      //Grid Validation Check
      let rBoolean = true;
      let trstTypC = this.trstTypC;

      if (trstTypC == "001") {
        if (this.dsList02.length == 0) {
          this.fn_alert("운용지시내역은 필수입력항목입니다.");
          rBoolean = false;
        } else if (this.dsList02.length > 0) {
          for (let ini = 0; this.dsList02.length > ini; ini++) {
            let CHK01 = this.dsList02[ini]["appnMethC"];
            let CHK02 = this.dsList02[ini]["appnStrtRto"];
            let CHK03 = this.dsList02[ini]["appnEndRto"];
            let CHK04 = this.dsList02[ini]["opdtExpl"];

            if (CHK01.length <= 0) {
              this.fn_alert("운용방법코드를 입력하여 주십시요");
              rBoolean = false;
              break;
            } else if (this.fn_getNumber(CHK02, 0) < 0) {
              this.fn_alert("운용비율(From)은 0%보다 작을수 없습니다.");
              rBoolean = false;
              break;
            } else if (this.fn_getNumber(CHK03) > 100) {
              this.fn_alert("운용비율 (To)은 100%를 넘을수 없습니다.");
              rBoolean = false;
              break;
            } else if (this.fn_getNumber(CHK02) == 0) {
              if (this.fn_getNumber(CHK03) == 0) {
                this.fn_alert(
                  "운용비율 (From) 과 운용비율(To) 이 둘다 0일수 없습니다."
                );
                rBoolean = false;
                break;
              }
            } else if (this.fn_getNumber(CHK03) == 0) {
              if (this.fn_getNumber(CHK02) == 0) {
                this.fn_alert(
                  "운용비율 (From) 과 운용비율(To) 이 둘다 0일수 없습니다."
                );
                rBoolean = false;
                break;
              }
            } else if (this.fn_getNumber(CHK02) > this.fn_getNumber(CHK03)) {
              this.fn_alert("운용비율 (To)가 운용비율(From)보다 클수없습니다.");
              rBoolean = false;
              break;
            }
          } //for
        } //else if
      } else if (trstTypC == "002" || trstTypC == "009") {
        //20230307 유언대용신탁(유가증권)추가
        if (this.dsList03.length == 0) {
          this.fn_alert("유가증권내역은 필수입력항목입니다.");
          rBoolean = false;
        } else if (this.dsList03.length > 0) {
          for (let ini = 0; this.dsList03.length > ini; ini++) {
            let CHK01 = this.dsList03[ini]["astsType"];
            let CHK02 = this.dsList03[ini]["secusNo"];
            let CHK03 = this.dsList03[ini]["dnmn"];
            let CHK04 = this.dsList03[ini]["qty"];
            let CHK05 = this.dsList03[ini]["snbQty"];
            let CHK06 = this.dsList03[ini]["trstPric"];

            if (CHK01.length <= 0) {
              this.fn_alert("유가증권내역의 구분을 선택하여 주십시요");
              rBoolean = false;
              break;
            }
            if (CHK02.length <= 0) {
              this.fn_alert("유가증권내역의 증권번호를 입력하여 주십시요");
              rBoolean = false;
              break;
            }
            if (this.fn_getNumber(CHK03) < 0) {
              this.fn_alert("유가증권내역의 권종은 0보다 작을수 없습니다.");
              rBoolean = false;
              break;
            } else {
              this.dsList03[ini]["dnmn"] = this.fn_getNumber(CHK03);
            }
            if (this.fn_getNumber(CHK04) < 0) {
              this.fn_alert("유가증권내역의 수량은 0보다 작을수 없습니다.");
              rBoolean = false;
              break;
            } else {
              this.dsList03[ini]["qty"] = this.fn_getNumber(CHK04);
            }
            if (this.fn_getNumber(CHK05) < 0) {
              this.fn_alert("유가증권내역의 액면금액은 0보다 작을수 없습니다.");
              rBoolean = false;
              break;
            } else {
              this.dsList03[ini]["snbQty"] = this.fn_getNumber(CHK05);
            }
            if (this.fn_getNumber(CHK06) <= 0) {
              this.fn_alert(
                "유가증권내역의 신탁가액은 0이거나 0보다 작을수 없습니다."
              );
              rBoolean = false;
              break;
            }
          }
        }
      } else if (trstTypC == "003" || trstTypC == "010") {
        //20230307 유언대용신탁(부동산)추가
        if (this.dsList04.length == 0) {
          this.fn_alert("부동산재산목록은 필수입력항목입니다.");
          rBoolean = false;
        } else if (this.dsList04.length > 0) {
          for (let ini = 0; this.dsList04.length > ini; ini++) {
            let CHK01 = this.dsList04[ini]["realEsttTc"];
            let CHK02 = this.dsList04[ini]["realEsttLoct"];
            let CHK03 = this.dsList04[ini]["plmk"];
            let CHK05 = this.dsList04[ini]["landLdar"];
            let CHK06 = this.dsList04[ini]["flrByLdar"];
            let CHK07 = this.dsList04[ini]["trstPric"];
            if (CHK01.length <= 0) {
              this.fn_alert("부동산재산목록의 구분을 선택하여 주십시요");
              rBoolean = false;
              break;
            } else if (CHK02.length <= 0) {
              this.fn_alert("부동산재산목록의 소재지를 입력하여 주십시요");
              rBoolean = false;
              break;
            } else if (CHK02.length <= 0) {
              this.fn_alert("부동산재산목록의 지번을 입력하여 주십시요");
              rBoolean = false;
              break;
            } else if (CHK05.length <= 0) {
              this.fn_alert(
                "부동산재산목록의 면적은 0이거나 0보다 작을수 없습니다."
              );
              rBoolean = false;
              break;
            } else if (CHK06.length <= 0) {
              this.fn_alert(
                "부동산재산목록의 현황/층별면적은 0이거나 0보다 작을수 없습니다."
              );
              rBoolean = false;
              break;
            } else if (CHK07.length <= 0) {
              this.fn_alert(
                "부동산재산목록의 신탁가액은 0이거나 0보다 작을수 없습니다."
              );
              rBoolean = false;
              break;
            }
          }
        }
      } else if (trstTypC == "004") {
        if (this.dsList05.length == 0) {
          this.fn_alert("금전채권재산은 필수입력항목입니다.");
          rBoolean = false;
        } else if (this.dsList05.length > 0) {
          for (let ini = 0; this.dsList05.length > ini; ini++) {
            let CHK01 = this.dsList05[ini]["bondKind"]; //채권종류
            let CHK02 = this.dsList05[ini]["debtMnNm"]; //채무자명
            let CHK03 = this.dsList05[ini]["bondFaceAmt"]; //채권액면금액
            let CHK04 = this.dsList05[ini]["trstPric"]; //신탁가액

            if (CHK01.length <= 0) {
              this.fn_alert("금전채권재산의 채권종류를 입력하여 주십시요");
              rBoolean = false;
              break;
            } else if (CHK02.length <= 0) {
              this.fn_alert("금전채권재산의 채무자명을 입력하여 주십시요");
              rBoolean = false;
              break;
            } else if (this.fn_getNumber(CHK03) <= 0) {
              this.fn_alert(
                "금전채권재산의 채권액면금액은 0이거나 0보다 작을수 없습니다."
              );
              rBoolean = false;
              break;
            } else if (this.fn_getNumber(CHK04) <= 0) {
              this.fn_alert(
                "금전채권재산의 신탁가액은 0이거나 0보다 작을수 없습니다."
              );
              rBoolean = false;
              break;
            }
          }
        }
      }
      return rBoolean;
    },
    /**********************************************************************************
     * gfn_callService의 콜백 함수 : transaction 응답처리
     **********************************************************************************/
    fn_callBack(svcID, res) {
      if (res.data.errorCode != "") {
        let msg =
          res.data.errorMsg == null || res.data.errorMsg == ""
            ? res.data.errorCode
            : res.data.errorMsg;
        this.fn_alert(msg);
        return;
      }
      let rtnDetail = res.data;

      if (
        svcID == "insertTFAC20000A" &&
        thisWin.radProcessGb == "1" &&
        thisWin.btnAdmissionText == "승인요청"
      ) {
        let strWarningRecd = "";

        if (rtnDetail["recYn"] == "Y") {
          //2021.05.06수정
          strWarningRecd = "녹취 대상건으로,";
          strWarningRecd += "\n #2900 녹취내역 미등록시 처리가 불가합니다.";
          strWarningRecd +=
            "\n\n확인 : #2900 녹취내역 등록 화면 이동 \n취소 : 닫기";
          thisWin.fn_alert(strWarningRecd);
        } //고령고객 녹취미등록시
      }
      switch (svcID) {
        case "insertTFAC20000": //등록후처리
          thisWin.$ToastManager.fn_ShowToast({
            text: "수정가능합니다. (승인요청은 AML고객인증 후 가능).",
          });
          let nRow = thisWin.dsList.length;
          let dsListTmp = _.cloneDeep(thisWin.dsList);
          dsListTmp[nRow] = {
            value: rtnDetail["ctno"],
            text: rtnDetail["ctno"],
          };
          thisWin.dsList = _.cloneDeep(dsListTmp);
          thisWin.cboCtno = rtnDetail["ctno"];
          thisWin.fn_searchList();
          break;
        case "updateTFAC20000B":
          if (thisWin.radProcessGb == "2") {
            thisWin.$ToastManager.fn_ShowToast({
              text: "추가 등록이 완료 되었습니다.",
            });
          } else {
            thisWin.$ToastManager.fn_ShowToast({
              text: "변경 등록이 완료 되었습니다.",
            });
          }
          let i09 = TSCommUtil.gfn_getIdxArrMapKeyVal(
            thisWin.dsList09,
            "value",
            rtnDetail["chngSeq"]
          );
          if (i09 == -1) {
            let d09 = _.cloneDeep(thisWin.dsList09);
            d09.push({
              value: rtnDetail["chngSeq"],
              text: rtnDetail["chngSeq"],
            });
            thisWin.dsList09 = _.cloneDeep(d09);
          }
          thisWin.cboChngSeq = rtnDetail["chngSeq"];
          thisWin.fn_searchList();
          break;
        case "deleteTFAC20000":
          thisWin.Div02_cbo_acno_onitemchanged();
          break;
        case "deleteTFAC20000A":
          thisWin.acctCallBackFunc();
          break;
        case "selectTFAC20000DList":
          break;
        default:
          thisWin.fn_searchList();
          break;
      }
    },
    /**********************************************************************************
     * Life age
     **********************************************************************************/
    fn_lifeAge() {
      //파생상품 위험도 팝업
      let derin = "N"; //파생상품 유무
      let vBirth = ""; //생년월일
      let aaYear = "19";
      let rcno = this.dsListS1["rcno"];
      if (rcno == undefined || rcno == null || rcno == "") {
        rcno = this.dsListS2["rcno"];
      }
      if (rcno == undefined || rcno == null || rcno == "") {
        rcno = this.dsListS1Base["rcno"];
      }
      rcno = rcno.replaceAll("-", "");
      let aDate = rcno.substr(0, 6); // 주민번호 앞자리만
      let sex = rcno.substr(6, 1); //성별

      //주민번호가 3이상이면 2000년도 출생
      if (sex == "3" || sex == "4" || sex == "7" || sex == "8") {
        aaYear = "20";
      }

      vBirth = aaYear + aDate;
      //나이계산
      let aYear = this.fn_getNumber(vBirth.substr(0, 4));
      let aMonth = this.fn_getNumber(vBirth.substr(4, 2));
      let aDay = this.fn_getNumber(vBirth.substr(6, 2));

      let bYear = this.fn_getNumber(this.busyDate.substr(0, 4));
      let bMonth = this.fn_getNumber(this.busyDate.substr(4, 2));
      let bDay = this.fn_getNumber(this.busyDate.substr(6, 2));
      if (aMonth - bMonth > 0) {
        //생일 월 안지난경우
        aYear = aYear + 1;
      } else if (aMonth - bMonth == 0) {
        if (aDay - bDay > 0) {
          //생일 일자 안지난경우
          aYear = aYear + 1;
        }
      }
      //this.lifeAge.set_value( bYear - aYear );
      return bYear - aYear;
    },
    /**********************************************************************************
     * 확인 : WFtitleCRUDbtn 의 callback
     **********************************************************************************/
    fn_Confirm() {
      let bri = false;
      for (let i = 0; i < this.dsList01.length; i++) {
        if (
          this.fn_getNumber(this.dsList01[i]["prftBnfcBnkSseq"]) == 0 ||
          this.fn_getNumber(this.dsList01[i]["prinBnfcBnkSseq"]) == 0
        ) {
          bri = true;
        }
      }
      let wArr = [];
      if (this.sSvcID == "updateTFAC20000B" && this.radProcessGb == "2") {
        let trstAmt = this.trstAmt; //추가 금액
        let trstOtxtAmt = this.dsDetail[0]["trstOtxtAmt"]; // 원래 신탁금액
        wArr.push(
          "계약  금액 : [" +
            this.fn_getComma(this.fn_getNumber(trstOtxtAmt)) +
            "]"
        );
        wArr.push(
          "추가  금액 : [" + this.fn_getComma(this.fn_getNumber(trstAmt)) + "]"
        );
        wArr.push(
          "변경후금액 : [" +
            this.fn_getComma(
              this.fn_getNumber(trstOtxtAmt) + this.fn_getNumber(trstAmt)
            ) +
            "]"
        );

        // 선취수수료가 존재하는경우 .
        if (this.poaoTc == "3") {
          // 선취 + 후취
          wArr.push(
            "선취 수수료 금액 : [" +
              this.fn_getComma(
                (this.fn_getNumber(trstAmt) * this.fn_getNumber(this.prfeRt)) /
                  100
              ) +
              "]"
          );
        }
      }
      if (bri) {
        let wArr2 = [];
        wArr2.push(
          "지급받을 계좌를 별도로 지정하지 않은 경우, 해당 계약의 브릿지계좌로 지급됩니다."
        );
        wArr2.push("계속 진행하시겠습니까?");
        thisWin.fn_alert(
          wArr2,
          function() {
            if (thisWin.fn_InDataCheck()) {
              if (wArr.length < 1) {
                thisWin.fn_ConfirmJob();
              } else {
                thisWin.fn_alert(
                  wArr,
                  function() {
                    thisWin.fn_ConfirmJob();
                  },
                  function() {
                    return false;
                  }
                );
              }
            }
          },
          function() {
            return false;
          }
        );
      } else {
        if (thisWin.fn_InDataCheck()) {
          if (wArr.length < 1) {
            thisWin.fn_ConfirmJob();
          } else {
            thisWin.fn_alert(
              wArr,
              function() {
                thisWin.fn_ConfirmJob();
              },
              function() {
                return false;
              }
            );
          }
        }
      }        
      
        this.ds_searchParam = {}; //초기화

        this.ds_searchParam.tacno = this.dsDetail[0]["tacno"].substr(0, 7);
        this.ds_searchParam.bnkbSeq = this.dsDetail[0]["bnkbSeq"].substr(7, 3);
        this.ds_searchParam.ctno = Number(this.dsDetail[0]["ctno"]);
        this.ds_searchParam.accSeq = this.dsDetail[0]["accSeq"];
        this.ds_searchParam.csId = this.dsDetail[0]["csId"];

        this.ds_searchParam.procType =
          thisWin.fn_getNumber(this.radProcessGb) - 1 + "";
        this.ds_searchParam.chngSeq = this.dsDetail[0]["chngSeq"];

      if(this.radProcessGb == "1" && this.soptTc == "20"){
        this.ds_searchReport.reportMrdNm = "TS000115";
        this.ds_searchReport.reportUrl = "reportTFAC20000";
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam);
        this.ds_searchReportList.push(
          JSON.parse(JSON.stringify(this.ds_searchReport))
        );
        this.fn_initReport(); //리포트 데이터 조회
        this.fn_searchReport(); //리포트 데이터 셋팅
        }
    },
    fn_ConfirmJob() {
      let brcd = this.lv_basInfo.brcd;
      if (brcd == "916") {
        this.fn_alert("신탁지원부는 처리 할 수 없습니다.");
        return;
      }
      let trstTypC = this.trstTypC;
      let idx = TSCommUtil.gfn_getIdxArrMapKeyVal(
        this.dsList08Acno,
        "value",
        this.cboAcno
      ); //선택된 통장번호
      if (this.sSvcID == "insertTFAC20000") {
        //신규등록
        this.fn_formTodsDetail();
        //특정금전신탁 1개월이내 대출계약 실행여부 체크 ( 2021.04.20)
        if (trstTypC == "001" && this.dsLoan["loanYn"] == "Y") {
          this.fn_alert(
            "1개월내  당사 대출내역이 있어 처리가 불가합니다. 대출여부를 확인하시기 바랍니다."
          );
          this.fn_initBody();
          return false;
        }

        if (thisWin.radProcessGb == "1") {
          if (
            thisWin.dsDetail03 != null &&
            thisWin.fn_getNumber(thisWin.dsDetail03["ctnoCnt"]) >= 9 &&
            thisWin.fn_getNumber(thisWin.dsDetail03["bnkbSeq"]) != 0 // 추가 - ASR240101094 / 무통장(999) 최대 계약 계좌수 확대
          ) {
                thisWin.fn_alert([
                  "통장에 거래가 다 찼습니다.",
                  "다른 통장을 이용하십시오.",
                ]);
            return false;
          }
        }
        //20210831 : 치매없음

        if (this.epayPoaoTc == "1" && this.epayFxrtFamtTc == "2") {
          this.dsDetail[0]["sumTamt"] =
            this.fn_getNumber(this.trstAmt) +
            this.fn_getNumber(this.epayFeeAmt);
        } else if (this.epayPoaoTc != "1") {
          this.dsDetail[0]["sumTamt"] = this.fn_getNumber(this.trstAmt);
        }
        if (this.dsListIsa == null || this.dsListIsa.length < 1) {
          this.dsListIsa = _.cloneDeep([dsListIsa_b]);
        }
        this.dsListIsa[0]["isaLmtAmt"] = this.trstAmt;

        //2018.12.04 add
        let vRetuCtrmRcno = this.dsDetail[0]["retuCtrmRcno"];
        if (
          this.dsDetail[0]["contDate"] > "20150701" &&
          !_.isEmpty(vRetuCtrmRcno) &&
          vRetuCtrmRcno.trim().length != 13
        ) {
          this.isueYn = "Y";
          this.dsDetail[0]["isueYn"] = "Y";
        } else {
          this.isueYn = "N";
          this.dsDetail[0]["isueYn"] = "N";
        }

        if (!this.fn_gridDataCheck()) {
          return;
        }

        //위에서 처리
        //if ( !this.fn_InDataCheck()) {
        //  return;
        //}
        //2021-04-06 add
        //alert("신탁상품정보조회 화면에서 상품설명서를 다운받아 고객에게 제공하고, 스크립트를 참고하여 설명하십시오." );  //#4820
        let aInfo1 = this.fn_getAcnoCondInfo();
        let ptacno1 = aInfo1["tacno"];
        let bnkSeq1 = aInfo1["bnkbSeq"];

        this.dsDetail[0]["procType"] = "2";
        this.dsDetail[0]["tacno"] = ptacno1;
        this.dsDetail[0]["bnkbSeq"] = bnkSeq1;
        this.dsDetail[0]["ctno"] = aInfo1["ctno"];
        this.dsDetail[0]["cnclDate"] = this.busyDate;
        this.dsDetail[0]["csId"] = thisWin.dsList00Svd["csId"];
        this.dsDetail[0]["mngBrcd"] = this.lv_basInfo.brcd;
        this.dsDetail[0]["invtMnBrcd"] = this.lv_basInfo.brcd;
        this.dsDetail[0]["hpcTc"] = this.hpcTc;//ASR240300857 / [사랑on신탁]신규입력화면 해피콜서비스값 생성
        let pParam = this.dsDetail[0];
        pParam["tftrfi"] = this.dsList06; //tftrfiLst
        pParam["tftbni"] = this.dsList01; //tftbnilst
        pParam["tfaill"] = this.dsListIsa; //tfaillLst
        pParam["tftoil"] = this.dsList02; //tftoilLst

        if (trstTypC == "002" || trstTypC == "009") {
          //20230307 유언대용신탁(유가증권)추가
          pParam["tftivd"] = this.dsList03; //tftivdLst
        } else if (trstTypC == "003" || trstTypC == "010") {
          //20230307 유언대용신탁(부동산)추가
          pParam["tftred"] = this.dsList04; //tftredLst
        } else if (trstTypC == "004") {
          pParam["tftmbd"] = this.dsList05; //tftmbdLst
        }
        this.eaiCommObj.lv_vm = this;
        this.eaiCommObj.auth = "I";
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600072_S";
        this.eaiCommObj.params = pParam;
        TSServiceUtil.invokeInDirect(
          this.eaiCommObj,
          function(res) {
            if (res && res.data) {
              thisWin.fn_callBack(thisWin.sSvcID, res);
            }
          },
          function(error) {
            return;
          },
          "UF10600072"
        );
      }
      if (this.sSvcID == "updateTFAC20000") {
        //계약 신규 수정(F10600091)
        this.fn_formTodsDetail();
        let modCnt = 0;
        //2018.12.04 add
        let vRetuCtrmRcno = this.dsDetail[0]["retuCtrmRcno"];

        if (
          this.dsDetail[0]["contDate"] > "20150701" &&
          !_.isEmpty(vRetuCtrmRcno) &&
          vRetuCtrmRcno.trim().length != 13
        ) {
          this.isueYn = "Y";
          this.dsDetail[0]["isueYn"] = "Y";
        } else {
          this.isueYn = "N";
          this.dsDetail[0]["isueYn"] = "N";
        }
        if (!this.fn_gridDataCheck()) {
          return;
        }
        //위에서 처리
        //if ( !this.fn_InDataCheck()) {
        //  return;
        //}

        this.dsDetail[0]["mngBrcd"] = this.lv_basInfo.brcd;
        this.dsDetail[0]["invtMnBrcd"] = this.lv_basInfo.brcd;
        this.dsDetail[0]["hpcTc"] = this.hpcTc;//ASR240300857 / [사랑on신탁]신규입력화면 해피콜서비스값 생성

        let pParam = this.dsDetail[0];
        pParam["tftrfi"] = this.dsList06; //tftrfiLst
        pParam["tftbni"] = this.dsList01; //tftbnilst
        pParam["tftoil"] = this.dsList02; //tftoilLst

        if (trstTypC == "002" || trstTypC == "009") {
          //20230307 유언대용신탁(유가증권)추가
          pParam["tftivd"] = this.dsList03; //tftivdLst
        } else if (trstTypC == "003" || trstTypC == "010") {
          //20230307 유언대용신탁(부동산)추가
          pParam["tftred"] = this.dsList04; //tftredLst
        } else if (trstTypC == "004") {
          //20230307 유언대용신탁(금전)추가
          pParam["tftmbd"] = this.dsList05; //tftmbdLst
        }
        this.eaiCommObj.lv_vm = this;
        this.eaiCommObj.auth = "U";
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600091_S";
        this.eaiCommObj.params = pParam;
        TSServiceUtil.invokeInDirect(
          this.eaiCommObj,
          function(res) {
            if (res && res.data) {
              thisWin.fn_callBack(thisWin.sSvcID, res);
            }
          },
          function(error) {
            return;
          },
          "UF10600091"
        );
      }
      if (this.sSvcID == "updateTFAC20000B" && this.radProcessGb == "2") {
        //추가입금
        this.fn_formTodsDetail();
        //////////////////////////////////////////////////////////////////////////////////////////////
        //2021-03-23
        //(일반투자자限) 추가입금 시, 상품등급이 고객투자성향을 상회할 경우 아래의 상품가입 불가 팝업 & 처리 불가
        //* 신탁유형이 특정금전신탁일 경우에 한함
        //해당 상품은 고객님의 투자성향보다 위험도가 높은 '부적합' 상품으로, 가입이 불가합니다.
        //고객님의 투자성향을 확인 후 이에 적합한 상품을 선택해 주시기 바랍니다.- 계좌기본의 투자위험등급코드와 고객성향설문 의 등급비교
        let genSpivrTc = this.dsList00["genSpivrTc"]; //genSpivrTc:일반전문투자가구분코드 1전문2일반
        // if( genSpivrTc == "2"  && //일반투자자限
        // (trstTypC == "001" || trstTypC == "007"  || trstTypC == "008" )  && //특정금전신탁 or 치매신탁(2021-08-31 add) or 유언대용신탁(금전, 20230307)
        // (   //고객투자성향이 0이거나 상품등급이 고객투자성향을 상회
        // this.fn_getNumber(this.dsList00["csIvtdRatingC"])  == 0
        // || this.fn_getNumber(this.dsList00["csIvtdRatingC"]) >  this.fn_getNumber(this.ivRskRatingC)
        // )
        // ){
        // let wArr = [];
        // wArr.push("해당 상품은 고객님의 투자성향보다 위험도가");
        // wArr.push("높은 '부적합' 상품으로, 가입이 불가합니다.");
        // wArr.push("고객님의 투자성향을 확인 후 이에 적합한");
        // wArr.push("상품을 선택해 주시기 바랍니다.");
        // wArr.push("");
        // thisWin.fn_alert(wArr);
        //
        // this.fn_initBody();
        // return;
        // }

        //////////////////////////////////////////////////////////////////////////////////////////////
        //2018.05.08 추가입금 시 파생숙려대상녹취여부 세팅
        let parseng = false;
        // 파생여부 확인
        if (trstTypC == "001" && this.gdTypDtlC == "5") {
          parseng = true;
        } else {
          for (let i = 0; i < this.dsList02.length; i++) {
            if (
              this.dsList02[i]["appnMethC"] == "10" ||
              this.dsList02[i]["appnMethC"] == "13" ||
              this.dsList02[i]["appnMethC"] == "14"
            ) {
              // 파생결합증권
              parseng = true;
            }
          }
        }

        //개인고객확인용 실명번호구분
        let sRcnoKindTc = this.dsList00Svd["rcnoKindTc"];
        let vCareYn = this.dsDetail[0]["careYn"];

        //파생녹취의무여부. 2019.08.20 add
        let vDrvtGdClasC = this.dsDetail[0]["drvtGdClasC"];

        //2019.08.20
        if (
          (sRcnoKindTc == "01" ||
            sRcnoKindTc == "04" ||
            sRcnoKindTc == "05" ||
            sRcnoKindTc == "06") &&
          vDrvtGdClasC == "01" &&
          parseng &&
          //2021.02.09 update
          this.fn_lifeAge() >= this.oldAge
        ) {
          this.dsDetail[0]["recYn"] = "Y";
        }
        if (
          (sRcnoKindTc == "01" ||
            sRcnoKindTc == "04" ||
            sRcnoKindTc == "05" ||
            sRcnoKindTc == "06") &&
          vDrvtGdClasC == "01" &&
          parseng &&
          (this.fn_getNumber(this.dsList00Svd["csIvtdRatingC"]) == 0 ||
            this.fn_getNumber(this.dsList00Svd["csIvtdRatingC"]) >
              this.fn_getNumber(this.ivRskRatingC))
        ) {
          this.dsDetail[0]["recYn"] = "Y";
        }
        if (
          (sRcnoKindTc == "07" || sRcnoKindTc == "09") &&
          vDrvtGdClasC == "01" &&
          parseng &&
          (this.fn_getNumber(this.dsList00Svd["csIvtdRatingC"]) == 0 ||
            this.fn_getNumber(this.dsList00Svd["csIvtdRatingC"]) >
              this.fn_getNumber(this.ivRskRatingC))
        ) {
          this.dsDetail[0]["recYn"] = "Y";
        }

        let trstAmt = this.trstAmt; //추가 금액
        let trstOtxtAmt = this.dsDetail[0]["trstOtxtAmt"]; // 원래 신탁금액

        let min = this.dsDetail02[0]["lwstIamtAmt"]; // 최저 입금액
        let max = this.dsDetail02[0]["histIamtAmt"]; // 최대 입금액
        let lim = this.dsDetail02[0]["lmtAmt"]; // 한도 금액

        if (this.fn_getNumber(trstAmt) < 1) {
          this.fn_alert("추가금액을 입력하십시오.");
          return;
        }
        //추가입금 ISA 체크
        if (this.gdTypDtlC == "E") {
          let isaLimitAmt = this.dsListIsa[0]["isaLmtAmt"];
          let trAmt = this.dsListIsaAmt["trAmt"];

          let openDt = this.dsDetail[0]["contDate"];
          let expDt1 = thisWin.addDateYmd(
            DateUtil.fn_AddMonth(openDt, 1 * 12),
            1
          ); //가입 1년뒤 만기일자
          let gDate = this.busyDate;

          if (gDate <= expDt1) {
            //가입일~1년이내
            if (
              this.fn_getNumber(this.trstAmt) > this.fn_getNumber(isaLimitAmt)
            ) {
              this.fn_alert("ISA 한도금액을 넘을 수 없습니다.");
              //this.rfTrstAmt.setFocus();
              return;
            }
          } else {
            if (
              this.fn_getNumber(this.trstAmt) >
              this.fn_getNumber(isaLimitAmt) - this.fn_getNumber(trAmt)
            ) {
              this.fn_alert("ISA 한도금액을 넘을 수 없습니다.");
              //this.rfTrstAmt.setFocus();
              return;
            }
          }
        }

        if (_.isEmpty(this.teleVssTc)) {
          this.teleVssTc = "2"; //유선내방구분  : 2 내방
        }

        //2021-03-23 add
        //let hpcYn = this.dsDetail[0]["hpcYn"];
        //if( hpcYn == "Y" ) {
        //  //2021-01-19 add
        //  if ( _.isEmpty( this.hpcGb)   ) {
        //    this.fn_alert(  "해피콜 구분을 선택하십시오." );
        //    return;
        //  }
        //}

        //위에서 처리함.
        //let wArr = [];
        //wArr.push("계약  금액 : ["+this.fn_getComma( this.fn_getNumber( trstOtxtAmt) )+"]");
        //wArr.push("추가  금액 : ["+this.fn_getComma( this.fn_getNumber( trstAmt ))+"]");
        //wArr.push("변경후금액 : ["+this.fn_getComma(this.fn_getNumber(trstOtxtAmt)+this.fn_getNumber(trstAmt) )+"]");
        //// 선취수수료가 존재하는경우 .
        //if ( this.poaoTc == "3" ) { // 선취 + 후취
        //  wArr.push("선취 수수료 금액 : ["+this.fn_getComma(this.fn_getNumber( trstAmt ) * this.fn_getNumber(this.prfeRt) / 100) +"]");
        //  //alrtStr2 += "선취 수수료 금액 : ["+this.fn_getComma(this.fn_getNumber( trstAmt ) * this.fn_getNumber(this.prfeRt) / 100) +"]\n";
        //}
        //if (!confirm(wArr.join("\n"))){
        //  return false;
        //}

        if (lim < this.fn_getNumber(trstOtxtAmt) + this.fn_getNumber(trstAmt)) {
          this.fn_alert(
            "변경후 금액이 한도금액을 넘을 수 없습니다." + alrtStr2
          );
          return;
        }
        this.dsDetail[0]["trstAmt"] =
          this.fn_getNumber(trstOtxtAmt) + this.fn_getNumber(trstAmt);

        let a3Info = this.fn_getAcnoCondInfo();
        this.dsDetail[0]["procType"] = "1";
        this.dsDetail[0]["tacno"] = a3Info["tacno"];
        this.dsDetail[0]["bnkbSeq"] = a3Info["bnkbSeq"];
        this.dsDetail[0]["hpcTc"] = this.hpcTc;//ASR240300857 / [사랑on신탁]신규입력화면 해피콜서비스값 생성
        let ctno = a3Info["ctno"];
        if (ctno == "" || ctno == "-1") {
          ctno = a3Info["accSeq"] + "";
          ctno = ctno.replace(/^0+/, "");
          this.dsDetail[0]["ctno"] = ctno;
        }
        this.dsDetail[0]["accSeq"] = a3Info["accSeq"];
        this.dsDetail[0]["chngSeq"] = a3Info["chngSeq"];
        this.dsDetail[0]["csId"] = thisWin.dsList00Svd["csId"];

        this.dsDetail[0]["procBrcd"] = this.lv_basInfo.brcd;
        this.dsDetail[0]["mngBrcd"] = this.lv_basInfo.brcd;
        this.dsDetail[0]["invtMnBrcd"] = this.lv_basInfo.brcd;

        let pParam = this.dsDetail[0];
        pParam["tfarfi"] = this.dsList06; //tftrfiLst
        pParam["tfabni"] = this.dsList01; //tftbnilst
        pParam["tfaoil"] = this.dsList02;
        if (trstTypC == "002" || trstTypC == "009") {
          //20230307 유언대용신탁(유가증권)추가
          pParam["tfaivd"] = this.dsList03; //tftivdLst
        } else if (trstTypC == "003" || trstTypC == "010") {
          //20230307 유언대용신탁(부동산)추가
          pParam["tfared"] = this.dsList04; //tftredLst
        } else if (trstTypC == "004") {
          pParam["tfambd"] = this.dsList05; //tftmbdLst
        }
        this.eaiCommObj.lv_vm = this;
        this.eaiCommObj.auth = "U";
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600092_S";
        this.eaiCommObj.params = _.cloneDeep(pParam);
        TSServiceUtil.invokeInDirect(
          this.eaiCommObj,
          function(res) {
            if (res && res.data) {
              thisWin.fn_callBack(thisWin.sSvcID, res);
            }
          },
          function(error) {
            return;
          },
          "UF10600092"
        );
      }
      if (this.sSvcID == "updateTFAC20000B" && this.radProcessGb == "3") {
        //변경
        this.fn_formTodsDetail();

        //////////////////////////////////////////////////////////////////////////////////////////////
        //2018.05.08 계약변경 시 파생숙려대상녹취여부 세팅
        let parseng = false;
        // 파생여부 확인
        if (trstTypC == "001" && this.gdTypDtlC == "5") {
          parseng = true;
        } else {
          for (let i = 0; i < this.dsList02.length; i++) {
            if (
              this.dsList02[i]["appnMethC"] == "10" || //운용방법-파생결합증권
              this.dsList02[i]["appnMethC"] == "13" || //운용방법-장내파생상품
              this.dsList02[i]["appnMethC"] == "14"
            ) {
              // 운용방법-장외파생상품
              parseng = true;
            }
          }
        }

        //개인고객확인용 실명번호구분
        let sRcnoKindTc = this.dsList00["rcnoKindTc"];
        let vCareYn = this.dsDetail[0]["careYn"];

        //파생녹취의무여부. 2019.08.20 add
        let vDrvtGdClasC = this.dsDetail[0]["drvtGdClasC"];

        //2019.08.20
        // 1. 파생녹취의무여부'N'인 상품을 선택 -> 녹취비대상
        // 2. 파생녹취의무여부'Y'인 상품 선택 AND ("고령자" OR "투자성향부적합") -> 녹취대상
        if (
          (sRcnoKindTc == "01" ||
            sRcnoKindTc == "04" ||
            sRcnoKindTc == "05" ||
            sRcnoKindTc == "06") &&
          vDrvtGdClasC == "01" &&
          parseng &&
          //2021.02.09 update
          this.fn_lifeAge() >= this.oldAge
        ) {
          for (let i = 0; i < this.dsList02.length; i++) {
            if (this.dsList02[i]["recYn"] == "Y") {
              this.dsDetail[0]["recYn"]["Y"];
            }
          }
        }

        if (
          (sRcnoKindTc == "01" ||
            sRcnoKindTc == "04" ||
            sRcnoKindTc == "05" ||
            sRcnoKindTc == "06") &&
          vDrvtGdClasC == "01" &&
          parseng &&
          (this.fn_getNumber(this.dsList00["csIvtdRatingC"]) == 0 ||
            this.fn_getNumber(this.dsList00["csIvtdRatingC"]) >
              this.fn_getNumber(this.dsDetail02[0]["ivRskRatingC"]))
        ) {
          for (let i = 0; i < this.dsList02.length; i++) {
            if (this.dsList02[i]["recYn"] == "Y") {
              this.dsDetail[0]["recYn"] = "Y";
            }
          }
        }

        if (
          (sRcnoKindTc == "07" || sRcnoKindTc == "09") &&
          vDrvtGdClasC == "01" &&
          parseng &&
          (this.fn_getNumber(this.dsList00["csIvtdRatingC"]) == 0 ||
            this.fn_getNumber(this.dsList00["csIvtdRatingC"]) >
              this.fn_getNumber(this.dsDetail02[0]["ivRskRatingC"]))
        ) {
          for (let i = 0; i < this.dsList02.length; i++) {
            if (this.dsList02[i]["recYn"] == "Y") {
              this.dsDetail[0]["recYn"] = "Y";
            }
          }
        }
        //2018.12.04 add
        let vRetuCtrmRcno = this.dsDetail[0]["retuCtrmRcno"];
        if (
          this.dsDetail[0]["contDate"] > "20150701" &&
          !_.isEmpty(vRetuCtrmRcno) &&
          vRetuCtrmRcno.trim().length != 13
        ) {
          this.isueYn = "Y";
          this.dsDetail[0]["isueYn"] = "Y";
        } else {
          this.isueYn = "N";
          this.dsDetail[0]["isueYn"] = "N";
        }

        if (!this.fn_gridDataCheck()) {
          return;
        }
        //위에서 처리
        //if ( !this.fn_InDataCheck()) {
        //  return;
        //}

        let a4Info = this.fn_getAcnoCondInfo();
        this.dsDetail[0]["procType"] = "2";
        this.dsDetail[0]["tacno"] = a4Info["tacno"];
        this.dsDetail[0]["bnkbSeq"] = a4Info["bnkbSeq"];
        this.dsDetail[0]["accSeq"] = a4Info["accSeq"];
        this.dsDetail[0]["chngSeq"] = a4Info["chngSeq"];
        this.dsDetail[0]["csId"] = thisWin.dsList00Svd["csId"];
        let ctno = a4Info["ctno"];
        if (ctno == "" || ctno == "-1") {
          ctno = a4Info["accSeq"] + "";
          ctno = ctno.replace(/^0+/, "");
          this.dsDetail[0]["ctno"] = ctno;
        }

        this.dsDetail[0]["procBrcd"] = this.lv_basInfo.brcd;
        this.dsDetail[0]["mngBrcd"] = this.lv_basInfo.brcd;
        this.dsDetail[0]["invtMnBrcd"] = this.lv_basInfo.brcd;
        this.dsDetail[0]["hpcTc"] = this.hpcTc;//ASR240300857 / [사랑on신탁]신규입력화면 해피콜서비스값 생성

        let pParam3 = this.dsDetail[0];
        pParam3["tfarfi"] = this.dsList06; //tftrfiLst
        pParam3["tfabni"] = this.dsList01; //tftbnilst
        pParam3["tfaoil"] = this.dsList02;
        if (trstTypC == "002" || trstTypC == "009") {
          //20230307 유언대용신탁(유가증권)추가
          pParam3["tfaivd"] = this.dsList03; //tftivdLst
        } else if (trstTypC == "003" || trstTypC == "010") {
          //20230307 유언대용신탁(부동산)추가
          pParam3["tfared"] = this.dsList04; //tftredLst
        } else if (trstTypC == "004") {
          pParam3["tfambd"] = this.dsList05; //tftmbdLst
        }
        this.eaiCommObj.lv_vm = this;
        this.eaiCommObj.auth = "I";
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600092_S";
        this.eaiCommObj.params = pParam3;
        TSServiceUtil.invokeInDirect(
          this.eaiCommObj,
          function(res) {
            if (res && res.data) {
              thisWin.fn_callBack(thisWin.sSvcID, res);
            }
          },
          function(error) {
            return;
          },
          "UF10600092"
        );
      }
    },
    /**********************************************************************************
     * 신탁유형 코드 변경시
     **********************************************************************************/
    cbo_trstTypC_onitemchanged() {
      let vTrstTypC = this.trstTypC;

      /*대출실행여부확인2021.04.20 add*/
      if (
        (vTrstTypC == "001" || vTrstTypC == "007") &&
        this.dsLoan["loanYn"] == "Y"
      ) {
        //치매신탁추가 20210831

        this.fn_alert(
          "1개월내  당사 대출내역이 있어 처리가 불가합니다. 대출여부를 확인하시기 바랍니다."
        );
        this.fn_initBody();
        return false;
      }
      /*대출실행여부확인2021.04.20 add*/

      //2021-05-07 add
      let sRcnoKindTc = this.dsListS1["rcnoKindTc"]; // 실명번호구분 코드 (01:주민등록번호 04:외국인등록번호 05:국내거소신고번호)
      if (sRcnoKindTc == "") {
        sRcnoKindTc = this.dsListS2[0]["rcnoKindTc"];
      }

      if (
        this.fn_lifeAge() >= this.fn_getNumber(this.superAge) &&
        (sRcnoKindTc == "01" ||
          sRcnoKindTc == "04" ||
          sRcnoKindTc == "05" ||
          sRcnoKindTc == "06") &&
        vTrstTypC != "002" &&
        vTrstTypC != "003" &&
        vTrstTypC != "006" &&
        vTrstTypC != "007" && //20210831
        vTrstTypC != "008" &&
        vTrstTypC != "009" &&
        vTrstTypC != "010"
      ) {
        //202301 유언대용신상

        this.fn_alert(
          "만 " +
            this.superAge +
            "세 이상 초고령자의 경우 유언대용신탁, 부동산신탁, 유가증권신탁, 치매신탁 외 상품가입이 불가합니다."
        );
        vTrstTypC = "";
        this.trstTypC = "";
        this.opdtNtcTc = ""; //운영내역통보
        return false;
      } else {
        this.fn_initBody(false);
        //신탁유형,상품유형,신탁상품/신탁상품팝업
        TSCommUtil.gfn_setDisableArr(
          this,
          ["disTrstTypC", "disGdTypDtlC", "disGdC"],
          false
        );

        this.trstTypC = vTrstTypC;
        this.cbo_trstTypCTogdTypDtlC();
      }
    },
    /**********************************************************************************
     * 자타익 구분 변경
     **********************************************************************************/
    cbo_soptTc_onitemchanged() {
      if (_.isEmpty(this.trstTypC)) {
        return;
      }
      if (this.soptTc == "10") {
        //자익

        for (let i = 0; i < this.dsList01.length; i++) {
          let mapL01 = _.cloneDeep(this.dsList01[i]);
          mapL01["prinBnfcCsId"] = this.dsList00["csId"];
          mapL01["prinBnfcRcno"] = this.dsList00["rcno"];
          mapL01["prinBnfcRcnoGb"] = this.dsList00["rcnoKindTc"];
          mapL01["prinBnfcCnm"] = this.dsList00["cnm"];
          mapL01["prinBnfcBnkSseq"] = "0";
          mapL01["prinBnfcBnkSseqNm"] = " - 선택안함(브릿지계좌로 송금) - ";
          mapL01["prftBnfcCsId"] = this.dsList00["csId"];
          mapL01["prftBnfcRcno"] = this.dsList00["rcno"];
          mapL01["prftBnfcRcnoGb"] = this.dsList00["rcnoKindTc"];
          mapL01["prftBnfcCnm"] = this.dsList00["cnm"];
          mapL01["prftBnfcBnkSseq"] = "0";
          mapL01["prftBnfcBnkSseqNm"] = " - 선택안함(브릿지계좌로 송금) - ";
          this.dsList01.splice(i, 1, mapL01);
        }
      } else {
        thisWin.$ToastManager.fn_ShowToast({
          text: '"자타익구분을 확인하십시오"',
        });
        //this.fn_alert("자타익구분을 확인하십시오");
        //this.$refs.rfSoptTc.focus();
        return;
      }

      this.fn_custRcnoMask(); //개인정보 마스킹
    },
    /**********************************************************************************
     * 상품정보를 조회한다.
     **********************************************************************************/
    fn_searchTfcprb(mode) {
      if (mode == undefined || mode == null) mode = 0;
      let gdC = this.gdC;
      let trstTypC = this.trstTypC;
      let gdTypDtlC = this.gdTypDtlC;
      if (gdC.length != 6) {
        return;
      }
      if (trstTypC == "001" && gdTypDtlC == "E") {
        let sCorpYn = this.dsList00["corpYn"]; // 법인여부
        if (sCorpYn != "Y") {
          let oArg = {
            tacno: this.edtAcno1,
            cnm: this.edtCnm1,
            dsListIsa: this.dsListIsa,
          }; //팝업화면으로 보낼 파라미터.

          //TFAC25101 : ISA는 대상이 아님(2023.09.11)
        } else {
          this.fn_alert("법인고객은 ISA상품을 선택할 수 없습니다");
          return;
        }
      } else {
        if (thisWin.gdC == thisWin.preGdC) {
          return;
        }

        let anoInfo = this.fn_getAcnoCondInfo();
        let ptacno = anoInfo["tacno"];
        if (ptacno == null || ptacno == "") {
          return;
        }
        //selectTFAC20000A
        let pParam = {
          gdC: thisWin.gdC,
          procTc: "2000",
          tacno: ptacno,
        };
        this.eaiCommObj.lv_vm = this;
        this.eaiCommObj.auth = "S";
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600080_S";
        this.eaiCommObj.params = pParam;
        TSServiceUtil.invokeInDirect(
          this.eaiCommObj,
          function(res) {
            if (res && res.data) {
              thisWin.dsDetail02 = TSCommUtil.gfn_getArrFrBase(
                dsDetail_b,
                res.data.tfcprb
              );
              // if (thisWin.radProcessGb == "1"){
              //   if( !_.isEmpty(thisWin.dsDetail02) && thisWin.dsDetail02[0]["fcInvtPosbYn"] == "Y" ) {
              //     //재무설계 FP/재무설계 FP 팝업
              //     TSCommUtil.gfn_setDisableArr(thisWin,["disFnlDsgnEmno"],false);
              //   }else{
              //     TSCommUtil.gfn_setDisableArr(thisWin,["disFnlDsgnEmno"],true);
              //   }
              // }
              //초기화문제(투자성향)로 아래 내용 추가함.(설문일자 후 투자성향.)
              if (
                thisWin.trstTypC == "001" ||
                thisWin.trstTypC == "007" ||
                thisWin.trstTypC == "008"
              ) {
                // if (thisWin.dsList00Svd["procDt"] != thisWin.busyDate) {
                //   let wArr = [];
                //   wArr.push("고객성향설문지등록일자와 계좌개설일자가");
                //   wArr.push("일치하지 않습니다. 이 경우 계좌개설");
                //   wArr.push("업무가 제한되오니 고객성향설문지를 ");
                //   wArr.push("재등록 하시기 바랍니다.");
                //   wArr.push("");
                //   wArr.push("확인 : 고객설문조사 등록");
                //   wArr.push("취소 : 닫기");
                //   thisWin.pAlertPopupObj.content = wArr;
                //   thisWin.pAlertPopupObj.confirm = true;
                //   thisWin.pAlertPopupObj.confirmFunc = thisWin.fn_popSurvey;
                //   thisWin.$refs.alertPopup.fn_Open(thisWin.pAlertPopupObj);
                //   return false;
                // }

                let genSpivrTc = thisWin.dsList00Svd["genSpivrTc"]; //genSpivrTc:일반전문투자가구분코드 1전문2일반
                if (
                  genSpivrTc == "2" && //일반투자자限 //고객투자성향이 0이거나 상품등급이 고객투자성향을 상회
                  (thisWin.fn_getNumber(thisWin.dsList00Svd["csIvtdRatingC"]) ==
                    0 ||
                    thisWin.fn_getNumber(thisWin.dsList00Svd["csIvtdRatingC"]) >
                      thisWin.fn_getNumber(
                        thisWin.dsDetail02[0]["ivRskRatingC"]
                      ))
                ) {
                  let wArr = [];
                  wArr.push("해당 상품은 고객님의 투자성향보다 위험도가");
                  wArr.push("높은 '부적합' 상품으로, 가입이 불가합니다.");
                  wArr.push("고객님의 투자성향을 확인 후 이에 적합한");
                  wArr.push("상품을 선택해 주시기 바랍니다.");
                  wArr.push("");

                  thisWin.fn_formClear();
                  thisWin.fn_alert(wArr);
                  return;
                }
              }

              thisWin.fn_copyGoodsResult(thisWin.dsDetail02);
              thisWin.dsList01 = TSCommUtil.gfn_getArrFrBase(
                dsList01_b,
                res.data.tftbni
              );
              thisWin.dsList02 = TSCommUtil.gfn_getArrFrBase(
                dsList02_b,
                res.data.tfcpil
              );
              thisWin.dsList06 = TSCommUtil.gfn_getArrFrBase(
                dsList06_b,
                res.data.tfcpfi
              );
              thisWin.preGdC = thisWin.gdC;
              if (mode == 0) {
                thisWin.fn_callBackSub("selectTFAC20000A");
              } else {
              }
            } else {
            }
          },
          function(error) {
            return;
          },
          "UF10600080"
        );
      }
    },
    /**********************************************************************************
     * Only dsDetail02
     **********************************************************************************/
    fn_getDetail02() {
      if (thisWin.gdC == null || thisWin.gdC == "") return;
      let anoInfo = this.fn_getAcnoCondInfo();
      let ptacno = anoInfo["tacno"];
      if (ptacno == null || ptacno == "") return;
      let pParam = {
        gdC: thisWin.gdC,
        procTc: "2000",
        tacno: ptacno,
      };
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600080_S";
      this.eaiCommObj.params = pParam;
      TSServiceUtil.invokeInDirect(
        this.eaiCommObj,
        function(res) {
          if (res && res.data) {
            thisWin.dsDetail02 = TSCommUtil.gfn_getArrFrBase(
              dsDetail_b,
              res.data.tfcprb
            );
          }
        },
        function(error) {
          return;
        },
        "UF10600080"
      );
    },
    /**********************************************************************************
     * 파생자격여부 Check 위한 파생상품 Check
     **********************************************************************************/
    fn_CheckGdC() {
      //2019.11.19 add : 계약변경시에도 파생자격 체크하도록 변경
      if (this.radProcessGb == "1") {
        if (this.dsDetail02 == null || this.dsDetail02.length < 1) return false;
        if (
          this.dsDetail02[0]["gdTypDtlC"] == "5" ||
          this.dsDetail02[0]["drvtInvtPosbYn"] == "Y"
        ) {
          return true;
        }
      } else if (this.radProcessGb == "3") {
        if (this.trstTypC == "001" && this.gdTypDtlC == "5") {
          return true;
        }
      }

      return false;
    },
    /**********************************************************************************
     * 파생자격여부 Check
     **********************************************************************************/
    fn_CheckTFMDLI(svcID) {
      let busyDate = this.busyDate; // 오늘일자
      let regYn = this.dsTfmdli["regYn"]; // 등록여부
      let regDate = this.dsTfmdli["regDate"]; // 등록일자
      let fctStopDate = this.dsTfmdli["fctStopDate"]; // 효력정지일자
      let feeEdDate = this.dsTfmdli["feeEdDate"]; // 보수교육완료일자
      let ersrDate = this.dsTfmdli["ersrDate"]; // 말소일자

      if (
        regYn != "Y" ||
        (ersrDate != "" && ersrDate < busyDate) ||
        (feeEdDate == "" && fctStopDate != "" && fctStopDate < busyDate) ||
        (regDate != "" && busyDate < regDate)
      ) {
        if (svcID == "selectTFAC20000InvtMnEmno") {
          this.invtMnEmno = "";
          this.invtMnEmnoNm = "";
        } else if (svcID == "selectTFAC20000MngrEmno") {
          this.mngrEmno = "";
          this.mngrEmnoNm = "";
        } else if (svcID == "selectTFAC20000SaleEmno") {
          //2019.11.19 add
          this.saleEmno = "";
          this.saleEmnoNm = "";
        }

        this.fn_alert("파생자격 확인요망");
        return false;
      }
      return true;
    },
    /**********************************************************************************
     * gfn_callService 의 콜백 함수 : transaction 응답처리 ( 구 : fn_call_back_cdi_select (SC))
     **********************************************************************************/
    fn_callBackTFMDLI() {
      if (!_.isEmpty(thisWin.dsTfmdli)) {
        let flag = thisWin.fn_CheckTFMDLI(this.sSvcIDSub);

        if (thisWin.emnoPopupYn == "N" && flag) {
          if (this.sSvcIDSub == "selectTFAC20000InvtMnEmno") {
            this.fn_getUserName(this.invtMnEmno, "invtMnEmnoNm");
            //this.gfn_getName( "usid" , this.edt_invtMnEmno.value , this.edt_invtMnEmnoNm );
          } else if (this.sSvcIDSub == "selectTFAC20000MngrEmno") {
            this.fn_getUserName(this.mngrEmno, "mngrEmnoNm");
            //this.gfn_getName( "usid" , this.edt_mngrEmno.value , this.edt_mngrEmnoNm );
            if (thisWin.invtMnEmno.length < 5) {
              thisWin.invtMnEmno = thisWin.mngrEmno;
              this.fn_getUserName(this.invtMnEmno, "invtMnEmnoNm");
              //this.gfn_getName( "usid" , this.edt_invtMnEmno.value , this.edt_invtMnEmnoNm );
            }
          } else if (this.sSvcIDSub == "selectTFAC20000SaleEmno") {
            //2019.11.19 add
            this.fn_getUserName(this.saleEmno, "saleEmnoNm");
            //this.gfn_getName( "usid" , this.edt_saleEmno.value , this.edt_saleEmnoNm );
          }
        }
      }
    },
    /**********************************************************************************
     * 파생상품일 경우 호출
     **********************************************************************************/
    fn_transactionEmno(emno) {
      this.sSvcIDSub = "";

      let pParam = {};
      let eaiId = "";
      let errId = "";
      if (emno == "edt_invtMnEmno") {
        pParam["usid"] = this.invtMnEmno;
        this.sSvcIDSub = "selectTFAC20000InvtMnEmno";
        eaiId = "C392_F10600087_S";
        errId = "UF10600087";
      } else if (emno == "edt_mngrEmno") {
        pParam["usid"] = this.mngrEmno;
        this.sSvcIDSub = "selectTFAC20000MngrEmno";
        eaiId = "C392_F10600088_S";
        errId = "UF10600088";
      } else if (emno == "edt_saleEmno") {
        //2019.11.19 add
        pParam["usid"] = this.saleEmno;
        this.sSvcIDSub = "selectTFAC20000SaleEmno";
        eaiId = "C392_F10600090_S";
        errId = "UF10600090";
      }

      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      this.eaiCommObj.commHeaderVO.eaiId = eaiId;
      this.eaiCommObj.params = pParam;
      TSServiceUtil.invokeInDirect(
        this.eaiCommObj,
        function(res) {
          if (res && res.data) {
            thisWin.dsTfmdli = TSCommUtil.gfn_getMapFrBase(
              dsTfmdli_b,
              res.data
            );
            thisWin.fn_callBackTFMDLI();
          }
        },
        function(error) {
          return;
        },
        errId
      );
    },
    /**********************************************************************************
     * 승인요청 진행
     **********************************************************************************/
    fn_admissionReq() {
      this.fn_formTodsDetail();
      let dsD01 = _.cloneDeep(thisWin.dsDetail[0]);
      //let aInfo = thisWin.fn_getAcnoCondInfo();

      dsD01["procType"] = thisWin.fn_getNumber(thisWin.radProcessGb) - 1 + "";

      //dsD01["tacno"] = aInfo["tacno"];
      //dsD01["bnkbSeq"] = aInfo["bnkbSeq"];
      //dsD01["accSeq"] = aInfo["accSeq"];
      //dsD01["ctno"] = aInfo["ctno"];

      if (dsD01["procType"] == "") {
        alert("업무구분을 확인하세요.");
        return;
      }
      //insertTFAC20000D
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "I";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600076_S";
      this.eaiCommObj.params = _.cloneDeep(dsD01);
      TSServiceUtil.invokeInDirect(
        this.eaiCommObj,
        function(res) {
          if (res && res.data) {
            thisWin.fn_callBack("insertTFAC20000D", res);
            thisWin.fn_setTimerApprvState();
          }
        },
        function(error) {
          return;
        },
        "UF10600076"
      );
    },
    /**********************************************************************************
     * 팝업 콜백
     **********************************************************************************/
    async fn_popupAfter(obj) {
      //if (_.isEmpty(obj)) return;
      switch (this.sPopupId) {
        case "TPUP95330": //신탁상품팝업
          if (_.isEmpty(this.dsDetail) || this.dsDetail.length < 1)
            this.dsDetail[0] = _.cloneDeep(dsDetail_b);
          this.trstTypC = obj.trstTypC;
          this.gdTypDtlC = obj.gdTypDtlC;
          this.gdC = obj.gdC;
          this.gdNm = obj.gdNm;
          this.dsDetail[0]["careYn"] = obj.careYn; //2018.05.08 add
          this.dsDetail[0]["frBondGdYn"] = obj.frBondGdYn; //2018.06.28 add
          this.dsDetail[0]["drvtGdClasC"] = obj.drvtGdClasC; //2019.08.20 add
          this.dsDetail[0]["issueDate"] = obj.issueDate; //2019.08.20 add
          this.fn_searchTfcprb();
          break;
        case "TPUP95020": //AML 등록 팝업
          this.fn_searchList();
          break;
        case "TFAC25101": // ISA 신탁상품팝업 콜백(대상아님.)
          break;
        case "TPUP95110_00": // 권유자 선택 팝업
          this.invtMnEmno = obj.usid;
          this.invtMnEmnoNm = obj.userNm;

          this.emnoPopupYn = "Y";
          if (this.fn_CheckGdC()) {
            this.fn_transactionEmno("edt_invtMnEmno");
          }

          break;
        case "TPUP95110_01": //관리자 선택 팝업
          this.mngrEmno = obj.usid;
          this.mngrEmnoNm = obj.userNm;

          this.emnoPopupYn = "Y";
          if (this.fn_CheckGdC()) {
            this.fn_transactionEmno("edt_mngrEmno");
          }

          if (this.invtMnEmno.length < 5) {
            this.invtMnEmno = obj.usid;
            this.invtMnEmnoNm = obj.userNm;
          }
          break;

        case "TPUP95110_02": // 판매직원 선택 팝업, 2019.11.19 add
          this.saleEmno = obj.usid;
          this.saleEmnoNm = obj.userNm;

          this.emnoPopupYn = "Y";
          if (this.fn_CheckGdC()) {
            this.fn_transactionEmno("edt_saleEmno");
          }
          break;

        case "TFAC20004": //관계자 팝업
          this.dsList01 = _.cloneDeep(obj);
          this.fn_custRcnoMask();
          break;
        case "TFAC20003": //관계자 팝업
          this.fn_custRcnoMask();
          break;
        case "TPUP95290": //권유 FC 선택 팝업
          this.fnlDsgnEmno = obj.fcEmno;
          this.fnlDsgnEmnoNm = obj.fcNm;
          break;
        case "TPUP95320_00": //원금수익자
          let nRow = this.dsList01.length;
          if (nRow == 0) {
            this.dsList01.push({
              bnfcRatingTc: "1",
              fxppYn: "N",
              fxrtFamtTc: "1",
              pymRto: "100",
            });
          }

          let csid = obj.csId;
          let seq = "0";
          let seqNm = " - 선택안함(브릿지계좌로 송금) - ";
          if (
            !_.isEmpty(this.dsList01[0]["prftBnfcCsId"]) &&
            this.dsList01[0]["prftBnfcCsId"] == csid
          ) {
            seq = this.dsList01[0]["prftBnfcBnkSseq"];
            seqNm = this.dsList01[0]["prftBnfcBnkSseqNm"];
          }

          let mapL01 = _.cloneDeep(this.dsList01[0]);
          mapL01["prinBnfcCsId"] = obj.csId;
          mapL01["prinBnfcRcno"] = obj.rcno;
          mapL01["prinBnfcRcnoGb"] = obj.rcnoKindTc;
          mapL01["prinBnfcCnm"] = obj.cnm;
          mapL01["prinBnfcBnkSseq"] = seq;
          mapL01["prinBnfcBnkSseqNm"] = seqNm;
          this.dsList01.splice(0, 1, mapL01);
          this.trBkcdNmbnkAcno00 = seq;

          //if ( this.fn_getValue(this.dsList01[0]["prftBnfcCsId"]).length  < 9 ){
          if (this.soptTc == "10") {
            let mapL02 = _.cloneDeep(this.dsList01[0]);
            mapL02["prftBnfcCsId"] = obj.csId;
            mapL02["prftBnfcRcno"] = obj.rcno;
            mapL02["prftBnfcRcnoGb"] = obj.rcnoKindTc;
            mapL02["prftBnfcCnm"] = obj.cnm;
            mapL02["prftBnfcBnkSseq"] = seq;
            mapL02["prftBnfcBnkSseqNm"] = seqNm;
            this.dsList01.splice(0, 1, mapL02);
            this.trBkcdNmbnkAcno01 = seq;
          }

          // 1보다클때, 모두 다적용함 .
          if (this.dsList01.length > 1) {
            for (let i = 0; i < this.dsList01.length; i++) {
              // 첫행은 이미 적용됨 .
              if (i > 0) {
                let mapL03 = _.cloneDeep(this.dsList01[i]);
                mapL03["prinBnfcCsId"] = obj.csId;
                mapL03["prinBnfcRcno"] = obj.rcno;
                mapL03["prinBnfcRcnoGb"] = obj.rcnoKindTc;
                mapL03["prinBnfcCnm"] = obj.cnm;
                mapL03["prinBnfcBnkSseq"] = seq;
                mapL03["prinBnfcBnkSseqNm"] = seqNm;
                this.dsList01.splice(i, 1, mapL03);
              }
            }
          }

          this.fn_custRcnoMask();
          // 일괄적용
          break;
        case "TPUP95320_01": //이익수익자
          if (this.dsList01.length == 0) {
            this.dsList01.push({
              bnfcRatingTc: "1",
              fxppYn: "N",
              fxrtFamtTc: "1",
              pymRto: "100",
            });
          }
          let csid2 = obj.csId;
          let seq2 = "0";
          let seqNm2 = " - 선택안함(브릿지계좌로 송금) - ";

          if (
            !_.isEmpty(this.dsList01[0]["prinBnfcCsId"]) &&
            this.dsList01["prinBnfcCsId"] == csid2
          ) {
            seq2 = this.dsList01[0]["prinBnfcBnkSseq"];
            seqNm2 = this.dsList01[0]["prinBnfcBnkSseqNm"];
          }

          //[0] .csId              고객ID
          //[1] .cno               고객번호
          //[2] .cnm               고객명
          //[3] .rcnoKindTc        실명확인번호종류구분코드
          //[4] .rcno              실명확인번호
          //[5] .cono              법인번호
          //[6] .genTaxtTc         일반과세구분코드
          //[7] .persnBusiMnBsno   개인사업자사업자등록번호
          //[8] .corpYn            법인여부
          //[9] .csIvtdRatingC     고객투자성향등급코드
          //[10].procDt            설문 처리일자
          //[11].valdTrmYys        유효기간년수
          //[12].ques10            파생상품투자기간
          //[13].tacno             종합계좌번호
          //[14].age               보험나이
          //[15].tacnoBnkbSeq      종합계좌번호 + 통장일련번호
          //[16].regDate           고객가입일자(고객기본.등록일자)
          //[17].tassIssncAccYn    TASS발급계좌여부(구고객 : N / 신고객:Y)
          let mapB01 = _.cloneDeep(this.dsList01[0]);
          mapB01["prftBnfcCsId"] = obj.csId; //0
          mapB01["prftBnfcRcno"] = obj.rcno; //4
          mapB01["prftBnfcRcnoGb"] = obj.rcnoKindTc; //3
          mapB01["prftBnfcCnm"] = obj.cnm; //2
          mapB01["prftBnfcBnkSseq"] = seq2;
          mapB01["prftBnfcBnkSseqNm"] = seqNm2;
          this.dsList01.splice(0, 1, mapB01);
          this.trBkcdNmbnkAcno01 = seq2;
          //if ( this.fn_getValue(this.dsList01["prinBnfcCsId"]).length  < 9 ){
          if (this.soptTc == "10") {
            let mapB02 = _.cloneDeep(this.dsList01[0]);
            mapB02["prinBnfcCsId"] = obj.csId; //0
            mapB02["prinBnfcRcno"] = obj.rcno; //4
            mapB02["prinBnfcRcnoGb"] = obj.rcnoKindTc; //3
            mapB02["prinBnfcCnm"] = obj.cnm; //2
            mapB02["prinBnfcBnkSseq"] = seq2;
            mapB02["prinBnfcBnkSseqNm"] = seqNm2;
            this.dsList01.splice(0, 1, mapB02);
            this.trBkcdNmbnkAcno00 = seq2;
          }
          // 1보다클때, 모두 다적용함 .
          if (this.dsList01.length > 1) {
            for (let i = 0; i < this.dsList01.length; i++) {
              // 첫행은 이미 적용됨 .
              if (i > 0) {
                let mapB03 = _.cloneDeep(this.dsList01[i]);
                mapB03["prftBnfcCsId"] = obj.csId; //0
                mapB03["prftBnfcRcno"] = obj.rcno; //4
                mapB03["prftBnfcRcnoGb"] = obj.rcnoKindTc; //3
                mapB03["prftBnfcCnm"] = obj.cnm; //2
                mapB03["prftBnfcBnkSseq"] = seq2;
                mapB03["prftBnfcBnkSseqNm"] = seqNm2;
                this.dsList01.splice(i, 1, mapB03);
              }
            }
          }

          this.fn_custRcnoMask();
          break;
        case "TPUP95320_02": //대리인 팝업
          if (this.dsList00["csId"] == obj.csId) {
            this.fn_alert("위탁자와 대리인이 동일할 수 없습니다.");
            return;
          }

          this.agntCsId = obj.csId;
          this.agntCsIdNm = obj.cnm;
          break;
        case "TFAC21003": //모계약 선택 팝업
          this.moCtno(obj.tacno + obj.bnkbSeq + obj.accSeq);

          //selectTFAC20000DList
          let pParam2 = {
            tacno: obj.tacno,
            bnkbSeq: obj.bnkbSeq,
            accSeq: obj.accSeq,
          };
          this.eaiCommObj.lv_vm = this;
          this.eaiCommObj.auth = "S";
          this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600086_S";
          this.eaiCommObj.params = pParam2;
          TSServiceUtil.invokeInDirect(
            this.eaiCommObj,
            function(res) {
              if (res && res.data) {
                thisWin.dsList15 = TSCommUtil.gfn_getArrFrBase(
                  dsList15_b,
                  res.data.tfcprb
                );
                thisWin.fn_callBack("selectTFAC20000DList");
              }
            },
            function(error) {
              return;
            },
            "UF10600086"
          );
          break;
        case "TPUP95340": //운용방법 코드 호출
          let rw02 = this.lv_SelectedItem02;
          let mp2 = _.cloneDeep(this.dsList02[rw02]);
          mp2["appnMethC"] = obj.c;
          mp2["appnMethCNm"] = obj.cnm;

          //2018.05.08 add. 계약변경 시, 운용지시내역 파생결합증권 추가되면 파생숙려제도녹취대상에 포함
          if (this.radProcessGb == "3") {
            if (
              obj.appnGdTc == "10" ||
              obj.appnGdTc == "13" ||
              obj.appnGdTc == "14"
            ) {
              mp2["recYn"] = "Y";
            }
          }
          this.dsList02.splice(rw02, 1, mp2);
          break;
        case "TPUP90180": //KR 코드 호출
          let rw021 = this.lv_SelectedItem02;
          let mp21 = _.cloneDeep(this.dsList02[rw021]);
          mp21["krItemC"] = obj.stndItemC;
          mp21["krItemNm"] = obj.itemNm;
          this.dsList02.splice(rw021, 1, mp21);
          break;
        case "TPUP90180_01": //KR 코드 호출
          let rw03 = this.lv_SelectedItem03;
          let mp3 = _.cloneDeep(this.dsList03[rw03]);
          mp3["secusNo"] = obj.stndItemC;
          mp3["itemNm"] = obj.itemNm;
          this.dsList03.splice(rw03, 1, mp3);
          break;
        case "TPUP96201": //유선스크립트 팝업 호출(추가입금) wyj
          let addmissionReq = obj.addmissionReq;
          if (addmissionReq == "Y") {
            //유선스크립트팝업에서 "확인"을 누른경우 승인 진행
            this.fn_admissionReq();
          }
          break;
        case "TPUP96202": //유선스크립트 팝업 호출(계약변경) wyj
          let addmissionReq2 = obj.addmissionReq;
          if (addmissionReq2 == "Y") {
            //유선스크립트팝업에서 "확인"을 누른경우 승인 진행
            this.fn_admissionReq();
          }
          break;
        case "TPUP9532011": //고객조회
          //csId.고객ID, cno.고객번호, cnm.고객명, rcnoKindTc.실명확인번호종류구분코드, rcno.실명확인번호, cono.법인번호
          //, genTaxtTc.일반과세구분코드, persnBusiMnBsno.개인사업자사업자등록번호, corpYn.법인여부, csIvtdRatingC.고객투자성향등급코드
          //, procDt.설문 처리일자, valdTrmYys.유효기간년수, ques10.파생상품투자기간, tacno.종합계좌번호, age.보험나이
          //, tacnoBnkbSeq.종합계좌번호 + 통장일련번호 , regDate.고객가입일자(고객기본.등록일자), tassIssncAccYn.TASS발급계좌여부(구고객 : N / 신고객:Y)
          this.dsListS1 = _.cloneDeep(obj);
          this.edtAcno1 = this.dsListS1["tacno"]; // 종합계좌번호
          this.regDate = this.dsListS1["regDate"]; // 고객가입일자
          this.edtCnm1 = this.dsListS1["cnm"];
          // 계좌번호가 있는 경우에만 호출화면으로 메소드를 전달한다.
          if (this.edtAcno1.length == 7) {
            try {
              thisWin.fn_initBody();
              this.acnoCallBackFunc();
            } catch (e) {}
          }
          break;
        case "TPUP95350": //계좌번호정보조회 btn_popupTPUP95350_onclick
          //tacno  ,bnkbSeq  ,accSeq  ,gdC  ,gdNm  ,trstTypC  ,cnm
          //,rcno  ,rcnoKindTc  ,accStatC  ,bnkbPswd  ,csId
          this.edtAcno2 = obj.tacno + obj.bnkbSeq;
          this.cboAccSeq = obj.accSeq;
          this.popAccSeq = obj.accSeq; //popup accSeq 처리
          this.fn_transactionS2(); // 조회 처리
          break;
        case "TPUP97050": //조력자 등록 팝업
          let addmissionReq3 = obj;
          if (addmissionReq3 == "Y") {
            //조력자 화면
            this.sPopupId = "MSPTS322P_00";
            let p1 = this.fn_getAcnoCondInfo();
            this.pPopup322pObj.params = {
              tacno: p1["tacno"],
              bnkbSeq: p1["bnkbSeq"],
              accSeq: p1["accSeq"],
              ctno: p1["ctno"],
            };
            this.$refs.popup322p.fn_Open();
          } else {
            //조력자 있음
            thisWin.oldAgeConfirm = true;
            thisWin.$ToastManager.fn_ShowToast({
              text: "승인요청 가능합니다(조력자완료).",
            });
          }
          break;
        case "MSPTS322P_00": //조력자 등록 완료
          //조력자 등록여부 확인
          this.oldAgeConfirm = false;
          let aInfo = thisWin.fn_getAcnoCondInfo();
          let ctno = aInfo["ctno"];
          if (ctno == null || ctno == "") {
            if (thisWin.dsDetail != null && thisWin.dsDetail.length > 0) {
              ctno =
                thisWin.dsDetail[0]["ctno"] == null
                  ? ""
                  : thisWin.dsDetail[0]["ctno"];
            }
          }
          let pParamA1 = {
            tacno: aInfo["tacno"],
            bnkbSeq: aInfo["bnkbSeq"],
            ctno: ctno,
            pageRowCnt: "0",
            stndKeyId: "",
          };
          let res = await TSServiceUtil.gfn_getUrlData(
            this,
            "F10600116",
            "S",
            pParamA1
          );
          if (res != null && res.data != null) {
            if (
              res.data.assiYn != null &&
              (res.data.assiYn == "Y" || res.data.assiYn == "N")
            ) {
              this.oldAgeConfirm = true;
              thisWin.$ToastManager.fn_ShowToast({
                text: "승인요청 가능합니다(조력자완료).",
              });
            }
          }
          break;
        case "MSPTS319P": //실시간입금
          this.routeParams = obj;
          this.routeId = obj.name;
          this.fn_checkProcess(); //전자서식 셋팅

          break;
        case "TFAC20004_01": //계약관계자
          this.dsDetail[0]["trsrLawcAgntRcno"] = obj.trsrLawcAgntRcno;
          this.dsDetail[0]["trsrLawcAgntRcnoNm"] = obj.trsrLawcAgntRcnoNm;
          this.dsDetail[0]["trstMngpRcno1"] = obj.trstMngpRcno1;
          this.dsDetail[0]["trstMngpRcno1Nm"] = obj.trstMngpRcno1Nm;
          this.dsDetail[0]["trstMngpRcno2"] = obj.trstMngpRcno2;
          this.dsDetail[0]["trstMngpRcno3"] = obj.trstMngpRcno3;
          this.dsDetail[0]["trstMngpRcno2Nm"] = obj.trstMngpRcno2Nm;
          this.dsDetail[0]["trstMngpRcno3Nm"] = obj.trstMngpRcno3Nm;
          this.dsDetail[0]["bnfcAporRcnoNm"] = obj.bnfcAporRcnoNm;
          this.dsDetail[0]["dethAlmnRcnoNm"] = obj.dethAlmnRcnoNm;
          this.dsDetail[0]["awilEtorRcnoNm"] = obj.awilEtorRcnoNm;
          this.dsDetail[0]["trsrLawcAgntCsId"] = obj.trsrLawcAgntCsId;
          this.dsDetail[0]["trstMngpCsId1"] = obj.trstMngpCsId1;
          this.dsDetail[0]["trstMngpCsId2"] = obj.trstMngpCsId2;
          this.dsDetail[0]["trstMngpCsId3"] = obj.trstMngpCsId3;
          this.dsDetail[0]["bnfcAporCsId"] = obj.bnfcAporCsId;
          this.dsDetail[0]["dethAlmnCsId"] = obj.dethAlmnCsId;
          this.dsDetail[0]["awilEtorCsId"] = obj.awilEtorCsId;
          this.dsDetail[0]["bnfcAporRcno"] = obj.bnfcAporRcno;
          this.dsDetail[0]["awilEtorRcno"] = obj.awilEtorRcno;
          this.dsDetail[0]["dethAlmnRcno"] = obj.dethAlmnRcno;
          this.dsDetail[0]["retuCtrmRcno"] = obj.retuCtrmRcno;
          this.dsDetail[0]["retuCtrmRcnoNm"] = obj.retuCtrmRcnoNm;
          this.dsDetail[0]["retuCtrmCsId"] = obj.retuCtrmCsId;
          this.dsDetail[0]["trstPrptDnrRcno"] = obj.trstPrptDnrRcno;
          this.dsDetail[0]["trstPrptDnrRcnoNm"] = obj.trstPrptDnrRcnoNm;
          this.dsDetail[0]["trstPrptDnrCsId"] = obj.trstPrptDnrCsId;
          this.dsDetail[0]["emailAddr"] = obj.emailAddr;
          this.dsDetail[0]["domnNm"] = obj.domnNm;
          this.dsDetail[0]["vlntrGudRcno"] = obj.vlntrGudRcno;
          this.dsDetail[0]["vlntrGudRcnoNm"] = obj.vlntrGudRcnoNm;
          this.dsDetail[0]["vlntrGudCsId"] = obj.vlntrGudCsId;
          this.dsDetail[0]["vlntrGudRel"] = obj.vlntrGudRel;
          break;
      }
    },
    /**********************************************************************************
     * 신탁상품팝업 호출
     **********************************************************************************/
    //@ts-popup110-callback="fn_Popup110Back" = > fn_popupAfter(return obj)
    btn_popupGdC_onclick() {
      let trstTypC = this.trstTypC;
      let gdTypDtlC = this.gdTypDtlC;
      if (_.isEmpty(trstTypC) || _.isEmpty(gdTypDtlC)) {
        this.fn_alert("신탁유형과  상품유형을 차례대로 입력하십시오.");
        //this.rfTrstTypC.setFocus();
        return;
      }
      let gGdC = this.fn_getValue(this.gdC);
      let gDivn;
      let gTrstTypC = this.fn_getValue(trstTypC);
      let gGdTypDtlC = this.fn_getValue(gdTypDtlC);
      let gURL = "2000";
      let gCorpYn = this.fn_getValue(this.dsListS1["corpYn"]);

      //TPUP95330
      this.sPopupId = "TPUP95330";
      let properties = {
        pPage: "MSPTS110P",
        pGdC: gGdC, // 신탁상품
        pTrstTypC: gTrstTypC, // 신탁유형
        pGdTypDtlC: gGdTypDtlC, // 상품유형
        //        pUrl : '2000',      //
        pProcTc: "2000",
      };
      this.popup110 = this.$refs.popup110.fn_Open(properties);
    },
    /**********************************************************************************
     * 상품유형 변경
     **********************************************************************************/
    cbo_gdTypDtlC_onitemchanged() {
      let vTrstTypC = this.trstTypC;
      let vGdTypDtlC = this.gdTypDtlC;

      this.fn_initBody(false);

      ////신탁유형,상품유형,신탁상품/신탁상품팝업
      TSCommUtil.gfn_setDisableArr(
        this,
        ["disTrstTypC", "disGdTypDtlC", "disGdC"],
        false
      );

      this.trstTypC = vTrstTypC;
      this.gdTypDtlC = vGdTypDtlC;
    },
    /**********************************************************************************
     * 계약관계자팝업 호출 버튼
     **********************************************************************************/
    btn_custInfoReg_onclick() {
      if (_.isEmpty(this.gdC)) {
        return false;
      }

      let sGdC = this.gdC.substr(0, 2);

      let sEnabled = "";
      if (!this.btnConfirm || this.radProcessGb == "2") {
        sEnabled = "N";
      } else {
        sEnabled = "Y";
        if (
          confirm(
            "지급받을 계좌를 별도로 지정하고자 하실 경우 \n송금계좌등록이 선행되어야 합니다.\n송금계좌 등록화면으로 이동하시겠습니까?"
          )
        ) {
          let args;
          if (this.radProcessGb == "1" && !_.isEmpty(this.edtAcno1)) {
            args = { tacno: this.edtAcno1 };
          } else if (!_.isEmpty(this.edtAcno2)) {
            args = { tacno: this.edtAcno2.substr(0, 7) };
          }
          this.sPopupId = "TFIO42250";
          this.popup204 = this.$refs.popup204.fn_Open(args);
          return;
        }
        //let alYn = false;
        //this.fn_alert("지급받을 계좌를 별도로 지정하고자 하실 경우 \n송금계좌등록이 선행되어야 합니다.\n송금계좌 등록화면으로 이동하시겠습니까?",()=>{alYn = true;},null);
        //if (alYn){
        //  let args ;
        //  if ( this.radProcessGb == '1' && !_.isEmpty(  this.edtAcno1 ) ){
        //    args  = {tacno: this.edtAcno1 };
        //  }else if ( !_.isEmpty( this.edtAcno2 )  ){
        //    args  = {tacno: this.edtAcno2.substr(0,7) };
        //  }
        //  this.sPopupId = "TFIO42250";
        //  this.popup204 = this.$refs.popup204.fn_Open(args)
        //  return;
        //}
      }
      this.fn_formTodsDetail();
      this.dsDetail[0]["csId"] = this.dsList00["csId"];
      let strpgmId = this.radProcessGb == "1" ? "신규" : "변경";

      let oArg = {
        pgmId: strpgmId,
        processGb: this.radProcessGb,
        dsDetail: _.cloneDeep(this.dsDetail),
        dsList01: _.cloneDeep(this.dsList01),
        dsList13: _.cloneDeep(this.dsList13),
        dsList00: _.cloneDeep(this.dsList00),
      }; //팝업화면으로 보낼 파라미터.

      this.sPopupId = "TFAC20004";
      this.popup301 = this.$refs.popup301.fn_Open(oArg);
    },
    /**********************************************************************************
     * 계약관계자팝업 호출 버튼 (지급청구대리인 입력)
     **********************************************************************************/
    btn_related_onclick() {
      if (_.isEmpty(this.gdC)) {
        return false;
      }
      let sGdC = this.gdC.substr(0, 2);

      let sEnabled = "";

      if (!this.btnConfirm || this.radProcessGb == "2") {
        //추가입금은 수정불가
        sEnabled = "N";
      } else {
        sEnabled = "Y";
      }
      this.fn_formTodsDetail();
      this.dsDetail[0]["csId"] = this.dsListS1["csId"];

      //      let strpgmId = this.radProcessGb == '1' ? "신규" : "변경";
      let strpgmId = this.radProcessGb;
      let oArg = {
        //팝업화면으로 보낼 파라미터.
        bEnabled: sEnabled,
        bSchedule: false,
        pgmId: strpgmId,
        dsDetail: _.cloneDeep(this.dsDetail),
        // ,dsList01:this.dsList01
        // ,dsList13:this.dsList13
        // ,dsList00:this.dsList00
        trstTypC: this.trstTypC, // 2021-12-17 add 치매신탁 보완사항 : 치매신탁일 시 계약관계자[신탁관계인] 필수입력 표기
      };

      this.sPopupId = "TFAC20004_01";
      this.popup302 = this.$refs.popup302.fn_Open(oArg);
    },
    /**********************************************************************************
     * 상품코드 변경시
     **********************************************************************************/
    edt_gdC_onchar(e) {
      let gdC9 = this.gdC;
      let preGdC = this.preGdC;
      if (gdC9.length == 6 && gdC9 != preGdC) {
        this.fn_getGoodsInfo();
      }
    },
    fn_getGoodsInfo() {
      let pParam = {
        gdC: thisWin.gdC,
      };
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600216_S";
      this.eaiCommObj.params = pParam;
      TSServiceUtil.invokeInDirect(
        this.eaiCommObj,
        function(res) {
          if (res && res.data) {
            let ds02 = _.cloneDeep(res.data.tpup95330);
            if (ds02 != null && ds02.length > 0) {
              thisWin.trstTypC = ds02[0]["trstTypC"];
              thisWin.cbo_trstTypCTogdTypDtlC();
              thisWin.gdTypDtlC = ds02[0]["gdTypDtlC"];
              thisWin.gdNm = ds02[0]["gdNm"];

              thisWin.dsDetail[0]["careYn"] = ds02[0]["careYn"]; //2018.05.08 add
              thisWin.dsDetail[0]["frBondGdYn"] = ds02[0]["frBondGdYn"]; //2018.06.28 add
              thisWin.dsDetail[0]["drvtGdClasC"] = ds02[0]["drvtGdClasC"]; //2019.08.20 add
              thisWin.dsDetail[0]["issueDate"] = ds02[0]["issueDate"]; //2019.08.20 add
              thisWin.fn_searchTfcprb();
            }
          } else {
          }
        },
        function(error) {
          return;
        },
        "UF10600216"
      );
    },
    /************************************************************************************************
     * 신탁유형코드 값 리턴
     ************************************************************************************************/
    _getTrstTypC() {
      // 데이터가 있는경우
      if (!_.isEmpty(this.trstTypC)) {
        let oParamData = this.trstTypC;
        return oParamData;
        // 데이터가 없는 경우
      } else {
        return "";
      }
    },
    /************************************************************************************************
     * 상품유형코드 값 리턴
     ************************************************************************************************/
    _getGdTypDtlC() {
      // 데이터가 있는경우
      if (!_.isEmpty(this.gdTypDtlC)) {
        let oParamData = this.gdTypDtlC;
        return oParamData;
        // 데이터가 없는 경우
      } else {
        return "";
      }
    },
    /************************************************************************************************
     * 운용지시내역(파생) 여부 값 리턴
     ************************************************************************************************/
    _getParseng() {
      // 데이터가 있는경우
      if (!_.isEmpty(this.dsList02.length)) {
        let oParamData = false;
        for (let i = 0; i < this.dsList02.length; i++) {
          if (
            this.dsList02[i]["appnMethC"] == "10" ||
            this.dsList02[i]["appnMethC"] == "13" ||
            this.dsList02[i]["appnMethC"] == "14"
          ) {
            // 파생결합증권
            oParamData = true;
          }
        }
        return oParamData;
        // 데이터가 없는 경우
      } else {
        return "";
      }
    },
    /************************************************************************************************
     * 실명번호구분 값 리턴
     ************************************************************************************************/
    _getRcnoKindTc() {
      // 데이터가 있는경우
      if (!_.isEmpty(this.dsList00["rcnoKindTc"])) {
        let oParamData = this.dsList00["rcnoKindTc"];
        return oParamData;
        // 데이터가 없는 경우
      } else {
        return "";
      }
    },
    /************************************************************************************************
     * 투자성향 값 리턴
     ************************************************************************************************/
    getCsIvtdRatingC() {
      // 데이터가 있는경우
      if (!_.isEmpty(this.dsList00["csIvtdRatingC"])) {
        let oParamData = this.dsList00["csIvtdRatingC"];
        return oParamData;
        // 데이터가 없는 경우
      } else {
        return "";
      }
    },
    /************************************************************************************************
     * 투자성향 기준 값 리턴
     ************************************************************************************************/
    _getIvRskRatingC() {
      //ASR180100650, [신탁부] 파생결합증권 판매과정 녹취 의무화 관련 변경
      // 데이터가 있는경우
      if (!_.isEmpty(this.dsDetail02[0]["ivRskRatingC"])) {
        let oParamData = this.dsDetail02[0]["ivRskRatingC"];
        return oParamData;
        // 데이터가 있는경우
      } else if (!_.isEmpty(this.ivRskRatingC)) {
        let oParamData = this.ivRskRatingC;
        return oParamData;
        // 데이터가 없는 경우
      } else {
        return "";
      }
    },
    /************************************************************************************************
     * 녹취대상여부 값 리턴 //2019.08.20 add
     ************************************************************************************************/
    _getRecYn() {
      // 데이터가 있는경우
      if (!_.isEmpty(this.dsDetail[0]["recYn"])) {
        let oParamData = this.dsDetail[0]["recYn"];
        return oParamData;
        // 데이터가 없는 경우
      } else {
        return "";
      }
    },
    /**********************************************************************************
     * 그리드 행 삭제
     **********************************************************************************/
    delRow_onclick() {
      if (
        isNaN(this.lv_SelectedItem02) == false &&
        this.fn_getNumber(this.lv_SelectedItem02) > -1
      ) {
        let iRow = this.fn_getNumber(this.lv_SelectedItem02);
        this.dsList02.splice(iRow, 1);
        this.lv_SelectedItem02 = "";
      }
    },
    /**********************************************************************************
     * 그리드 행 삭제
     **********************************************************************************/
    delRow00_onclick() {
      let trstTypC = this.trstTypC;
      if (trstTypC == "002" || trstTypC == "009") {
        //20230307 유언대용신탁(유가증권)추가
        if (
          isNaN(this.lv_SelectedItem03) == false &&
          this.fn_getNumber(this.lv_SelectedItem03) > -1
        ) {
          let iRow = this.fn_getNumber(this.lv_SelectedItem03);
          this.dsList03.splice(iRow, 1);
          this.lv_SelectedItem03 = "";
        }
        let trstPric = 0;
        for (let i = 0; i < this.dsList03.length; i++) {
          trstPric += this.fn_getNumber(this.dsList03[i]["trstPric"]);
        }
        this.trstAmt = trstPric;
        this.dsDetail[0]["trstAmt"] = trstPric;
      } else if (trstTypC == "003" || trstTypC == "010") {
        //20230307 유언대용신탁(부동산)추가
        if (
          isNaN(this.lv_SelectedItem04) == false &&
          this.fn_getNumber(this.lv_SelectedItem04) > -1
        ) {
          let iRow = this.fn_getNumber(this.lv_SelectedItem04);
          this.dsList04.splice(iRow, 1);
          this.lv_SelectedItem04 = "";
        }
        let trstPric = 0;
        for (let i = 0; i < this.dsList04.length; i++) {
          trstPric += this.fn_getNumber(this.dsList04[i]["trstPric"]);
        }
        this.trstAmt = trstPric;
        this.dsDetail[0]["trstAmt"] = trstPric;
      } else if (trstTypC == "004") {
        if (
          isNaN(this.lv_SelectedItem05) == false &&
          this.fn_getNumber(this.lv_SelectedItem05) > -1
        ) {
          let iRow = this.fn_getNumber(this.lv_SelectedItem05);
          this.dsList05.splice(iRow, 1);
          this.lv_SelectedItem05 = "";
        }
        let trstPric = 0;
        for (let i = 0; i < this.dsList05.length; i++) {
          trstPric += this.fn_getNumber(this.dsList05[i]["trstPric"]);
        }
        this.trstAmt = trstPric;
        this.dsDetail[0]["trstAmt"] = trstPric;
      }
    },
    /**********************************************************************************
     * 그리드 행 추가
     **********************************************************************************/
    insertRow_onclick() {
      if (!_.isEmpty(this.dsDetail[0]["gdC"])) {
        this.dsList02.push(dsList02_b);
      } else {
        this.fn_alert("신탁상품을 먼저 선택하여 주십시요.");
      }
    },
    /**********************************************************************************
     * 그리드 행 추가
     **********************************************************************************/
    insertRow00_onclick() {
      let trstTypC = this.trstTypC;
      if (!_.isEmpty(this.dsDetail[0]["gdC"])) {
        if (trstTypC == "002" || trstTypC == "009") {
          //20230307 유언대용신탁(유가증권)추가
          this.dsList03.push(_.cloneDeep(dsList03_b));
        } else if (trstTypC == "003" || trstTypC == "010") {
          //20230307 유언대용신탁(부동산)추가
          this.dsList04.push(_.cloneDeep(dsList04_b));
        } else if (trstTypC == "004") {
          this.dsList05.push(_.cloneDeep(dsList05_b));
        }
      } else {
        this.fn_alert("신탁상품을 먼저 선택하여 주십시요.");
      }
    },
    /**********************************************************************************
     *권유자검색팝업
     **********************************************************************************/
    btn_empInfo00_onclick() {
      this.sPopupId = "TPUP95110_00";
      let properties = {};
      this.popup103 = this.$refs.popup103.fn_Open(properties);
    },
    /**********************************************************************************
     *관리자 검색팝업
     **********************************************************************************/
    btn_empInfo01_onclick() {
      this.sPopupId = "TPUP95110_01";
      let properties = {};
      this.popup103 = this.$refs.popup103.fn_Open(properties);
    },
    /**********************************************************************************
     *FP 관리자 검색팝업
     **********************************************************************************/
    btn_empInfo02_onclick() {
      this.sPopupId = "TPUP95290";
      let properties = { invPosYn: "Y" };
      this.popup316 = this.$refs.popup316.fn_Open(properties);
    },
    /**********************************************************************************
     *판매직원 검색팝업 2019.11.19 add
     **********************************************************************************/
    btn_empInfo03_onclick() {
      this.sPopupId = "TPUP95110_02";
      let properties = {};
      this.popup103 = this.$refs.popup103.fn_Open(properties);
    },
    /**********************************************************************************
     *원금수익자 검색팝업
     **********************************************************************************/
    btn_custInfo00_onclick() {
      this.sPopupId = "TPUP95320_00";
      let properties = {};
      this.popup107 = this.$refs.popup107.fn_Open(properties);
    },
    /**********************************************************************************
     *이익수익자 검색팝업
     **********************************************************************************/
    btn_custInfo01_onclick() {
      this.sPopupId = "TPUP95320_01";
      let properties = {};
      this.popup107 = this.$refs.popup107.fn_Open(properties);
    },
    /**********************************************************************************
     * 대리인 조회
     **********************************************************************************/
    btn_agntCsId_onclick() {
      this.sPopupId = "TPUP95320_02";
      let properties = {};
      this.popup107 = this.$refs.popup107.fn_Open(properties);
    },
    /**********************************************************************************
     *계약번호 변경시 초기화
     **********************************************************************************/
    Div02_cbo_ctno_onitemchanged() {
      this.fn_initBody();
    },
    /**********************************************************************************
     *변경 일련번호 변경시 초기화
     **********************************************************************************/
    Div02_cbo_chngSeq_onitemchanged() {
      let cboChngSeq = this.cboChngSeq;
      if (cboChngSeq == null || cboChngSeq == "") {
        this.fn_initBody();
      } else {
        this.fn_searchList();
      }
    },
    /**********************************************************************************
     * 별납 수수료 선택시 금액계산
     **********************************************************************************/
    fn_feeCalculation() {
      if (this.poaoTc != "1") return; //별납 확인

      //유언대용신탁(부동산)추가 20230321
      if (
        this.trstTypC == "002" ||
        this.trstTypC == "003" ||
        this.trstTypC == "010"
      ) {
        if (this.fn_getNumber(this.trstAmt) < 1) {
          //this.fn_alert("재산신탁을 먼져 넣어 등록 하십시오.");
          return false;
        }
      }
      let fee20 = "";
      let v_dc_rt = 0;
      let feeRt = 0.0;
      feeRt = this.epayTfrt;

      if (this.epayCyclTc == "2") {
        v_dc_rt = (feeRt * (1 / 2)) / 100;
      } else if (this.epayCyclTc == "3") {
        v_dc_rt = (feeRt * (1 / 4)) / 100;
      } else if (this.epayCyclTc == "4") {
        v_dc_rt = (feeRt * (1 / 12)) / 100;
      } else {
        v_dc_rt = feeRt / 100;
      }
      fee20 = this.fn_getNumber(this.trstAmt) * v_dc_rt;
      fee20 = Math.round(this.fn_getNumber(fee20), 0);
      //20120430 lbo, 보수 수수료 체계 변경의 건, 유언대용신탁(부동산)추가 20230321
      this.addValue = Math.round(
        Math.round(
          fee20 *
            0.1 *
            (this.trstTypC == "003" || this.trstTypC == "010" ? 1 : 0)
        )
      );

      this.dsDetail[0]["sumTamt"] =
        this.fn_getNumber(fee20) +
        this.fn_getNumber(this.trstAmt) +
        this.fn_getNumber(this.addValue);

      this.epayFeeAmt = fee20;
      if (this.dsDetail[0]["padTc"] == "1" && this.epayPoaoTc == "2") {
        let vStr = "별납 후취의 경우 돌아오는 수수료 납부일에 낼 금액입니다.\n";
        vStr += "계약신규시에는 납부하지 않으시니, 참고하시기 바랍니다.";
        this.fn_alert(vStr);
      }
    },
    /**********************************************************************************
     * 상품별 상세 값변경시
     **********************************************************************************/
    fn_OnColumnChanged(ds, col, row) {
      let rBoolean = true;

      switch (ds) {
        case "dsList03": //유가증권
          if (
            col == "dnmn" ||
            col == "snbQty" ||
            col == "snbFaceAmt" ||
            col == "trstPric"
          ) {
            if (
              _.isEmpty(this.dsList03[row]["astsType"]) ||
              _.isEmpty(this.dsList03[row]["secusNo"])
            ) {
              this.fn_alert("구분과 증권번호를 차례대로 입력하십시오.");
              this.dsList03[row][col] = 0;
              return;
            }
          }
          if (col == "snbQty" || col == "snbFaceAmt") {
            let QTY = this.fn_getNumber(this.dsList03[row]["snbQty"]);
            let FACE_AMT = this.fn_getNumber(this.dsList03[row]["snbFaceAmt"]);
            if (
              this.fn_getNumber(QTY) >= 0 &&
              this.fn_getNumber(FACE_AMT) >= 0
            ) {
              let CALC = QTY * FACE_AMT;
              if (
                !_.isEmpty(this.dsList03[row]["astsType"]) &&
                (this.dsList03[row]["astsType"] == "1" || //수익증권
                  this.dsList03[row]["astsType"] == "20") //외화 수익증권
              ) {
                CALC = CALC / 1000;
              } else if (
                !_.isEmpty(this.dsList03[row]["astsType"]) &&
                (this.dsList03[row]["astsType"] == "3" || //채권
                this.dsList03[row]["astsType"] == "21" || //외화 채권
                  this.dsList03[row]["astsType"] == "8") //ELS
              ) {
                CALC = CALC / 10000;
              }
              this.dsList03[row]["trstPric"] = CALC;

              let FACE_AMT_SUM = 0;
              for (let jni = 0; this.dsList03.length > jni; jni++) {
                FACE_AMT_SUM += this.fn_getNumber(
                  this.dsList03[jni]["trstPric"]
                );
              }
              this.trstAmt = FACE_AMT_SUM;
              this.fn_feeCalculation();
              //              this.dsDetail[0]["trstAmt"] = FACE_AMT_SUM;
            }
          } else if (col == "trstPric") {
            let FACE_AMT_SUM = 0;
            for (let jni = 0; this.dsList03.length > jni; jni++) {
              FACE_AMT_SUM += this.fn_getNumber(this.dsList03[jni]["trstPric"]);
            }
            this.trstAmt = FACE_AMT_SUM;
            this.fn_feeCalculation();
            //            this.dsDetail[0]["trstAmt"] = FACE_AMT_SUM;
          }
          break;
        case "dsList04": //부동산
          if (col == "trstPric") {
            let FACE_AMT_SUM = 0;
            for (let jni = 0; this.dsList04.length > jni; jni++) {
              FACE_AMT_SUM += this.fn_getNumber(this.dsList04[jni]["trstPric"]);
            }
            this.trstAmt = FACE_AMT_SUM;
            //            this.dsDetail[0]["trstAmt"] = FACE_AMT_SUM;
          }
          break;
        case "dsList05": //금전채권
          if (col == "trstPric") {
            let FACE_AMT_SUM = 0;
            for (let jni = 0; this.dsList05.length > jni; jni++) {
              FACE_AMT_SUM += this.fn_getNumber(this.dsList05[jni]["trstPric"]);
            }
            this.trstAmt = FACE_AMT_SUM;
            //            this.dsDetail[0]["trstAmt"] = FACE_AMT_SUM;
          }
          break;
        case "dsDetail":
          if (col == "trstAmt") {
            if (
              this.poaoTc == "1" &&
              this.epayFxrtFamtTc == "1" &&
              this.fn_getNumber(this.trstAmt) > 0
            ) {
              this.fn_feeCalculation();
            } else if (
              this.poaoTc == "3" &&
              this.fn_getNumber(this.prfeRt) > 0
            ) {
              this.prfe = parseInt(
                this.fn_getNumber(this.trstAmt) * (this.prfeRt / 100)
              );
            }
          }
          if (col == "prfeRt") {
            if (this.poaoTc == "3" && this.fn_getNumber(this.prfeRt) > 0) {
              this.prfe = parseInt(
                this.fn_getNumber(this.trstAmt) * (this.prfeRt / 100)
              );
            }
          } else if (col == "prfe") {
            if (this.fn_getNumber(this.prfeRt) > 0) {
              //this.prfeRt = 0;
            }
          } else if (col == "epayFeeAmt") {
            //유언대용신탁(부동산)추가 20230321
            this.addValue = Math.round(
              this.fn_getNumber(this.epayFeeAmt) *
                0.1 *
                (this.trstTypC == "003" || this.trstTypC == "010" ? 1 : 0)
            );
          } else if (col == "epayTfrt") {
            this.fn_feeCalculation();
          }
          break;
        default:
          break;
      }
      return rBoolean;
    },
    /**********************************************************************************
     * 승인 요청등 클릭시
     **********************************************************************************/
    async btn_admission_onclick() {
      let sRcnoKindTc = this.dsList00["rcnoKindTc"];
      //업무구분 : 신규
      if (this.radProcessGb == "1") {
        if (this.visBtnAdmission && this.btnAdmissionText == "승인요청") {
          // 승인
          //고령자가 고령전용상품 가입시 반드시 녹취후 승인가능하도록 처리위해 승인테이블에 셋팅
          if (
            this.fn_lifeAge() >= this.oldAge &&
            this.dsDetail[0]["oldExclYn"] == "Y"
          ) {
            this.dsDetail[0]["recYn"] = "Y";
          }
          //this.fn_Customer_PA2();
          //개인고객 && 고령자일시 조력자등록 체크
          if (
            (sRcnoKindTc == "01" ||
              sRcnoKindTc == "04" ||
              sRcnoKindTc == "05" ||
              sRcnoKindTc == "06") &&
            this.fn_lifeAge() >= this.oldAge &&
            this.oldAgeConfirm == false
          ) {
            //2021.02.09 update,//20190806 WYJ 계약신규인 경우 조력자 등록 팝업
            let accInfo = this.fn_getAcnoCondInfo();
            let msgCntn =
              accInfo["tacno"] + accInfo["bnkbSeq"] + accInfo["ctno"];
            let oArg = { pMsgCntn: msgCntn };
            //TPUP97050
            this.sPopupId = "TPUP97050";
            this.popup311 = this.$refs.popup311.fn_Open(oArg); //==> insertTFAC20000A
            return;
          }

          this.fn_formTodsDetail();
          this.dsDetail[0]["mngBrcd"] = this.lv_basInfo.brcd;
          this.dsDetail[0]["invtMnBrcd"] = this.lv_basInfo.brcd;
          this.sSvcID = "insertTFAC20000A";
          let pParam = this.dsDetail[0];
          this.eaiCommObj.lv_vm = this;
          this.eaiCommObj.auth = "I";
          this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600073_S";
          this.eaiCommObj.params = pParam;
          TSServiceUtil.invokeInDirect(
            this.eaiCommObj,
            function(res) {
              if (res && res.data) {
                thisWin.fn_callBack("insertTFAC20000A", res);
                thisWin.fn_setTimerApprvState();
              }
            },
            function(error) {
              return;
            },
            "UF10600073"
          );
        } else if (
          this.visBtnAdmission &&
          this.btnAdmissionText == "승인요청취소"
        ) {
          // 승인
          this.fn_clearTimerApprvState();
          this.fn_formTodsDetail();
          this.dsDetail[0]["mngBrcd"] = this.lv_basInfo.brcd;
          this.dsDetail[0]["invtMnBrcd"] = this.lv_basInfo.brcd;
          this.sSvcID = "insertTFAC20000B";
          let pParam = this.dsDetail[0];
          this.eaiCommObj.lv_vm = this;
          this.eaiCommObj.auth = "I";
          this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600074_S";
          this.eaiCommObj.params = pParam;
          TSServiceUtil.invokeInDirect(
            this.eaiCommObj,
            function(res) {
              if (res && res.data) {
                thisWin.fn_searchList();
                thisWin.$ToastManager.fn_ShowToast({
                  text: "승인요청취소가 완료되었습니다.",
                });
                //thisWin.fn_callBack("insertTFAC20000B",res);
              }
            },
            function(error) {
              return;
            },
            "UF10600074"
          );
        } else if (
          this.visBtnAdmission &&
          this.btnAdmissionText == "승인취소요청"
        ) {
          // 승인
          this.fn_clearTimerApprvState();
          this.fn_formTodsDetail();
          this.dsDetail[0]["mngBrcd"] = this.lv_basInfo.brcd;
          this.dsDetail[0]["invtMnBrcd"] = this.lv_basInfo.brcd;
          this.sSvcID = "insertTFAC20000C";
          let pParam = this.dsDetail[0];
          this.eaiCommObj.lv_vm = this;
          this.eaiCommObj.auth = "I";
          this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600075_S";
          this.eaiCommObj.params = pParam;
          TSServiceUtil.invokeInDirect(
            this.eaiCommObj,
            function(res) {
              if (res && res.data) {
                thisWin.fn_searchList();
                thisWin.$ToastManager.fn_ShowToast({
                  text: "승인취소요청이 완료되었습니다.",
                });
                //thisWin.fn_callBack("insertTFAC20000C",res);
              }
            },
            function(error) {
              return;
            },
            "UF10600075"
          );
        }
        TSCommUtil.gfn_setVisibleArr(this, ["visBtnAdmission"], false);

        //업무구분 : 신규아님 (1:추가입금, 2:변경)
      } else {
        if (this.visBtnAdmission && this.btnAdmissionText == "승인요청") {
          // 승인
          if (this.radProcessGb == "2") {
            //추가입금인 경우

            let passYn = "Y";
            ////개인고객 && 고령자일시 조력자등록 체크 (박진영 23.09.04 조력자 제외요청)
            //if ((sRcnoKindTc == "01" ||sRcnoKindTc == "04" || sRcnoKindTc == "05" || sRcnoKindTc == "06") && this.fn_lifeAge() >= this.oldAge
            //  && this.oldAgeConfirm == false){ //2021.02.09 update,//20190806 WYJ 계약신규인 경우 조력자 등록 팝업
            //  let accInfo = this.fn_getAcnoCondInfo();
            //  let msgCntn = accInfo["tacno"] +  accInfo["bnkbSeq"] + accInfo["ctno"];
            //  let oArg = { pMsgCntn: msgCntn };
            //  //TPUP97050
            //  this.sPopupId = "TPUP97050";
            //  this.popup311 = this.$refs.popup311.fn_Open(oArg)  //==> insertTFAC20000A
            //  return;
            //}

            if (passYn == "Y") {
              //if( this.teleVssTc == "1" ){//유선내방구분: 유선
              //1.추가입금 && 유선 ==> 없음
              //TPUP96201 : POP_유선안내팝업_추가입금
              //this.sPopupId = "TPUP96201";
              //let msgCntn  = this.edtAcno2 + this.cboAccSeq;   //계약번호
              //let cnm    = this.edtCnm2; //고객명
              //let gdnm     = this.dsList02[0]["gdNm"]; //상품명
              //let trstAmt   = this.trstAmt;  //추가입금후 신탁금액
              //let trstOtxtAmt = this.dsDetail[0]["trstOtxtAmt"]; // 원래 신탁금액
              //let addTrstAmt  =  this.fn_getNumber(trstAmt)-this.fn_getNumber(trstOtxtAmt); //추가입금금액
              //let properties = {msgCntn:msgCntn,
              //  cnm :cnm, gdnm:gdnm, trstAmt:trstAmt, addTrstAmt:addTrstAmt
              //}
              //this.popup000 = this.$refs.popup000.fn_Open(properties)
              //}else{//유선내방구분: 내방
              //2.추가입금 && 내방
              this.fn_admissionReq();
              TSCommUtil.gfn_setVisibleArr(this, ["visBtnAdmission"], false);
              //}
            }
          } else if (this.radProcessGb == "3") {
            //계약변경인 경우

            //if( this.teleVssTc == "1" ){//유선내방구분: 유선인 경우 유선스크립트 호출
            //3.변경 && 유선
            //let msgCntn  = this.edtAcno2 + this.cboAccSeq;   //계약번호
            //let cnm    = this.edtCnm2; //고객명
            //let gdnm     = this.dsList02[0]["gdNm"]; //상품명
            //let properties = {msgCntn:msgCntn, cnm :cnm, gdnm:gdnm}
            //} else {//유선내방구분: 내방
            //4.변경 && 내방
            this.fn_admissionReq();
            TSCommUtil.gfn_setVisibleArr(this, ["visBtnAdmission"], false);
            //}
          } else {
            //현재 여기 올일은 없다.
          }
        } else if (
          this.visBtnAdmission &&
          this.btnAdmissionText == "승인요청취소"
        ) {
          // 승인
          this.fn_clearTimerApprvState();
          this.fn_formTodsDetail();
          this.dsDetail[0]["mngBrcd"] = this.lv_basInfo.brcd;
          this.dsDetail[0]["invtMnBrcd"] = this.lv_basInfo.brcd;
          this.sSvcID = "insertTFAC20000E";
          let pParam = this.dsDetail[0];
          this.eaiCommObj.lv_vm = this;
          this.eaiCommObj.auth = "I";
          this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600077_S";
          this.eaiCommObj.params = pParam;
          TSServiceUtil.invokeInDirect(
            this.eaiCommObj,
            function(res) {
              if (res && res.data) {
                thisWin.fn_searchList();
                thisWin.$ToastManager.fn_ShowToast({
                  text: "승인요청취소가 완료되었습니다.",
                });
                //thisWIn.fn_callBack("insertTFAC20000E",res);
              }
            },
            function(error) {
              return;
            },
            "UF10600077"
          );
          TSCommUtil.gfn_setVisibleArr(this, ["visBtnAdmission"], false);
        } else if (
          this.visBtnAdmission &&
          this.btnAdmissionText == "승인취소요청"
        ) {
          // 승인
          this.fn_clearTimerApprvState();
          this.fn_formTodsDetail();
          this.dsDetail[0]["mngBrcd"] = this.lv_basInfo.brcd;
          this.dsDetail[0]["invtMnBrcd"] = this.lv_basInfo.brcd;
          this.sSvcID = "insertTFAC20000F";
          let pParam = this.dsDetail[0];
          this.eaiCommObj.lv_vm = this;
          this.eaiCommObj.auth = "I";
          this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600078_S";
          this.eaiCommObj.params = pParam;
          TSServiceUtil.invokeInDirect(
            this.eaiCommObj,
            function(res) {
              if (res && res.data) {
                thisWin.fn_searchList();
                thisWin.$ToastManager.fn_ShowToast({
                  text: "승인취소요청이 완료되었습니다.",
                });
                //thisWin.fn_callBack("insertTFAC20000F",res);
              }
            },
            function(error) {
              return;
            },
            "UF10600078"
          );
          TSCommUtil.gfn_setVisibleArr(this, ["visBtnAdmission"], false);
        }
      }
      //TSCommUtil.gfn_setVisibleArr(this,["visBtnAdmission"],false);
    },
    /**********************************************************************************
     * AML 등록 팝업
     **********************************************************************************/
    fn_btnAmlPopup() {
      //2020.04.21 add
      if (this.radProcessGb != "1") {
        //추가입금,계약변경
        let amlRstC = "";
        if (this.dsList20.length > 0) {
          for (let i = 0; i < this.dsList20.length; i++) {
            if (this.dsList20[i]["amlRstC"] == "W") {
              amlRstC = this.dsList20[i]["amlRstC"];
            }
          }
        }

        if (amlRstC == "W") {
          this.fn_alert(
            "AML 재이행 대상입니다. 실제소유자 확인 및 AML 수행해 주시기 바랍니다"
          );
        }
      }
      /*
      //부모화면에서 보낸 파라미터 받는 방법.
      */
      let vTacno = "";
      let vBnkbSeq = "";
      let vCtno = "";
      let vProcSeq = "";

      let aInfo = this.fn_getAcnoCondInfo();
      if (this.radProcessGb == "1") {
        vTacno = aInfo["tacno"];
        vBnkbSeq = aInfo["bnkbSeq"];
        vCtno = aInfo["ctno"];
        vProcSeq = this.dsDetail[0]["amlSeq"];
      } else {
        let ctno = aInfo["accSeq"] + "";
        ctno = ctno.replace(/^0+/, "");
        vTacno = aInfo["tacno"];
        vBnkbSeq = aInfo["bnkbSeq"];
        vCtno = ctno;
        vProcSeq = this.dsDetail[0]["amlSeq"];
      }

      this.sPopupId = "TPUP95020";
      let properties = {
        tacno: vTacno,
        bnkbSeq: vBnkbSeq,
        ctno: vCtno,
        procSeq: vProcSeq,
        soptTc: this.dsDetail[0]["soptTc"]    // ASR240800403_[사랑On신탁] 개인고객확인서 서식변경
      };

      this.popup303 = this.$refs.popup303.fn_Open(properties);
    },
    /**********************************************************************************
     * 위험평가수행이력 조회. 2020.04.21 add
     **********************************************************************************/
    fn_performReAmlYn() {
      this.sSvcID = "selectTPUP95020CList"; // 위험평가수행이력 조회
      /*서비스 호출시 정보 세팅*/
      let a27Info = this.fn_getAcnoCondInfo();
      let pParam = {
        tacno: a27Info["tacno"], // 종합계좌번호
        bnkbSeq: a27Info["bnkbSeq"], // 통장일련번호
        ctno: a27Info["ctno"], // 계약번호
        procSeq: this.dsDetail[0]["amlSeq"], // 처리일련번호
      };
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600194_S";
      this.eaiCommObj.params = pParam;
      TSServiceUtil.invokeInDirect(
        this.eaiCommObj,
        function(res) {
          if (res && res.data) {
            thisWin.dsList20 = TSCommUtil.gfn_getArrFrBase(
              dsList20_b,
              res.data.tftarl
            );
            thisWin.fn_btnAmlPopup();
          }
        },
        function(error) {
          return;
        },
        "UF10600194"
      );
    },
    /**********************************************************************************
     * AML 등록 팝업 : 2020.04.21 add
     **********************************************************************************/
    fn_btnReAmlCheck() {
      //2020.04.21 add
      if (this.radProcessGb != "1") {
        //추가입금/계약변경인 경우
        this.fn_performReAmlYn(); //위험평가수행이력 조회
      } else {
        this.fn_btnAmlPopup(); //계약신규 -> AML팝업 호출
      }
    },
    /**********************************************************************************
     * 업무구분 변경시 조회 컨트롤 변경
     **********************************************************************************/
    fn_RadProcess() {
      this.fn_init();
      //progress bar
      if (this.radProcessGb == "1" || this.radProcessGb == "2") {
        this.getStore("tsStore").getters.getState.isProcess = true;
      } else {
        this.getStore("tsStore").getters.getState.isProcess = false;
      }
      if (this.radProcessGb == "1") {
        TSCommUtil.gfn_setVisibleArr(this, ["visDivAcno"], true); //고객
        TSCommUtil.gfn_setVisibleArr(this, ["visDivAccno"], false); //통장
        TSCommUtil.gfn_setVisibleArr(this, ["visBnfcDethYn"], false); //사망여부
        //치매없음
        //teleVssTc = "2",   //유선내방구분 없음
      } else if (this.radProcessGb == "2" || this.radProcessGb == "3") {
        TSCommUtil.gfn_setVisibleArr(this, ["visDivAcno"], false); //고객
        TSCommUtil.gfn_setVisibleArr(this, ["visDivAccno"], true); //통장
        TSCommUtil.gfn_setVisibleArr(this, ["visAgntCsId"], true); //대리인

        //신탁유형치매신탁 없음

        if (this.radProcessGb == "3") {
          TSCommUtil.gfn_setVisibleArr(this, ["visBnfcDethYn"], true); //사망여부
        } else {
          TSCommUtil.gfn_setVisibleArr(this, ["visBnfcDethYn"], false); //사망여부
        }
        //teleVssTc = "2",   //유선내방구분 없음
        this.acctCallBackFunc();
      }
    },
    /**********************************************************************************
     * 지급구분 변경시
     **********************************************************************************/
    cbo_fxppYn_onitemchanged() {
      //정기지급 없음
    },
    /***************************************************************************************
    부서코드 직원명 자동조회
   ************************************************************************************* ****/
    edt_onkeyupToGetName(objId) {
      if (objId == "invtMnEmno") {
        if (this.invtMnEmno.length == 5) {
          this.emnoPopupYn = "N";
          if (this.fn_CheckGdC()) {
            this.fn_transactionEmno("edt_invtMnEmno");
          } else {
            this.fn_getUserName(this.invtMnEmno, "invtMnEmnoNm");
            //this.gfn_getName( "usid" , this.invtMnEmno , this.edt_invtMnEmnoNm ); //서비스없음
          }
        } else {
          this.invtMnEmnoNm = "";
        }
      } else if (objId == "saleEmno") {
        //2019.11.19 add
        if (this.saleEmno.length == 5) {
          this.emnoPopupYn = "N";
          if (this.fn_CheckGdC()) {
            this.fn_transactionEmno("edt_saleEmno");
          } else {
            this.fn_getUserName(this.saleEmno, "saleEmnoNm");
            //this.gfn_getName( "usid" , this.saleEmno , this.edt_saleEmnoNm ); //서비스없음
          }
        } else {
          this.saleEmnoNm = "";
        }
      } else if (objId == "mngrEmno") {
        if (this.mngrEmno.length == 5) {
          this.emnoPopupYn = "N";
          if (this.fn_CheckGdC()) {
            this.fn_transactionEmno("edt_mngrEmno");
          } else {
            this.fn_getUserName(this.mngrEmno, "mngrEmnoNm");
            //this.gfn_getName( "usid" , this.mngrEmno , this.edt_mngrEmnoNm ); //서비스없음
            if (this.invtMnEmno.length < 5) {
              this.invtMnEmno = this.mngrEmno;
              this.fn_getUserName(this.invtMnEmno, "invtMnEmnoNm");
              //this.gfn_getName( "usid" , this.invtMnEmno , this.edt_invtMnEmnoNm ); //서비스없음
            }
          }
        } else {
          this.mngrEmnoNm = "";
        }
      } else if (objId == "fnlDsgnEmno") {
        if (this.fnlDsgnEmno.length == 8) {
          //조회용 dataset을 초기화 해준다.
          this.dsList14 = [];
          //this.sSvcIDSub    = "selectTFAC20000D";
          let pParam = {
            fcEmno: this.fnlDsgnEmno, //권유대행FC
          };
          this.eaiCommObj.lv_vm = this;
          this.eaiCommObj.auth = "S";
          this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600085_S";
          this.eaiCommObj.params = pParam;
          TSServiceUtil.invokeInDirect(
            this.eaiCommObj,
            function(res) {
              if (res && res.data) {
                thisWin.dsList14 = TSCommUtil.gfn_getArrFrBase(
                  dsList14_b,
                  res.data.tfmfci
                );
                thisWin.fn_callBackSub("selectTFAC20000D");
              }
            },
            function(error) {
              return;
            },
            "UF10600085"
          );
        } else {
          this.fnlDsgnEmnoNm = "";
        }
      }
    },
    /**********************************************************************************
     *송금 계좌 변경시
     **********************************************************************************/
    cbo_trBkcdNmbnkAcno00_onitemchanged() {
      let csid = this.dsList01[0]["prinBnfcCsId"];
      let seq = this.trBkcdNmbnkAcno00;
      let idx = TSCommUtil.gfn_getIdxArrMapKeyVal(this.dsList11, "value", seq);
      let seqNm = _.isEmpty(this.dsList11[idx]["text"])
        ? ""
        : this.dsList11[idx]["text"];
      let dsL01 = _.cloneDeep(this.dsList01);
      let soptTc = thisWin.soptTc;
      for (let i = 0; i < this.dsList01.length; i++) {
        if (this.dsList01[i]["prinBnfcCsId"] == csid) {
          dsL01[i]["prinBnfcBnkSseq"] = seq;
          dsL01[i]["prinBnfcBnkSseqNm"] = seqNm;
        }
        if (this.dsList01[i]["prftBnfcCsId"] == csid) {
          dsL01[i]["prftBnfcBnkSseq"] = seq;
          dsL01[i]["prftBnfcBnkSseqNm"] = seqNm;
        }
      }
      this.dsList01 = _.cloneDeep(dsL01);
      //this.trBkcdNmbnkAcno00 = seq;
      this.fn_custRcnoMask();
    },
    /**********************************************************************************
     *송금 계좌 변경시
     **********************************************************************************/
    cbo_trBkcdNmbnkAcno01_onitemchanged() {
      let csid = this.dsList01[0]["prftBnfcCsId"];
      let seq = this.trBkcdNmbnkAcno01;
      let idx = TSCommUtil.gfn_getIdxArrMapKeyVal(this.dsList12, "value", seq);
      let seqNm = _.isEmpty(this.dsList12[idx]["text"])
        ? ""
        : this.dsList12[idx]["text"];
      let dsL01 = _.cloneDeep(this.dsList01);
      let soptTc = thisWin.soptTc;
      for (let i = 0; i < this.dsList01.length; i++) {
        if (this.dsList01[i]["prinBnfcCsId"] == csid) {
          dsL01[i]["prinBnfcBnkSseq"] = seq;
          dsL01[i]["prinBnfcBnkSseqNm"] = seqNm;
        }
        if (this.dsList01[i]["prftBnfcCsId"] == csid) {
          dsL01[i]["prftBnfcBnkSseq"] = seq;
          dsL01[i]["prftBnfcBnkSseqNm"] = seqNm;
        }
      }
      this.dsList01 = _.cloneDeep(dsL01);
      //this.trBkcdNmbnkAcno01 = seq;
      this.fn_custRcnoMask();
    },
    /**********************************************************************************
     * 계좌조회
     **********************************************************************************/
    acnoCallBackFunc() {
      // 계좌번호 조회
      thisWin.dsList08 = []; // 데이터 셋 초기화
      let aInfo = this.fn_getAcnoCondInfo();
      let tacno = aInfo["tacno"];
      let bnkbSeq = aInfo["bnkbSeq"];
      if (tacno.length != 7) {
        return;
      }
      //thisWin.sSvcIDSub = "selectTFAC20000BList" ;
      let pParam = {
        tacno: tacno, // 종합계좌번호
      };
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600083_S";
      this.eaiCommObj.params = pParam;
      TSServiceUtil.invokeInDirect(
        this.eaiCommObj,
        function(res) {
          if (res && res.data) {
            thisWin.dsList08 = TSCommUtil.gfn_getArrFrBase(
              dsList08_b,
              res.data.tfabnb
            );
            thisWin.fn_callBackSub("selectTFAC20000BList");
          } else {
          }
        },
        function(error) {
          return;
        },
        "UF10600083"
      );
    },
    /**********************************************************************************
     * 계좌번호조회(base)
     **********************************************************************************/
    fn_transactionS1_base() {
      let a70Info = this.fn_getAcnoCondInfo();
      let acno = a70Info["tacno"];
      if (acno.length < 7) return;
      // 계좌번호 조회
      //this.sSvcID  = "selectTPUP95320";
      let pParam = {
        tacno: acno, // 종합계좌번호
      };
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600215_S";
      this.eaiCommObj.params = pParam;
      TSServiceUtil.invokeInDirect(
        this.eaiCommObj,
        function(res) {
          if (res && res.data) {
            thisWin.dsListS1Base = _.cloneDeep(res.data);
          }
        },
        function(error) {
          return;
        },
        "UF10600215"
      );
    },
    /**********************************************************************************
     * 계좌번호조회
     **********************************************************************************/
    fn_transactionS1() {
      this.fn_initBody();
      let acno = this.edtAcno1.trim();
      this.dsListS1 = {};
      if (acno.length < 7) {
        return;
      }
      // 계좌번호 조회
      //this.sSvcID  = "selectTPUP95320";

      let pParam = {
        tacno: acno, // 종합계좌번호
      };
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600215_S";
      this.eaiCommObj.params = pParam;
      TSServiceUtil.invokeInDirect(
        this.eaiCommObj,
        function(res) {
          if (res && res.data) {
            thisWin.dsListS1 = _.cloneDeep(res.data);
            if (
              _.isEmpty(thisWin.dsListS1) ||
              _.isEmpty(thisWin.dsListS1["tacno"])
            )
              return;
            thisWin.edtAcno1 = thisWin.dsListS1["tacno"];
            thisWin.edtCnm1 = thisWin.dsListS1["cnm"];
            thisWin.cboAcno = thisWin.dsListS1["tacnoBnkbSeq"];
            thisWin.cboCtno = thisWin.dsListS1["ctno"];

            thisWin.tacnoBase = thisWin.dsListS1["tacno"];
            // 계좌번호가 있는 경우에만 호출화면으로 메소드를 전달한다.
            if (!_.isEmpty(thisWin.edtAcno1)) {
              try {
                thisWin.acnoCallBackFunc();
              } catch (e) {}
            }
          } else {
          }
        },
        function(error) {
          return;
        },
        "UF10600215"
      );
    },
    /**********************************************************************************
     * 통장조회 call back
     **********************************************************************************/
    fn_callBack_s2() {
      try {
        thisWin.dsListS2Seq = [];
        if (_.isEmpty(thisWin.dsListS2) || thisWin.dsListS2.length < 1) {
          return;
        }
        //dsListS2Seq
        thisWin.dsListS2Seq = TSCommUtil.gfn_getDropdownFrArr(
          thisWin.dsListS2,
          "accSeq",
          "gdNm"
        );
        //thisWin.$refs.rfCboAccSeq.focus();
        // 초기값 설정
        if (_.isEmpty(thisWin.dsDetail) || thisWin.dsDetail.length < 1) {
          thisWin.dsDetail[0] = _.cloneDeep(dsDetail_b);
        }
        thisWin.cboAccSeq = thisWin.dsListS2[0]["accSeq"];
        thisWin.dsDetail[0]["curC"] = thisWin.dsListS2[0]["curC"];
        thisWin.dsDetail[0]["contDate"] = thisWin.dsListS2[0]["contDate"];
        thisWin.dsDetail[0]["mtrtDate"] = thisWin.dsListS2[0]["mtrtDate"];
        if (
          this.dsDetail[0]["mtrtDate"] != null &&
          this.dsDetail[0]["mtrtDate"] != ""
        ) {
          thisWin.fn_setCalendar("rfMtrtDate", this.dsDetail[0]["mtrtDate"]);
        }
        thisWin.dsDetail[0]["brigAccYn"] = thisWin.dsListS2[0]["brigAccYn"];
        thisWin.dsDetail[0]["trstTypC"] = thisWin.dsListS2[0]["trstTypC"];
        thisWin.dsDetail[0]["gdC"] = thisWin.dsListS2[0]["gdC"];
        thisWin.dsDetail[0]["trstAmt"] = thisWin.dsListS2[0]["trstAmt"];
        thisWin.dsDetail[0]["gdTypDtlC"] = thisWin.dsListS2[0]["gdTypDtlC"];
        thisWin.dsDetail[0]["mngBrcd"] = thisWin.dsListS2[0]["mngBrcd"];
        thisWin.dsDetail[0]["csId"] = thisWin.dsListS2[0]["csId"];
        thisWin.dsDetail[0]["cnm"] = thisWin.dsListS2[0]["cnm"];
        thisWin.edtCnm2 = thisWin.dsListS2[0]["cnm"];

        thisWin.dsDetail[0]["fundC"] = thisWin.dsListS2[0]["fundC"];
        thisWin.dsDetail[0]["rcno"] = thisWin.dsListS2[0]["rcno"];
        thisWin.dsDetail[0]["rcnoKindTc"] = thisWin.dsListS2[0]["rcnoKindTc"];
        thisWin.dsDetail[0]["soptTc"] = thisWin.dsListS2[0]["soptTc"];
        thisWin.dsDetail[0]["gdNm"] = thisWin.dsListS2[0]["gdNm"];
        if (!_.isEmpty(thisWin.dsListS2[0]["admkNm"])) {
          thisWin.dsDetail[0]["cnm"] =
            thisWin.dsListS2[0]["cnm"] +
            "[" +
            thisWin.dsListS2[0]["admkNm"] +
            "]";
        }
        thisWin.dsDetail[0]["bnkbAccYn"] = "Y";

        if (thisWin.sSvcID == "selectTPUP95090ListB") {
          if (!_.isEmpty(thisWin.dsListS2[0]["bnkb"])) {
            //등록된 계좌가 있는지
            thisWin.dsDetail[0]["bnkbAccYn"] = "Y"; //등록된 계좌있음
            thisWin.popAccSeq = "";
            thisWin.fn_transactionS2();
          } else {
            thisWin.dsDetail[0]["bnkbAccYn"] = "N"; //등록된 계좌없음
          }
        } else {
          thisWin.dsDetail[0]["bnkbAccYn"] = "Y"; //등록된 계좌있음
        }
        if (!_.isEmpty(thisWin.dsListS2[0]["admkNm"])) {
          thisWin.dsDetail[0]["cnm"] =
            thisWin.dsListS2[0]["cnm"] +
            "[" +
            thisWin.dsListS2[0]["admkNm"] +
            "]";
        }

        if (thisWin.popAccSeq != null && thisWin.popAccSeq != "") {
          thisWin.cboAccSeq = thisWin.popAccSeq;
        }

        // 계좌번호가 있는 경우에만 호출화면으로 메소드를 전달한다.
        if (thisWin.dsListS2.length > 0) {
          try {
            thisWin.acctCallBackFunc();
          } catch (e) {}
        }
      } catch (e) {
      } finally {
      }
    },
    /**********************************************************************************
     * 통장조회
     **********************************************************************************/
    fn_transactionS2() {
      this.fn_initBody();
      let edtAcno2 = this.fn_getValue(this.edtAcno2).trim();
      if (edtAcno2.length != 10) {
        return;
      }

      this.dsListS2 = [];
      //this.sSvcID = "selectTPUP95090List";
      // 계좌번호 조회
      let pParam = {
        tacno: edtAcno2.substr(0, 7), // 종합계좌번호
        bnkbSeq: edtAcno2.substr(7, 3), // 통장일련번호
        accStatC: "11", // 계좌상태코드(11.정상)
        brigAccYn: "", //브릿지 계좌여부
      };
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600245_S";
      this.eaiCommObj.params = pParam;
      TSServiceUtil.invokeInDirect(
        this.eaiCommObj,
        function(res) {
          if (res && res.data) {
            thisWin.dsListS2 = _.cloneDeep(res.data.tpup95090);
            thisWin.fn_callBack_s2(); //조회영역 accno data
          } else {
          }
        },
        function(error) {
          return;
        },
        "UF10600245"
      );
    },
    /************************************************************************************************
     * 통장 정보 조회 TR 전송
     ************************************************************************************************/
    fn_transactionB() {
      // 통장번호 조회
      let edtAcno2 = this.edtAcno2;
      let cboAccSeq = this.cboAccSeq;
      if (edtAcno2.length != 10 || cboAccSeq.length != 3) return;

      this.dsListS2 = [];
      this.sSvcID = "selectTPUP95090ListB";
      // 계좌번호 조회
      let pParam = {
        tacno: edtAcno2.substr(0, 7), // 종합계좌번호
        bnkbSeq: edtAcno2.substr(7, 3), // 통장일련번호
        accStatC: cboAccSeq, //
        brigAccYn: String(Number(this.radProcessGb) - 1),
      };
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600246_S";
      this.eaiCommObj.params = pParam;
      TSServiceUtil.invokeInDirect(
        this.eaiCommObj,
        function(res) {
          if (res && res.data) {
            thisWin.dsListS2 = _.cloneDeep(res.data.tpup95090);
            thisWin.fn_callBack_s2();
          }
        },
        function(error) {
          return;
        },
        "UF10600246"
      );
    },
    /************************************************************************************************
     * 콤보박스 변경 이벤트
     ************************************************************************************************/
    Cbo_accSeq_onitemchanged() {
      let acnoValue = this.fn_getValue(this.edtAcno2).trim();
      let cboAccSeq = this.fn_getValue(this.cboAccSeq).trim();
      this.fn_initBody();
      this.cboChngSeq = "";
      this.edtAcno2 = acnoValue;
      this.cboAccSeq = cboAccSeq;

      let idx = TSCommUtil.gfn_getIdxArrMapKeyVal(
        this.dsListS2Seq,
        "value",
        this.cboAccSeq
      );
      // 콤보값이 변경될때마다 데이터 변경
      if (_.isEmpty(this.dsDetail) || this.dsDetail.length < 1) {
        this.dsDetail[0] = _.cloneDeep(dsDetail_b);
      }
      this.dsDetail[0]["curC"] = this.dsListS2[idx]["curC"];
      this.dsDetail[0]["contDate"] = this.dsListS2[idx]["contDate"];
      this.dsDetail[0]["mtrtDate"] = this.dsListS2[idx]["mtrtDate"];
      this.dsDetail[0]["brigAccYn"] = this.dsListS2[idx]["brigAccYn"];
      this.dsDetail[0]["trstTypC"] = this.dsListS2[idx]["trstTypC"];
      this.dsDetail[0]["gdC"] = this.dsListS2[idx]["gdC"];
      this.dsDetail[0]["trstAmt"] = this.dsListS2[idx]["trstAmt"];
      this.dsDetail[0]["gdTypDtlC"] = this.dsListS2[idx]["gdTypDtlC"];
      this.dsDetail[0]["mngBrcd"] = this.dsListS2[idx]["mngBrcd"];
      this.dsDetail[0]["csId"] = this.dsListS2[idx]["csId"];
      this.dsDetail[0]["fundC"] = this.dsListS2[idx]["fundC"];
      this.dsDetail[0]["rcno"] = this.dsListS2[idx]["rcno"];
      this.dsDetail[0]["rcnoKindTc"] = this.dsListS2[idx]["rcnoKindTc"];
      this.dsDetail[0]["soptTc"] = this.dsListS2[0]["soptTc"];
      this.dsDetail[0]["gdNm"] = this.dsListS2[idx]["gdNm"];

      let dS2Arr = [
        "mtrtDate",
        "trstTypC",
        "gdC",
        "trstAmt",
        "gdTypDtlC",
        "soptTc",
        "gdNm",
      ];
      this.fn_copyDataArrMap(dS2Arr, this.dsListS2[idx]);
      // 계좌번호가 있는 경우에만 호출화면으로 메소드를 전달한다.
      if (this.dsListS2.length > 0) {
        try {
          this.acctCallBackFunc();
        } catch (e) {}
      }
    },
    /**********************************************************************************
     * 타화면에서 제어시
     **********************************************************************************/
    TFAC20601_onLoad(strArg) {
      //Parameter Receive  Cbo_accSeq
      TSCommUtil.gfn_setVisibleArr(this, ["visDivAcno"], false); //고객
      TSCommUtil.gfn_setVisibleArr(this, ["visDivAccno"], false); //통장
      TSCommUtil.gfn_setVisibleArr(this, ["visBtnAdmission"], false);
      this._P_PARAMS = strArg;
      if (this._P_PARAMS) {
        this.radProcessGb = this.getNumber(this._P_PARAMS.processGb) + 1 + "";
        this.fn_RadProcess();

        if (this.radProcessGb == "1") {
          this.edtAcno1 = this._P_PARAMS.tacno;
        } else {
          this.edtAcno2 = this._P_PARAMS.tacno + this._P_PARAMS.bnkbSeq;
          this.cboAccSeq = this._P_PARAMS.accSeq;
        }
      } else {
        this.fn_RadProcess();
      }
    },
    /**********************************************************************************
     * 삭제 : WFtitleCRUDbtn 의 callback
     **********************************************************************************/
    fn_Delete() {
      //서버에 호출 할 정보를 셋팅해준다.
      let brcd = this.lv_basInfo.brcd;
      if (brcd == "916") {
        this.fn_alert("신탁지원부는 처리 할 수 없습니다.");
        return;
      }
      let a7Info = thisWin.fn_getAcnoCondInfo();
      let tacno = a7Info["tacno"];
      let ctno = a7Info["ctno"];
      let chngSeq = a7Info["chngSeq"];
      if (this.radProcessGb == "1") {
        if (tacno.length != 7) {
          this.fn_alert("고객번호를 확인하십시오.");
          //this.$refs.rfEdtAcno1.focus();
        } else if (this.fn_getNumber(ctno) < 1) {
          this.fn_alert("계약번호를 확인하십시오.");
        } else {
          this.sSvcID = "deleteTFAC20000";
          let pParam = this.dsDetail[0];

          this.eaiCommObj.lv_vm = this;
          this.eaiCommObj.auth = "D";
          this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600070_S";
          this.eaiCommObj.params = pParam;
          TSServiceUtil.invokeInDirect(
            this.eaiCommObj,
            function(res) {
              if (res && res.data) {
                thisWin.btnAdmissionText = "";
                thisWin.cboCtno = "";
                thisWin.fn_callBack("deleteTFAC20000", res);
              }
            },
            function(error) {
              return;
            },
            "UF10600070"
          );
        }
      } else if (this.radProcessGb == "2" || this.radProcessGb == "3") {
        if (tacno.length != 7) {
          this.fn_alert("계좌번호를 확인하십시오.");
        } else if (this.fn_getNumber(chngSeq) < 1) {
          this.fn_alert("일련번호를 확인하십시오.");
        } else {
          this.sSvcID = "deleteTFAC20000A";
          let pParam = this.dsDetail[0];

          this.eaiCommObj.lv_vm = this;
          this.eaiCommObj.auth = "D";
          this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600071_S";
          this.eaiCommObj.params = pParam;
          TSServiceUtil.invokeInDirect(
            this.eaiCommObj,
            function(res) {
              if (res && res.data) {
                thisWin.btnAdmissionText = "";
                thisWin.cboChngSeq = "";
                thisWin.fn_callBack("deleteTFAC20000A", res);
              }
            },
            function(error) {
              return;
            },
            "UF10600071"
          );
        }
      }
    },
    /**********************************************************************************
     * 운용지시내역 셀클릭스 ===> row check
     **********************************************************************************/
    grd_list_oncellclick(col, row) {
      if (this.visGrdList02 == false) {
        return;
      }
      if (col == 1) {
        this.sPopupId = "TPUP95340";
        let properties = { row: row };
        this.popup312 = this.$refs.popup312.fn_Open(properties);
      }
      if (col == 8) {
        this.sPopupId = "TPUP90180";
        let properties = { row: row };
        this.popup313 = this.$refs.popup313.fn_Open(properties);
      }
    },
    cbo_trBkcdNmbnkAcno00_ondropdown() {
      let nRow = 0;
      if (
        !_.isEmpty(this.dsList01[nRow]["prinBnfcCsId"]) &&
        this.dsList01[nRow]["prinBnfcCsId"].length == 9
      ) {
        //서버에 호출 할 정보를 셋팅해준다.
        //selectTFIO42100CList
        let pParam = {
          csId: this.dsList01[nRow]["prinBnfcCsId"],
        };
        this.eaiCommObj.lv_vm = this;
        this.eaiCommObj.auth = "S";
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600164_S";
        this.eaiCommObj.params = pParam;
        TSServiceUtil.invokeInDirect(
          this.eaiCommObj,
          function(res) {
            if (res && res.data) {
              thisWin.fn_callBackOtxt(res);
            }
          },
          function(error) {
            return;
          },
          "UF10600164"
        );
      }

      if (
        !_.isEmpty(this.dsList01[nRow]["prinBnfcCsId"]) &&
        this.dsList01[nRow]["prinBnfcCsId"].length == 9 &&
        !_.isEmpty(this.dsList01[nRow]["prftBnfcCsId"]) &&
        this.dsList01[nRow]["prftBnfcCsId"].length == 9 &&
        _.isEmpty(this.dsList01[nRow]["prinBnfcCsId"]) ==
          this.dsList01[nRow]["prftBnfcCsId"].length
      ) {
        //조회용 dataset에 조회할 항목을 넣어준다.
        //selectTFIO42100CList
        let pParam = {
          csId: this.dsList01[nRow]["prinBnfcCsId"],
        };
        this.eaiCommObj.lv_vm = this;
        this.eaiCommObj.auth = "S";
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600164_S";
        this.eaiCommObj.params = pParam;
        TSServiceUtil.invokeInDirect(
          this.eaiCommObj,
          function(res) {
            if (res && res.data) {
              thisWin.fn_callBackPrft(res);
            }
          },
          function(error) {
            return;
          },
          "UF10600164"
        );
      }
    },
    cbo_trBkcdNmbnkAcno01_ondropdown() {
      let nRow = 0;
      if (
        !_.isEmpty(this.dsList01[nRow]["prftBnfcCsId"]) &&
        this.dsList01[nRow]["prftBnfcCsId"].length == 9
      ) {
        //조회용 dataset에 조회할 항목을 넣어준다.
        //selectTFIO42100CList
        let pParam = {
          csId: this.dsList01[nRow]["prftBnfcCsId"],
        };
        this.eaiCommObj.lv_vm = this;
        this.eaiCommObj.auth = "S";
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600164_S";
        this.eaiCommObj.params = pParam;
        TSServiceUtil.invokeInDirect(
          this.eaiCommObj,
          function(res) {
            if (res && res.data) {
              thisWin.fn_callBackPrft(res);
            }
          },
          function(error) {
            return;
          },
          "UF10600164"
        );
      }

      if (
        !_.isEmpty(this.dsList01[nRow]["prinBnfcCsId"]) &&
        this.dsList01[nRow]["prinBnfcCsId"].length == 9 &&
        !_.isEmpty(this.dsList01[nRow]["prftBnfcCsId"]) &&
        this.dsList01[nRow]["prftBnfcCsId"].length == 9 &&
        _.isEmpty(this.dsList01[nRow]["prinBnfcCsId"]) ==
          this.dsList01[nRow]["prftBnfcCsId"].length
      ) {
        //서버에 호출 할 정보를 셋팅해준다.
        //selectTFIO42100CList
        let pParam = {
          csId: this.dsList01[nRow]["prinBnfcCsId"],
        };
        this.eaiCommObj.lv_vm = this;
        this.eaiCommObj.auth = "S";
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600164_S";
        this.eaiCommObj.params = pParam;
        TSServiceUtil.invokeInDirect(
          this.eaiCommObj,
          function(res) {
            if (res && res.data) {
              thisWin.fn_callBackOtxt(res);
            }
          },
          function(error) {
            return;
          },
          "UF10600164"
        );
      }
    },
    grd_list03_oncellclick(col, row) {
      if (this.visGrdList03 == false) {
        return;
      }
      if (col == 2) {
        if (_.isEmpty(this.dsList03[row]["astsType"])) {
          this.fn_alert("구분을 먼저 입력 하십시오.");
          return;
        }
        let rw03 = this.fn_getNumber(this.lv_SelectedItem03);
        let astsType = this.dsList03[rw03]["astsType"];

        this.sPopupId = "TPUP90180_01";
        let properties = { row: row, astsType: astsType };
        this.popup313 = this.$refs.popup313.fn_Open(properties);
      }
    },
    /*****************************************************************************************
   바이트수 체크
   *****************************************************************************************/
    fn_getByteLength(sValue) {
      let byteLength = 0;
      let charCode = "";

      if (_.isEmpty(sValue)) {
        return 0;
      }
      for (let i = 0; i < sValue.length; i++) {
        charCode = sValue.charCodeAt(i);
        if (charCode <= "0x00007F") {
          byteLength += 1;
        } else if (charCode <= "0x0007FF") {
          byteLength += 2;
        } else if (charCode <= "0x00FFFF") {
          byteLength += 2;
        } else {
          byteLength += 4;
        }
      }
      return byteLength;
    },
    /**********************************************************************************
     * 계좌번호조회
     **********************************************************************************/
    edtAcno1_onkeyup(e) {
      let acnoValue = this.fn_getValue(this.edtAcno1).trim();
      this.fn_userInit();
      this.edtAcno1 = acnoValue;

      //if (!_.isEmpty(thisWin.dsListS1) && thisWin.dsListS1.length > 0 && acnoValue.length != 7){
      //  thisWin.acnoClearCallBackFunc();
      //}
      // 계좌번호 10자리 입력시 자동 조회
      if (acnoValue.length == 7) {
        this.fn_transactionS1();
      } else {
        // 계좌번호 입력 후 엔터 시 계좌 조회 tr 호출
        if (e != undefined || e == null) return;
        if (e.keyCode != undefined || e.keyCode == null) return;
        if (e.keyCode == 13) {
          if (acnoValue.length != 7) {
            //this.fn_alert("계좌번호를 다시 입력하세요.");
            //this.$refs.rfEdtAcno1.focus();
          } else {
            this.fn_transactionS1();
          }
        }
      }
    },
    /**********************************************************************************
     * 고객조회
     **********************************************************************************/
    Button11_onclick() {
      this.sPopupId = "TPUP9532011";
      let properties = {};
      this.popup107 = this.$refs.popup107.fn_Open(properties);
    },
    /************************************************************************************************
     * 통장 정보 조회
     ************************************************************************************************/
    edtAcno2_onkeyup(e) {
      let acnoValue = this.fn_getValue(this.edtAcno2).trim();
      this._fn_dataInit();
      this.edtAcno2 = acnoValue;

      // 계좌번호 10자리 입력시 자동 조회
      if (acnoValue.length == 10) {
        this.cboAccSeq = "";
        //if(this.popCls == "4"){
        //  this.fn_transactionB(acnoValue);
        //}else{
        this.popAccSeq = "";
        this.fn_transactionS2();
        //}
      } else {
        // 계좌번호 입력 후 엔터 시 계좌 조회 tr 호출
        if (e != undefined || e == null) return;
        if (e.keyCode != undefined || e.keyCode == null) return;
        if (e.keyCode == 13) {
          if (acnoValue.length != 10) {
            //this.fn_alert("계좌번호를 다시 입력하시오.");
            //this.$refs.rfEdtAcno2.focus();
          } else {
            this.cboAccSeq = "";
            this.popAccSeq = "";
            //this.fn_transactionS2();
          }
        }
      }
    },
    /************************************************************************************************
     * 계좌정보조회 팝업 호출
     ************************************************************************************************/
    btn_popupTPUP95350_onclick() {
      // 초기화
      this.sPopupId = "TPUP95350";
      let properties = { cnm: "" }; //팝업화면으로 보낼 파라미터.
      this.popup206 = this.$refs.popup206.fn_Open(properties);
    },
    /************************************************************************************************
     * 송금계좌등록 버튼 (040010060 : TFIO42250)
     ************************************************************************************************/
    Div02_Btn_ivorInfoConfirmation00_onclick() {
      let a50Info = this.fn_getAcnoCondInfo();
      let args = { tacno: a50Info["tacno"] };
      this.sPopupId = "TFIO42250";
      this.popup204 = this.$refs.popup204.fn_Open(args);
    },
    Mspts108_onclick() {
      let properties = {};
      this.popup108 = this.$refs.popup108.fn_Open(properties);
    },
    /************************************************************************************************
     * 대리인 (030020105 : 2700 화면호출)
     ************************************************************************************************/
    Div02_Btn_reprInfoConfirm_onclick() {
      let processGb = this.radProcessGb;
      let a60Info = this.fn_getAcnoCondInfo();
      let tacno = a60Info["tacno"];
      let bnkbSeq = a60Info["bnkbSeq"];
      let ctno = a60Info["ctno"];
      let accSeq = a60Info["accSeq"];
      this.pPopup106mObj.params = {
        tacno: tacno,
        bnkbSeq: bnkbSeq,
        ctno: ctno,
        accSeq: accSeq,
      };
      this.$refs.popup106m.fn_Open();
    },
    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/
    fn_AlertPopup(type, pPopupObj) {
      switch (type) {
        case 0:
          if (!TSCommUtil.gfn_isNull(pPopupObj)) {
            this.pAlertPopupObj.content = pPopupObj.content;

            if (pPopupObj.confirm) {
              this.pAlertPopupObj.confirm = pPopupObj.confirm;
              this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc;
            }
          }
          break;
        case 7:
        case 9:
          if (!TSCommUtil.gfn_isNull(pPopupObj)) {
            this.pAlertPopupObj.content = pPopupObj.content;
            this.pAlertPopupObj.contentTitle = "";
            this.pAlertPopupObj.confirm = false;
            this.pAlertPopupObj.cancel = true;
            this.pAlertPopupObj.type = 0;

            if (!pPopupObj.cancel) {
              this.pAlertPopupObj.cancel = pPopupObj.cancel;
            }
            if (pPopupObj.confirm) {
              this.pAlertPopupObj.confirm = pPopupObj.confirm;
              this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc;
              this.pAlertPopupObj.confirmData = pPopupObj.confirmData;
            }
            if (pPopupObj.closeFunc) {
              this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc;
              this.pAlertPopupObj.closeData = pPopupObj.closeData;
            }
            if (!TSCommUtil.gfn_isNull(type) && type != 0) {
              this.pAlertPopupObj.type = type;
            }
            if (pPopupObj.contentTitle) {
              this.pAlertPopupObj.contentTitle = pPopupObj.contentTitle;
            }
          }
          break;
      }

      this.$refs.alertPopup.fn_Open();
    },
    /******************************************************************************
     * Function명 : fn_reqApprvState, fn_setTimerApprvState
     * 설명       : 승인요청 => 승인완료 계속 실행.
     ******************************************************************************/
    fn_setTimerApprvState() {
      if (this.timerId == null) {
        this.timerId = setInterval(thisWin.fn_reqApprvState, 15000);
      }
    },
    fn_clearTimerApprvState() {
      if (this.timerId != null) {
        clearInterval(thisWin.timerId);
      }
      thisWin.timerId = null;
    },
    fn_reqApprvState() {
      if (thisWin.timerId == null) return;
      //thisWin.$ToastManager.fn_ShowToast({ text: '책임자 승인 요청중입니다. 잠시 대기바랍니다.'});
      let pParamApp = thisWin.fn_getAcnoCondInfo();
      //pParamApp["csId"] = thisWin.fn_getValue(this.dsList00["csId"]);

      //selectTFAC20000
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      let uId = "UF10600079";
      if (thisWin.radProcessGb == "1") {
        //신규
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600079_S";
      } else {
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600082_S";
        uId = "UF10600082";
      }
      this.eaiCommObj.params = pParamApp;
      TSServiceUtil.invokeInDirect(
        this.eaiCommObj,
        function(res) {
          if (res && res.data) {
            let dsDt1 = [];
            if (thisWin.radProcessGb == "1") {
              dsDt1 = TSCommUtil.gfn_getArrFrBase(dsDetail_b, [
                res.data.tfac20000[0],
              ]);
            } else {
              dsDt1 = [TSCommUtil.gfn_getMapFrBase(dsDetail_b, res.data)];
            }
            let aprvStatTc = dsDt1[0]["aprvStatTc"]; //11.승인요청완료 12.승인요청확정 15.승인완료 19.처리완료
            if (aprvStatTc != "12" && aprvStatTc != "19") {
              thisWin.fn_setTimerApprvState();
            } else {
              //popup
              thisWin.fn_clearTimerApprvState();
              //popup
              if (thisWin.radProcessGb == "1" || thisWin.radProcessGb == "2") {
                thisWin.fn_searchList();
                thisWin.$ToastManager.fn_ShowToast({
                  text: "승인처리가 완료되었습니다.",
                });
                thisWin.sPopupId = "MSPTS319P";
                let aprvBusnTc = "1011"; //신규계약
                if (thisWin.radProcessGb == "2") {
                  aprvBusnTc = "1031"; //추가입금
                }
                let pMsgCntn =
                  aprvBusnTc +
                  dsDt1[0]["tacno"] +
                  dsDt1[0]["bnkbSeq"] +
                  dsDt1[0]["accSeq"];
                let properties = {
                  pMsgCntn: pMsgCntn,
                  trstTypC: dsDt1[0]["trstTypC"],
                };
                thisWin.popup319 = thisWin.$refs.popup319.fn_Open(properties);
              } else {
                thisWin.fn_searchList();
                thisWin.fn_checkProcess(); //전자서식 셋팅
                thisWin.$ToastManager.fn_ShowToast({
                  text: "승인처리가 완료되었습니다.",
                });
              }
            }
          }
        },
        function(error) {
          return;
        },
        uId
      );
    },

    /******************************************************************************
     * Function명 : fn_setTotActive
     * 설명       : componant 활성화(신규,추가,변경 시)
     *   기존 fn_insertEnableCompNew,fn_insertEnableCompChan,fn_insertEnableCompAdd
     *   대체
     ******************************************************************************/
    fn_setTotActive() {
      this.fn_Init_Btn_Enable();
      this.fn_enableComponent("btnConfirm,btnCancel");
      let radProcessGb = this.radProcessGb; //업무구분(1.New 2.Add 3.Change)
      let trstTypC = this.trstTypC; //신탁유형
      let cboChngSeq = this.cboChngSeq; //변경일련번호

      //신규, 변경
      if (radProcessGb == "1" || radProcessGb == "3") {
        //만기일
        TSCommUtil.gfn_setDisableArr(this, ["disMtrtDate"], false);
        //운영내역통보,자타익구분
        TSCommUtil.gfn_setDisableArr(
          this,
          ["disOpdtNtcTc", "disSoptTc"],
          false
        );
        //(후취) 기본보수율,선후취구분,주기
        TSCommUtil.gfn_setDisableArr(
          this,
          ["disBasTfrt", "disEpayPoaoTc", "disEpayCyclTc"],
          false
        );
        //별납정률정액구분,후취정기보수정액구분코드
        TSCommUtil.gfn_setDisableArr(
          this,
          ["disEpayFxrtFamtTc", "disAocpRgfeFamtTc"],
          false
        );
        //별납보수율,별납보수 금액,금액(후취정기)
        TSCommUtil.gfn_setDisableArr(
          this,
          ["disEpayTfrt", "disAocpRgfe"],
          false
        );
        //2019.11.19 add
        //판매직원/판매직원팝업,유선구분(없음)
        TSCommUtil.gfn_setDisableArr(this, ["disSaleEmno"], false);
        //운용지시,유가증권내역,부동산재산,금전채권신탁
        TSCommUtil.gfn_setDisableArr(
          this,
          ["disGrdList02", "disGrdList03", "disGrdList04", "disGrdList05"],
          false
        );
        //해외채권 쿠폰처리여부
        TSCommUtil.gfn_setDisableArr(this, [, "disFrBondCpTc"], false);
        //(원금수익자,이익수익자)(무조건disabled),원금수익자계좌선택,이익수익자 계좌 팝업
        TSCommUtil.gfn_setDisableArr(
          this,
          [
            "btnCustInfo00",
            "btnCustInfo01",
            "disTrBkcdNmbnkAcno00",
            "disTrBkcdNmbnkAcno01",
          ],
          false
        );
        if (cboChngSeq != null && cboChngSeq != "") {
          TSCommUtil.gfn_setDisableArr(
            this,
            [
              "btnCustInfo00",
              "btnCustInfo01",
              "disTrBkcdNmbnkAcno00",
              "disTrBkcdNmbnkAcno01",
            ],
            true
          );
        }
        if (
          trstTypC != "001" &&
          trstTypC != "008" &&
          this.poaoTc == "1" &&
          this.epayFxrtFamtTc == "1"
        ) {
          // 별납정률 20230321
          //신탁금액
          TSCommUtil.gfn_setDisableArr(this, ["disTrstAmt"], true);
        } else {
          TSCommUtil.gfn_setDisableArr(this, ["disTrstAmt"], false);
        }
        //계약 관계자, 스케쥴 관리
        TSCommUtil.gfn_setDisableArr(
          this,
          ["disRelated", "btnCustInfoReg"],
          false
        );
        if (cboChngSeq != null && cboChngSeq != "") {
          TSCommUtil.gfn_setDisableArr(
            this,
            ["disRelated", "btnCustInfoReg"],
            true
          );
        }

        //행추가/삭제
        TSCommUtil.gfn_setVisibleArr(this, ["visInsertRow"], true);
        //수익보수율 / 중도해지수수료율 없음
        if (this.visGrdList03 || this.visGrdList04 || this.visGrdList05) {
          // 행추가/삭제
          TSCommUtil.gfn_setVisibleArr(this, ["visInsertRow00"], true);
        }

        if (radProcessGb == "1") {
          //별납보수금액
          TSCommUtil.gfn_setDisableArr(this, ["disEpayFeeAmt"], false);
          //권유자/권유자팝업,관리자/관리자팝업
          TSCommUtil.gfn_setDisableArr(
            this,
            ["disInvtMnEmno", "disMngrEmno"],
            false
          );
          //송금계좌
          TSCommUtil.gfn_setDisableArr(this, ["disTrBkcdNmbnkAcno"], false);

          if (trstTypC == "008") {
            //유언대용신탁(금전)
            //정기보수여부
            TSCommUtil.gfn_setDisableArr(this, ["disAocpRgfeYn"], true);
            //주기,별납정률정액구분
            TSCommUtil.gfn_setVisibleArr(this, ["visEpayFxrtFamtTc"], true);
          }

          //권유대행 FC
          TSCommUtil.gfn_setDisableArr(this, ["disFnlDsgnEmno"], true);
          if (
            !_.isEmpty(thisWin.dsDetail02) &&
            thisWin.dsDetail02[0]["fcInvtPosbYn"] == "Y"
          ) {
            //재무설계 FP/재무설계 FP 팝업
            TSCommUtil.gfn_setDisableArr(thisWin, ["disFnlDsgnEmno"], false);
          }
        }
        if (radProcessGb == "3") {
          this.sSvcID = "updateTFAC20000B";
          //만기후보수율
          TSCommUtil.gfn_setDisableArr(this, ["disMtrtAfTfrt"], false);
          //발행여부
          TSCommUtil.gfn_setDisableArr(this, ["disIsueYn"], false);
          //정기보수여부
          TSCommUtil.gfn_setDisableArr(this, ["disAocpRgfeYn"], false);
          //주기,별납정률정액구분
          TSCommUtil.gfn_setDisableArr(this, ["disAocpRgfeCyclTc", ""], false);
          //해지소요일수,유선구분(없음),대리인팝업,사망여부,사망일자
          TSCommUtil.gfn_setDisableArr(
            this,
            [
              "disTmtlNeedDds",
              "disAgntCsId",
              "disBnfcDethYn",
              "disBnfcDethDate",
            ],
            false
          );
          //부동산 운용
          if (
            thisWin.trstTypC == "002" ||
            thisWin.trstTypC == "003" ||
            thisWin.trstTypC == "004"
          ) {
            TSCommUtil.gfn_setDisableArr(this, ["disGrdList02"], true);
          }
          //신탁금액
          TSCommUtil.gfn_setDisableArr(this, ["disTrstAmt"], true);
        }

        this.cbo_epayPoaoTc_onitemchanged(); // visible
        this.cbo_aocpRgfeYn_onitemchanged(); // visible
        this.cbo_poaoTc_onitemchanged(); // 포함컨트롤 visible
        this.cbo_epayFxrtFamtTc_onitemchanged();
      }
      //추가
      if (radProcessGb == "2") {
        this.sSvcID = "updateTFAC20000B";
        this.trstAmtNm = "추가금액";
        let trstAmt = this.dsDetail[0]["trstAmt"]; //추가 금액
        let trstOtxtAmt = this.dsDetail[0]["trstOtxtAmt"]; // 원래 신탁금액

        if (
          trstTypC != "001" &&
          trstTypC != "080" &&
          this.poaoTc == "1" &&
          this.epayFxrtFamtTc == "1"
        ) {
          // 별납정률 20230321 유언대용신탁(금전)추가
          //행추가/삭제
          TSCommUtil.gfn_setVisibleArr(this, ["visInsertRow00"], true);
          //운용지시,유가증권내역,부동산재산,금전채권신탁
          TSCommUtil.gfn_setDisableArr(
            this,
            ["disGrdList02", "disGrdList03", "disGrdList04", "disGrdList05"],
            false
          );
          this.fn_visibleGrid();
        } else {
          //신탁금액
          TSCommUtil.gfn_setDisableArr(this, ["disTrstAmt"], false);
        }
        if (this.fn_getNumber(trstOtxtAmt) == this.fn_getNumber(trstAmt)) {
          //초기 추가입금
          this.trstAmt = "0";
        } else {
          // 추가입금 수정
          this.trstAmt =
            this.fn_getNumber(trstAmt) - this.fn_getNumber(trstOtxtAmt) + "";
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_checkProcess
     * 설명       : 프로세스에 따라 분기 처리
     ******************************************************************************/
    fn_checkProcess() {
      let vmTitle = "계약 신규신청 완료";
      if (this.radProcessGb == "2") {
        vmTitle = "추가입금 완료";
      }
      if (this.radProcessGb == "3") {
        vmTitle = "변경 완료";
      }

      let lv_Vm = this;
      let tmpContentTitle = vmTitle;
      let tmpContent = [];
      let isProcess = lv_Vm.getStore("tsStore").getters.getState.isProcess;
      let basInfo = lv_Vm.getStore("tsStore").getters.getBasInfo.data;
      let isLast = lv_Vm.$bizUtil.tsUtils.getIsLastProc(lv_Vm);
      let t_type = 0;

      if (isProcess) {
        let msg = "실시간입금처리 화면으로 이동합니다.";
        if (this.routeId == "MSPTS209M") {
          msg = "가상계좌입금 화면으로 이동합니다.";
        }
        if (!isLast) {
          tmpContent.push(msg);
          t_type = 7; // 프로세스 진행 중 마지막 화면이 아닐 경우
        } else {
          t_type = 9; // 프로세스 진행 중 마지막 화면일 경우
        }
      } else {
        // 프로세스 진행 중이 아닐 경우
        t_type = 9;
      }
      if (this.radProcessGb == "3") {
        t_type = 9;
      }

      let csId01 = "";
      if (this.dsDetail.length > 0 && this.dsDetail[0]["csId"] != null) {
        csId01 = this.dsDetail[0]["csId"];
      }
      let aTrInfo = thisWin.fn_getAcnoCondInfo();
      let t_popupObj = {
        confirm: true,
        confirmFunc: this.fn_confirmPrintTrue,
        content: tmpContent,
        contentTitle: tmpContentTitle,
        confirmData: {
          tacno: aTrInfo["tacno"],
          bnkbSeq: aTrInfo["bnkbSeq"],
          accSeq: aTrInfo["accSeq"],
          csId: csId01,
        },
      };
      if (thisWin.dsList00Svd != null && thisWin.dsList00Svd["csId"] != "") {
        t_popupObj["csId"] = thisWin.dsList00Svd["csId"];
      }
      if (aTrInfo["tacno"] != null && aTrInfo["tacno"] != "") {
        t_popupObj["tacno"] = aTrInfo["tacno"];
      }
      if (aTrInfo["bnkbSeq"] != null && aTrInfo["bnkbSeq"] != "") {
        t_popupObj["bnkbSeq"] = aTrInfo["bnkbSeq"];
      }
      if (aTrInfo["accSeq"] != null && aTrInfo["accSeq"] != "") {
        t_popupObj["accSeq"] = aTrInfo["accSeq"];
      }
      t_popupObj["csId"] = csId01;

      lv_Vm.fn_AlertPopup(t_type, t_popupObj);
    },

    /******************************************************************************
     * Function명 : fn_confirmPrintTrue, fn_confirmPrintFalse
     * 설명       : radio, mo-radio-wrapper 컴포넌트의 css error class 삭제
     ******************************************************************************/
    async fn_confirmPrintTrue() {
      this.ds_searchReportList = [];

      if (this.radProcessGb == "3") {
        //변경

        this.ds_searchParam = {}; //초기화

        let edtAcno2 = this.edtAcno2;
        this.ds_searchParam.tacno = this.edtAcno2.substr(0, 7);
        this.ds_searchParam.bnkbSeq = this.edtAcno2.substr(7, 3);
        this.ds_searchParam.ctno = Number(this.cboAccSeq);

        this.ds_searchParam.accSeq = this.cboAccSeq;
        this.ds_searchParam.csId = this.dsDetail[0]["csId"];

        this.ds_searchParam.procType =
          thisWin.fn_getNumber(this.radProcessGb) - 1 + "";
        this.ds_searchParam.chngSeq = this.cboChngSeq;

        // 전자서명 동의서
        this.ds_searchReport.reportMrdNm = "TS000001";
        this.ds_searchReport.reportUrl = "reportTFAC20000";
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam);
        this.ds_searchReportList.push(
          JSON.parse(JSON.stringify(this.ds_searchReport))
        );

        //신탁계약변경신청서
        this.ds_searchReport.reportMrdNm = "TS000055";
        this.ds_searchReport.reportUrl = "reportTFAC20000C";
        this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam);
        this.ds_searchReportList.push(
          JSON.parse(JSON.stringify(this.ds_searchReport))
        );
      
        this.fn_initReport(); //리포트 데이터 조회
        this.fn_searchReport(); //리포트 데이터 셋팅
      } else {
        this.fn_movePage_MSPTS207M();
      }
    },

    /***************************************************************************************
        리포트 데이터 조회
    *****************************************************************************************/
    fn_initReport() {
      this.ds_resultReportList = [];
      for (let i = 0; i < this.ds_searchReportList.length; i++) {
        let report = { formId: this.ds_searchReportList[i].reportMrdNm };
        report.params = this.ds_searchReportList[i];
        this.ds_resultReportList.push(JSON.parse(JSON.stringify(report)));
      }
    },

    /***************************************************************************************
        리포트 데이터 셋팅
    *****************************************************************************************/
    async fn_searchReport() {
      let isProcess = this.getStore("tsStore").getters.getState.isProcess;
      let isLast = this.$bizUtil.tsUtils.getIsLastProc(this);
      if (isProcess) {
        if (this.radProcessGb == "3") {
          TSInfoUtil.commReportInfo(this, this.ds_resultReportList);
        } else {
          if (!isLast) {
            TSInfoUtil.commReportInfo(
              this,
              this.ds_resultReportList,
              this.fn_movePage_MSPTS207M,
              false
            );
          } else {
            TSInfoUtil.commReportInfo(this, this.ds_resultReportList);
          }
        }
      } else {
        // 프로세스 진행 중이 아닐 경우
        TSInfoUtil.commReportInfo(this, this.ds_resultReportList);
      }
    },
    fn_movePage_MSPTS207M() {
      //routeParams : tacno, bnkbSeq, accSeq
      let tacno = this.routeParams.tacno;
      let bnkbSeq = this.routeParams.bnkbSeq;
      let accSeq = this.routeParams.accSeq;
      let pParam9 = { tacno: tacno, bnkbSeq: bnkbSeq, accSeq: accSeq };
      if (this.routeId == "MSPTS209M") {
        this.$router.push({ name: "MSPTS209M", params: pParam9 });
      }
      if (this.routeId == "MSPTS210M") {
        pParam9 = { tab1Tacno: tacno + bnkbSeq, accSeq: accSeq }; //MSPTS211M
        //this.$router.push({ name: 'MSPTS211M',"params":pParam9 });
        this.$router.push({ name: "MSPTS210M", params: pParam9 });
      }
    },
    async fn_emno() {
      let pParam = thisWin.fn_getAcnoCondInfo();
      console.log(
        "pParam................................" + JSON.stringify(pParam)
      );
      if (this.radProcessGb == "1") {
        let res = await TSServiceUtil.gfn_getUrlData(
          this,
          "F10600079",
          "S",
          pParam
        );
        let vDsDetail = TSCommUtil.gfn_getArrFrBase(dsDetail_b, [
          res.data.tfac20000[0],
        ]);
        let vDsList01 = TSCommUtil.gfn_getArrFrBase(
          dsList01_b,
          res.data.tftbni
        );
        let vDsList02 = TSCommUtil.gfn_getArrFrBase(
          dsList02_b,
          res.data.tftoil
        );
        let vDsList03 = TSCommUtil.gfn_getArrFrBase(
          dsList03_b,
          res.data.tftivd
        );
        let vDsList04 = TSCommUtil.gfn_getArrFrBase(
          dsList04_b,
          res.data.tftred
        );
        let vDsList05 = TSCommUtil.gfn_getArrFrBase(
          dsList05_b,
          res.data.tftmbd
        );
        let vDsList06 = TSCommUtil.gfn_getArrFrBase(
          dsList06_b,
          res.data.tftrfi
        );
        let vDsList07 = TSCommUtil.gfn_getArrFrBase(
          dsList07_b,
          res.data.tftapl
        );
        let vDsListIsa = TSCommUtil.gfn_getArrFrBase(
          dsListIsa_b,
          res.data.tfaill
        );
        let vDsLoan = TSCommUtil.gfn_getMapFrBase(
          dsDetail_b,
          res.data.tfac20000[1]
        );
        console.log(
          "vDsDetail..........................." + JSON.stringify(vDsDetail)
        );
        console.log(
          "vDsList01..........................." + JSON.stringify(vDsList01)
        );
        console.log(
          "vDsList02..........................." + JSON.stringify(vDsList02)
        );
        console.log(
          "vDsList03..........................." + JSON.stringify(vDsList03)
        );
        console.log(
          "vDsList04..........................." + JSON.stringify(vDsList04)
        );
        console.log(
          "vDsList05..........................." + JSON.stringify(vDsList05)
        );
        console.log(
          "vDsList06..........................." + JSON.stringify(vDsList06)
        );
        console.log(
          "vDsList07..........................." + JSON.stringify(vDsList07)
        );
        console.log(
          "vDsListIsa..........................." + JSON.stringify(vDsListIsa)
        );
        console.log(
          "vDsLoan..........................." + JSON.stringify(vDsLoan)
        );
      } else {
        let res = await TSServiceUtil.gfn_getUrlData(
          this,
          "F10600082",
          "S",
          pParam
        );
        let vDsDetail = [TSCommUtil.gfn_getMapFrBase(dsDetail_b, res.data)];
        let vDsList01 = TSCommUtil.gfn_getArrFrBase(
          dsList01_b,
          res.data.tfabni
        );
        let vDsList02 = TSCommUtil.gfn_getArrFrBase(
          dsList02_b,
          res.data.tfaoil
        );
        let vDsList03 = TSCommUtil.gfn_getArrFrBase(
          dsList03_b,
          res.data.tfaivd
        );
        let vDsList04 = TSCommUtil.gfn_getArrFrBase(
          dsList04_b,
          res.data.tfared
        );
        let vDsList05 = TSCommUtil.gfn_getArrFrBase(
          dsList05_b,
          res.data.tfambd
        );
        let vDsList06 = TSCommUtil.gfn_getArrFrBase(
          dsList06_b,
          res.data.tfarfi
        );
        let vDsListIsa = TSCommUtil.gfn_getArrFrBase(
          dsListIsa_b,
          res.data.tfaill
        );
        console.log(
          "vDsDetail..........................." + JSON.stringify(vDsDetail)
        );
        console.log(
          "vDsList01..........................." + JSON.stringify(vDsList01)
        );
        console.log(
          "vDsList02..........................." + JSON.stringify(vDsList02)
        );
        console.log(
          "vDsList03..........................." + JSON.stringify(vDsList03)
        );
        console.log(
          "vDsList04..........................." + JSON.stringify(vDsList04)
        );
        console.log(
          "vDsList05..........................." + JSON.stringify(vDsList05)
        );
        console.log(
          "vDsList06..........................." + JSON.stringify(vDsList06)
        );
        console.log(
          "vDsListIsa..........................." + JSON.stringify(vDsListIsa)
        );
      }
      console.log("end...ddd.........................");
    },
    async fn_realAlert() {
      thisWin.fn_alert("real time test.....", null, null, true);
      let rtn = "";
      rtn = await this.fn_wait();
      console.log("rtn......................................." + rtn);
      return rtn;
    },
  },
};
</script>
