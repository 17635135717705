/*
 * 업무구분: 신탁>고객/상품>상품자료>판매자료조회>신탁상품 요약정보_P
 * 화 면 명: MSPTS112P
 * 화면설명: 신탁상품 요약정보 POPUP
 * 작 성 일: 2023.05.03
 * 작 성 자: 송진의
 */
<template>
  <!-- popup -->
  <mo-modal class="fts-modal large" ref="modal" title="신탁상품 요약정보" style="z-index:2999;" >
    <template>
      <div class="wrap-modalcontents">
        <div class="input-top">
          <div class="wrap-input row">
            <label>상품코드</label>            
            <mo-text-field 
              class="w130" 
              v-model="lv_gd_cd.value" 
              disabled />            
            <mo-text-field 
              class="input-long" 
              v-model="lv_gd_nm.value" 
              disabled />
          </div>
        </div>

        <div class="wrap-table-title">
          <h2 class="table-title"> 기본정보 </h2>
        </div>
        <div class="wrap-table mt-3">
          <table class="table col-type">            
            <tbody>
              <tr>
                <th class="w130">
                  <span> 상품명 </span>
                </th>
                <td class="w260">
                  <div class="wrap-input">
                    <mo-text-field class="input-long" v-model="lv_ed_gd_nm" disabled />
                  </div>
                </td>
                <th rowspan="3" class="w100">
                  <span> 중도해지<br>수수료율 </span>
                </th>
                <td>
                  <!-- 중도해지 수수료율1 -->
                  <div class="wrap-input row">
                    1. <mo-text-field class="w50 input-text-right" v-model="lv_msk_mdtm_end_dds01" disabled /> 일 미만 해지시
                    <mo-text-field class="w50 input-text-right" v-model="lv_msk_mdtm_fert01" disabled /> (%)
                  </div>
                </td>
              </tr>
              <tr>
                <th rowspan="2">
                  <!-- 만기일/운용기간 선택 -->
                  <span><mo-dropdown :items="cmb_mtrt_dt_appn_tp" v-model="lv_cob_mtrt_dt_appn_tp" disabled ref="dropdown1" /></span>
                </th>
                <td>
                  <!-- 만기일 선택 시 -->
                  <div class="wrap-input row">
                    <mo-date-picker class="input-long" v-model="lv_cal_mtrt_dt" :bottom="false" disabled />                    
                  </div>
                </td>
                <td>
                  <!-- 중도해지 수수료율2 -->
                  <div class="wrap-input row">
                    2. <mo-text-field class="w50 input-text-right" v-model="lv_msk_mdtm_end_dds02" disabled /> 일 미만 해지시  
                    <mo-text-field class="w50 input-text-right" v-model="lv_msk_mdtm_fert02" disabled /> (%)
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- 운용기간 선택 시 -->
                  <div class="wrap-input row">
                    <mo-text-field class="input-long input-text-right" v-model="lv_ed_appn_trm" disabled/>
                    <mo-dropdown :items="cmb_appn_trm_unit_cd" v-model="lv_cob_appn_trm_unit_cd" disabled ref="dropdown2" />                    
                  </div>
                </td>
                <td>
                  <!-- 중도해지 수수료율3 -->
                  <div class="wrap-input row">
                    3. <mo-text-field class="w50 input-text-right" v-model="lv_msk_mdtm_end_dds03" disabled /> 일 미만 해지시  
                    <mo-text-field class="w50 input-text-right" v-model="lv_msk_mdtm_fert03" disabled /> (%)
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 보수구분 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                  <mo-dropdown :items="cmb_poao_tc" v-model="lv_cob_poao_tc" disabled ref="dropdown3" />
                  <mo-checkbox v-model="lv_chk_rufe_yn" class="row" disabled> 성과보수 </mo-checkbox>
                  </div>
                </td>
                <!-- <td colspan="2"></td> -->                
              </tr>
              <tr>
                <th>
                  <span> (후취)기본보수율 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long input-text-right" v-model="lv_ed_bas_tfrt" disabled />
                    <span>%</span>
                    만기보수율
                    <mo-text-field class="input-long input-text-right" v-model="lv_ed_mtrt_af_tfrt" disabled />
                    <span>%</span>
                  </div>
                </td>
                <th>
                  <span> 지급스케쥴 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown :items="cmb_fxpp_yn" v-model="lv_cob_fxpp_yn2" disabled ref="dropdown4" />                    
                  </div>
                </td>                                
              </tr>
              <!-- 별납 선택 시 -->
              <tr v-if="lv_cob_poao_tc==='1'">
                <th>
                  <span> 별납보수구분 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown :items="cmb_epay_poao_tc" v-model="lv_cob_epay_poao_tc" disabled ref="dropdown5" />
                    주기
                    <mo-dropdown :items="cmb_epay_cycl_tc" v-model="lv_cob_epay_cycl_tc" disabled ref="dropdown6" />
                  </div>

                </td>
                <th rowspan="3">
                  <span> 특약사항 </span>
                </th>
                <td rowspan="3">
                  <div class="wrap-input row">
                    <mo-text-area v-model="lv_txt_scon_mtr" rows="4" class="w100p" disabled />
                  </div>
                </td>              
              </tr>
              <tr v-if="lv_cob_poao_tc==='1'">
                <th>
                  <span> 정률정액구분 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown :items="cmb_epay_fxrt_famt_tc" v-model="lv_cob_epay_fxrt_famt_tc" disabled ref="dropdown7" />
                    <!-- 정률(1) / 정액(2) 선택 시 -->
                    {{lv_cob_epay_fxrt_famt_tc === '1' ? '보수률' : '금액'}}
                    <mo-text-field class="input-long input-text-right" v-model="lv_ed_epay_tfrt" v-if="lv_cob_epay_fxrt_famt_tc === '1'" disabled />
                    <mo-text-field class="input-long input-text-right" v-model="lv_ed_epay_fee_amt" v-if="lv_cob_epay_fxrt_famt_tc === '2'" disabled />                    
                  </div>
                </td>                
              </tr>                           
              <!-- 후취 선택 시 -->
              <tr v-if="(lv_cob_poao_tc=='2' || lv_cob_poao_tc=='3')">
                <th>
                  <span> 정기보수여부 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown :items="cmb_yn" v-model="lv_cob_aocp_rgfe_yn" disabled ref="dropdown8" />
                    <!-- 예(Y) / 아니오(N) 선택 시 -->
                    <span v-if="lv_cob_aocp_rgfe_yn === 'Y'"> 주기 </span>
                    <mo-dropdown :items="cmb_aocp_rgfe_cycl_tc" v-model="lv_cob_aocp_rgfe_cycl_tc" v-if="lv_cob_aocp_rgfe_yn ==='Y'" disabled ref="dropdown9" />
                  </div>
                </td>
                <th rowspan="3">
                  <span> 특약사항 </span>
                </th>
                <td rowspan="3">
                  <div class="wrap-input row">
                    <mo-text-area v-model="lv_txt_scon_mtr" rows="4" class="w100p" disabled />
                  </div>
                </td>
              </tr>
              <tr v-if="lv_cob_poao_tc==='2'">  
                <td></td>              
              </tr>
              <tr v-if="lv_cob_poao_tc==='3'">
                <th>
                  <span> 선취수수료율 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long input-text-right" v-model="lv_ed_prfe_rt" disabled />
                    <span>%</span>
                    선취수수료
                    <mo-text-field class="input-long input-text-right" v-model="lv_ed_prfe" disabled />
                    <!-- <span>%</span> -->
                  </div>
                </td>                
              </tr> 
              <tr>
                <th>
                  <span> 상품등급 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown :items="cmb_iv_rsk_rating" v-model="lv_cob_iv_rsk_rating" disabled ref="dropdown10" />
                  </div>
                </td>                
              </tr>              
            </tbody>
          </table>
        </div>

        <div class="wrap-table-title row">
          <div class="row">
            <h2 class="table-title"> 운용지시등록 </h2>
            <mo-checkbox v-model="lv_chk_blkt_appn_indc_yn" class="row" disabled> 포괄운용지시 </mo-checkbox>
          </div>
        </div>
        <div class="wrap-table">
          <table class="table row-type">            
            <thead>
              <tr>
                <th class="w30"> </th>
                <th> 운용방법 </th>
                <th colspan="3"> 운용비율 </th>
                <th colspan="2"> KR코드 </th>
                <th> 운용지시내역 </th>
              </tr>
            </thead>
            <tbody v-for="(row, idx) in lv_tfcpil_lst" :key="idx">
              <tr>
                <td> {{row.appnMethC}} </td>
                <td class="aL"> {{row.appnMethCNm}} </td>
                <td> {{row.appnStrtRto}} </td>
                <td class="w20"> ~ </td>
                <td> {{row.appnEndRto}} </td>
                <td> {{row.krItemC}} </td>
                <td> {{row.krItemNm}} </td>
                <td class="aL"> {{row.opdtExpl}} </td>
              </tr>              
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_Close">닫기</mo-button>
      </div>
    </template>
  </mo-modal>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'  // Alert 팝업 (공통)

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPTS112P",
  screenId: "MSPTS112P",
  components: {
    'ts-alert-popup': TSAlertPopup,
  },
  props: {
    popupObj: {type:Object, default:null},
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {},
  mounted() {
    this.$bizUtil.insSrnLog("MSPTS112P");
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // pub.
      valueSlt1: '1',
      valueSlt2: '1',
      valueSlt3: '1',
      valueSlt4: '1',
      valueSlt5: '1',
      valueSlt6: '1',
      pay: false,
      indicate: false,

      // real.
      // 조회
      lv_cob_trst_typ_cd: '',        // 신탁유형
      lv_cob_prdt_typ_cd: {},        // 상품유형
      lv_gd_cd: {},                  // 상품코드
      lv_gd_nm: {},                  // 상품명

      //공통코드
      cmb_trst_typ_cd: [],          // 신탁유형
      cmb_prdt_typ_cd_all: [],      // 상품유형 전체
      cmb_prdt_typ_cd: [],          // 상품유형
      cmb_poao_tc: [],              // 선후취구분코드 (5017)
      cmb_fxpp_yn: [],              // 지급스케쥴 (5101)      
      cmb_iv_rsk_rating: [],        // 상품등급 (5071)
      cmb_appn_trm_unit_cd: [],     // 운용기간단위코드 (5062)  
      cmb_epay_cycl_tc: [],         // 별납주기 (5099)
      cmb_aocp_rgfe_cycl_tc: [],    // 정기보수주기 (5099) 
      cmb_epay_fxrt_famt_tc: [],    // 정률정액구분 ()
      cmb_epay_poao_tc: [],         // 별납보수구분      

      cmb_gd_typ_dtl_cd: [],        // 상품유형상세코드  
      cmb_fss_gd_clas_cd: [],       // 금융감독원상품분류코드 
      cmb_gd_typ_dtl_cd_all: [],    
      cmb_fss_gd_clas_cd_all: [],
      // cmb_mtrt_dt_appn_tp: [],      // 만기일, 운용기간
      
      //기본정보
      lv_ed_gd_nm: '',              // gdNm - 상품명
      lv_cob_mtrt_dt_appn_tp: '',   // 만기일자, 운용기간유형
      lv_cal_mtrt_dt: '',           // mtrtDate        - 만기일자
      lv_msk_mdtm_end_dds01: '',    // mdtmEndDds01    - 중도해지종료일수01
      lv_msk_mdtm_end_dds02: '',    // mdtmEndDds02    - 중도해지종료일수02
      lv_msk_mdtm_end_dds03: '',    // mdtmEndDds03    - 중도해지종료일수03
      lv_msk_mdtm_fert01: '',       // mdtmFert01      - 중도해지수수료율01
      lv_msk_mdtm_fert02: '',       // mdtmFert02      - 중도해지수수료율02
      lv_msk_mdtm_fert03: '',       // mdtmFert03      - 중도해지수수료율03
      lv_ed_appn_trm: '',           // appnTrm         - 운용기간
      lv_cob_appn_trm_unit_cd: '',  // appnTrmUnitC    - 운용기간단위코드
      lv_cob_poao_tc: '',           // poaoTc          - 선취구분코드      
      lv_chk_rufe_yn: '',           // rufeYn          - 성과보수여부
      lv_ed_bas_tfrt: '',           // basTfrt         - 기본보수율
      lv_ed_mtrt_af_tfrt: '',       // mtrtAfTfrt      - 만기보수율
      // 정기 또는 별납주기 edit 창 구분 확인
      lv_cob_fxpp_yn: '',           // fxppYn          - 정기지급여부 
      lv_cob_fxpp_yn2: '',          // fxppYn2         - ds_cmb5101
      lv_ed_aocp_rgfe: '',          // aocpRgfe        - 후취정기보수
      lv_cob_aocp_rgfe_yn: '',      // aocpRgfeYn      - 후취정기보수여부
      lv_cob_aocp_rgfe_cycl_tc: '', // aocpRgfeCyclTc  - 후취정기보수주기구분코드            
      // 정률정액구분
      lv_ed_prfe_rt: '',            // prfeRt          - 선취수수료율
      lv_ed_prfe: '',               // prfe            - 선취수수료

      // 별납시
      lv_cob_epay_poao_tc: '',      // epayPoaoTc      - 별납보수구분
      lv_cob_epay_cycl_tc: '',      // epayCyclTc      - 별납주기구분코드 (5099)
      lv_cob_epay_fxrt_famt_tc: '', // epayFxrtFamtTc  - 정률정액구분 (5019)     
      lv_ed_epay_tfrt: '',          // epayTfrt        - 별납보수율
      lv_ed_epay_fee_amt: '',       // epayFeeAmt      - 별납보수금액

      // 상품코드            
      lv_cob_iv_rsk_rating: '',     // ivRskRatingC    - 투자위험등급코드

      lv_chk_blkt_appn_indc_yn: '', // blktAppnIndcYn  - 포괄운용지시여부

      // 특약사항
      lv_txt_scon_mtr: '',          // sconMtr         - 특약사항

      lv_tfcprb_info: {},           // 신탁상세조회목록 - 기본정보
      lv_tfcprb_lst: [],            // 신탁상세조회목록 - 기본정보
      lv_tfcpil_lst: [],            // 신탁상세조회목록 - 운용지시등록
      lv_tfcpfi_lst: [],            // 신탁상세조회목록
      lv_tfcppi_lst: [],            // 신탁상세조회목록

      // Contents Model 
      eaiCommObj: TSCommUtil.gfn_eaiCommObj(),
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    cmb_mtrt_dt_appn_tp() {
      let rtn = []
      rtn.push({value: '1', text: '만기일'})
      rtn.push({value: '2', text: '운용기간'})
      return rtn
    },
    cmb_yn() {
      let rtn = []
      rtn.push({value: 'N', text: '아니오'})
      rtn.push({value: 'Y', text: '예'})
      return rtn
    },
    itemsSlt2() {
      let rtn = [];
      rtn.push({value: '1', text: '년'});
      rtn.push({value: '2', text: '월'});
      return rtn;
    },
    itemsSlt3() {
      let rtn = [];
      rtn.push({value: '1', text: '후취'});
      rtn.push({value: '2', text: '선취'});
      return rtn;
    },
    itemsSlt4() {
      let rtn = [];
      rtn.push({value: '1', text: '아니오'});
      rtn.push({value: '2', text: '예'});
      return rtn;
    },
    
    itemsSlt6() {
      let rtn = [];
      rtn.push({value: '1', text: '투자위험1등급'});
      rtn.push({value: '2', text: '투자위험3등급'});
      return rtn;
    },
    mtrtDate() { // 만기일
      return TSCommUtil.gfn_dateReplace(1, this.lv_tfcprb_info.mtrtDate)
    },
    // modal
    modal() {
      return this.$refs.modal
    },
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_Open() {
      this.fn_SetCommCode()
      this.fn_Init()
      this.modal.open()
    },
    fn_Close() {
      this.modal.close()
      this.closeDropdown()
    },
    closeDropdown() {
      this.$refs.dropdown1.close()
      this.$refs.dropdown2.close()
      this.$refs.dropdown3.close()
      this.$refs.dropdown4.close()
      if (this.$refs.dropdown5) this.$refs.dropdown5.close()
      if (this.$refs.dropdown6) this.$refs.dropdown6.close()
      if (this.$refs.dropdown7) this.$refs.dropdown7.close()
      if (this.$refs.dropdown8) this.$refs.dropdown8.close()
      if (this.$refs.dropdown9) this.$refs.dropdown9.close()
      this.$refs.dropdown10.close()
    },
    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/      
    fn_AlertPopup (type, pPopupObj) {
      switch (type) {
        case 0:
          if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
            this.pAlertPopupObj.content = pPopupObj.content

            if( pPopupObj.confirm ) {
              this.pAlertPopupObj.confirm = pPopupObj.confirm
              this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
            }
          }
          break;
      }

      this.$refs.alertPopup.fn_Open()
    },
    /******************************************************************************
     * Function명 : fn_SetCommCode, fn_CommCodeCallBack
     * 설명       : 공통코드 세팅
     ******************************************************************************/
    fn_SetCommCode() {
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.trnstId = 'txTSSTS90S1' // selectTFCP11000A
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600046_S'        
      this.eaiCommObj.params = {'TUKEMK':[
                                  {cId:"5014"},   // 신탁유형
                                  {cId:"5061"},   // 일반맞춤구분
                                  {cId:"5101"},   // 판매허용여부
                                  {cId:"5015"},   // 판매대상구분
                                  {cId:"5056"},   // 과세구분
                                  {cId:"502902"}, // 해지구분이 일부해지
                                  {cId:"5055"},   // 지급일구분코드
                                  {cId:"5035"},   // 지급주기구분코드
                                  {cId:"5017"},   // 선후취구분코드 (cmb_poao_tc)
                                  {cId:"5071"},   // 투자위험등급코드 (cmb_iv_rsk_rating)
                                  {cId:"5018"},   // 별납선후취구분코드 (cmb_epay_poao_tc)
                                  {cId:"5099"},   // 별납주기구분코드 (cmb_epay_cycl_tc, cmb_aocp_rgfe_cycl_tc)
                                  {cId:"5019"},   // 후취정기보수정액구분코드 (cmb_epay_fxrt_famt_tc)
                                  {cId:"5020"},   // 승인요청구분코드
                                  {cId:"5021"},   // 수익보수기준코드
                                  {cId:"0036"},   // 여부
                                  {cId:"5062"},   // 운용기간단위코드                                  
                                  {cId:"0029"},   // 통화코드
                                  // *** 신탁유형코드 ****
                                  {cId:"5010"},   // *금전신탁일때 - 신탁상세유형 : 금전신탁(001),재산신탁(002)
                                  {cId:"5011"},   // 유가증권신탁유형코드
                                  {cId:"5012"},   // 부동산신탁유형코드
                                  {cId:"5013"},   // 금전채권신탁유형코드
                                  {cId:"5139"},   // 종합재산 신탁유형코드
                                  // *** 금융감독원상품분류코드 ****
                                  {cId:"515202"}, // 금전신탁금융감독원코드 파생상품
                                  {cId:"5152"},   // 금전신탁금융감독원코드
                                  {cId:"5153"},   // 유가증권신탁금융감독원코드
                                  {cId:"5154"},   // 부동산신탁금융감독원코드
                                  {cId:"5155"},   // 금전채권신탁금융감독원코드                                                                 
                              ]}
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CommCodeCallBack, this.fn_error)
    },
    fn_CommCodeCallBack ( res ) {
      // 초기 콤보박스 설정      
      let tmpKey = ''
      let tmpType = ''

      this.cmb_trst_typ_cd.push({value: '' , text: ''})
      this.cmb_poao_tc.push({value: '' , text: ''})
      this.cmb_iv_rsk_rating.push({value: '' , text: '- 해당없음 -'})
      this.cmb_appn_trm_unit_cd.push({value: '' , text: ''})
      this.cmb_aocp_rgfe_cycl_tc.push({value: '' , text: ''})

      for ( let index in res.data.tukemk ) {
        let item = res.data.tukemk[index]
        switch (item.cid) 
        {
          case "5014": 
            this.cmb_trst_typ_cd.push({value: item.c , text: item.cnm})
            break
          case "5017":           
            this.cmb_poao_tc.push({value: item.c , text: item.cnm})
            break          
          case "5101":            
            this.cmb_fxpp_yn.push({value: item.c , text: item.cnm})
            break      
          case "5071":            
            this.cmb_iv_rsk_rating.push({value: item.c , text: item.cnm})
            break     
          case "5062":            
            this.cmb_appn_trm_unit_cd.push({value: item.c , text: item.cnm})
            break
          case "5099":
            this.cmb_aocp_rgfe_cycl_tc.push({value: item.c , text: item.cnm})
            this.cmb_epay_cycl_tc.push({value: item.c , text: item.cnm})
            break
          case "5018":
            this.cmb_epay_poao_tc.push({value: item.c , text: item.cnm})
            break
          case "5019":
            this.cmb_epay_fxrt_famt_tc.push({value: item.c , text: item.cnm})
            break
          case "5010":
            tmpKey = '001'
            tmpType = 'trst'
            break
          case "5011":
            tmpKey = '002'
            tmpType = 'trst'
            break
          case "5012":
            tmpKey = '003'
            tmpType = 'trst'
            break
          case "5013":
            tmpKey = '004'
            tmpType = 'trst'
            break
          case "5139":
            tmpKey = '005'
            tmpType = 'trst'            
            break
          case "515202":
            tmpKey = '001A'
            tmpType = 'fss'            
            break
          case "5152":
            tmpKey = '001B'
            tmpType = 'fss'
            break
          case "5153":
            tmpKey = '002'
            tmpType = 'fss'
            break
          case "5154":
            tmpKey = '003'
            tmpType = 'fss'
            break
          case "5155":
            tmpKey = '004'
            tmpType = 'fss'
            break          
        }
        
        if(tmpType === 'trst') {
          this.cmb_gd_typ_dtl_cd_all.push({key: tmpKey, value: item.c , text: item.cnm})          
        }

        if(tmpType === 'fss') {
          this.cmb_fss_gd_clas_cd_all.push({key: tmpKey, id: item.cid, value: item.c, text: item.cnm})
        }
      }

      // 정렬      
      this.cmb_gd_typ_dtl_cd_all  = _.orderBy(this.cmb_gd_typ_dtl_cd_all, ['key'], ['asc'])
      this.cmb_fss_gd_clas_cd_all = _.orderBy(this.cmb_fss_gd_clas_cd_all, ['key'], ['asc'])

    },
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     ******************************************************************************/
    fn_Init() {
      console.log('[MSPTS112P] fn_Init...')

      this.lv_cob_trst_typ_cd          = ''                                                      // 신탁유형
      this.lv_cob_prdt_typ_cd          = TSCommUtil.gfn_dataSet(1, '', true, '', 'prdt_typ_cd')  // 상품코드 (콤보박스 선택 시)            
      this.lv_gd_cd                    = TSCommUtil.gfn_dataSet(1, '', false, '', 'gd_cd')       // 상품코드
      this.lv_gd_nm                    = TSCommUtil.gfn_dataSet(1, '', false, '', 'gd_nm')       // 상품명      

      this.fn_InitData()

      this.$nextTick(() => {
        console.log('fn_Init - popupObj > ', this.popupObj)
        if (!TSCommUtil.gfn_isNull(this.popupObj.gdC) || !TSCommUtil.gfn_isNull(this.popupObj.gdNm)) {
          this.lv_gd_cd.value     = this.popupObj.gdC
          this.lv_gd_nm.value     = this.popupObj.gdNm          
          this.fn_GdDtlSearch()
        }
      })      
    },
    
    /******************************************************************************
     * Function명 : fn_InitData
     * 설명       : 조회 전 데이터 초기화
     * 파라미터   : init - 초기화 시 / search - 조회 시
     ******************************************************************************/
    fn_InitData(type = 'init') {
      // 바디 검색결과
      this.lv_tfcprb_info = {}                    // 신탁상세조회목록 - 기본정보
      // this.lv_tfcprb_lst  = []                 // 신탁상세조회목록 - 기본정보
      this.lv_tfcpil_lst  = []                    // 신탁상세조회목록 - 운용지시등록
      this.lv_tfcpfi_lst  = []                    // 신탁상세조회목록
      this.lv_tfcppi_lst  = []                    // 신탁상세조회목록      
    },
    /******************************************************************************
     * Function명 : fn_SetParam
     * 설명       : 파라미터 설정
     ******************************************************************************/
    fn_SetParam() {
      let param = {}      
      const tfcprb = {
        gdC: this.lv_gd_cd.value,        
      }
      const tfcpil = {
        gdC: this.lv_gd_cd.value,        
      }
      const tfcpfi = {
        gdC: this.lv_gd_cd.value
      }
      const tfcppi = {
        gdC: this.lv_gd_cd.value
      }
      let tfcprbLst = []
      let tfcpilLst = []
      let tfcpfiLst = []
      let tfcppiLst = []

      if (!TSCommUtil.gfn_isNull(this.lv_gd_cd.value)) {
        tfcprbLst.push(tfcprb)
        tfcpilLst.push(tfcpil)
        tfcpfiLst.push(tfcpfi)
        tfcppiLst.push(tfcppi)
      }

      param.tfcprb = tfcprbLst
      param.tfcpil = tfcpilLst
      param.tfcpfi = tfcpfiLst
      param.tfcppi = tfcppiLst

      return param
    },
    /******************************************************************************
     * Function명 : fn_GdDtlSearch
     * 설명       : 상품상세조회 (selectTFCP15000)
     ******************************************************************************/
    fn_GdDtlSearch(state = '') {
      console.log('fn_GdDtlSearch.....')
      
      this.fn_InitData()
      
      // 서비스 호출
      this.eaiCommObj.lv_vm = this      
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600230_S'          
      this.eaiCommObj.params = this.fn_SetParam()
      console.log('fn_GdDtlSearch - eaiCommObj >',  this.eaiCommObj)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CallBack, this.fn_error)      
    },
    /******************************************************************************
     * Function명 : fn_CallBack
     * 설명       : 조회 시 호출 결과
     ******************************************************************************/
    fn_CallBack(pResultData) {      
      
      // 오류 확인
      let t_data = pResultData.data
      let tmpDsList = {}   // tfcprb - 기본정보      
      let tmpGridList = {} // tfcpil - 운용지시등록
      let tmpDsList01 = {} // tfcppi - N/A

      if (t_data !== null && t_data !== '') {
        if (TSCommUtil.gfn_trim(t_data.errorCode) !== '') {
          return false
        }

        if(!TSCommUtil.gfn_isNull(t_data.tfcpil) && t_data.tfcpil.length > 0){
          this.lv_tfcpil_lst = t_data.tfcpil
        }

        if(!TSCommUtil.gfn_isNull(t_data.tfcpfi) && t_data.tfcpfi.length > 0){
          this.lv_tfcpfi_lst = t_data.tfcpfi
        }

        // 기본정보 
        if(!TSCommUtil.gfn_isNull(t_data.tfcprb) && t_data.tfcprb.length > 0){
          //this.lv_tfcprb_info = t_data.tfcprb[0] 
          tmpDsList = t_data.tfcprb[0]
          
          // 데이터 설정
          this.fn_SetData('tfcprb',tmpDsList)

          // 상품코드 설정
          this.fn_SetTrstTypCd(tmpDsList.trstTypC, tmpDsList.fssGdClasC) 

          // 상품성향 설정
          if(TSCommUtil.gfn_isNull(tmpDsList.ivRskRatingC)) {
            this.lv_cob_iv_rsk_rating = ''
          }

          // 만기일 OR 운영기간 설정
          if(!TSCommUtil.gfn_isNull(tmpDsList.appnTrm) && !TSCommUtil.gfn_isNull(tmpDsList.appnTrmUnitC)){
            this.lv_cob_mtrt_dt_appn_tp = '2'
          } else {
            this.lv_cob_mtrt_dt_appn_tp = '1'
          }

          if(!TSCommUtil.gfn_isNull(t_data.tfcppi) && t_data.tfcppi.length > 0){
            tmpDsList01 = t_data.tfcppi
            this.lv_cob_fxpp_yn2 = 'Y' // 정기지급여부 (fxppYn)
          } else {
            this.lv_cob_fxpp_yn2 = 'N'
          }
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_SetData
     * 설명       : 조회 후 데이터 설정
     * 파라미터    : type - 데이터 목록 구분 , data - 데이터 목록
     ******************************************************************************/
    fn_SetData(type, data) {
      switch(type){
        case 'tfcprb': // 기본정보        
          this.lv_cob_trst_typ_cd = TSCommUtil.gfn_trim(data.trstTypC)
          this.lv_ed_gd_nm        = TSCommUtil.gfn_trim(data.gdNm)     
          this.lv_cal_mtrt_dt     = TSCommUtil.gfn_dateReplace(1, TSCommUtil.gfn_trim(data.mtrtDate)) 
          this.lv_ed_appn_trm     = TSCommUtil.gfn_trim(data.appnTrm) === '0' || TSCommUtil.gfn_trim(data.appnTrm) === 0 ? '' : TSCommUtil.gfn_trim(data.appnTrm)
          this.lv_cob_appn_trm_unit_cd = TSCommUtil.gfn_trim(data.appnTrmUnitC)                
          this.lv_msk_mdtm_end_dds01 = TSCommUtil.gfn_trim(data.mdtmEndDds01)
          this.lv_msk_mdtm_end_dds02 = TSCommUtil.gfn_trim(data.mdtmEndDds02)
          this.lv_msk_mdtm_end_dds03 = TSCommUtil.gfn_trim(data.mdtmEndDds03)
          this.lv_msk_mdtm_fert01 = TSCommUtil.gfn_trim(data.mdtmFert01)
          this.lv_msk_mdtm_fert02 = TSCommUtil.gfn_trim(data.mdtmFert02)
          this.lv_msk_mdtm_fert03 = TSCommUtil.gfn_trim(data.mdtmFert03)
          this.lv_cob_poao_tc     = TSCommUtil.gfn_trim(data.poaoTc)
          this.lv_chk_rufe_yn     = TSCommUtil.gfn_trim(data.rufeYn) === 'Y' ? true : false
          this.lv_ed_bas_tfrt     = TSCommUtil.gfn_trim(data.basTfrt)
          this.lv_ed_mtrt_af_tfrt = TSCommUtil.gfn_trim(data.mtrtAfTfrt)
          this.lv_cob_fxpp_yn     = TSCommUtil.gfn_trim(data.fxppYn)
          this.lv_cob_fxpp_yn2    = TSCommUtil.gfn_trim(data.fxppYn2)
          this.lv_ed_aocp_rgfe    = TSCommUtil.gfn_trim(data.aocpRgfe) 
          this.lv_cob_aocp_rgfe_yn = TSCommUtil.gfn_trim(data.aocpRgfeYn)
          this.lv_cob_aocp_rgfe_cycl_tc = TSCommUtil.gfn_trim(data.aocpRgfeCyclTc)
          this.lv_cob_epay_cycl_tc = TSCommUtil.gfn_trim(data.epayCyclTc)
          this.lv_cob_epay_poao_tc = TSCommUtil.gfn_trim(data.epayPoaoTc)
          this.lv_cob_epay_fxrt_famt_tc = TSCommUtil.gfn_trim(data.epayFxrtFamtTc)
          this.lv_ed_prfe_rt      = TSCommUtil.gfn_trim(data.prfeRt)
          this.lv_ed_prfe         = TSCommUtil.gfn_trim(data.prfe)
          this.lv_ed_epay_tfrt    = TSCommUtil.gfn_trim(data.epayTfrt)
          this.lv_ed_epay_fee_amt = !TSCommUtil.gfn_isNull(data.epayFeeAmt) ? this.$bizUtil.numberWithCommasCurr(TSCommUtil.gfn_trim(data.epayFeeAmt)) : ''
          this.lv_cob_iv_rsk_rating  = TSCommUtil.gfn_trim(data.ivRskRatingC)
          this.lv_chk_blkt_appn_indc_yn  = TSCommUtil.gfn_trim(data.blktAppnIndcYn) === 'Y' ? true : false
          this.lv_txt_scon_mtr    = TSCommUtil.gfn_trim(data.sconMtr)

          
          // 사용안함
          // this.Cob_genCumdTc     = data.genCumdTc
          // this.Edt_gdIfnm        = data.gdIfnm
          // this.Edt_gdEnnm        = data.gdEnnm
          // this.Cob_salePrmsYn    = data.salePrmsYn
          // this.Cal_saleStrtDate  = data.saleStrtDate
          // this.Cal_saleEndDate   = data.saleEndDate
          // this.Com_saleTagtTc    = data.saleTagtTc
          // this.Edt_tmtlNeedDds   = data.tmtlNeedDds
          // this.Cob_intMtrtYn     = data.intMtrtYn
          // this.Cob_fundStactYn   = data.fundStactYn
          // this.Cob_fxprTc        = data.fxppTc
          // this.cob_intPymDdTc    = data.intPymDdTc
          // this.Cob_pymCyclTc     = data.pymCyclTc
          // this.Edt_ftrmPymAmt    = data.ftrmPymAmt
          // this.Edt_lmtAmt        = data.lmtAmt
          // this.Cob_genTaxtTc     = data.genTaxtTc
          // this.Edt_lwstIamtAmt   = data.lwstIamtAmt
          // this.Edt_histIamtAmt   = data.histIamtAmt
          // this.Edt_lwstEtrtAmt   = data.lwstEtrtAmt
          // this.Edt_lwstEtrtAmt   = data.lwstEtrtAmt
          // this.Cob_dmngrAprvYn   = data.dmngrAprvYn
          // this.Cob_tddAppnYn     = data.tddAppnYn
          break
      }
    },



    /******************************************************************************
     * Function명 : fn_SetTrstTypCd
     * 설명       : 신탁유형 선택 시 이벤트     
     ******************************************************************************/
    fn_SetTrstTypCd(trstTypC, fssGdClasC) {      
      let rtn = []
      let tmpTrstCds = []
      let tmpFssCds = []
      if (!TSCommUtil.gfn_isNull(trstTypC)) {
        console.log('fn_SetTrstTypCd > ', trstTypC, fssGdClasC)
        
        rtn = []
        tmpTrstCds = this.cmb_gd_typ_dtl_cd_all.filter(v => v.key === trstTypC)
        tmpTrstCds.forEach(item => {          
          rtn.push({text: item.text, value: item.value})
        })
        this.cmb_gd_typ_dtl_cd = rtn

        rtn = []        
        tmpFssCds = this.cmb_fss_gd_clas_cd_all.filter(v => v.key === trstTypC)
        tmpFssCds.forEach(item => {
          if (item.key !== '001') {
            if (fssGdClasC === '5') {
              if (item.id === '515202') {
                rtn.push({text: item.text, value: item.value})
              }
            } else {
              if (item.id === '5152') {
                rtn.push({text: item.text, value: item.value})
              }
            }            
          } else {
            rtn.push({text: item.text, value: item.value})
          }
        })
        this.cmb_fss_gd_clas_cd = rtn
      }
    },

  },
};
</script>
<style scoped>
</style>
