/*
 * 업무구분: 신탁상품 정보조회
 * 화 면 명: MSPTS108P
 * 화면설명: 신탁상품 정보조회
 * 작 성 일: 2023.08.18
 * 작 성 자: 
 */

/*********************************************************************************
 * Vue 화면 templates 설정 영역
 *********************************************************************************/
<template>
  <mo-modal class="fts-modal large wide" ref="modal" title="신탁상품 정보조회" style="z-index:2000;" >
    <div class="wrap-modalcontents" id="dv108P">
        <div class="input-top align-end">
          <div class="left column">
            <div class="row w100p gap40">
              <div class="wrap-input row">                
                <label class="w74"> 신탁유형 </label>
                <mo-dropdown class="w200" :items="cmb_trst_typ_cd" v-model="lv_cob_trst_typ_cd" />
              </div>
              <div class="wrap-input row">
                <label> 상품코드 </label>
                <mo-text-field
                  ref="gd_cd"
                  :class="lv_gd_cd.error ? 'error' : ''"
                  v-model="lv_gd_cd.value"
                  :disabled="lv_gd_cd.disabled"
                  maxlength="6"                                    
                  type="searchable"
                  @keyup="fn_GdSearchKeyUp"
                  @click-icon="fn_OpenPopup(0)"
                />                
                <mo-text-field v-model="lv_gd_nm" disabled/>
              </div>
            </div>
            <div class="row w100p gap40">
              <div class="wrap-input row">
                <label class="w74"> 판매허용여부 </label>                
                <mo-dropdown class="fts-dropdown" :items="selectType" v-model="lv_cob_sale_prms_yn" />
              </div>
              <div class="wrap-input row">
                <label> FC권유가능여부 </label>
                <mo-dropdown class="fts-dropdown" :items="selectType" v-model="lv_cob_fc_invt_posb_yn" />
                <div class="mo-blank"> <mo-text-field disabled /> </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="wrap-button row">
              <mo-button :disabled="lv_btn_init" @click="fn_Init"> 초기화 </mo-button>
              <mo-button primary class="btn-inquiry" :disabled="lv_btn_search" @click="fn_GdSearch('S')"> 조회 </mo-button>
            </div>
          </div>
        </div>
        <div class="wrap-table-title row">
          <h2 class="table-title"> 검색결과 </h2>
        </div>
        <div class="wrap-table h-scroll-250">
          <!-- <div class="bg-white"></div> -->
          <table class="table col-type th-row-gray">
            <thead>
              <tr>
                <th> 상품코드 </th>
                <th> 상품명 </th>
                <th> 신탁유형 </th>
                <th> 상품유형 </th>
                <th> 상품등급 </th>
                <th> 보수형태 </th>
                <th> 판매여부 </th>
                <th> FC권유 </th>
                <th> 파생자격 </th>
                <th> 고객교부자료 </th>
                <th> 판매참조자료 </th>
                <th> 상품요약정보 </th>
              </tr>
            </thead>
            <tbody v-for="(row, idx) in lv_gd_search_list" :key="idx">
              <tr>
                <td> {{row.gdC}} </td>
                <td class="wsN aL"> {{row.gdNm}} </td>
                <td class="wsN"> {{row.trstTypCNm}} </td>
                <td class="wsN"> {{row.gdTypDtlCNm}} </td>
                <td> {{row.ivRskRatingCnm}} </td>
                <td class="wsN"> {{row.poaoTcNm}} </td>
                <td> {{row.salePrmsYnNm}} </td>
                <td> {{row.fcInvtPosbYn}} </td>
                <td> {{row.drvtInvtPosbYn}} </td>
                <td> <mo-button class="btn-pop-download" v-if="row.apndYn === 'Y'" @click="fn_OpenPopup(1, row, 'A')"> </mo-button> </td>
                <td> <mo-button class="btn-pop-download" v-if="row.apndYn2 === 'Y'" @click="fn_OpenPopup(1, row, 'B')"> </mo-button> </td>
                <td> <mo-button class="btn-pop-download" @click="fn_OpenPopup(2, row)"> </mo-button> </td>
              </tr>              
            </tbody>
          </table>
        </div>
    </div>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_Close">닫기</mo-button>
      </div>
    </template>
    <ts-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></ts-alert-popup>

    <msp-ts-110p
      ref="popup110"      
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup110Obj"
    ></msp-ts-110p>
    
    <msp-ts-111p
      ref="popup111"      
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup111Obj"
    ></msp-ts-111p>

    <msp-ts-112p
      ref="popup112"      
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup112Obj"
    ></msp-ts-112p>
  </mo-modal>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
  import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
  import TSDataSet from '~/src/ui/ts/dts/TSDataSet'         // dataSet 정리
  import TSHeader from '~/src/ui/ts/comm/TSHeader'          // header 영역 (공통)
  import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'  // Alert 팝업 (공통)
  import MSPTS110P from '~/src/ui/ts/MSPTS110S' //상품조회
  import MSPTS111P from '~/src/ui/ts/MSPTS111P' //첨부파일 다운로드
  import MSPTS112P from '~/src/ui/ts/MSPTS112P' //신탁상품 요약

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPTS108M",
    screenId: "MSPTS108M",
    components: {
      'ts-header': TSHeader,
      'ts-alert-popup': TSAlertPopup,
      'msp-ts-110p': MSPTS110P,
      'msp-ts-111p': MSPTS111P,
      'msp-ts-112p': MSPTS112P
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      //this.fn_SetCommCode()
      this.fn_Init()
    },
    mounted() {      
      this.$bizUtil.insSrnLog('MSPTS107M')
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // 공통 객체
        pHeaderObj: {          
          title: '신탁상품 정보조회',
        },

        pAlertPopupObj: {},
        pPopup110Obj: {},
        pPopup111Obj: {},
        pPopup112Obj: {},

        // 버튼
        lv_btn_search: false,          // 조회
        lv_btn_init: false,            // 초기화

        // 조회
        lv_cob_trst_typ_cd: '',       // 신탁유형        
        lv_gd_cd: {},                 // 상품코드
        lv_gd_nm: '',                 // 상품명
        lv_cob_sale_prms_yn: '',      // 판매허용여부
        lv_cob_fc_invt_posb_yn: '',   // FC권유가능여부

        lv_gd_search_list: [],        // 상품조회 목록
        lv_gd_search_keyup_list: [],  // 상품조회 목록(상품코드 입력 시)

        //공통코드
        cmb_trst_typ_cd: [],          // 신탁유형
        cmb_sel_typ: [],              // 선택유형
        
        // Contents Model 
        eaiCommObj: TSCommUtil.gfn_eaiCommObj(),
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      modal () {
        return this.$refs.modal;
      },
      selectType() { // 선택유형 (데이터정렬)
        let rtn = [];
        rtn.push({value: '', text: '전체'})
        this.cmb_sel_typ.forEach(item => {
          rtn.push({value: item.value, text: item.text})
        })
        return rtn
      },      
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_display(mode){
        //mode: true(display), false(none display)
        let el = document.getElementById("dv108P");
        if (el == null) return;
        if(mode){
          el.style.display = "";
        }else{
          el.style.display = "none";
        }
      },
      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
       ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
        switch (type) {
          case 0:
            if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
              this.pAlertPopupObj.content = pPopupObj.content

              if( pPopupObj.confirm ) {
                this.pAlertPopupObj.confirm = pPopupObj.confirm
                this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
              }
            }
            break;
        }

        this.$refs.alertPopup.fn_Open()
      },
      /******************************************************************************
       * Function명 : fn_SetCommCode, fn_CommCodeCallBack
       * 설명       : 공통코드 세팅
       ******************************************************************************/
      fn_SetCommCode() {
        if (this.cmb_trst_typ_cd != null && this.cmb_trst_typ_cd.length > 0) return;
        this.fn_display(false);

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.trnstId = 'txTSSTS90S1' // selectTFCP11000A
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600046_S'        
        this.eaiCommObj.params = {'TUKEMK':[
                                    {cId:"5014"},    // 신탁유형                                    
                                    {cId:"5101"}     // 선택유형
                                ]}
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CommCodeCallBack, this.fn_error)
      },
      fn_CommCodeCallBack ( res ) {
        // 초기 콤보박스 설정
        this.cmb_trst_typ_cd.push({value: '' , text: '전체'})
        for ( let index in res.data.tukemk ) {
            let item = res.data.tukemk[index]
            switch (item.cid) 
            {
              case "5014": 
                this.cmb_trst_typ_cd.push({value: item.c , text: item.cnm})
                break
              case "5101":
                this.cmb_sel_typ.push({value: item.c , text: item.c})
                break                       
            }
        }
        this.fn_display(true);
        // 정렬
        let _orderBy = require('lodash/orderBy')
        this.cmb_sel_typ = _orderBy(this.cmb_sel_typ, 'value', 'desc')        
      },
      /******************************************************************************
       * Function명 : fn_Open
       * 설명       : open
       ******************************************************************************/
      fn_Open (properties) {
        this.fn_SetCommCode();
          this.fn_Init ();
          this.modal.open ();
      },
      /******************************************************************************
       * Function명 : fn_Close
       * 설명       : 닫기
       ******************************************************************************/
      fn_Close () {
        this.modal.close ();
      },
      /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 화면 초기화
       ******************************************************************************/
      fn_Init() {
        
        // 검색조건
        this.lv_cob_trst_typ_cd          = ''                                                         // 신탁유형
        this.lv_cob_sale_prms_yn         = 'Y'                                                        // 판매허용여부
        this.lv_cob_fc_invt_posb_yn      = ''                                                         // FC권유가능여부
        this.lv_gd_cd                    = TSCommUtil.gfn_dataSet(1, '', false, '', 'gd_cd')          // 상품코드
        
        // 헤더 검색결과
        this.lv_gd_nm                    = ''                                                         // 상품명
        
        this.lv_btn_search               = false                          // 조회
        this.lv_btn_init                 = false                          // 초기화

        this.fn_InitData()
      },
      /******************************************************************************
       * Function명 : fn_InitData
       * 설명       : 조회 전 데이터 초기화
       * 파라미터   : init - 초기화 시 / search - 조회 시
       ******************************************************************************/
      fn_InitData(type = 'init') {
        this.lv_gd_search_list           = []
        this.lv_gd_search_keyup_list     = []
      },      
      /******************************************************************************
       * Function명 : fn_GdSearch
       * 설명       : 상품조회 (selectTFCP15050List)
       ******************************************************************************/
      fn_GdSearch(state = '') {
        
        this.fn_InitData()
        
        // 서비스 호출
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.trnstId = 'txTSSTS15S2'
        this.eaiCommObj.auth = 'S'

        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600146_S'          
        this.eaiCommObj.params = this.fn_SetParam()
        console.log('fn_GdSearch - eaiCommObj >',  this.eaiCommObj)
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CallBack, this.fn_error)
      },
      /******************************************************************************
       * Function명 : fn_SetParam
       * 설명       : 파라미터 설정
       ******************************************************************************/
      fn_SetParam(type = 'search') {
        let param = {}

        if (type === 'search') {
          if (!TSCommUtil.gfn_isNull(this.lv_cob_trst_typ_cd)) {
            param.trstTypC = this.lv_cob_trst_typ_cd
          } else {
            param.trstTypC = '%'
          }

          if (!TSCommUtil.gfn_isNull(this.lv_gd_cd.value)) {
            param.gdC = this.lv_gd_cd.value
          } else {
            param.gdC = '%'
          }

          if (!TSCommUtil.gfn_isNull(this.lv_cob_sale_prms_yn)) {
            param.salePrmsYn = this.lv_cob_sale_prms_yn
          } else {
            param.salePrmsYn = '%'
          }

          if (!TSCommUtil.gfn_isNull(this.lv_cob_fc_invt_posb_yn)) {
            param.fcInvtPosbYn = this.lv_cob_fc_invt_posb_yn
          } else {
            param.fcInvtPosbYn = '%'
          }

          param.pageRowCnt = '0'
          param.stndKeyId = ''

        } else {
          
          if (!TSCommUtil.gfn_isNull(this.lv_gd_cd.value)) {
            param.gdC = this.lv_gd_cd.value
          }

          param.tmtlNeedDds='' 
          param.intPymDd='' 
          param.lmtAmt='' 
          param.amtUnit='' 
          param.lwstIamtAmt='' 
          param.histIamtAmt='' 
          param.lwstEtrtAmt='' 
          param.mtrtMinTrm='' 
          param.mtrtMaxTrm='' 
          param.loanPosbRto='' 
          param.saleAmt='' 
          param.aprvAmt='' 
          param.rsvAmt='' 
          param.basTfrt='' 
          param.mdtmFert01='' 
          param.mdtmFert02='' 
          param.mdtmFert03='' 
          param.mdtmStrtDds01='' 
          param.mdtmEndDds01='' 
          param.mdtmStrtDds02='' 
          param.mdtmEndDds02='' 
          param.mdtmStrtDds03='' 
          param.mdtmEndDds03='' 
          param.appnTrm='' 
          param.epayTfrt='' 
          param.ftrmPymAmt='' 
          param.prfeRt='' 
          param.aocpRgfeDd='' 
          param.aocpRgfe='' 
          param.epayFeeAmt='' 
          param.mtrtAfTfrt='' 
          param.prfe=''

        }       
        
        return param
      },
      /******************************************************************************
       * Function명 : fn_CallBack
       * 설명       : 상품조회 시 호출 결과
       ******************************************************************************/
      fn_CallBack(pResultData) {
        // 오류 확인
        let t_data = pResultData.data
        if (t_data !== null && t_data !== '') {
          if (TSCommUtil.gfn_trim(t_data.errorCode) !== '') {
            return false
          }

          if(!TSCommUtil.gfn_isNull(t_data.tfcp15050) && t_data.tfcp15050.length > 0){
            this.lv_gd_search_list = t_data.tfcp15050
          }
        }
      },

      /******************************************************************************
       * Function명 : fn_GdSearchKeyUp
       * 설명       : 상품코드 입력 후 자동 조회 (selectTPUP95330List)
       ******************************************************************************/
      fn_GdSearchKeyUp() {
        // console.log('fn_GdSearchKeyUp.....')
        if( TSCommUtil.gfn_length(this.lv_gd_cd.value) == 6) {
          // 서비스 호출
          this.eaiCommObj.lv_vm = this
          this.eaiCommObj.trnstId = 'txTSSTS95S1'
          this.eaiCommObj.auth = 'S'

          this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600216_S'          
          this.eaiCommObj.params = this.fn_SetParam('keyup')
          console.log('fn_GdSearchKeyUp - eaiCommObj >',  this.eaiCommObj)
          TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_GdSearchKeyUpResult, this.fn_error)        
        } else {
          this.lv_gd_nm = ''
        }
      },
      /******************************************************************************
       * Function명 : fn_GdSearchKeyUpResult
       * 설명       : 상품코드 입력 후 자동 조회 시 호출 결과
       ******************************************************************************/
      fn_GdSearchKeyUpResult(pResultData) {      
        let t_data = pResultData.data
        let tmpSearchData = {}
        let lv_Vm = this
        if (t_data !== null && t_data !== '') {
          if (TSCommUtil.gfn_trim(t_data.errorCode) !== '') {
            return false
          }

          if(!TSCommUtil.gfn_isNull(t_data.tpup95330) && t_data.tpup95330.length > 0){
            tmpSearchData = t_data.tpup95330[0]

            if (!TSCommUtil.gfn_isNull(tmpSearchData.gdC)) {
              lv_Vm.lv_gd_cd.value = tmpSearchData.gdC
            }

            if (!TSCommUtil.gfn_isNull(tmpSearchData.gdNm)) {
              lv_Vm.lv_gd_nm = tmpSearchData.gdNm
            }
          }
        }
      },        
      fn_OpenPopup(type, row = null, fileType = '') {
        let lv_Vm = this
        let popupObj = {}
        switch (type) {
          case 0:                    
            popupObj = {              
              // gdC : lv_Vm.lv_gd_cd.value,                // 상품코드
              gdC : '',                                     // 상품코드 (as-is 전달 안함)
              gdTypDtlC : lv_Vm.lv_cob_trst_typ_cd,         // 신탁유형
            }
            this.pPopup110Obj = popupObj
            console.log('fn_OpenPopup - 110 > ', this.pPopup110Obj)
            this.$refs.popup110.fn_Open()
            break
          case 1:            
            popupObj = {              
              gdC : row.gdC,                                // 상품코드
              gdNm : row.gdNm,                              // 상품명
              fileBusnTc : fileType,                        // 파일유형 (A: 교부자료, B: 참조자료)
              apndFileId : row.apndFileId,                  // 첨부파일 ID
              moApndFileId : row.moApndFileId               // 모첨부파일 ID
            }
            this.pPopup111Obj = popupObj
            console.log('fn_OpenPopup - 111 > ', this.pPopup111Obj)
            this.$refs.popup111.fn_Open()
            break
          case 2:
            popupObj = {              
              gdC : row.gdC,                                // 상품코드
              gdNm : row.gdNm                               // 상품명              
            }
            this.pPopup112Obj = popupObj
            console.log('fn_OpenPopup - 112 > ', this.pPopup112Obj)
            this.$refs.popup112.fn_Open()
            break
        }        
      },

      /*********************************************************
      * Function명: fn_Popup_CallBack
      * 설명: 팝업호출 콜백
      *********************************************************/
      fn_Popup_CallBack(type, pData){
        let lv_Vm = this
        switch (type) {  
          case 0:            
            console.log('상품정보조회 110P callback...', pData)
            if (!TSCommUtil.gfn_isNull(pData.gdC)) {
              lv_Vm.lv_gd_cd.value = pData.gdC
            }

            if (!TSCommUtil.gfn_isNull(pData.gdNm)) {
              lv_Vm.lv_gd_nm = pData.gdNm
            }

            // if (!TSCommUtil.gfn_isNull(pData.trstTypC)) {
            //   lv_Vm.lv_cob_trst_typ_cd = pData.trstTypC
            // }
            if (!TSCommUtil.gfn_isNull(pData.gdC) || !TSCommUtil.gfn_isNull(pData.gdNm)) { // 데이터 있을 경우 신탁유형 초기화
              lv_Vm.lv_cob_trst_typ_cd = ''
            }
            break
          case 1:
            console.log('첨부파일 111P callback...', pData)
            break
          case 2:
            console.log('상품상세요약 112P callback...', pData)
            break            
        }
      },

      /*********************************************************
      * Function명: fn_OpenModal
      * 설명: 모달팝업 오픈
      *********************************************************/
      fn_OpenModal(type) {
         this["modal"+type].open();
      },
      // modal
      openModal(type) {
        for( let i = 1; i < 101; i++ ) {
          this["modal"+type].open(); break;
        }
      },
      closeModal(type) {
        for( let i = 1; i < 101; i++ ) {
          this["modal"+type].close(); break;
        }
      },
      onClickSearch(){
        this.$addSnackbar("click: searchIcon"); // test code
      }
    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      
    }
  }
</script>
