/*
* 업무구분: 신탁>영업>개인신규>유가증권상품구분코드검색
* 화 면 명: MSPTS314P(Nex : TPUP90190.xfdl)
* 화면설명: [ ] 유가증권상품구분코드검색 POPUP
* 접근권한: 
* 작 성 일: 2023.02.20
* 작 성 자: 유동훈
*
* F10600238	selectTPUP90190List  [NEXT 사랑On 신탁]유가증권상품구분코드검색	
*/
<template>
  <div>

    <!-- popup -->
    <mo-modal class="fts-modal medium" ref="modal1" title="유가증권상품구분코드검색">
      <template>
        <div class="wrap-modalcontents">
          <div class="wrap-content h-scroll">
            <!-- 
              * d1, d2, d3 : depth
                중간 depth 없을 시, d2없이 d3만 사용
              * folder : 열고 닫기
              * cate : 열고 닫기 & 선택 
                상위 depth 선택 시 하위 항목 모두 선택
            -->
            <ul class="list-folder d1">

              <!-- case1 : 1depth만 : 하위메뉴 없음 -->
              <li v-for="(row1, idx1) in ds_list1.filter(p=>p.depth == 1)" :key="idx1" >
                <button class="folder" @click="toggleFolder"></button>
                <button class="cate"   :value="JSON.stringify(row1)" @click="selecedFile" >{{ row1.treeNm }}</button>
                <ul class="d2" v-for="(row2, idx2) in ds_list2.filter(p=>p.depth == 2 && p.grp1 == row1.grp1)" :key="idx2" >
                  <li>
                    <button class="folder" @click="toggleFolder"></button>
                    <button class="cate"   :value="JSON.stringify(row2)" @click="selecedFile" >{{ row2.treeNm }}</button>
                    <ul class="d3">
                      <li v-for="(row3, idx3) in ds_list3.filter(p=>p.depth == 3 && p.grp2 == row2.grp2)" :key="idx3">
                        <button class="cate" :value="JSON.stringify(row3)" @click="selecedFile" >{{ row3.treeNm }}</button>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button point   size="large" @click="fn_Close   ()">닫기</mo-button>
          <mo-button primary size="large" @click="fn_Confirm ()">확인</mo-button>
        </div>
      </template>
    </mo-modal>

    <!-- alert modal popup -->
    <ts-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
      @childReturnEvt="returnEvt"
    ></ts-alert-popup>

  </div>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
  import TSCommUtil    from '~/src/ui/ts/comm/TSCommUtil'
  import TSDataSet     from '~/src/ui/ts/dts/TSDataSet'          // dataSet 정리
  import TSAlertPopup  from '~/src/ui/ts/comm/TSAlertPopup'  // Alert 팝업 (공통)

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name       : "MSPTS314P",
    screenId   : "MSPTS314P",
    components : {
      'ts-alert-popup': TSAlertPopup,
    },

    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
    },
    mounted() {
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // Contents Model 
        eaiCommObj: TSCommUtil.gfn_eaiCommObj(),

        ds_list1            : [],
        ds_list2            : [],
        ds_list3            : [],
        ds_search           : {},

        objRtnArr           : {}, //팝업창 close시 전달할 값을 위한 배열 선언

        pAlertPopupObj      : {}, // 공통 객체
      };
    },

    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      // modal
      modal1() {return this.$refs.modal1},
    },

    /***********************************************************************************
     * filters 함수 정의 영역                                                         *
     ***********************************************************************************/
    filters: {
      // modal
      modal1() {return this.$refs.modal1},
    },

    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {

    },

    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
       * Function명 : fn_searchList
       * 설명       : Service 호출
       ******************************************************************************/
      fn_searchList() {
        this.g_eaiId = "C392_F10600238_S" //selectTPUP95050B
        this.eaiCommObj.lv_vm   = this
        this.eaiCommObj.auth    = "S"
        this.eaiCommObj.commHeaderVO.eaiId = this.g_eaiId
        this.eaiCommObj.params = this.ds_search

        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack_F10600238, this.fn_error)
      },

      /******************************************************************************
       * Function명 : fn_callSvc
       * 설명       : Service Callback
       ******************************************************************************/
      fn_callBack_F10600238(res) {

        const resData = Object.assign({}, res.data)
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          this.fn_AlertPopup(0,{content : resData.errorMsg}) 
          return false
        }

        this.ds_list1 = res.data.tusgdi
        for ( let i = 0; i < this.ds_list1.length; i++ ) {
          this.ds_list1[i].grp1 = this.ds_list1[i].snbGdTc.substring(0, 1)
          if ( this.ds_list1[i].snbGdTc.length > 1 ) {
            this.ds_list1[i].grp2 = this.ds_list1[i].snbGdTc.substring(0, 2)
          }
        }

        this.ds_list2 = this.ds_list1
        this.ds_list3 = this.ds_list1
      },

      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
       ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
        switch (type) {
          case 0:
            if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
              this.pAlertPopupObj.content = pPopupObj.content

              if( !TSCommUtil.gfn_isNull(pPopupObj.confirm) ) {
                this.pAlertPopupObj.confirm = pPopupObj.confirm
              } else {
                this.pAlertPopupObj.confirm = ''
              }

              if( !TSCommUtil.gfn_isNull(pPopupObj.confirmFunc) ) {
                this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
              } else {
                this.pAlertPopupObj.confirmFunc = ''
              }

              if( !TSCommUtil.gfn_isNull(pPopupObj.closeFunc) ) {
                this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc
              } else {
                this.pAlertPopupObj.closeFunc = ''
              }
            }
            break
        }

        this.$refs.alertPopup.fn_Open()
      },

      // 팝업 확인, 닫기 event
      returnEvt (value) {
        console.log ( 'emit 이벤트 : ' +  value)
      },

      /******************************************************************************
       * Function명 : fn_Confirm
       * 설명       : 확인
       ******************************************************************************/
      fn_Confirm() {
          if ( TSCommUtil.gfn_isNull(this.objRtnArr.snbGdTc) ) {
            this.fn_AlertPopup (0, {content : "선택 된 유가증권이 없습니다."})
            return
          }

          this.$emit('ts-popup314-callback', this.objRtnArr)
          this.fn_Close()
      },

      /******************************************************************************
       * Function명 : fn_Open
       * 설명       : 화면열기
       ******************************************************************************/
      fn_Open() {
        this.modal1.open()
        this.fn_searchList()
      },

      /******************************************************************************
       * Function명 : fn_Close
       * 설명       : 닫기
       ******************************************************************************/
      fn_Close() {
        this.modal1.close()
      },

      /******************************************************************************
       * Function명 : toggleFolder
       * 설명       : toggleFolder toggle 버튼
       ******************************************************************************/
      toggleFolder(e) {
        let clickedFolder = e.target.parentNode
        clickedFolder.classList.toggle('on')
      },

      /******************************************************************************
       * Function명 : selecedFile
       * 설명       : toggleFolder 에서 item 선택
       ******************************************************************************/
      selecedFile(e) {
        let clickedFolder = e.target.parentNode
        clickedFolder.classList.toggle('on')

        let cates = document.querySelectorAll('.list-folder .cate')
        let selectedCate = e.target
        for (let i = 0; i < cates.length; i++) {
          let item = cates.item(i)
          item.classList.remove('on')
        }
        selectedCate.classList.add('on')

        this.objRtnArr = {}
        if ( !TSCommUtil.gfn_isNull(selectedCate.value) ) {
          this.objRtnArr = JSON.parse(selectedCate.value)
        }
      }
    }
  }
</script>
<style scoped>
</style>