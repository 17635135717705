/*
 * 업무구분: 신탁>고객/상품>고객정보등록>개인고객등록>AML송수신승인처리_P
 * 화 면 명: MSPTS205P[TFIO42251.xfdl]
 * 화면설명: AML송수신승인처리 POPUP
 * 작 성 일: 2023.03.24
 * 작 성 자: 박은희 
 */
<template>
  <!-- popup -->
  <mo-modal class="fts-modal large" ref="modal" title="AML송수신승인처리">
    <template>
      <div class="wrap-modalcontents">
        <div class="input-top">
          <div class="left gap40">
            <div class="wrap-input row">
              <label> 계좌번호 </label>
              <mo-text-field readonly v-model="edt_ctno" placeholder="입력하세요" />
            </div>
            <div class="wrap-input row">
              <label> 순번 </label>
              <mo-text-field readonly v-model="edt_procSeq" placeholder="입력하세요" class="w130" />
            </div>
          </div>
        </div>
        <div class="wrap-button result mt6">
          <mo-button :disabled="isAmlBtnDisabled" @click="fn_amlSend"> AML송수신 </mo-button>            
        </div>
        <div class="right-bg-white">
          <div class="wrap-table mt-2 x-scroll">
            <table class="table row-type even-border">
              <thead>
                <tr>
                  <th scope="col" colspan="2">진행상태</th>
                  <th scope="col">계좌번호</th>
                  <th scope="col">처리일자</th>
                  <th scope="col">고객명</th>
                  <th scope="col">AML결과값</th>
                  <th scope="col">위험등급 결과값</th>
                  <th scope="col">처리결과</th>
                  <!-- <th scope="col">AML일괄 처리결과</th> -->
                </tr>
                <tr>
                  <th scope="col">AML 결과송신</th>
                  <th scope="col">거래구분</th>
                  <th scope="col">참조번호</th>
                  <th scope="col">업무구분</th>
                  <th scope="col">실명번호</th>
                  <th scope="col">신원검증</th>
                  <th scope="col">W/L대사(동일인확인)</th>
                  <th scope="col">EDD</th>
                  <!-- <th scope="col">PEP</th> -->
                </tr>
              </thead>
              <tbody v-for="(row, idx) in ds_list" :key="idx">
                <tr>
                  <td colspan="2">{{row.nowStatTypeNm}}</td>
                  <td>{{bnkAcnoMask (edt_ctno)}}</td>
                  <td>{{fn_displayDate (1,row.procDate)}}</td>
                  <td>{{row.cnm}}</td>
                  <td>{{row.amlRstValTcNm}} </td>
                  <td>{{row.rskType}}</td>
                  <td>{{row.amlRstCNm}}</td>
                  <td @click="fn_rowSelected (3, idx)">{{row.msgNm}}</td> <!-- AML 처리결과 전송엑션 -->
                </tr>  
                <tr>
                  <td>{{stringAmlResultSendFormat (row.amlPrcsPfrmType, row.nowStatType)}} </td>
                  <td>{{stringDealFormat (row.amlPrcsPfrmType)}}</td>
                  <td>{{row.refNo}}</td>
                  <td>{{row.amlScrnTcNm}}</td>
                  <td>{{row.rcno}} </td>
                  <td @click="fn_rowSelected (1, idx)">{{stringIdentityVerificationFormat (row.idtyVrfcYn)}}</td><!-- 신원검증 등록 -->
                  <td @click="fn_rowSelected (2, idx)">{{stringSamePersonFormat (row.wlNvtnYn)}}</td> <!-- WL대사 등록 -->
                  <td @click="fn_rowSelected (4, idx)">{{stringEddPepFormat (row.eddRegYn, row.nowStatType, row.eddRegYn)}}</td> <!--개인 EDD 등록-->
                  <!--개인 PEP 등록 -->
                  <!-- <td @click="fn_rowSelected (5, idx)">{{stringEddPepFormat(row.pepRegYn, row.nowStatType, row.pepRegYn)}}</td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom">
        <mo-button point size="large" @click="fn_Close" class="float-right">닫기</mo-button>
      </div>
    </template>
    <ts-alert-popup 
      ref="alertPopup"
      :alertPopupObj="pAlertPopupObj"
    ></ts-alert-popup>
    <!-- <ts-alert-popup2 
      ref="alertPopup2"
      :alertPopupObj2="pAlertPopupObj2"
    ></ts-alert-popup2> -->
    <msp-ts-306p
      ref="popup306"
      :popup306Obj="pPopup306Obj"
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup306-callback="fn_Popup306Back"
    ></msp-ts-306p>
    <msp-ts-307p
      ref="popup307"
      :popup307Obj="pPopup307Obj"
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup307-callback="fn_Popup307Back"
    ></msp-ts-307p>    
  </mo-modal>
</template>

<script>
const defaultSearch = {
  refNo	: '',	
  tacno   : '',
  bnkbSeq : '',
  ctno    : '',
  procSeq : '',
};
const defaultList000 = {
    amlBusiGb: '',
    amlPrcsPfrmType: '2',
    amlRstC: '',
    amlRstCNm: '',
    amlRstValTc: '',
    amlRstValTcNm: '',
    amlScrnTc: '40',
    amlScrnTcNm: '송금계좌등록',
    aprvNo: '0',
    bnkbSeq: '555',
    cdiNatC: '',
    cdiNatCRl: '',
    cnm: '우하하',
    corpYn: 'N',
    csEnnm: '',
    csId: '939201181',
    csidCnt: '0',
    ctno: '555',
    deweGb: '',
    eddRegYn: '9',
    fdscTypeC: '',
    ftstApmnEmno: '',
    ftstAprvCntn: '',
    ftstAprvStat: '',
    genTaxtTc: '11',
    idtyVrfcYn: '9',
    insrJobC: '',
    insrStndIndsTypeC: '',
    lastChngDt: '2023-05-04 17:23:42.0',
    lastChngMnTmno: '100.21.104.36',
    lastChngMnUsid: '20876',
    listYn: '',
    mngAprvSeq: '0',
    msgNm: '',
    natC: 'KR',
    natCNm: '대한민국',
    nowStatType: '10',
    nowStatTypeNm: 'AML정보 송수신요청',
    pepRegYn: '9',
    pgGb: '',
    procBrcd: '906',
    procDate: '20230504',
    procErrC: '',
    procSeq: '1',
    prosGb: '',
    rcno: '990404-1******',
    rcnoKindTc: '01',
    refNo: '55521101612555555000140172342662',
    reqCntn: '',
    rlOwnMnCnfmYn: '',
    rlOwnMnNatC: '',
    rlOwnMnNatCNm: '',
    rlOwnMnNm: '',
    rlOwnMnQtn1: '',
    rlOwnMnQtn2: '',
    rlOwnMnQtn31: '',
    rlOwnMnQtn32: '',
    rlOwnMnQtn33: '',
    rlOwnMnQtn4: '',
    rlOwnMnRrno: '',
    rqmnEmno: '20876',
    rskType: '',
    scdApmnEmno: '',
    scdAprvCntn: '',
    scdAprvStat: '',
    tacno: '1101612',
    trPpsTc: '',
    wlDealLmtYn: '',
    wlNvtnYn: '9',
    wlSamrYn: ''
  };
const defaultList = {
  aprvNo			    : '',	
  refNo           : '',
  tacno           : '',
  bnkbSeq         : '',
  ctno            : '',
  procSeq         : '',
  lastChngDt      : '',
  lastChngMnTmno  : '',
  lastChngMnUsid  : '',
  amlPrcsPfrmType : '',
  amlScrnTc       : '',
  amlScrnTcNm     : '',
  csId            : '',
  cnm             : '',
  rcno            : '',
  rcnoKindTc      : '',
  natC            : '',
  natCNm          : '',
  procDate        : '',
  procBrcd        : '',
  rqmnEmno        : '',
  reqCntn         : '',
  ftstApmnEmno    : '',
  ftstAprvCntn    : '',
  ftstAprvStat    : '',
  scdApmnEmno     : '',
  scdAprvCntn     : '',
  scdAprvStat     : '',
  amlRstValTc     : '',
  amlRstValTcNm   : '',
  rskType         : '',
  amlRstC         : '',
  amlRstCNm       : '',
  idtyVrfcYn      : '',
  wlNvtnYn        : '',
  eddRegYn        : '',
  pepRegYn        : '',
  nowStatType     : '',
  nowStatTypeNm   : '',
  msgNm           : '',
  procErrC        : '',
  corpYn          : '',
  rlOwnMnCnfmYn   : '',
  rlOwnMnNm       : '',
  rlOwnMnRrno     : '',
  rlOwnMnNatC     : '',
  rlOwnMnNatCNm   : '',
  rlOwnMnQtn1     : '',
  rlOwnMnQtn2     : '',
  rlOwnMnQtn31    : '',
  rlOwnMnQtn32    : '',
  rlOwnMnQtn33    : '',
  rlOwnMnQtn4     : '',
  genTaxtTc       : '',
};
const defaultDetail = {
  prosGb			    : '',
  aprvNo          : '',
  refNo           : '',
  tacno           : '',
  bnkbSeq         : '',
  ctno            : '',
  procSeq         : '',
  lastChngDt      : '',
  lastChngMnTmno  : '',
  lastChngMnUsid  : '',
  amlPrcsPfrmType : '',
  amlScrnTc       : '',
  amlScrnTcNm     : '',
  csId            : '',
  cnm             : '',
  rcno            : '',
  natC            : '',
  natCNm          : '',
  procDate        : '',
  procBrcd        : '',
  rqmnEmno        : '',
  reqCntn         : '',
  ftstApmnEmno    : '',
  ftstAprvCntn    : '',
  ftstAprvStat    : '',
  scdApmnEmno     : '',
  scdAprvCntn     : '',
  scdAprvStat     : '',
  amlRstValTc     : '',
  amlRstValTcNm   : '',
  rskType         : '',
  amlRstC         : '',
  amlRstCNm       : '',
  idtyVrfcYn      : '',
  wlNvtnYn        : '',
  eddRegYn        : '',
  pepRegYn        : '',
  nowStatType     : '',
  nowStatTypeNm   : '',
  msgNm           : '',
  procErrC        : '',
  corpYn          : '',
  rlOwnMnCnfmYn   : '',
  rlOwnMnNm       : '',
  rlOwnMnRrno     : '',
  rlOwnMnNatC     : '',
  rlOwnMnNatCNm   : '',
  rlOwnMnQtn1     : '',
  rlOwnMnQtn2     : '',
  rlOwnMnQtn31    : '',
  rlOwnMnQtn32    : '',
  rlOwnMnQtn33    : '',
  rlOwnMnQtn4     : '',
  genTaxtTc       : '',
};
const defaultCmb5045 = {
  c: '',	
  cNm: '',
};

/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import _cloneDeep from "lodash/cloneDeep"
import moment from 'moment'

import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'

import MSPTS306P from '@/ui/ts/MSPTS306P' //개인 EDD_P
import MSPTS307P from '@/ui/ts/MSPTS307P' //개인 PEP_P

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPTS205P",
  screenId: "MSPTS205P",
  components: {
   'ts-alert-popup': TSAlertPopup,   
   'msp-ts-306p': MSPTS306P,
   'msp-ts-307p': MSPTS307P,
  }, // 화면명
  props: {
    pPage: String,      // 부모 페이지명
    pTacno: String,     
    pBnkbSeq: String,   
    pCtno: String,     
    pProcSeq: String,  
    pUrl: String,
    pProcTc: String,
    popupObj: {
      type: Object,
      default: null,
    },
  },
  /******************************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Created 설정 영역
   ******************************************************************************************/
  created () {
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::created ]___[ 시작 ]");
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::created ]___[ 종료 ]");
  },
  /******************************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Mounted 설정 영역
   ******************************************************************************************/
  mounted () {
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::mounted ]___[ 시작 ]");
    this.$bizUtil.insSrnLog ("MSPTS205P");
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::mounted ]___[ 종료 ]");
  },
  /******************************************************************************************
   * Data 설정 영역
   ******************************************************************************************/
  data () {
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-MODEL:::data ]___[ 시작 ]");
    return {
      // ------
      // 개발/검증용 로깅출력 여부, added by anarchi (2023.08.7)
      // (※ 개발시: true, 운영이관시: false 설정할 것)
      // ------
      isLoggable: false,
      // valueCount: '1234567897',
      // valueNumber: '3',
      ds_cmb5045: Object.assign ({}, defaultCmb5045),
      ds_detail: Object.assign ({}, defaultDetail),
      ds_list: Object.assign ({}, defaultList),
      ds_list000: Object.assign ({}, defaultList000),
      ds_search: Object.assign ({}, defaultSearch),
      edt_ctno: '',
      edt_procSeq: '',  // 메인에서 값을 받아 오는 로직에 문제가 있어 임시 박음
      pAlertPopupObj: {},
      // pAlertPopupObj2: {},
      eaiCommObj: TSCommUtil.gfn_eaiCommObj (),
      selectedItem: Object.assign ({}, defaultList),
      /* hidden 처리 속성선언 */
      hid_edt_refNo:'', //참조번호
      hid_edt_tacno: '',   //종합계좌번호
      hid_edt_bnkbSeq: '',  //통장일련번호
      hid_edt_ctno: '',  //계약번호
      pPopup306Obj: {},
      popup306: {}, // MSPTS306P
      pPopup306Type: '',
      pPopup307Obj: {},
      popup307: {}, // MSPTS307P
      pPopup307Type: '',
      isAmlBtnDisabled: true, //등록 버튼 활성화 여부 
    };
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-MODEL:::data ]___[ 종료 ]");
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    // modal    
    modal() {
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::메소드명 ]___[ 시작 ]");
      return this.$refs.modal;
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::메소드명 ]___[ 종료 ]");
    },
  },
  /******************************************************************************************
   * Methods 설정 영역
   ******************************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_Open
     * 설명       : open
     ******************************************************************************/
    fn_Open (properties) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::메소드명 ]___[ fn_Open ]");
      this.edt_ctno = this.bnkAcnoMask (properties.pTacno + '-' + properties.pBnkbSeq + '-' + properties.pCtno);
      this.edt_procSeq = properties.pProcSeq
      this.ds_search.tacno = properties.pTacno
      this.ds_search.ctno  = properties.pCtno
      this.ds_search.procSeq = properties.pProcSeq
      this.ds_search.bnkbSeq = properties.pBnkbSeq
      //this.ds_list000 = Object.assign ({}, defaultList000)
      if ( TSCommUtil.gfn_isNull ( properties.pTacno ) ) {
        let t_popupObj = {
          confirm: false,
          content: '종합계좌번호가 제대로 넘어 오지 않았습니다.',
        };
        this.$emit ('ts-alert-popup', 0, t_popupObj);
        return;
      }
      if ( TSCommUtil.gfn_isNull ( properties.pBnkbSeq ) ) {
        let t_popupObj = {
          confirm: false,
          content: '통장일련번호가 제대로 넘어 오지 않았습니다.',
        };
        this.$emit ('ts-alert-popup', 0, t_popupObj);
        return;
      }
      if ( TSCommUtil.gfn_isNull ( properties.pCtno ) ) {
        let t_popupObj = {
          confirm: false,
          content: '계약번호가 제대로 넘어 오지 않았습니다.',
        };
        this.$emit ('ts-alert-popup', 0, t_popupObj);
        return;
      }
      if ( TSCommUtil.gfn_isNull ( properties.pProcSeq ) ) {
        let t_popupObj = {
          confirm: false,
          content: '처리일련번호가 제대로 넘어 오지 않았습니다.',
        };
        this.$emit ('ts-alert-popup', 0, t_popupObj);
        return;
      }
      this.fn_searchList ();
      this.modal.open ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::메소드명 ]___[ fn_Open ]");
    },
    /******************************************************************************
     * Function명 : fn_Close
     * 설명       : 닫기
     ******************************************************************************/
    fn_Close () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Close ]___[ 시작 ]");
      this.$emit ('ts-popup205-callback');
      this.modal.close ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Close ]___[ 종료 ]");
    },
    // ======================================================
    // (화면흐름 제어) fn_AlertPopup:: 
    // ======================================================
    fn_AlertPopup (type, pPopupObj) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AlertPopup ]___[ 시작 ]");
      switch (type) {
        case 0:
          if ( !TSCommUtil.gfn_isNull (pPopupObj) ) {
            this.pAlertPopupObj.content = pPopupObj.content;
            if ( pPopupObj.confirm ) {
              this.pAlertPopupObj.confirm = pPopupObj.confirm;
              this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc;
            }
            if ( pPopupObj.closeFunc ) {
              this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc;
              this.pAlertPopupObj.closeData = pPopupObj.closeData;
            }
          }
          break;
        default:
          if ( !TSCommUtil.gfn_isNull (pPopupObj) ) {
            this.pAlertPopupObj.content = pPopupObj.content;
            if ( pPopupObj.confirm ) {
              this.pAlertPopupObj.confirm = pPopupObj.confirm;
              this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc;
            }
            if ( pPopupObj.closeFunc ) {
              this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc;
              this.pAlertPopupObj.closeData = pPopupObj.closeData;
            }
          }
      }
      this.$refs.alertPopup.fn_Open ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AlertPopup ]___[ 종료 ]");
    },
    // ======================================================
    // (화면흐름 제어) confirmCall:: 
    // ======================================================
    confirmCall () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::confirmCall ]___[ 시작 ]");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::confirmCall ]___[ 종료 ]");
    },
    // ======================================================
    // (업무:비동기 서비스 호출) fn_updateAcct:: 
    // ======================================================
    fn_updateAcct () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_updateAcct ]___[ 시작 ]");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_updateAcct ]___[ 종료 ]");
    },
    // ======================================================
    // (화면흐름 제어--콜맥) callbackClose:: 
    // ======================================================
    callbackClose () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::callbackClose ]___[ 시작 ]");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::callbackClose ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_searchList
     * 설명       : search
     ******************************************************************************/
    fn_searchList () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_searchList ]___[ 시작 ]");
      // if (TSCommUtil.gfn_validate (this, '계좌번호 형식에 맞지 않습니다.')) {
      //   return;
      // }
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = 'S';
      // this.eaiCommObj.trnstId = 'txTSSTS42S3' // selectTFIO49000AList
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600252_S';
      this.eaiCommObj.params = {
        tacno: this.ds_search.tacno,
        bnkbSeq: this.ds_search.bnkbSeq,
        ctno: this.ds_search.ctno , // ※ 확인결과 ctno는 555로 고정임..this.ds_search.ctno, (commented by 전임자, 김경태)
        procSeq: this.ds_search.procSeq,
        refNo: this.ds_search.refNo,
      };
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_searchListCallback);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_searchList ]___[ 종료 ]");
    },
    // ======================================================
    // (업무:비동기 서비스 호출--콜백) fn_searchListCallback:: 
    // ======================================================
    fn_searchListCallback (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_searchListCallback ]___[ 시작 ]");
      this.ds_list = [];
      // this.ds_list = res.data.tftarl
      if (res.data.tftarl.length == 0) {
        let t_popupObj = {
          confirm: false,
          content: '검색한 결과가 없습니다.',
        };
        this.$emit ('ts-alert-popup', 0, t_popupObj);
        return;
      }
      else {
        this.ds_search.refNo = res.data.tftarl[0].refNo;
        this.hid_edt_refNo = this.ds_search.refNo;
        this.hid_edt_tacno = this.ds_search.tacno;
        this.hid_edt_bnkbSeq = this.ds_search.bnkbSeq;
        this.hid_edt_ctno = this.ds_search.ctno;
        this.ds_list = res.data.tftarl;
        res.data.tftarl.forEach ((item, idx) => {
          console.log ('item.nowStatType ', item.nowStatType, ", idx → ", idx);
          if (item.nowStatType == "10") {
            this.isAmlBtnDisabled = false;
          }
        });
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_searchListCallback ]___[ 종료 ]");
    }, 
    /************************************************************************************************
     * Function명 : fn_amlSend   
     * 설명       : AML송수신
     ************************************************************************************************/
    fn_amlSend () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_amlSend ]___[ 시작 ]");
      if ( TSCommUtil.gfn_isNull ( this.edt_ctno ) ) {
        let t_popupObj = {
          confirm: false,
          content: '계약번호가 잘못 되었습니다.',
        };
        this.$emit ('ts-alert-popup', 0, t_popupObj);
        return;
      }
      if ( TSCommUtil.gfn_isNull ( this.edt_procSeq ) ) {
        let t_popupObj = {
          confirm: false,
          content: '순번이 잘못 되었습니다.',
        };
        this.$emit ('ts-alert-popup', 0, t_popupObj);
        return;
      }
      this.ds_detail.refNo = this.hid_edt_refNo;
      this.ds_detail.tacno = this.hid_edt_tacno;
      this.ds_detail.bnkbSeq = this.hid_edt_bnkbSeq;
      this.ds_detail.ctno = this.hid_edt_ctno;
      this.ds_detail.procSeq = this.edt_procSeq;
      this.ds_detail.prosGb = 'A';
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = 'I';
      // this.eaiCommObj.trnstId = 'txTSSTS42I3' // insertTPUP95020A
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600192_S';
      this.eaiCommObj.params = {
        refNo: this.ds_detail.refNo,
        tacno: this.ds_detail.tacno,
        ctno: this.ds_detail.ctno,
        procSeq: this.ds_detail.procSeq,
        prosGb: this.ds_detail.prosGb,
        bnkbSeq: this.ds_detail.bnkbSeq,
      };
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_amlSendCallBack);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_amlSend ]___[ 종료 ]");
    },
    // ======================================================
    // (업무:비동기 서비스 호출--콜백) fn_amlSendCallBack:: AML송수신 후처리
    // ======================================================
    fn_amlSendCallBack (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_amlSendCallBack ]___[ 시작 ]");
      // 결과를 알수 없다. 
      console.log ('AML 송신 버튼 클릭 후처리 결과', res);
      // 후처리후 AS-IS에 뭐하는지 알수가 없다.. 로그만 찍는듯
      // this.ds_list = Object.assign ({}, defaultList)
      this.isAmlBtnDisabled = true;
      this.fn_searchList ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_amlSendCallBack ]___[ 종료 ]");
    },   
    /******************************************************************************
     * Function명 : fn_RowSelected
     * 설명       : 선택한 셀 정보에 따라 분개처리(신원검증, WL 대사, 개인 EDD, 개인PEP  등록 엑션 )
     ******************************************************************************/
    fn_rowSelected (type, selectedIdx) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_rowSelected ]___[ 시작 ]");
      this.selectedItem = this.ds_list[selectedIdx];
      let v_nowStatType = this.selectedItem.nowStatType;
      let	v_eddRegYn = this.selectedItem.eddRegYn;
      let	v_pepRegYn = this.selectedItem.pepRegYn;
      let	v_idtyVrfcYn = this.selectedItem.idtyVrfcYn;
      let	v_tacno = this.selectedItem.tacno;
      let	v_refNo	= this.selectedItem.refNo;
      let	v_cnm	= this.selectedItem.cnm;
      let	v_csId = this.selectedItem.csId;
      let	v_ctno = this.selectedItem.ctno;
      let	v_amlPrcsPfrmType = this.selectedItem.amlPrcsPfrmType;
      let v_insertYn = 'N';
     	if ( v_nowStatType < 50 ) {
        v_insertYn = "Y";
      }
      let properties = {
        pPage: 'MSPTS205M',
        csId: v_csId,
        tacno: v_tacno,
        cnm: v_cnm,
        insertYn: v_insertYn,
        refNo: v_refNo,
        amlPrcsPfrmType: v_amlPrcsPfrmType,
        pUrl: '2000', // 
        pProcTc: '2000', //
      };
      if (type == 4) { // 개인 EDD
        this.fn_OpenMSPTS306P (properties); // MSPTS306P
      }
      else if (type == 5) { // 개인 PEP        
        this.fn_OpenMSPTS307P (properties); // MSPTS307P
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_rowSelected ]___[ 종료 ]");
    },
    /*********************************************************
     * Function명: fn_OpenMSPTS306P
     * 설명: AML 팝업호출
     *********************************************************/
    fn_OpenMSPTS306P (properties) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OpenMSPTS306P ]___[ 시작 ]");
      this.popup306 = this.$refs.popup306.fn_Open (properties);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OpenMSPTS306P ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_Popup306Back
    * 설명: EDD 등록 팝업호출 콜백
    *********************************************************/
    fn_Popup306Back (rtnData) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup306Back ]___[ 시작 ]");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup306Back ]___[ 종료 ]");
    }, 
    /*********************************************************
    * Function명: fn_OpenMSPTS307P
    * 설명: PEP 팝업호출
    *********************************************************/
    fn_OpenMSPTS307P (properties) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OpenMSPTS307P ]___[ 시작 ]");
      this.popup307 = this.$refs.popup307.fn_Open (properties);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OpenMSPTS307P ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_Popup307Back
    * 설명: PEP 등록 팝업호출 콜백
    *********************************************************/
    fn_Popup307Back (rtnData) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup307Back ]___[ 시작 ]");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup307Back ]___[ 종료 ]");
    }, 
    /************************************************************************************************
     * Function명 : stringDealFormat  
     * 설명       : 
     * @param {String} value : 거래구분
     ************************************************************************************************/
    stringDealFormat (value) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::stringDealFormat ]___[ 시작 ]");
      if (!value) return '';
      if (value == '1') return '신규';
      else return '지속';
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::stringDealFormat ]___[ 종료 ]");
    }, 
    /************************************************************************************************
     * Function명 : stringAmlResultSendFormat
     * 설명       : 
     * @param {String} value : AML결과송신
     ************************************************************************************************/
    stringAmlResultSendFormat (value1, value2) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::stringAmlResultSendFormat ]___[ 시작 ]");
      if (!value1) return '';
      if (value1 != '1') return '';
      if (value2 < 11) return '';
      if (value2 > 50) return '전송완료';
      else  return '전송';ㅣ
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::stringAmlResultSendFormat ]___[ 종료 ]");
    },      
    /************************************************************************************************
     * Function명 : stringIdentityVerification1Format  
     * 설명       : 
     * @param {String} value : 신원검증
     ************************************************************************************************/
    stringIdentityVerificationFormat (value) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::stringIdentityVerificationFormat ]___[ 시작 ]");
      if (!value) return '';
      if (value == 'Y') return '일치';
      else if (value == 'N') return '불일치';
      else return '';
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::stringIdentityVerificationFormat ]___[ 종료 ]");
    },      
    /************************************************************************************************
     * Function명 : stringSamePersonFormat  
     * 설명       : 
     * @param {String} value : 신원검증
     ************************************************************************************************/
    stringSamePersonFormat (value) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::stringSamePersonFormat ]___[ 시작 ]");
      if (!value) return '';
      if (value == 'Y') return '동일인';
      else if (value == 'N') return '동일인아님';
      else if (value == 'X') return '확인불가';
      else return '';
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::stringSamePersonFormat ]___[ 종료 ]");
    },
    /************************************************************************************************
     * Function명 : stringEddPepFormat  
     * 설명       : 
     * @param {String} value : 신원검증
     ************************************************************************************************/
    stringEddPepFormat (value1, value2, value3) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::stringEddPepFormat ]___[ 시작 ]");
      if (!value1) return '';
      if (value2 > 50 || value3 < 10) return '조회';
      if (value3 == 'Y') return '수정';
      else return '등록';
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::stringEddPepFormat ]___[ 종료 ]");
    },  
    // ======================================================
    // (공통:데이터 제어) fn_displayDate:: 날짜변환처리
    // ======================================================
    fn_displayDate (type, val) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_displayDate ]___[ 시작 ]");
      if (!val) return "";
      return TSCommUtil.gfn_dateReplace (type, val);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_displayDate ]___[ 종료 ]");
    },
    // ======================================================
    // (업무:데이터 제어) bnkAcnoMask:: 계좌번호 매스킹 처리
    // ======================================================
    bnkAcnoMask (val) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::bnkAcnoMask ]___[ 시작 ]");
      let _maskVal = val;
      if (!TSCommUtil.gfn_isNull (_maskVal)) {
        return TSCommUtil.gfn_bnkAcno_mask (_maskVal);
      }
      return _maskVal;
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::bnkAcnoMask ]___[ 종료 ]");
    },
  },
};
</script>

<style scoped></style>