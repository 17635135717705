/*
* 업무구분: 신탁>영업>변경/해지/이체>이체>실시간입금>송금계좌등록_P
* 화 면 명: MSPTS204P [TFIO42252.xfdl]
* 화면설명: 신규계좌등록
* 작 성 일: 2023.03.24
* 작 성 자: 박은희 (전면개정 -> refined by anarchi) 
 */

/******************************************************************************************
 * Vue 메인 templates 설정 영역
 ******************************************************************************************/
<template>
  <!-- popup -->
  <mo-modal class="fts-modal medium" ref="modal" title="송금계좌등록">
    <template>
      <div class="wrap-modalcontents">
        <div class="wrap-button result mt0 mb6">
          <mo-button :disabled="isAml"  @click="fn_OpenMSPTS205P"> AML </mo-button>
        </div>
        <div class="wrap-table">
          <table class="table col-type col3070 mb-0">
            <tbody>
              <tr>
                <th>
                  <span> 비밀번호 </span>
                </th>
                <td>
                  <div class="wrap-input">
                    <!-- 비밀번호 -->
                    <!-- 보안 키패드 (모바일전용) //-->
                    <m-trans-key-input
                      v-if="isMobile"
                      v-model="edt_pwd" 
                      type="numberMax4" 
                      class="w130" 
                      maxlength="4"
                      :start="'0'"
                      :end="'-1'"
                      dialog="Y"
                      :isClear="lv_isClear"
                      @masked-txt="fn_SetMaskedTxt">
                    </m-trans-key-input>
                    <!-- 일반 키입력 //-->
                    <mo-text-field 
                      v-else  
                      v-model="edt_pwd" 
                      type="password" 
                      class="w130" 
                      maxlength="4"
                      @keyup="fn_PwdValid" 
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 은행명 </span>
                </th>
                <td>
                  <div class="wrap-input">
                    <mo-dropdown :items="bnkItems" v-model="cbo5095" placeholder="선택하세요" ref="dropdown1" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 출금계좌번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      v-model="edt_bnkAcno" 
                      type="number" 
                      class="w130" 
                      maxlength="20"
                      :disabled="isbakAcnoDisabled" 
                    />
                    <mo-button class="mw80" @click="fn_cNm"> 예금주확인 </mo-button>
                    <mo-text-field class="input-long" v-model="edt_bkAcNm"/>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p class="colorR text-xs mt-2 mb-2" v-html="processMsg">{{ processMsg }}</p>
      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_Close">닫기</mo-button>
        <mo-button primary size="large" :disabled="isConfirmBtnDisabled" @click="fn_confirm">등록</mo-button>        
      </div>
    </template>
    <!-- <ts-alert-popup
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></ts-alert-popup>     -->
    <ts-alert-popup
      ref="alertPopup_204p"
      :popupObj="pAlertPopupObj_204p"
    ></ts-alert-popup>
    <msp-ts-205p
      ref="popup205_204p"
      :popup205Obj="pPopup205Obj"
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup205-callback="fn_Popup205Back"
    ></msp-ts-205p>
    <!-- popup106 책임자 승인 -->
    <!-- <msp-ts-106p
      ref="popup106"
      :popupObj="pPopup106Obj"
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup106-callback="fn_Popup_CallBack_106"
    ></msp-ts-106p> -->
    <msp-ts-106p
      ref="popup106_204p"
      :popup106Obj="pPopup106Obj"
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup106-callback="fn_Popup106Back"
    ></msp-ts-106p>
  </mo-modal>
</template>

<script>
  const defaultSearch = {
    tacno: "",
    pwd: "",
    bnkAcno: "",
    bkcd: "",
  };
  const defaultList = {
    bnkAcno: "",
    trBkcdNmBnkAcno: "",
  };
  const defaultDetail = {
    trtRsltCntnt: "",
    bnkDpstrNm: "",
    bnkRegNo: "",
    trsfRsltRespCd: "",
    dpstrChkTrtRsltVal: "",
  };
  const defaultInsert = {
    stipSeq: "",
    bnkAccDepoNm: "",
    trBkcd: "",
    bnkAcno: "",
    csId: "",
    relsYn: "",
    regDate: "",
    relsDate: "",
    regMnEmno: "",
    aprvNo: "",
    tacno: "",
    bnkAccNm: "",
    eaiRegProcYn: "",
    insrAccRegYn: "",
  };
  const defaultDetail01 = {
    aprvNo: "",	
    amlProcYn: "",
    amlProcNo: "",
  };
  const defaultAcctNm = {
    trBkcd: "",	
    bnkAcno: "",
    bnkAccNm: "",
    procEmno: "",
    csId: "",
    pwd: "",
    tacno: "",
    pwdYn: "",
  };
  const defaultCbo5095 = {
    c: "",
    cNm: "",
  };
  const defaultSearch00 = {
    cId: "",
  };
  const defaultPbpr = {
    pbprTp: "",
    pbprCntl: "",
    pbprPageNo: "",
    pbprLineNo: "",
    pbprOutLen: "",
    pbprData: "",
    pbprPrtCnt: "",
  };
  const defaultPrintAcct = {
    stipSeq: "",
    csId: "",
    relsDate: "",
    trBkcd: "",
    bnkAcno: "",
    procCls: "",
    trBkcdNm: "",
    tacno: "",
  };
  const defaultCmb0080 = {
    cNm: "",
    c: "",
  };

/*********************************************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 *********************************************************************************************************/
import _ from "lodash"
// import moment from "moment"

import TSAlertPopup from "~/src/ui/ts/comm/TSAlertPopup" // TS_공통팝업
import TSCommUtil from "~/src/ui/ts/comm/TSCommUtil" // TS_공통유틸
import TSInfoUtil from "~/src/ui/ts/comm/TSInfoUtil" // TS_공통전자서식(리포트)정보
import TSServiceUtil from "~/src/ui/ts/comm/TSServiceUtil" // TS_서비스호출유틸

import MSPTS106P from "~/src/ui/ts/MSPTS106P" // 책임자승인요청_P
import MSPTS205P from "~/src/ui/ts/MSPTS205P" // AML송수신승인처리_P

export default {
  /******************************************************************************************
   * Vue 파일 속성 정의 영역
   ******************************************************************************************/
  name: "MSPTS204P",
  screenId: "MSPTS204P",
  /******************************************************************************************
   * Components 설정 영역
   ******************************************************************************************/
  components: {
   "ts-alert-popup": TSAlertPopup,
   // "ts-alert-popup2": TSAlertPopup,
   "msp-ts-106p": MSPTS106P,    
   "msp-ts-205p": MSPTS205P,
  },
  /******************************************************************************************
   * Props 설정 영역
   ******************************************************************************************/
  props: {
    pPage: String, // 부모 페이지명
    pTacno: String, // 종합계좌번호  
    pBnkbSeq: String, // 종합계좌번호  
    pCtno: String, // 종합계좌번호          
    pProcSeq: String, // 종합계좌번호   
    pCsId: String,  
    pCnm: String,     
    pUrl: String,
    pProcTc: String,
    popupObj: {
      type: Object,
      default: null,
    },
  },
  /******************************************************************************************
   * [Step-2][Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Created 설정 영역
   ******************************************************************************************/
  created () {
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::created ]___[ 시작 ]");
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::created ]___[ 종료 ]");
  },
  /******************************************************************************************
   * [Step-4][Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Mounted 설정 영역
   ******************************************************************************************/
  mounted () {
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::mounted ]___[ 시작 ]");
    this.$bizUtil.insSrnLog ("MSPTS204P");
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::mounted ]___[ 종료 ]");
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data () {
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-MODEL:::data ]___[ 시작 ]");
    return {
      // ------
      // 개발/검증용 로깅출력 여부, added by anarchi (2023.08.7)
      // (※ 개발시: true, 운영이관시: false 설정할 것)
      // ------
      isLoggable: false,
      lv_basInfo: this.getStore ("tsStore").getters.getBasInfo.data, 
      ds_search: Object.assign ({}, defaultSearch),
      ds_list: Object.assign ({}, defaultList),
      ds_detail: Object.assign ({}, defaultDetail),
      ds_insert: Object.assign ({}, defaultInsert),
      ds_detail0: Object.assign ({}, defaultDetail01),
      ds_acctNm: Object.assign ({}, defaultAcctNm),
      ds_cbo5095: Object.assign ({}, defaultCbo5095),
      ds_search: Object.assign ({}, defaultSearch00),
      ds_pbpr: Object.assign ({}, defaultPbpr),
      ds_printAcct: Object.assign ({}, defaultPrintAcct),
      ds_cmb0080: Object.assign ({}, defaultCmb0080),
      ds_tfio42250: [], // 등록/해지 송금계좌 목록 (반환객체), added by anarchi (2023.08.14)
      ds_tfio42250_report: [], // 등록/해지 후, 전자서식 출력을 위한 정보항목
      edt_bnkAcno: "",
      edt_bnkAcno_mask: "",
      edt_amlProcNo: "",
      edt_aprvNo: "",
      edt_acno: "",
      edt_bkAcNm: "",
      edt_pwd: "",
      cbo5095: "",
      tacno: "",            
      csId: "",            
      bkcd: "",
      bnkItems: [],
      bnkList: [],      
      eaiCommObj: TSCommUtil.gfn_eaiCommObj (),
      processMsg: "",
      isAml: true,
      pAlertPopupObj: {},
      pAlertPopupObj_204p: {}, // 공통팝업처리오류 대체용
      pPopup106Obj: {},
      pPopup205Obj: {},
      popup205: {}, // MSPTS205P 'AML송수신팝업_P'
      pPopup205Type: "",
      pPopup106Obj: {},
      popup106: {}, // MSPTS106P '책임자승인요청팝업'
      popup106_204p: {}, // MSPTS106P '책임자승인요청팝업' (전용)
      pPopup106Type: "",
      isConfirmBtnDisabled: true, // 등록 버튼 활성화 여부 
      isbakAcnoDisabled: false, // 출금계좌번호 활성화 여부 
      isMobile: window.vue.getStore ("deviceState").getters.getIsMobile,
      lv_masked_val: "", // 마스크 변수 체크
      lv_isClear: false, // 보안키패드 초기화
      successYn: "", // 책임자승인요청 성공여부
      successYn_Aml: "", // AML송수신처리 성공여부
    };
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-MODEL:::data ]___[ 종료 ]");
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    // modal        
    modal () {
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::modal ]___[ 시작 ]");
      return this.$refs.modal;
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::modal ]___[ 종료 ]");
    },
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명: fn_checkProcess
     * 설명       : 프로세스에 따라 분기 처리
     ******************************************************************************/
    fn_checkProcess () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_checkProcess ]___[ 시작 ]");
      let lv_Vm = this;
      let tmpContentTitle = "";
      let tmpContent = [];
      let isProcess = lv_Vm.getStore ("tsStore").getters.getState.isProcess;
      let basInfo = lv_Vm.getStore ("tsStore").getters.getBasInfo.data;
      let isLast = lv_Vm.$bizUtil.tsUtils.getIsLastProc (lv_Vm);
      let t_type = 0;
      if (isProcess) {
        if (!isLast) {
          tmpContent.push ("송금계좌등록 해지");
          tmpContent.push ("송금계좌등록 해지가 완료되었습니다.");
          if (basInfo.procTyp === "custInfoReg") {
            tmpContent.push ("실시간임금관리 화면으로 이동합니다.");
          }
          t_type = 7; // 프로세스 진행 중 마지막 화면이 아닐 경우
        }
        else {
          t_type = 9; // 프로세스 진행 중 마지막 화면일 경우
        }
      }
      else { // 프로세스 진행 중이 아닐 경우
        t_type = 9;
      }
      let t_popupObj = {
        confirm: true,
        confirmFunc: lv_Vm.fn_ReportPrint,
        content: tmpContent,
        contentTitle: tmpContentTitle,
      };
      lv_Vm.fn_AlertPopup (t_type, t_popupObj);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_checkProcess ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_ReportPrint
     * 설명       : 전자서식 호출
     ******************************************************************************/
    async fn_ReportPrint () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ReportPrint ]___[ 시작 ]");
      let formList = [];
      let TS000047 = {
        formId: "TS000047",
      };
      // 리포트 테스트 데이터
      this.ds_tfio40000.newAddCls = "NEW";
      this.ds_acno.trstTypC = "001";
      this.ds_tfaacb.gdTypDtlC = "E";
      if (this.ds_tfio40000.newAddCls == "NEW") {
        // 리포트 파라미터 내 파라미터
        this.ds_searchParam = {};
        this.ds_searchParam.tacno = this.ds_acno.tacno;
        this.ds_searchParam.bnkbSeq = this.ds_acno.bnkbSeq;
        this.ds_searchParam.ctno = Number (this.ds_acno.accSeq);
        this.ds_searchParam.procType = "PP";
        this.ds_searchParam.signYn = "N";
        // 리포트 파라미터
        this.ds_searchReport.reportParam = {};
        this.ds_searchReport.reportMrdNm = "";
        this.ds_searchReport.reportUrl   = "reportTFAC20000";
        // 구분 값에 따른 설정
        if (this.ds_acno.trstTypC == "001") { // 특전금전신탁
          if (this.ds_tfaacb.gdTypDtlC == "A") { // 생전증여
            //
          }
          else { //특금 그외
            this.ds_searchParam.signYn = "Y"; // 직인을 사용하는 계약서 인 경우 Y
            this.ds_searchReport.reportMrdNm = "/cronix/fiduciary/contract/TFAC20007.mrd";
            this.ds_searchReport.reportParam = JSON.stringify (this.ds_searchParam);
            // 리포트 form 객체 목록 추가 
            TS000047.params = this.ds_searchReport;
            formList.push (TS000047);
          }
        }
      }
      /**
       * 개인신규(basInfo.procTyp : custInfoReg) 프로세스 일 경우 'fn_movePage_MSPTS213M' 이동
       */
      TSInfoUtil.commReportInfo (this, formList, this.fn_movePage_MSPTS213M);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ReportPrint ]___[ 종료 ]");
    },
    /***************************************************************************************
      code        : 호출할 공통코드
      dsName      : 호출된 공통코드를 담을 Dataset
      selecttype  : ComboBox 최상단에 추가할 문구( A: -전체-, S:-선택-, N: 빈칸)
      
      selecttype은 추후 변경될 수 있음.
      this.fn_commonAfterOnload를 화면에 선언하면 gfn_setCommonCode처리후 호출됨.
    *****************************************************************************************/
    fn_comCode () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_comCode ]___[ 시작 ]");
      // ---------
      // 'C392_F10600167_S' |
      // 'UF10600167' // [NEXT 사랑On 신탁]RT계좌 목록을 조회-selectTFIO42250AList
      // ---------
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = "S"
      // this.eaiCommObj.trnstId = "txTSSTS90S1" // selectTFCP11000A
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600167_S" // [NEXT 사랑On 신탁]RT계좌 목록을 조회-selectTFIO42250AList
      this.eaiCommObj.params = {
        cId: "5095",
      };
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [RT계좌 목록을 조회]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_comCodeCallBack);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_comCode ]___[ 종료 ]");
    }, 
    /******************************************************************************
     * Function명 : fn_comCodeCallBack
     * 설명       : 공통코드 후처리
     ******************************************************************************/
    fn_comCodeCallBack (response) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_comCodeCallBack ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [RT계좌 목록을 조회]_[EAI(PO)_(", response.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " response → ", response);
      if (this.isLoggable) console.log ("+++++++++");
      this.bnkList = response.data.slfsbizUtil
      // const resData = Object.assign ({}, response.data.tukemk)
      // this.bnkItems = response.data.tukemk
      this.bnkList.forEach (element => {
        // 추후 스크립트 처리 필요
        if (!TSCommUtil.gfn_isNull (element.cnm.trim)) {
          this.bnkItems.push ({
            value: element.c,
            text: element.cnm,
          });
        }
      });
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_comCodeCallBack ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_init
     * 설명       : data 변수 데이터 초기화
     ******************************************************************************/
    fn_init () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_init ]___[ 시작 ]");
      this.edt_acno = "";
      this.edt_amlProcNo = "";
      this.edt_aprvNo = "";
      this.edt_bkAcNm = "";
      this.edt_bnkAcno = "";
      this.edt_pwd = "";
      this.csId = "";
      this.tacno = "";
      this.cbo5095 = "";
      this.isbakAcnoDisabled = false;
      this.successYn = "";
      this.edt_pwd = "";
      this.lv_masked_val = ""; // 비밀번호 정합성 체크를 위한 초기화
      this.lv_isClear = !this.lv_isClear ? true : false;
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_init ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Open
     * 설명       : open
     ******************************************************************************/
    fn_Open (properties) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Open ]___[ 시작 ]");
      this.fn_init ();
      this.csId = properties.pCsId;
      this.tacno = properties.pTacno;
      this.edt_bkAcNm = properties.pCnm;
      this.modal.open ();
      this.fn_comCode ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Open ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Close
     * 설명       : 닫기
     ******************************************************************************/
    fn_Close () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Close ]___[ 시작 ]");
      this.modal.close ();
      this.closeDropdown ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Close ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : closeDropdown
     * 설명       : 
     ******************************************************************************/
    closeDropdown () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::closeDropdown ]___[ 시작 ]");
      this.$refs.dropdown1.close ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::closeDropdown ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       :  공통팝업호출 처리오류 코드 전면수정, corrected by anarchi (2023.08.02)
     ******************************************************************************/
    fn_AlertPopup (type, pPopupObj) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AlertPopup ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [공통팝업]_[(수신)-파라미터]", " type → ", type, " pPopupObj → ", pPopupObj);
      if (this.isLoggable) console.log ("+++++++++");
      switch (type) {
        case 0:
          if (!TSCommUtil.gfn_isNull (pPopupObj)) {
            TSInfoUtil.initAlertPopObj (type, this.pAlertPopupObj_204p, pPopupObj);
          }
          break;
        case 1:
          //
          break;
        case 2:
          //
          break;
        case 7:
          //
          break;
        case 9:
          //
          break;
          // if (!TSCommUtil.gfn_isNull (pPopupObj)) {
          //   this.pAlertPopupObj.content = pPopupObj.content;
          //   if (!TSCommUtil.gfn_isNull (pPopupObj.confirm)) {
          //     this.pAlertPopupObj.confirm = pPopupObj.confirm;
          //   }
          //   else {
          //     this.pAlertPopupObj.confirm = "";
          //   }
          //   if (!TSCommUtil.gfn_isNull (pPopupObj.confirmFunc)) {
          //     this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc;
          //   }
          //   else {
          //     this.pAlertPopupObj.confirmFunc = "";
          //   }
          //   if (pPopupObj.closeFunc != undefined) {
          //     this.pAlertPopupObj.closeFunc = "fn_Close";
          //   }
          //   else {
          //     if (!TSCommUtil.gfn_isNull (pPopupObj.closeFunc)) {
          //       this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc;
          //     }
          //     else {
          //       this.pAlertPopupObj.closeFunc = "";
          //     }
          //   }
          // }
          // break;
        default:
          // this.$refs.alertPopup_204p.fn_Open ();
      }
      this.$refs.alertPopup_204p.fn_Open ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AlertPopup ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_searchList
     * 설명       : search
     ******************************************************************************/
    fn_searchList () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_searchList ]___[ 시작 ]");
      // @TO-DO: 
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_searchList ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_cNm
     * 설명       : 예금주 확인 버튼
     ******************************************************************************/
    fn_cNm () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_cNm ]___[ 시작 ]");
      if (this.lv_basInfo == undefined) {
        let t_popupObj = {
          confirm: false,
          content: "로그인 정보가 없습니다.",
        };
        // this.$emit("ts-alert-popup", 0, t_popupObj);
        this.fn_AlertPopup (0, t_popupObj);
        return;
      }
      if(this.isMobile) {
        if (TSCommUtil.gfn_isNull (this.lv_masked_val) ) {  
          let t_popupObj = {
            confirm: false,
            content: "비밀번호를 입력하십시오.",
          }
          // this.$emit("ts-alert-popup", 0, t_popupObj);
          this.fn_AlertPopup (0, t_popupObj);
          return;
        }
      } else {
        if (TSCommUtil.gfn_length (this.edt_pwd) != 4) {  
          let t_popupObj = {
            confirm: false,
            content: "비밀번호를 입력하십시오.",
          }
          // this.$emit("ts-alert-popup", 0, t_popupObj);
          this.fn_AlertPopup (0, t_popupObj);
          return;
        }
      }
      if ( TSCommUtil.gfn_isNull ( this.cbo5095 ) ) {
        let t_popupObj = {
          confirm: false,
          content: "은행을 선택해 주십시오.",
        };
        // this.$emit("ts-alert-popup", 0, t_popupObj);
        this.fn_AlertPopup (0, t_popupObj);
        return;
      }
      // ------
      // 책임자승인용 데이터 초기화?
      // ------
      // this.processMsg = "";
      // this.edt_aprvNo = "";
      // this.edt_amlProcNo = "";
      this.ds_acctNm = Object.assign ({}, defaultAcctNm); // ds_acctNm 초기화
      this.ds_acctNm.trBkcd = this.cbo5095;
      this.ds_acctNm.bnkAcno = this.edt_bnkAcno;
      this.ds_acctNm.bnkAccNm = this.edt_bkAcNm;
      this.ds_acctNm.procEmno =  this.lv_basInfo.usid; //처리자사번
      this.ds_acctNm.csId = this.csId;
      this.ds_acctNm.tacno = this.tacno;
      this.ds_acctNm.pwd = this.edt_pwd;
      this.ds_acctNm.pwdYn = "Y";
      // ---------
      // 'C392_F10600253_S' |
      // 'UF10600253' // [NEXT 사랑On 신탁]송금계좌 상세조회
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      // this.eaiCommObj.trnstId = "txTSSTS42S2" // selectTFIO42250
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600253_S"; // [NEXT 사랑On 신탁]송금계좌 상세조회
      this.eaiCommObj.params = {
        trBkcd: this.ds_acctNm.trBkcd,
        bnkAcno: this.ds_acctNm.bnkAcno,
        bnkAccNm: this.ds_acctNm.bnkAccNm,
        procEmno: this.ds_acctNm.procEmno,
        csId: this.ds_acctNm.csId,
        tacno: this.ds_acctNm.tacno,
        pwd: this.ds_acctNm.pwd,
        pwdYn: this.ds_acctNm.pwdYn,                        
      };
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [송금계좌 상세조회]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_nameCallBack);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_cNm ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_nameCallBack
     * 설명       : 예금주 확인 후처리
     ******************************************************************************/
    fn_nameCallBack (pResultData) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_nameCallBack ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [송금계좌 상세조회]_[EAI(PO)_(", pResultData.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " pResultData → ", pResultData);
      if (this.isLoggable) console.log ("+++++++++");
      if (!TSCommUtil.gfn_isNull (pResultData.data.errorMsg)) {
        let t_popupObj = {
          confirm: false,
          content: pResultData.data.errorMsg,
        };
        this.$emit ("ts-alert-popup", 0, t_popupObj);
        return;
      }
      this.ds_detail.bnkDpstrNm = pResultData.data.bnkDpstrNm;
      this.edt_bkAcNm = pResultData.data.bnkDpstrNm;
      this.isConfirmBtnDisabled = false;
      // ---------
      // ※ JIRA 이슈[ PMPM22000016-931 ] 조치함, amended by anarchi (2023.07.11)
      //   → 송금계좌등록 시에는 예금주확인이 완료되어도 계좌번호 입력영역 readOnly 상태로 바뀌지 말아야 함
      // ---------
      // this.isbakAcnoDisabled = true;
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_nameCallBack ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_confirm
     * 설명       : 확인 버튼
     ******************************************************************************/
    fn_confirm () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_confirm ]___[ 시작 ]");
      if (this.isMobile) {
        if (TSCommUtil.gfn_isNull (this.lv_masked_val) || TSCommUtil.gfn_length (this.lv_masked_val) != 4) {
          let t_popupObj = {
            confirm: false,
            content: "비밀번호를 입력하십시오.",
          };
          // this.$emit ("ts-alert-popup", 0, t_popupObj);
          this.fn_AlertPopup (0, t_popupObj);
          return;
        }
      }
      else {
        if (TSCommUtil.gfn_isNull (this.edt_pwd) || TSCommUtil.gfn_length (this.edt_pwd) != 4) {
          let t_popupObj = {
            confirm: false,
            content: "비밀번호를 입력하십시오.",
          };
          // this.$emit ("ts-alert-popup", 0, t_popupObj);
          this.fn_AlertPopup (0, t_popupObj);
          return;
        }
      }
      if (TSCommUtil.gfn_isNull (this.cbo5095)) {
        let t_popupObj = {
          confirm: false,
          content: "은행을 선택해 주십시오.",
        };
        // this.$emit ("ts-alert-popup", 0, t_popupObj);
        this.fn_AlertPopup (0, t_popupObj);
        return;
      }    
      if (TSCommUtil.gfn_isNull (this.edt_bnkAcno)) {
        let t_popupObj = {
          confirm: false,
          content: "출금 계좌 번호를 입력해 주십시오.",
        };
        // this.$emit ("ts-alert-popup", 0, t_popupObj);
        this.fn_AlertPopup (0, t_popupObj);
        return;
      }
      this.ds_insert = Object.assign ({}, defaultInsert); // ds_insert 초기화
      this.ds_insert.csId         = this.csId;
      this.ds_insert.trBkcd       = this.cbo5095;
      this.ds_insert.bnkAcno      = this.edt_bnkAcno;
      this.ds_insert.bnkAccNm     = this.edt_bkAcNm;
      this.ds_insert.reslYn       = "N"; //취소여부
      this.ds_insert.regDate      = this.lv_basInfo.busyDate;
      this.ds_insert.regMnEmno    = this.lv_basInfo.usid; //처리자사번
      this.ds_insert.aprvNo       = this.edt_aprvNo;
      this.ds_insert.tacno        = this.tacno;
      this.ds_insert.eaiRegProcYn = "Y";
      this.ds_insert.insrAccRegYn = "Y";
      // ---------
      // 'C392_F10600166_S' |
      // 'UF10600166' // [NEXT 사랑On 신탁]송금계좌 등록-insertTFIO42250
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "I";
      // this.eaiCommObj.trnstId = "txTSSTS42I2"; // selectTFIO42250
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600166_S";
      this.eaiCommObj.params = {
        csId         : this.ds_insert.csId,
        trBkcd       : this.ds_insert.trBkcd,
        bnkAcno      : this.ds_insert.bnkAcno,
        bnkAccNm     : this.ds_insert.bnkAccNm,
        reslYn       : this.ds_insert.reslYn,
        regDate      : this.ds_insert.regDate,
        regMnEmno    : this.ds_insert.regMnEmno,
        aprvNo       : this.ds_insert.aprvNo,
        tacno        : this.ds_insert.tacno,
        eaiRegProcYn : this.ds_insert.eaiRegProcYn,
        insrAccRegYn : this.ds_insert.insrAccRegYn,
      };
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [송금계좌 등록]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_confirmCallBack);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_confirm ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_confirmCallBack
     * 설명       : 확인 후처리
     ******************************************************************************/
    fn_confirmCallBack (pResultData) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_confirmCallBack ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [송금계좌 등록]_[EAI(PO)_(", pResultData.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " pResultData → ", pResultData);
      if (this.isLoggable) console.log ("+++++++++");
      if (undefined == pResultData.data || "" != pResultData.data.errorCode) {
        let t_popupObj = {
          confirm: false,
          content: [
            pResultData.data.errorCode,
            pResultData.data.errorMsg,
          ],
        };
        this.fn_AlertPopup (0, t_popupObj);
        return;
      }
      // debugger;
      this.ds_detail0 = Object.assign ({}, defaultDetail01); // ds_insert 초기화
      // this.ds_detail0 = {}; // ds_insert 초기화
      // this.ds_detail0.amlProcYn = pResultData.data.amlProcYn;
      // this.ds_detail0.amlProcNo = pResultData.data.amlProcNo;
      // this.ds_detail0.aprvNo = pResultData.data.aprvNo;
      this.ds_detail0 = pResultData.data;
      this.edt_aprvNo = this.ds_detail0.aprvNo;
      /*
      if ( TSCommUtil.gfn_isNull ( pResultData.data.tacno ) ) {
        let t_popupObj = {
          confirm: false,
          content: "송금계좌 등록처리시 오류가 발생하였습니다.\n관리자에게 문의바랍니다."
        }
        this.$emit ("ts-alert-popup", 0, t_popupObj)
        return
      }
      */
      if (this.ds_detail0.amlProcYn == "N") {
         this.processMsg = "※ [AML]당일 송금계좌등록 고객인증 완료내역이 없습니다.(AML고객인증 후 사용)";
         this.edt_amlProcNo = this.ds_detail0.amlProcNo;
         this.isAml = false;
         return;
      }
      if (this.ds_detail0.aprvNo > 0) {
        this.fn_OpenMSPTS106P ();
        return;
      }
      else {
        this.ds_printAcct = Object.assign ({}, defaultPrintAcct); // ds_printAcct 초기화
        if ("Y" == this.successYn) {
          // ------------------
          // ※ 신탁 전자서식연계(리포트)연계호출 처리 추가, added by anarchi (2023.07.13)
          //    → TS000053_송금계좌 등록·해지 신청서
          // ------------------
          // this.fn_checkProcess ();
          this.ds_tfio42250_report = [];
          this.ds_tfio42250_report.push (this.ds_detail0);
          // this.$emit ("ts-popup204-callback", this.successYn); // 호출화면-송금계좌등록/해지(MSPTS213M) 팝업콜백 실행
          // this.$emit ("ts-popup204-callback", this.successYn, this.ds_tfio42250_report); // 호출화면-송금계좌등록/해지(MSPTS213M) 팝업콜백 실행
          this.$emit ("ts-popup204-callback", this.successYn, this.ds_insert); // 호출화면-송금계좌등록/해지(MSPTS213M) 팝업콜백 실행
          // this.modal.close ();
          this.fn_init ();
          this.fn_Close ();
        }
      }     
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_confirmCallBack ]___[ 종료 ]");
    },
    /*********************************************************
     * Function명: fn_OpenMSPTS205P
     * 설명: AML 팝업호출
     *********************************************************/
    fn_OpenMSPTS205P (type) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OpenMSPTS205P ]___[ 시작 ]");
      // let lv_vm = this;
      this.pPopup205Type = type;
      let properties = {
        pPage: "MSPTS213M",
        pTacno: this.tacno,
        pBnkbSeq: "555",
        pCtno: "555",
        pProcSeq: this.edt_amlProcNo,
        // pGdC: lv_vm.gdC, // 신탁상품
        // pTrstTypeC: lv_vm.trstTypC, // 신탁유형
        // pGdTypDtlC: lv_vm.gdTypDtlC, // 상품유형
        pUrl: "2000",
        pProcTc: "2000",
      };
      // this.popup205 = this.$refs.popup205.fn_Open (properties);
      this.$refs.popup205_204p.fn_Open (properties);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OpenMSPTS205P ]___[ 종료 ]");
    },
    /*********************************************************
     * Function명: fn_Popup205Back
     * 설명: AML 등록 팝업호출 콜백
     *********************************************************/
    fn_Popup205Back (rtnData) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup205Back ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [실제소유자 확인(개인) 팝업-(콜백)]_[(수신)-리턴객체]", " rtnData → ", rtnData);
      if (this.isLoggable) console.log ("+++++++++");
      // this.tacno = rtnData.tacno
      // this.cnm = rtnData.cnm
      // 검색
      // 처리결과 "Y" 인 경우
      // this.fn_searchList ()
      this.fn_confirm ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup205Back ]___[ 종료 ]");
    },      
    /*********************************************************
     * Function명: callbackClose
     * 설명: ??? --> deprecated 처리예정
     *********************************************************/
    callbackClose () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::callbackClose ]___[ 시작 ]");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::callbackClose ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_SetMaskedTxt
     * 설명       : 보안키패드 체크
     ******************************************************************************/
    fn_SetMaskedTxt(val) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SetMaskedTxt ]___[ 시작 ]");
      this.lv_masked_val = val;
      this.fn_PwdValid_SecuPad ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SetMaskedTxt ]___[ 종료 ]");
    },
    /*********************************************************
     * Function명: fn_OpenMSPTS106P
     * 설명: 책임자 승인요청 팝업호출
     *********************************************************/
    fn_OpenMSPTS106P (type) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OpenMSPTS106P ]___[ 시작 ]");
      // debugger;
      // let lv_vm = this;
      // ------
      // 로그인 정보 체크 ...
      // ------
      if (this.lv_basInfo == undefined) {
        let t_popupObj = {
          confirm: false,
          content: "로그인 정보가 없습니다.",
        };
        this.fn_AlertPopup (0, t_popupObj);
        return;
      }
      this.pPopup106Type = type;
      let fileName = TSCommUtil.fn_strToday () + 
        // this.getStore ("userInfo").getters.getUserInfo.brcd + 
        this.lv_basInfo.brcd + 
        this.ds_detail0.aprvNo + 
        ".png"
      ;
      let properties = {
        // pPage: "MSPTS213M",
        pPage: "MSPTS204P",
        pProcDate: TSCommUtil.fn_strToday (),
        pBrcd: this.lv_basInfo.brcd,
        pMngAprvSeq: this.ds_detail0.aprvNo,
        pFileName: "",
        pAdmGrade: "",
        pReqEmno: this.lv_basInfo.usid,
        pAmlYn: "",
        pTacno: this.tacno,
        pBnkbSeq: "555",
        pCtno: "555",
        pProcSeq: this.edt_amlProcNo,
        pUrl: "2000",
        pProcTc: "2000",
      };
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [책임자 승인요청 팝업호출_(송신)파라미터]", " properties → ", properties);
      if (this.isLoggable) console.log ("+++++++++");
      // this.popup106 = this.$refs.popup106.fn_Open (properties);
      this.$refs.popup106_204p.fn_Open (properties);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OpenMSPTS106P ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_Popup_CallBack_106
    * 설명: 책임자 승인요청  팝업호출 콜백
    *********************************************************/
    fn_Popup_CallBack_106 (pData) { // pData == successYn
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup_CallBack_106 ]___[ 시작 ]");
      if (!TSCommUtil.gfn_isNull (pData) && pData == "Y") {
        this.fn_confirm ();
      }
      else {
        // ---------
        // @TO-DO
        // 1) PO서비스 오류 반환 시, 후속처리
        // 2) 승인반려 코드 반환 시, 후속처리
        // ---------
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup_CallBack_106 ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_Popup106Back
    * 설명: 책임자 승인요청  팝업호출 콜백
    *********************************************************/
    fn_Popup106Back (rtnData) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup106Back ]___[ 시작 ]");
      if (!TSCommUtil.gfn_isNull (rtnData) && rtnData == "Y") {
        this.successYn = rtnData; // 'Y'
        this.fn_confirm ();
      }
      else {
        // 책임자승인요청처리결과 초기화
        this.successYn = ""; // '' (N)
        // 책임자승인요청 일련번호 초기화
        this.edt_aprvNo = "";
      }
      // ------------------------------------
      // (전임자 주석, 의미 파악이 되지 않음....)
      // this.ds_acno = rtnData
      // this.ds_acno.acno = rtnData.tacno
      // 등록처리 리턴결과 
      // this.tacno = rtnData.tacno
      // this.cnm = rtnData.cnm
      // 검색
      // 처리결과를 'Y' 인경우
      // this.fn_searchList ()
      // ------------------------------------
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup106Back ]___[ 종료 ]");
    },  
    /******************************************************************************
     * Function명 : fn_PwdValid
     * 설명       : 필수값 검증 후 조회
     ******************************************************************************/
    fn_PwdValid (event) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_PwdValid ]___[ 시작 ]");
      if (TSCommUtil.gfn_length (this.edt_pwd) == 4) {
        // 기존 검색 결과 초기화 함수 필요함
        this.eaiCommObj.lv_vm = this;
        this.eaiCommObj.auth = "S";
        // this.eaiCommObj.trnstId = "txTSSTS49S8" // selectTFIO49000AList
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600247_S";
        this.eaiCommObj.params = {
            acctNo: this.tacno,
            pwd: this.edt_pwd,
            pwdCls: "N",
        };
        TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_passwordCheckResult);
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_PwdValid ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_PwdValid_SecuPad
     * 설명       : 필수값 검증 후 조회 (보안키패드용)
     ******************************************************************************/
    fn_PwdValid_SecuPad (event) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_PwdValid_SecuPad ]___[ 시작 ]");
      // if (TSCommUtil.gfn_length (this.edt_pwd) == 4 || TSCommUtil.gfn_length (this.lv_masked_val) == 4) {
        // 기존 검색 결과 초기화 함수 필요함
        this.eaiCommObj.lv_vm = this;
        this.eaiCommObj.auth = "S";
        // this.eaiCommObj.trnstId = "txTSSTS49S8" // selectTFIO49000AList
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600247_S";
        this.eaiCommObj.params = {
            acctNo: this.tacno,
            pwd: this.edt_pwd,
            pwdCls: "N",
        };
        TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_passwordCheckResult);
      // }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_PwdValid_SecuPad ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_passwordCheckResult
     * 설명       : 비밀번호 체크 후처리
     ******************************************************************************/
    fn_passwordCheckResult (pResultData) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_passwordCheckResult ]___[ 시작 ]");
      if (!TSCommUtil.gfn_isNull (pResultData.data.errorMsg)) {
        this.fn_AlertPopup (0, {
          content: pResultData.data.errorMsg,
        });
        this.edt_pwd = ""; // 비밀번호 입력란 초기화
        this.lv_masked_val = ""; // (보안키패드) 비밀번호 입력란 데이터 초기화
        this.lv_isClear = false; // (보안키패드) 비밀번호 입력란 데이터 초기화
        return;
      } 
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_passwordCheckResult ]___[ 종료 ]");
    },
    // ======================================================
    // (업무:데이터 제어) fn_bnkAcnoMask:: 계좌번호 매스킹 처리
    // ======================================================
    fn_bnkAcnoMask (val) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_bnkAcnoMask ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [계좌번호(입력요소) 매스킹 처리 반환]", " val → ", val);
      if (this.isLoggable) console.log ("+++++++++");
      let _maskVal = val;
      if (!TSCommUtil.gfn_isNull (_maskVal)) {
        return TSCommUtil.gfn_bnkAcno_mask (_maskVal);
      }
      return _maskVal;
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_bnkAcnoMask ]___[ 종료 ]");
    },
  },
};
</script>

<style scoped></style>