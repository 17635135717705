/*
 * 업무구분: 신탁>고객/상품>상품자료>판매자료조회>상품조회팝업_P
 * 화 면 명: MSPTS110S
 * 화면설명: 상품조회 POPUP (임시)
 * 작 성 일: 2023.04.26
 * 작 성 자: 송진의
 */
<template>
  <!-- popup -->
  <mo-modal class="fts-modal large" ref="modal" title="상품조회">
    <template>
      <div class="wrap-modalcontents">
        <div class="input-top">
          <div class="column w100p gap12">
            <div class="row w100p">
              <div class="left w100p mr0">
                <div class="wrap-input row">
                  <label> 상품코드 </label>
                  <mo-text-field                      
                    v-model="lv_gd_cd.value"
                    :disabled="lv_gd_cd.disabled"
                    maxlength="6"
                  />
                </div>
                <div class="wrap-input row">
                  <label> 신탁유형 </label>
                  <mo-dropdown class="w200"
                    ref='dropdown1' 
                    :items="cmb_trst_typ_cd" 
                    v-model="lv_cob_trst_typ_cd" 
                    @input="fn_SetTrstTypCd"
                  />
                </div>
                <div class="wrap-input row">
                  <label> 상품유형 </label>
                  <mo-dropdown class="w200"
                    ref='dropdown2' 
                    :items="cmb_prdt_typ_cd" 
                    v-model="lv_cob_prdt_typ_cd.value" 
                    :disabled="lv_cob_prdt_typ_cd.disabled"
                  />
                </div>
              </div>
            </div>
            <div class="row w100p">
              <div class="left w100p">
                <div class="wrap-input row">
                  <label class="w49"> 상품명 </label>
                  <mo-text-field class="form-input-name w100p" 
                    v-model="lv_gd_nm.value"
                    :disabled="lv_gd_nm.disabled" 
                    clearable placeholder="입력하세요" 
                  />
                </div>
              </div>
              <div class="right">
                <div class="wrap-button row">
                  <mo-button :disabled="lv_btn_init" @click="fn_Init('btn')"> 초기화 </mo-button>
                  <mo-button primary class="btn-inquiry" :disabled="lv_btn_search" @click="fn_GdSearch('S')"> 조회 </mo-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="wrap-table-title">
          <h3 class="table-title"> 조회내역 </h3>
        </div>
        <div class="wrap-table h-scroll-250">
          <table class="table col-type th-row-gray text-center">
            <thead>
              <tr>
                <th class="w40"> </th>
                <th> 상품코드 </th>
                <th> 상품명 </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, idx) in lv_gd_search_list" :key="idx" :class="{'checked': lv_prdt_id == String(idx)}" @click="lv_prdt_id = String(idx)" > 
                <td>
                  <mo-radio v-model="lv_prdt_id" :value="String(idx)"></mo-radio>
                </td>
                <td>
                  {{row.gdC}}
                </td>
                <td>
                  {{row.gdNm}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_Close">닫기</mo-button>
        <mo-button primary size="large" :disabled="lv_prdt_id === ''" @click="fn_Confirm">확인</mo-button>
      </div>
    </template>
  </mo-modal>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'  // Alert 팝업 (공통)

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPTS110P",
  screenId: "MSPTS110P",
  components: {    
    'ts-alert-popup': TSAlertPopup,
  },
  props: {
    popupObj: {type:Object, default:null},
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
  },  
  mounted() {    
    this.$bizUtil.insSrnLog("MSPTS110P")    
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // 공통 객체
      pHeaderObj: {          
        title: '상품조회팝업',
      },

      pAlertPopupObj: {},

      // 버튼
      lv_btn_search: false,          // 조회
      lv_btn_init: false,            // 초기화

      // 조회
      lv_cob_trst_typ_cd: '',        // 신탁유형
      lv_cob_prdt_typ_cd: {},        // 상품유형
      lv_gd_cd: {},                  // 상품코드
      lv_gd_nm: {},                  // 상품명
      lv_prdt_id: '',                // 상품 선택 ID

      lv_gd_search_list: [],        // 상품조회 목록

      //공통코드
      cmb_trst_typ_cd: [],          // 신탁유형
      cmb_prdt_typ_cd_all: [],      // 상품유형 전체
      cmb_prdt_typ_cd: [],          // 상품유형
      
      // Contents Model 
      eaiCommObj: TSCommUtil.gfn_eaiCommObj(),
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    // modal
    modal() {
      return this.$refs.modal
    },
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_Open() {      
      this.fn_Init()
      this.fn_SetCommCode()
      this.modal.open()
    },
    fn_Close() {
      this.modal.close()
      this.fn_CloseDropdown()
    },
    fn_CloseDropdown() {
      this.$refs.dropdown1.close()
      this.$refs.dropdown2.close()
    },
    fn_Confirm() {
      if( !TSCommUtil.gfn_isNull(this.lv_gd_search_list) && this.lv_gd_search_list.length > 0) {                 
        let tmpRtn = this.lv_gd_search_list[Number(this.lv_prdt_id)]
        let objRtn = {          
          gdC: tmpRtn.gdC,
          gdNm: tmpRtn.gdNm,
          // trstTypC: tmpRtn.trstTypC
        }      
        this.$emit('ts-popup-callback', 0, objRtn)
        this.fn_Close()        
      } else {        
        TSCommUtil.gfn_validate(this, '조회 데이터가 올바르지 않습니다.')
        return
      }
    },   
    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/      
    fn_AlertPopup (type, pPopupObj) {
      switch (type) {
        case 0:
          if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
            this.pAlertPopupObj.content = pPopupObj.content

            if( pPopupObj.confirm ) {
              this.pAlertPopupObj.confirm = pPopupObj.confirm
              this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
            }
          }
          break;
      }

      this.$refs.alertPopup.fn_Open()
    },
    /******************************************************************************
     * Function명 : fn_SetCommCode, fn_CommCodeCallBack
     * 설명       : 공통코드 세팅
     ******************************************************************************/
    fn_SetCommCode() {
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.trnstId = 'txTSSTS90S1' // selectTFCP11000A
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600046_S'        
      this.eaiCommObj.params = {'TUKEMK':[
                                  {cId:"5014"},   // 신탁유형                                    
                                  {cId:"5010"},   // 금전신탁일때 - 신탁상세유형 : 금전신탁(001),재산신탁(002)
                                  {cId:"5011"},
                                  {cId:"5012"},
                                  {cId:"5013"},
                                  {cId:"5139"},
                                  {cId:"5140"},
                                  {cId:"5059"},   // 치매신탁 20210831
                                  {cId:"5173"},   // 유언대용신탁(금전)유형코드 20230126
                                  {cId:"5174"},   // 유언대용신탁(유가증권)유형코드 20230126
                                  {cId:"5175"}    // 유언대용신탁(부동산)유형코드 20230126
                              ]}
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CommCodeCallBack, this.fn_error)
    },
    fn_CommCodeCallBack ( res ) {      
      let tmpKey = ''      
      for ( let index in res.data.tukemk ) {
        let item = res.data.tukemk[index]
        let tmpIsAll = false
        switch (item.cid) 
        {
          case "5014": 
            this.cmb_trst_typ_cd.push({value: item.c , text: item.cnm})
            break
          case "5010":
            tmpIsAll = true
            tmpKey = '001'            
            break
          case "5011":
            tmpIsAll = true
            tmpKey = '002'
            break
          case "5012":
            tmpIsAll = true
            tmpKey = '003'
            break
          case "5013":
            tmpIsAll = true
            tmpKey = '004'
            break
          case "5139":
            tmpKey = '005'
            break
          case "5140":
            tmpKey = '006'
            break
          case "5059":
            tmpKey = '007'
            break
          case "5173":
            tmpKey = '008'
            break
          case "5174":
            tmpKey = '009'
            break
          case "5175":
            tmpKey = '010'              
            break
        }
        
        if(item.cid !== '5014') {
          this.cmb_prdt_typ_cd_all.push({key: tmpKey, value: item.c , text: item.cnm, isAll: tmpIsAll})
        }
      }  
      
      // 정렬      
      this.cmb_prdt_typ_cd_all = _.orderBy(this.cmb_prdt_typ_cd_all, ['key'], ['asc'])
      this.$nextTick(() => {
        console.log('fn_Init - popupObj > ', this.popupObj)
        this.lv_cob_trst_typ_cd = this.popupObj.gdTypDtlC
        this.lv_gd_cd.value     = this.popupObj.gdC
        this.fn_SetTrstTypCd()
        this.fn_GdSearch()
      }) 
    },
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     * 파라미터(type) : init - 팝업 열릴 시, btn - 초기화 버튼 클릭 시
     ******************************************************************************/
    fn_Init(type = 'init') {
      console.log('[MSPTS108M] fn_Init...')

      // 초기 콤보박스 설정 
      if(type === 'init') {
        this.cmb_trst_typ_cd = [] // 신탁유형
        this.cmb_prdt_typ_cd = [] // 상품유형
        this.cmb_prdt_typ_cd_all = []
        this.cmb_trst_typ_cd.push({value: '' , text: '전체'})      
      }

      // 검색조건
      this.lv_cob_trst_typ_cd          = ''                                                      // 신탁유형
      this.lv_cob_prdt_typ_cd          = TSCommUtil.gfn_dataSet(1, '', true, '', 'prdt_typ_cd')  // 상품코드 (콤보박스 선택 시)
      this.lv_gd_cd                    = TSCommUtil.gfn_dataSet(1, '', false, '', 'gd_cd')       // 상품코드
      
      // 헤더 검색결과
      this.lv_gd_nm                    = TSCommUtil.gfn_dataSet(1, '', false, '', 'gd_nm')       // 상품명
      
      this.lv_btn_search               = false                          // 조회
      this.lv_btn_init                 = false                          // 초기화

      this.fn_InitData()
    },
    
    /******************************************************************************
     * Function명 : fn_InitData
     * 설명       : 조회 전 데이터 초기화
     * 파라미터   : init - 초기화 시 / search - 조회 시
     ******************************************************************************/
    fn_InitData(type = 'init') {
      // 바디 검색결과
      this.lv_gd_search_list          = []
      this.lv_prdt_id                 = ''      // 데이터 선택 시
    },
    /******************************************************************************
     * Function명 : fn_SetTrstTypCd
     * 설명       : 신탁유형 선택 시 이벤트     
     ******************************************************************************/
    fn_SetTrstTypCd() {      
      let rtn = []
      let tmpCd = []
      let isAllFlag = false // 전체 포함 여부

      if(TSCommUtil.gfn_isNull(this.cmb_prdt_typ_cd_all)){
        return
      }

      this.cmb_prdt_typ_cd = []
      this.lv_cob_prdt_typ_cd.value = ''      
      if (!TSCommUtil.gfn_isNull(this.lv_cob_trst_typ_cd)) {                
        tmpCd = this.cmb_prdt_typ_cd_all.filter(v => v.key === this.lv_cob_trst_typ_cd)        
        if (tmpCd.length > 0) {
          tmpCd.forEach(item => {
            if(item.isAll && !isAllFlag) {           
              rtn.push({value: '' , text: '전체'})
              isAllFlag = true
            }
            rtn.push({text: item.text, value: item.value})
          })
        }
        
        if (rtn.length > 0){
          if(rtn[0].value !== ''){ // 첫번째 항목이 전체가 아니면 첫번째 항목 선택
            this.lv_cob_prdt_typ_cd.value = rtn[0].value
          }
        }

        this.cmb_prdt_typ_cd = rtn        
        this.lv_cob_prdt_typ_cd.disabled = false
      } else {
        // 신탁유형이 없을 때, 전체 선택으로 간주
        rtn.push({value: '' , text: '전체'})
        this.cmb_prdt_typ_cd_all.forEach(item => {          
          rtn.push({text: item.text, value: item.value})
        })
        this.cmb_prdt_typ_cd = rtn
        this.lv_cob_prdt_typ_cd.disabled = true
      }
    },    
    /******************************************************************************
     * Function명 : fn_SetParam
     * 설명       : 파라미터 설정
     ******************************************************************************/
    fn_SetParam() {
      let param = {}

      if (!TSCommUtil.gfn_isNull(this.lv_cob_trst_typ_cd)) {
        param.trstTypC = this.lv_cob_trst_typ_cd
      }

      if (!TSCommUtil.gfn_isNull(this.lv_cob_prdt_typ_cd.value)) {
        param.gdTypDtlC = this.lv_cob_prdt_typ_cd.value
      }

      if (!TSCommUtil.gfn_isNull(this.lv_gd_cd.value)) {
        param.gdC = this.lv_gd_cd.value
      }

      if (!TSCommUtil.gfn_isNull(this.lv_gd_nm.value)) {
        param.gdNm = this.lv_gd_nm.value
      }

      param.tmtlNeedDds='' 
      param.intPymDd='' 
      param.lmtAmt='' 
      param.amtUnit='' 
      param.lwstIamtAmt='' 
      param.histIamtAmt='' 
      param.lwstEtrtAmt='' 
      param.mtrtMinTrm='' 
      param.mtrtMaxTrm='' 
      param.loanPosbRto='' 
      param.saleAmt='' 
      param.aprvAmt='' 
      param.rsvAmt='' 
      param.basTfrt='' 
      param.mdtmFert01='' 
      param.mdtmFert02='' 
      param.mdtmFert03='' 
      param.mdtmStrtDds01='' 
      param.mdtmEndDds01='' 
      param.mdtmStrtDds02='' 
      param.mdtmEndDds02='' 
      param.mdtmStrtDds03='' 
      param.mdtmEndDds03='' 
      param.appnTrm='' 
      param.epayTfrt='' 
      param.ftrmPymAmt='' 
      param.prfeRt='' 
      param.aocpRgfeDd='' 
      param.aocpRgfe='' 
      param.epayFeeAmt='' 
      param.mtrtAfTfrt='' 
      param.prfe=''

      return param
    },
    /******************************************************************************
     * Function명 : fn_GdSearch
     * 설명       : 상품조회 (selectTPUP95330List)
     ******************************************************************************/
    fn_GdSearch(state = '') {
      console.log('fn_GdSearch.....')
      
      this.fn_InitData()
      
      // 서비스 호출
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.trnstId = 'txTSSTS95S1'
      this.eaiCommObj.auth = 'S'

      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600216_S'          
      this.eaiCommObj.params = this.fn_SetParam()
      console.log('fn_GdSearch - eaiCommObj >',  this.eaiCommObj)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CallBack, this.fn_error)
      // TSServiceUtil.invoke(this.eaiCommObj, this.fn_CallBack, this.fn_error)
    },
    /******************************************************************************
     * Function명 : fn_CallBack
     * 설명       : 조회 시 호출 결과
     ******************************************************************************/
    fn_CallBack(pResultData) {      
      
      // 오류 확인
      let t_data = pResultData.data      
      if (t_data !== null && t_data !== '') {
        if (TSCommUtil.gfn_trim(t_data.errorCode) !== '') {
          return false
        }

        if(!TSCommUtil.gfn_isNull(t_data.tpup95330) && t_data.tpup95330.length > 0){
          this.lv_gd_search_list = t_data.tpup95330
        }
      }
    },    
  }
};
</script>
<style scoped>
</style>