/*
 * 업무구분: 계약관계자 관리
 * 화 면 명: MSPTS302P
 * 화면설명: 계약관계자 관리
 * 작 성 일: 2023.03.02
 * 작 성 자: 김경태
 */
<template>
  <mo-modal class="fts-modal large" ref="modal" title="계약관계자 관리">
    <template>
      <div class="wrap-modalcontents">
        <div class="wrap-table">
          <table class="table col-type num-type">
            <colgroup>
              <col width="20%">
              <col width="30%">
              <col width="20%">
              <col width="30%">
            </colgroup>
            <tbody>
              <tr>
                <th> 위탁자의 친권자 또는 후견인 </th>
                <td>
                  <div class="wrap-input row">
                    <!--<mo-text-field v-model="mae_rcno00" @click-icon="fn_OpenMSPTS107P('00')" type="searchable" :disabled="btnPopUp00" class="input-long"> </mo-text-field>-->
                    <mo-text-field v-model="mae_rcno00" :disabled="btnPopUp00" class="input-long" readonly > </mo-text-field>
                    <mo-button class="btn-pop-download" @click="fn_OpenMSPTS107P('00')" ></mo-button>
                    <mo-text-field v-model="mae_cnm00" class="w80" disabled />
                  </div>
                </td>
                <th> 수익자지정권자 </th>
                <td>
                  <div class="wrap-input row">
                    <!--<mo-text-field v-model="mae_rcno04" @click-icon="fn_OpenMSPTS107P('04')" type="searchable" :disabled="btnPopUp04" class="btn-pop-download"> </mo-text-field>-->
                    <mo-text-field v-model="mae_rcno04" :disabled="btnPopUp04" class="input-long" readonly > </mo-text-field>
                    <mo-button class="btn-pop-download" @click="fn_OpenMSPTS107P('04')" ></mo-button>
                    <mo-text-field v-model="mae_cnm04" class="w80" disabled />
                  </div>
                </td>
              </tr>
              <tr>
                <th rowspan="3"> 신탁관리인 </th>
                <td>
                  <div class="wrap-input row">
                    <!--<mo-text-field v-model="mae_rcno01" @click-icon="fn_OpenMSPTS107P('01')" type="searchable" :disabled="btnPopUp01" class="btn-pop-download"> </mo-text-field>-->
                    <mo-text-field v-model="mae_rcno01" :disabled="btnPopUp01" class="input-long" readonly > </mo-text-field>
                    <mo-button class="btn-pop-download" @click="fn_OpenMSPTS107P('01')" ></mo-button>
                    <mo-text-field v-model="mae_cnm01" class="w80" disabled />
                  </div>
                </td>
                <th> 사망통지인 </th>
                <td>
                  <div class="wrap-input row">
                    <!--<mo-text-field v-model="mae_rcno05" @click-icon="fn_OpenMSPTS107P('05')" type="searchable" :disabled="btnPopUp05" class="btn-pop-download"> </mo-text-field>-->
                    <mo-text-field v-model="mae_rcno05" :disabled="btnPopUp05" class="input-long" readonly > </mo-text-field>
                    <mo-button class="btn-pop-download" @click="fn_OpenMSPTS107P('05')" ></mo-button>
                    <mo-text-field v-model="mae_cnm05" class="w80" disabled />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- Jira : PMPM22000016-1151, 2023-07-17, 김지원 부장님 삭제 요청으로 주석 처리
                  <div class="wrap-input row">
                    <mo-text-field v-model="mae_rcno02" :disabled="btnPopUp02" class="input-long" readonly > </mo-text-field>
                    <mo-button class="btn-pop-download" @click="fn_OpenMSPTS107P('02')" ></mo-button>
                    <mo-text-field v-model="mae_cnm02" class="w80" disabled />
                  </div>
                  -->
                </td>
                <th> 유언집행자 </th>
                <td>
                  <div class="wrap-input row">
                    <!--<mo-text-field v-model="mae_rcno06" @click-icon="fn_OpenMSPTS107P('06')" type="searchable" :disabled="btnPopUp06" class="btn-pop-download"> </mo-text-field>-->
                    <mo-text-field v-model="mae_rcno06" :disabled="btnPopUp06" class="input-long" readonly > </mo-text-field>
                    <mo-button class="btn-pop-download" @click="fn_OpenMSPTS107P('06')" ></mo-button>
                    <mo-text-field v-model="mae_cnm06" class="w80" disabled />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- Jira : PMPM22000016-1151, 2023-07-17, 김지원 부장님 삭제 요청으로 주석 처리
                  <div class="wrap-input row">
                    <mo-text-field v-model="mae_rcno03" :disabled="btnPopUp03" class="input-long" readonly > </mo-text-field>
                    <mo-button class="btn-pop-download" @click="fn_OpenMSPTS107P('03')" ></mo-button>
                    <mo-text-field v-model="mae_cnm03" class="w80" disabled />
                  </div>
                  -->
                </td>
                <th> 별납수수료영수증 발행 기타입력사항 </th>
                <td>
                  <div class="wrap-input row">
                    <!--<mo-text-field v-model="mae_rcno07" @click-icon="fn_OpenMSPTS107P('07')" type="searchable" :disabled="btnPopUp07" class="btn-pop-download"> </mo-text-field>-->
                    <mo-text-field v-model="mae_rcno07" :disabled="btnPopUp07" class="btn-pop-download" readonly > </mo-text-field>
                    <mo-button class="btn-pop-download" @click="fn_OpenMSPTS107P('07')" ></mo-button>
                    <mo-text-field v-model="mae_cnm07" class="w80" disabled />
                  </div>
                </td>
              </tr>
              <tr>
                <th> 신탁재산의 증여자 </th>
                <td>
                  <div class="wrap-input row">
                    <!--<mo-text-field v-model="mae_rcno08" class="input-long" type="searchable" :disabled="btnPopUp08" @click-icon="fn_OpenMSPTS107P('08')" />-->
                    <mo-text-field v-model="mae_rcno08" class="input-long" :disabled="btnPopUp08" readonly />
                    <mo-button class="btn-pop-download" @click="fn_OpenMSPTS107P('08')" ></mo-button>
                    <mo-text-field v-model="mae_cnm08" class="w80" disabled />
                  </div>
                </td>
              </tr>
              <tr>
                <th> 임의 후견인 </th>
                <td>
                  <div class="wrap-input row">
                    <!--<mo-text-field v-model="mae_rcno09" class="input-long" type="searchable" :disabled="btnPopUp09" @click-icon="fn_OpenMSPTS107P('09')" />-->
                    <mo-text-field v-model="mae_rcno09" class="input-long" :disabled="btnPopUp09" readonly />
                    <mo-button class="btn-pop-download" @click="fn_OpenMSPTS107P('09')" ></mo-button>
                    <mo-text-field v-model="mae_cnm09" class="w80" disabled />
                  </div>
                </td>
                  <th> 고객과의 관계 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field v-model="edt_vlntrGudRel" class="input-long"/>
                  </div>
                </td>
              </tr>
              <tr>
                <th> E-mail 주소 </th>
                <td colspan="3">
                  <div class="wrap-input row">
                    <mo-text-field v-model="edt_emailAddr" class="w204" placeholder="입력하세요" />
                    <span class="ico-between-email"> @ </span>
                    <mo-text-field v-model="edt_domnNm" class="input-long" placeholder="입력하세요" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_Close">닫기</mo-button>
        <mo-button primary size="large" @click="fn_Confirm">확인</mo-button>
      </div>
    </template>

    <ts-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></ts-alert-popup>

    <msp-ts-107p
      ref="popup107"
      :popup107Obj="pPopup107Obj"
      @ts-popup107-callback="fn_Popup107Back"
    ></msp-ts-107p>
  </mo-modal>
</template>

<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import moment from 'moment'
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'
import MSPTS107P from '@/ui/ts/MSPTS107P' //고객조회

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPTS302P',
  // 현재 화면 ID
  screenId: 'MSPTS302P',
  // 컴포넌트 선언
  components: {
    'ts-alert-popup': TSAlertPopup,
    'msp-ts-107p': MSPTS107P
  },
  // 화면명
  props: {
    pPage: String,      // 부모 페이지명
    pGdC: String,       // 신탁상품
    pTrstTypeC: String, // 신탁유형
    pGdTypDtlC: String, // 상품유형
    pUrl: String,
    pProcTc: String,
    popupObj: {type:Object, default:null}
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      // 공통 변수
      isInit: false,          // 초기화 여부
      rowType: '',
      rowData: [],
      rowSelect: {},
      pAlertPopupObj: {},
      lv_SelectedItem: '',
      mae_rcno00: '',
      mae_rcno01: '',
      mae_rcno02: '',
      mae_rcno03: '',
      mae_rcno04: '',
      mae_rcno05: '',
      mae_rcno06: '',
      mae_rcno07: '',
      mae_rcno08: '',
      mae_rcno09: '',
      mae_cnm00:'',
      mae_cnm01:'',
      mae_cnm02:'',
      mae_cnm03:'',
      mae_cnm04:'',
      mae_cnm05:'',
      mae_cnm06:'',
      mae_cnm07:'',
      mae_cnm08:'',
      mae_cnm09:'',
      mae_csId00:'',
      mae_csId01:'',
      mae_csId02:'',
      mae_csId03:'',
      mae_csId04:'',
      mae_csId05:'',
      mae_csId06:'',
      mae_csId07:'',
      mae_csId08:'',
      mae_csId09:'',
      edt_emailAddr:'',
      edt_domnNm:'',
      edt_vlntrGudRel:'',
      btnPopUp00:false,
      btnPopUp01:false,
      btnPopUp02:false,
      btnPopUp03:false,
      btnPopUp04:false,
      btnPopUp05:false,
      btnPopUp06:false,
      btnPopUp07:false,
      btnPopUp08:false,
      btnPopUp09:false,
      lv_Params:{},
      lv_ReturnVal: {}, // 리턴값
      lv_ReturnStr: [], // 베이스 화면으로 리턴할 조회조건 텍스트
      popup107: {}, // MSPTS107P
      pPopup107Obj: {},
      isPopupInit: '', // 추가입금 여부
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    modal() {
      return this.$refs.modal
    }
  },
  
  /** watch 정의 영역 */
  watch: {},

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {

  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPTS302P')
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     ******************************************************************************/
    fn_Init (lvParam) {
      this.isInit = false          // 초기화 여부
      this.rowData = []
    },
    /******************************************************************************
     * Function명 : fn_Open
     * 설명       : open
     ******************************************************************************/
    fn_Open(properties){
      //copy data
      this.mae_rcno00      = properties.dsDetail[0]["trsrLawcAgntRcno"];
      this.mae_cnm00       = properties.dsDetail[0]["trsrLawcAgntRcnoNm"];
      this.mae_rcno01      = properties.dsDetail[0]["trstMngpRcno1"];
      this.mae_cnm01       = properties.dsDetail[0]["trstMngpRcno1Nm"];
      this.mae_rcno02      = properties.dsDetail[0]["trstMngpRcno2"];
      this.mae_rcno03      = properties.dsDetail[0]["trstMngpRcno3"];
      this.mae_cnm02       = properties.dsDetail[0]["trstMngpRcno2Nm"];
      this.mae_cnm03       = properties.dsDetail[0]["trstMngpRcno3Nm"];
      this.mae_cnm04       = properties.dsDetail[0]["bnfcAporRcnoNm"];
      this.mae_cnm05       = properties.dsDetail[0]["dethAlmnRcnoNm"];
      this.mae_cnm06       = properties.dsDetail[0]["awilEtorRcnoNm"];
      this.mae_csId00      = properties.dsDetail[0]["trsrLawcAgntCsId"];
      this.mae_csId01      = properties.dsDetail[0]["trstMngpCsId1"];
      this.mae_csId02      = properties.dsDetail[0]["trstMngpCsId2"];
      this.mae_csId03      = properties.dsDetail[0]["trstMngpCsId3"];
      this.mae_csId04      = properties.dsDetail[0]["bnfcAporCsId"];
      this.mae_csId05      = properties.dsDetail[0]["dethAlmnCsId"];
      this.mae_csId06      = properties.dsDetail[0]["awilEtorCsId"];
      this.mae_rcno04      = properties.dsDetail[0]["bnfcAporRcno"];
      this.mae_rcno06      = properties.dsDetail[0]["awilEtorRcno"];
      this.mae_rcno05      = properties.dsDetail[0]["dethAlmnRcno"];
      this.mae_rcno07      = properties.dsDetail[0]["retuCtrmRcno"];
      this.mae_cnm07       = properties.dsDetail[0]["retuCtrmRcnoNm"];
      this.mae_csId07      = properties.dsDetail[0]["retuCtrmCsId"];
      this.mae_rcno08      = properties.dsDetail[0]["trstPrptDnrRcno"];
      this.mae_cnm08       = properties.dsDetail[0]["trstPrptDnrRcnoNm"];
      this.mae_csId08      = properties.dsDetail[0]["trstPrptDnrCsId"];
      this.edt_emailAddr   = properties.dsDetail[0]["emailAddr"];
      this.edt_domnNm      = properties.dsDetail[0]["domnNm"];
      this.mae_rcno09      = properties.dsDetail[0]["vlntrGudRcno"];
      this.mae_cnm09       = properties.dsDetail[0]["vlntrGudRcnoNm"];
      this.mae_csId09      = properties.dsDetail[0]["vlntrGudCsId"];
      this.edt_vlntrGudRel = properties.dsDetail[0]["vlntrGudRel"];

      this.lv_Params = properties
      if(_.isEmpty(this.lv_Params.pBenabled) || this.lv_Params.pBenabled === 'N'){
        this.btnPopUp00 = true
        this.btnPopUp01 = true
        this.btnPopUp02 = true
        this.btnPopUp03 = true
        this.btnPopUp04 = true
        this.btnPopUp05 = true
        this.btnPopUp06 = true
        this.btnPopUp07 = true
        this.btnPopUp08 = true
        this.btnPopUp09 = true

        if ( this.lv_Params.pgmId == '2' ) { //추가입금 여부
          this.isPopupInit = '2'
        } else {
          this.isPopupInit = ''
        }
      }
      this.fn_Init()
      this.modal.open()
    },
    /******************************************************************************
     * Function명 : fn_Close
     * 설명       : 닫기
     ******************************************************************************/
    fn_Close(){
      this.modal.close()
    },
    /******************************************************************************
     * Function명 : fn_Clear
     * 설명       : 입력값 모두 초기화 (초기 값으로 셋팅)
     ******************************************************************************/
    fn_Clear () {
      
    },
    /******************************************************************************
     * Function명 : fn_Search
     * 설명       : 조회
     ******************************************************************************/
    fn_Search () {
    },
    /******************************************************************************
     * Function명 : fn_Confirm
     * 설명       : 확인
     ******************************************************************************/
    fn_Confirm () {
      let dsDetail={
        trsrLawcAgntRcno	: this.mae_rcno00,
        trsrLawcAgntRcnoNm: this.mae_cnm00,
        trstMngpRcno1		  : this.mae_rcno01,
        trstMngpRcno1Nm		: this.mae_cnm01,
        trstMngpRcno2		  : this.mae_rcno02,
        trstMngpRcno3		  : this.mae_rcno03,
        trstMngpRcno2Nm		: this.mae_cnm02,
        trstMngpRcno3Nm		: this.mae_cnm03,
        bnfcAporRcnoNm		: this.mae_cnm04,
        dethAlmnRcnoNm		: this.mae_cnm05,
        awilEtorRcnoNm		: this.mae_cnm06,
        trsrLawcAgntCsId	: this.mae_csId00,
        trstMngpCsId1		  : this.mae_csId01,
        trstMngpCsId2		  : this.mae_csId02,
        trstMngpCsId3		  : this.mae_csId03,
        bnfcAporCsId		  : this.mae_csId04,
        dethAlmnCsId		  : this.mae_csId05,
        awilEtorCsId		  : this.mae_csId06,
        bnfcAporRcno		  : this.mae_rcno04,
        awilEtorRcno		  : this.mae_rcno06,
        dethAlmnRcno		  : this.mae_rcno05,
        retuCtrmRcno		  : this.mae_rcno07,
        retuCtrmRcnoNm		: this.mae_cnm07,
        retuCtrmCsId		  : this.mae_csId07,
        trstPrptDnrRcno		: this.mae_rcno08,
        trstPrptDnrRcnoNm	: this.mae_cnm08,
        trstPrptDnrCsId		: this.mae_csId08,
        emailAddr			    : this.edt_emailAddr,
        domnNm				    : this.edt_domnNm,
        vlntrGudRcno		  : this.mae_rcno09,
        vlntrGudRcnoNm		: this.mae_cnm09,
        vlntrGudCsId		  : this.mae_csId09,
        vlntrGudRel 		  : this.edt_vlntrGudRel
      }
      this.$emit('ts-popup302-callback', dsDetail)
      this.fn_Close()
    },
    /*********************************************************
    * Function명: fn_OpenMSPTS107P
    * 설명: 고객조회 팝업호출
    *********************************************************/
    fn_OpenMSPTS107P (type) {
      let lv_vm = this
      this.rowType = type
      let properties = {
        pPage : 'MSPTS302P',
        pType: type
      }
      if ( this.isPopupInit != '2') { //추가입금 아닌 경우 만 POPUP 호출
        this.popup107 = this.$refs.popup107.fn_Open(properties)
      }

    },
    fn_Popup107Back(rtnData){
      if(this.rowType === '00'){
        this.mae_rcno00 = rtnData.rcno
        this.mae_cnm00 = rtnData.cnm
        this.mae_csId00 = rtnData.csId
      }else if(this.rowType === '01'){
        this.mae_rcno01 = rtnData.rcno
        this.mae_cnm01 = rtnData.cnm
        this.mae_csId01 = rtnData.csId
      }else if(this.rowType === '02'){
        this.mae_rcno02 = rtnData.rcno
        this.mae_cnm02 = rtnData.cnm
        this.mae_csId02 = rtnData.csId
      }else if(this.rowType === '03'){
        this.mae_rcno03 = rtnData.rcno
        this.mae_cnm03 = rtnData.cnm
        this.mae_csId03 = rtnData.csId
      }else if(this.rowType === '04'){
        this.mae_rcno04 = rtnData.rcno
        this.mae_cnm04 = rtnData.cnm
        this.mae_csId04 = rtnData.csId
      }else if(this.rowType === '05'){
        this.mae_rcno05 = rtnData.rcno
        this.mae_cnm05 = rtnData.cnm
        this.mae_csId05 = rtnData.csId
      }else if(this.rowType === '06'){
        this.mae_rcno06 = rtnData.rcno
        this.mae_cnm06 = rtnData.cnm
        this.mae_csId06 = rtnData.csId
      }else if(this.rowType === '07'){
        this.mae_rcno07 = rtnData.rcno
        this.mae_cnm07 = rtnData.cnm
        this.mae_csId07 = rtnData.csId
      }else if(this.rowType === '08'){
        this.mae_rcno08 = rtnData.rcno
        this.mae_cnm08 = rtnData.cnm
        this.mae_csId08 = rtnData.csId
      }else if(this.rowType === '09'){
        this.mae_rcno09 = rtnData.rcno
        this.mae_cnm09 = rtnData.cnm
        this.mae_csId09 = rtnData.csId
      }
    }
  }
}
</script>