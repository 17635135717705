/*
 * 업무구분: 종목정보검색
 * 화 면 명: MSPTS313P
 * 화면설명: 종목정보검색
 * 작 성 일: 2023.03.06
 * 작 성 자: 김경태
 */
<template>
  <mo-modal class="fts-modal large" ref="modal" title="종목정보검색">
    <div class="wrap-modalcontents">
      <div class="input-top">
        <div class="column w100p gap12">
          <div class="row w100p">
            <div class="left">
              <div class="wrap-input row">
                <label> 종목코드 </label>
                <mo-text-field v-model="stndItemC" class="w130" maxlength="12" />
              </div>
              <div class="wrap-input row">
                <label> 종목명 </label>
                <mo-text-field v-model="itemNm" placeholder="입력하세요" />
              </div>
            </div>
          </div>
          <div class="row w100p">
            <div class="left w100p">
              <div class="wrap-input row">
                <label> 상품구분 </label>
                <mo-text-field class="w130" v-model="snbGdTc" type="searchable" @click-icon="fn_OpenMSPTS314P()" @input="fn_CodeNm('snbGdTc')" maxlength="3"/>
                <mo-text-field v-model="snbGdNm" disabled />
                <div class="wrap-checkbox row">
                  <mo-checkbox v-model="mtrtExclYn">만기건제외</mo-checkbox>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="wrap-button row">
                <mo-button @click="fn_Clear()"> 초기화 </mo-button>
                <mo-button primary @click="fn_Search()"> 조회 </mo-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrap-table-title row justify-between">
        <h2 class="table-title"> 조회내역 </h2>
        <div class="wrap-button">
          <mo-button @click="fn_SearchNext()" :disabled="btnSearchPage"> 다음 </mo-button>
        </div>
      </div>
      <div class="wrap-table h-scroll-200">
        <table class="table row-type">
          <thead>
            <tr>
              <th class="w40"> </th>
              <th> 상품구분 </th>
              <th class="w100"> 종목코드 </th>
              <th> 종목명 </th>
              <th class="w90"> 발행일자 </th>
              <th class="w90"> 만기일자 </th>
            </tr>
          </thead>
          <tbody>
            <tr  v-for="(data, idx) in taitemList" :key="idx" @click="fn_RowSelected(idx)">
              <td><mo-radio :id="idx.toString()" v-model='lv_SelectedItem' :value="idx.toString()" @click="fn_RowSelected(idx)"/></td>
              <td>{{ data.snbGdNm}}</td>
              <td>{{ data.stndItemC}}</td>
              <td class="aL">{{ data.itemNm}}</td>
              <td>{{ data.isueDate | formatDate }}</td>
              <td>{{ data.mtrtDate | formatDate }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_Close">닫기</mo-button>
        <mo-button primary size="large" @click="fn_Confirm">확인</mo-button>
      </div>
    </template>
    <msp-ts-314p
    ref="popup314"
    :popup314Obj="pPopup314Obj"
    @ts-popup314-callback="fn_Popup314Back"
  ></msp-ts-314p>
  </mo-modal>
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import moment from 'moment'
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'
import MSPTS314P from '@/ui/ts/MSPTS314P'
export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPTS313P',
  // 현재 화면 ID
  screenId: 'MSPTS313P',
  // 컴포넌트 선언
  components: {
    'ts-alert-popup': TSAlertPopup,
    'msp-ts-314p': MSPTS314P,
  },
  // 화면명
  props: {
    pPage: String,      // 부모 페이지명
    popupObj313: {type:Object, default:null},
  },
  filters:{
    formatDate:function(val){
      if(val){
        return moment(val).format('YYYY-MM-DD')
      }
    }
  },
  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      // 공통 변수
      isInit: false, // 초기화 여부
      pageIndex: 0,		// 페이지번호
      pageSize: 200,	// 페이지별 목록 크기 (FIXED)
      stndItemC:'',
      itemNm:'',
      snbGdTc:'',
      snbGdNm:'',
      mtrtExclYn:true,
      snbGdTcCall:'',
      lv_Params:{},
      lv_ReturnVal: {}, // 리턴값
      lv_ReturnStr: [], // 베이스 화면으로 리턴할 조회조건 텍스트
      lv_SelectedItem: "",
      eaiCommObj: TSCommUtil.gfn_eaiCommObj(),
      taitemList:[],
      btnSearchPage:true,
      popup312: {},
      pPopup314Obj: {},
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    modal() {
      return this.$refs.modal
    },
  },
  
  /** watch 정의 영역 */
  watch: {},

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    const lv_Vm = this
    this.pageIndex = 1
    this.pageSize = 200
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPTS313P')

  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    fn_Init () {
      this.modal.open()
      this.fn_Search()
    },
    fn_Open(param){
      this.lv_Params = param
      this.modal.open()
    },
    fn_Close(){
      this.modal.close()
    },
    /******************************************************************************
     * Function명 : fn_Clear
     * 설명       : 입력값 모두 초기화 (초기 값으로 셋팅)
     ******************************************************************************/
    fn_Clear () {
      this.stndItemC = ''
      this.itemNm = ''
      this.snbGdTc = ''
      this.snbGdNm = ''
      this.mtrtExclYn = true
      this.btnSearchPage = true
      this.taitemList = []
    },
    /******************************************************************************
     * Function명 : fn_Search
     * 설명       : 조회 후 팝업 닫음
     ******************************************************************************/
    fn_Search () {
      this.pageIndex = 0
      let pParams = {
        stndItemC: this.stndItemC,
        itemNm: this.itemNm,
        snbGdTc: this.snbGdTc,
        snbGdNm: this.snbGdNm,
        mtrtExclYn: this.mtrtExclYn,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        gdType: this.snbGdTcCall
      }
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.trnstId = 'txTSSTS92S4'
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.params = pParams
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600237_S'
      // TSServiceUtil.invoke(this.eaiCommObj, this.fn_SearchResult)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_SearchResult,function (error) {console.log(error)},'UF10600237')
    },
    /******************************************************************************
     * Function명 : fn_SearchResult
     * 설명       : 조회 콜백
     ******************************************************************************/
    fn_SearchResult (res) {
      this.taitemList = res.data.taitem
      this.btnSearchPage = false
    },
    /******************************************************************************
     * Function명 : fn_Search
     * 설명       : 조회 다음
     ******************************************************************************/
    fn_SearchNext () {
      this.pageIndex++
      console.log(this.pageIndex)
      let pParams = {
        stndItemC: this.stndItemC,
        itemNm: this.itemNm,
        snbGdTc: this.snbGdTc,
        snbGdNm: this.snbGdNm,
        mtrtExclYn: this.mtrtExclYn,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        gdType: this.snbGdTcCall
      }
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.trnstId = 'txTSSTS92S4'
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.params = pParams
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600237_S'
      //TSServiceUtil.invoke(this.eaiCommObj, this.fn_SearchNextResult)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_SearchNextResult)
    },
    /******************************************************************************
     * Function명 : fn_SearchNextResult
     * 설명       : 조회 다음 콜백
     ******************************************************************************/
    fn_SearchNextResult (res) {
      if ( this.taitemList.length >= this.pageSize ) {
        this.btnSearchPage = false
      }else{
        this.btnSearchPage = true
      }
      for(let i=0;i<res.data.taitem.length; i++){
        this.taitemList.push(res.data.taitem[i])  
      }
    },
    /******************************************************************************
     * Function명 : fn_Confirm
     * 설명       : 확인
     ******************************************************************************/
    fn_Confirm () {
      if(_.isEmpty(this.rowSelect)){
        this.fn_Close()
      }else{
        this.rowSelect.pRow = this.lv_Params.pRow
        this.$emit('ts-popup313-callback', this.rowSelect)
        this.fn_Close()
      }
    },
    /******************************************************************************
     * Function명 : fn_RowSelected
     * 설명       : 조회내역 선택
     ******************************************************************************/
    fn_RowSelected(idx){
      this.lv_SelectedItem = idx+"";

      this.rowSelect = this.taitemList[idx]
    },
    /*********************************************************
    * Function명: fn_OpenMSPTS314P
    * 설명: 종목정보검색 팝업호출
    *********************************************************/
    fn_OpenMSPTS314P () {
      
      this.popup314 = this.$refs.popup314.fn_Open()
    },
    /*********************************************************
    * Function명: fn_Popup313Back
    * 설명: 종목정보 팝업호출 콜백
    *********************************************************/
    fn_Popup314Back(rtnData){
console.log(rtnData)
      this.snbGdTc = rtnData.snbGdTc
      this.snbGdNm = rtnData.snbGdNm
      this.$forceUpdate()
    },
    /******************************************************************************
     * Function명 : fn_CodeNm
     * 설명       : 유가증권상품구분코드 자동조회
    ******************************************************************************/  
    fn_CodeNm(type){
      if ( type == "snbGdTc" ) {
        // 영문명 소문자->대문자로 변환
        const validOnlyEnglish = (s) => !/(?=.*[^\w\s])/.test(s)
        if (validOnlyEnglish(this.snbGdTc)) {
          this.snbGdTc = this.snbGdTc.toUpperCase()
        }

        if ( this.snbGdTc.length == 3 ) {
          this.eaiCommObj.lv_vm = this
          // this.eaiCommObj.trnstId = 'txTSSTS91S2'
          this.eaiCommObj.auth = 'S'
          this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600048_S'
          this.eaiCommObj.params = {searchType:'gdTc',data:this.snbGdTc}
          console.log(this.eaiCommObj)
          TSServiceUtil.invokeInDirect(this.eaiCommObj, function (res) {
            console.log(res)
            this.snbGdNm = res.data.cdmnidNm
          },function (error) {return},'UF10600048')
        }
      }
    },
  }
}
</script>
