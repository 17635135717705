/*
 * 업무구분: 신탁>고객/상품>상품자료>판매자료조회>첨부파일다운로드_P
 * 화 면 명: MSPTS111P
 * 화면설명: 첨부파일다운로드 POPUP
 * 작 성 일: 2023.05.03
 * 작 성 자: 송진의
 */
<template>
  <!-- popup -->
  <mo-modal class="fts-modal large" ref="modal" :title="pHeaderObj.title" style="z-index:2999;">
    <template>
      <div class="wrap-modalcontents">
        <div class="input-top">
          <div class="wrap-input row">
            <label> 상품코드 </label>
            <mo-text-field 
              class="form-input-name" 
              v-model="lv_gd_cd.value" 
              disabled />
            <mo-text-field 
              class="form-input-name" 
              v-model="lv_gd_nm.value" 
              disabled />
          </div>
        </div>

        <div class="wrap-table-title">
          <h3 class="table-title"> {{lv_file_busn_tc === 'A'? '고객교부자료' : '판매참조자료(타사)'}} </h3>          
        </div>
        <div class="wrap-table h-scroll table-pop-download">
          <table class="table col-type th-row-gray">
            <thead>
              <tr>
                <th> 파일명 </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, i) in lv_file_search_list" :key="i">
                <td>
                  <a href="javascript:void(0)" @click="fn_FileDetailSearch(row)">{{row.orglFileNm}}</a>                  
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_Close">닫기</mo-button>          
      </div>
    </template>
    <!-- alert modal popup -->
    <ts-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></ts-alert-popup>
  </mo-modal>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'  // Alert 팝업 (공통)
import CtConstants from '@/config/constants/ctConstants'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPTS111P",
  screenId: "MSPTS111P",
  components: {
    'ts-alert-popup': TSAlertPopup,
  },
  props: {
    popupObj: {type:Object, default:null},
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {},
  mounted() {
    this.$bizUtil.insSrnLog("MSPTS111P");
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // 공통 객체
      pHeaderObj: {          
        title: '첨부파일 다운로드',
      },
      pAlertPopupObj: {},
      lv_file_search_list: [],        // 파일조회 목록
      lv_apnd_file_id: '',            // 첨부파일ID
      lv_mo_apnd_file_id: '',         // 모상품 첨부파일ID
      lv_gd_cd: {},                   // 상품코드
      lv_gd_nm: {},                   // 상품명
      lv_file_id: '',                 // 파일목록 중 클릭 한 ID
      lv_file_busn_tc: '',            // 파일업무구분 코드
      DOWNLOAD_URL: 'http://u-channel.t.samsunglife.kr:8080/PDF/TS/',  // 다운로드 URL      
      eaiCommObj: TSCommUtil.gfn_eaiCommObj(), // Contents Model 
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    // modal
    modal() {
      return this.$refs.modal
    },
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_Open() {
      // this.fn_SetCommCode()
      this.fn_Init()
      this.modal.open()
    },
    fn_Close() {
      this.modal.close()      
    },
    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/      
    fn_AlertPopup (type, pPopupObj) {
      switch (type) {
        case 0:
          if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
            this.pAlertPopupObj.content = pPopupObj.content

            if( pPopupObj.confirm ) {
              this.pAlertPopupObj.confirm = pPopupObj.confirm
              this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
            }
          }
          break;
      }

      this.$refs.alertPopup.fn_Open()
    },
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     ******************************************************************************/
    fn_Init() {
      console.log('[MSPTS111P] fn_Init...')
      
      // 검색조건      
      this.lv_gd_cd                    = TSCommUtil.gfn_dataSet(1, '', false, '', 'gd_cd')       // 상품코드
      this.lv_gd_nm                    = TSCommUtil.gfn_dataSet(1, '', false, '', 'gd_nm')       // 상품명
      this.lv_file_busn_tc             = ''                             
      this.lv_apnd_file_id             = ''
      this.lv_mo_apnd_file_id          = ''

      this.fn_InitData()

      this.$nextTick(() => {
        console.log('fn_Init - popupObj > ', this.popupObj)
        if (!TSCommUtil.gfn_isNull(this.popupObj.gdC) || !TSCommUtil.gfn_isNull(this.popupObj.gdNm) || 
            !TSCommUtil.gfn_isNull(this.popupObj.apndFileId)|| !TSCommUtil.gfn_isNull(this.popupObj.moApndFileId)) {
          this.lv_gd_cd.value     = this.popupObj.gdC
          this.lv_gd_nm.value     = this.popupObj.gdNm
          this.lv_apnd_file_id    = this.popupObj.apndFileId
          this.lv_mo_apnd_file_id = this.popupObj.moApndFileId
          this.lv_file_busn_tc    = this.popupObj.fileBusnTc
          this.fn_FileSearch()
        }
      })      
    },
    
    /******************************************************************************
     * Function명 : fn_InitData
     * 설명       : 조회 전 데이터 초기화
     * 파라미터   : init - 초기화 시 / search - 조회 시
     ******************************************************************************/
    fn_InitData(type = 'init') {
      // 바디 검색결과
      this.lv_file_search_list          = []
      this.lv_file_id                   = ''      // 데이터 선택 시
    },
    /******************************************************************************
     * Function명 : fn_SetParam
     * 설명       : 파라미터 설정
     ******************************************************************************/
    fn_SetParam() {
      let param = {}

      if (!TSCommUtil.gfn_isNull(this.lv_apnd_file_id)) {
        param.apndFileId = this.lv_apnd_file_id
      }

      if (!TSCommUtil.gfn_isNull(this.lv_mo_apnd_file_id)) {
        param.moApndFileId = this.lv_mo_apnd_file_id
      }

      if (!TSCommUtil.gfn_isNull(this.lv_file_busn_tc)) {
        param.fileBusnTc = this.lv_file_busn_tc
      }

      return param
    },
    /******************************************************************************
     * Function명 : fn_FileSearch
     * 설명       : 파일조회 (selectFileList)
     ******************************************************************************/
    fn_FileSearch(state = '') {
      console.log('fn_FileSearch.....')
      
      this.fn_InitData()
      
      // 서비스 호출
      this.eaiCommObj.lv_vm = this      
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600191_S'          
      this.eaiCommObj.params = this.fn_SetParam()      
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CallBack, this.fn_error)      
    },
    /******************************************************************************
     * Function명 : fn_CallBack
     * 설명       : 조회 시 호출 결과
     ******************************************************************************/
    fn_CallBack(pResultData) {
      console.log('fn_CallBack.....')
      
      // 오류 확인
      let t_data = pResultData.data
      console.log(t_data)
      if (t_data !== null && t_data !== '') {
        if (TSCommUtil.gfn_trim(t_data.errorCode) !== '') {
          return false
        }

        if(!TSCommUtil.gfn_isNull(t_data.tufild) && t_data.tufild.length > 0){
          this.lv_file_search_list = t_data.tufild
        }
      }
    },
    /******************************************************************************
     * Function명 : fn_FileDetailSearch
     * 설명       : 파일상세조회
     * 파라미터   : 목록 중 선택한 정보
     ******************************************************************************/
    fn_FileDetailSearch(pParam) {
      console.log('fn_FileDetailSearch.....')
      
      // 서비스 호출
      this.eaiCommObj.lv_vm = this      
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600053_S'          
      this.eaiCommObj.params = {
        fileBusnTc: pParam.fileBusnTc,
        apndFileId: pParam.apndFileId,
        fileSeq: pParam.fileSeq        
      }
      console.log(this.eaiCommObj.params)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_FileDetailSearchResult, this.fn_error)      
    },
    /******************************************************************************
     * Function명 : fn_FileDetailSearchResult
     * 설명       : 상세조회 시 호출 결과
     ******************************************************************************/
    fn_FileDetailSearchResult(pResultData) {
      console.log('fn_FileDetailSearchResult.....')
      
      // 오류 확인
      let t_data = pResultData.data
      console.log(t_data)
      if (t_data !== null && t_data !== '') {
        if (TSCommUtil.gfn_trim(t_data.errorCode) !== '') {
          return false
        }

        if(!TSCommUtil.gfn_isNull(t_data.saveFileNm)){
          this.fn_DownloadFile(t_data)
        }
      }
    },
    /******************************************************************************
     * Function명 : fn_DownloadFile
     * 설명       : 파일다운로드
     * 파라미터    : 상세 파일정보 객체
     ******************************************************************************/
    fn_DownloadFile(fileInfo) {
      console.log('fn_DownloadFile....')
      let t_fileNm = fileInfo.saveFileNm + '.' + fileInfo.fileExtnMn
      let t_fileTitleLen = !TSCommUtil.gfn_isNull(fileInfo.orglFileNm)? fileInfo.orglFileNm.length - 4 : 0
      let t_fileTitle = !TSCommUtil.gfn_isNull(fileInfo.orglFileNm)?fileInfo.orglFileNm.substr(0, t_fileTitleLen) : ''
      let viewerTargetFileList = ''
      let lv_Vm = this
      console.log(t_fileTitle)

      if(fileInfo.fileExtnMn.toLowerCase().trim() !== 'pdf'){
        lv_Vm.fn_AlertPopup(0, {content: 'PDF 파일만 다운로드 가능합니다.'})
        return
      }      

      if (lv_Vm.$commonUtil.isMobile()) { // 모바일일때
        let item = {
          fileTitle: t_fileTitle,
          localFileNm: t_fileNm,
          eltrnDoctFileNm: t_fileNm,
          eltrnDoctFilePathNm: 'TS_GD_FILE_DIR'
        }
        console.log('fn_DownloadFile > ', item)

        if (process.env.NODE_ENV !== 'local') {
          try {
              lv_Vm.fn_FdpSaveFile(item)
                .then(response => {
                  // if (CtConstants.DEBUG_MODE_CON) window.alert('■■■■■ TSSSA001M ■■■■■ savedFileName : ' + response)
                  // 파일명과 PDF Viewer에 노출될 제목을 '|'로 결합하여 한 파일에 대한 정보를 구성
                  // viewerTargetFileList += response + '|' + item.fileTitle + ','
                  viewerTargetFileList += response + 'tssB' + item.fileTitle + 'tssA'
                  // viewerTargetFileList += response
                  // 단일 선택일 경우는 바로 PDF Viewer 호출
                  lv_Vm.fn_CallPdfViewer(viewerTargetFileList)
                }).catch(error => {
                  // window.alert('파일 다운로드 실패 콜백 : ' + new Error(error))
                  // 단일 선택에 오류 발생 시 시도 횟수는 비교하지 않음.
                  // 화면 깜박거림을 방지하기 위한 로딩바를 해제
                  lv_Vm.getStore('progress').getters.getState.isShow = false
                  // 오류 팝업 메시지 출력
                  lv_Vm.getStore('confirm').dispatch('ADD', '총 1건 중 1건 저장이 실패하였습니다')
                  if (CtConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
                  window.vue.getStore('progress').dispatch('SUB')
                })
            } catch (error) {
              if (CtConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
              window.vue.getStore('progress').dispatch('SUB')
            }
          
        } else { // PC 일때
          let t_Url = lv_Vm.DOWNLOAD_URL + t_fileNm +'?IS_POPUP=true'
          window.open(t_Url, '_blank')
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_FdpSaveFile
    * 설명        : Native 저장 장소로 실제 PDF 파일을 다운로드
    *              실제 파일 존재 유무는 다음과 같은 URL을 이용 확인 가능
    *              https://vtss.samsunglife.com:8410/filedown.do?path=file.path.fmlmt&filename=/20190122/000300336200252107122109102.pdf&type=pdf
    * Params     : 다운로드할 PDF 정보를 담고있는 개체
    * Return     : N/A
    ******************************************************************************/
    fn_FdpSaveFile (obj) {
      return new Promise((resolve, reject) => {        
        // .pdf 확장자가 없을 경우 확장자 첨부
        if (obj.localFileNm.indexOf('.pdf') < 0) {
          obj.localFileNm = obj.localFileNm + '.pdf'
        }
        window.fdpbridge.exec('downloadPlugin', {
          path: obj.eltrnDoctFilePathNm,
          filename: obj.eltrnDoctFileNm, // src Directory + fileName
          type: 'pdf',
          localpath: '/sli/tss/comm/fmlmt', // local Directory(안내 자료는 fmlmt 폴더 사용)
          localfilename: obj.localFileNm, // local fileName
          rename: 'N' // 파일명 중복에 관계없이 신규 파일 생성 여부
        }, (result) => {
          resolve(result.data)
        }, (result) => {
          reject(result.data)
        })
      })
    },
    /******************************************************************************
    * Function명 : fn_CallPdfViewer
    * 설명        : PDF Viewer 호출
    * Params     : PDF Viewer를 통해 보여줄 PDF 파일 및 제목 목록
    * Return     : N/A
    ******************************************************************************/
    fn_CallPdfViewer (viewerTargetFileList) {
      // PDF Viewer를 통해 PDF 확인은 가능하나 툴팁 메뉴 사용은 불가
      let lv_Vm = this
      // PDF 뷰어 호출
      window.fdpbridge.exec('callPdfViewerPlugin', {
        path: '/sli/tss/comm/fmlmt',
        file: viewerTargetFileList,
        menu: '0000'
      }, function (result) {
        // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
        lv_Vm.getStore('progress').getters.getState.isShow = false
        // 성공콜백
        // // PDF Viewer에서 툴팁 메뉴를 눌렀을 경우

        // lv_Vm.fn_CallPdfViewerNextStep(lv_Vm, result.data)
      }, function (result) {
        // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
        lv_Vm.getStore('progress').getters.getState.isShow = false
        // 실패콜백
        // alert('실패콜백=' + result.data)
      })
    },

  }
};
</script>
<style scoped>
</style>