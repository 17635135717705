/*
* 업무구분: 신탁>영업>최초신규고객>신규입력>조력자등록대상_P
 * 화 면 명: MSPTS311P
 * 화면설명: 조력자등록대상 POPUP
 * 작 성 일: 2023.03.21
 * 작 성 자: 안제욱
 */
<template>
    <!-- popup -->
     <mo-modal class="fts-modal small " ref="modal" title="신탁상품 정보조회" style="z-index:2999;" >
      <template>
        <div class="lh14">
          <p class="text-base" v-html="processMsg1"></p>
          <p class="text-sm mt-3" v-html="processMsg2"></p>
        </div>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large" @click="closeModal(1)">닫기</mo-button>
          <mo-button primary size="large" @click="fn_confirm()">확인</mo-button>
        </div>
      </template>
    </mo-modal>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import moment from 'moment'
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  // 현재 화면명
  name: "MSPTS311P",
  // 현재 화면 ID
  screenId: "MSPTS311P",
  // 컴포넌트 선언
  components: {
  },
  // 화면명
  props: {
    pPage: String,     
    pMsgCntn: String,  
    popupObj: {type:Object, default:null},
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.fn_comCode()
  },
  mounted() {
    this.$bizUtil.insSrnLog("MSPTS311P");
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      msgCntn : ''
      ,tacno : ''
      ,bnkbSeq : ''
      ,ctno : ''
      ,processMsg1 : ''
      ,processMsg2 : ''
      ,addmissionReq : ''
      ,age : ''      
      ,eaiCommObj: TSCommUtil.gfn_eaiCommObj(),
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    // modal
    modal() {return this.$refs.modal},
    select01() {
      let rtn = [];
      rtn.push({value: '0', text: '사유 01'});
      rtn.push({value: '1', text: '사유 02'});
      return rtn;
    }
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     ******************************************************************************/
    fn_Init(){},
    /******************************************************************************
     * Function명 : fn_Clear
     * 설명       : 입력값 모두 초기화 (초기 값으로 셋팅)
     ******************************************************************************/
    fn_Clear () {},

    fn_Close(){
      this.modal.close()
    },

    fn_comCode(){
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.trnstId = 'txTSSTS90S1' // selectTFCP11000A
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600046_S'
      this.eaiCommObj.params = {'TUKEMK':[
                                  {cId:"5186"} //5095  5006
                              ]}

      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_comCodeCallBack)
    },

    /******************************************************************************
     * Function명 : fn_ComCodeCallBack
     * 설명       : 공통코드 후처리
     ******************************************************************************/
    fn_comCodeCallBack(res) {
      let t_data = res.data
      for ( let index in res.data.tukemk ) {
          let item = res.data.tukemk[index]
          this.age = item.cnm
          break;
      }
    },


    fn_Open(param){
      this.msgCntn    = param.pMsgCntn;

      this.tacno      = param.pMsgCntn.substr(0, 7)    //종합계좌번호
      this.bnkbSeq    = param.pMsgCntn.substr(7, 3)  //통장번호

      if( TSCommUtil.gfn_length(this.msgCntn) == 10 ) {
        this.ctno = this.msgCntn.substr(10, 1); //계좌일련번호
      }else{
        this.ctno = this.msgCntn.substr(10, 3); //계좌일련번호
      }

      this.modal.open()
      this.fn_afterFormOnload()
    },    


    fn_afterFormOnload(){
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600116_S'  //selectTFAC28000
      this.eaiCommObj.params = {
          tacno:this.tacno
          ,bnkbSeq:this.bnkbSeq
          ,ctno:this.ctno
      }
      //TSServiceUtil.invoke(this.eaiCommObj, this.fn_transactionResult)

      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)
    },

    fn_callBack(res){
      let t_data = res.data
      if ( t_data.tacno.trim().length != 0 ) {
        this.addmissionReq = ''        
        this.processMsg1 = '<strong class="colorR">만 ' + this.age + '세 이상</strong>의 고객으로<br>'
                         + '<strong class="colorB">조력자 등록</strong> 대상입니다.</p>' 

        this.processMsg2 = '해당계좌의 조력자등록 건이 있습니다.<br>승인요청을 진행해 주세요.'
      }else{
        this.addmissionReq = 'Y'
        this.processMsg1 = '<strong class="colorR">만 ' + this.age + '세 이상</strong>의 고객으로<br>'
                         + '<strong class="colorB">조력자 등록</strong> 대상입니다.' 
        this.processMsg2 = '해당계좌의 조력자등록 건이 없습니다.<br>조력자 등록 후 승인요청이 가능합니다.'                         
        
      }
    },

    fn_confirm(){
      this.$emit('ts-popup311-callback', this.addmissionReq)
      this.fn_Close()
    },

    closeModal(type) {
      this.modal.close()
      this.closeDropdown()
    },
    closeDropdown() {
      for (let i=0; i<document.getElementsByClassName('mo-dropdown__items').length; i++) {
        document.getElementsByClassName('mo-dropdown__title')[i].classList.remove("mo-dropdown__title--active")
        document.getElementsByClassName('mo-dropdown__icon')[i].style.transform = "rotate(0deg)"
        document.getElementsByClassName('mo-dropdown__items')[i].style.display = "none"
        // document.getElementsByClassName('mo-dropdown')[0].addEventListener(this.close);
      }
    },


  }
};
</script>
<style scoped>
</style>