/*
 * 업무구분: 조력자 등록 Popup
 * 화 면 명: MSPTS322P
 * 화면설명: 조력자 등록 Popup
 * 작 성 일: 2023.08.22
 * 작 성 자: 
 */
<template>
  <mo-modal class="fts-modal full" ref="modal" title="조력자 등록">
    <template>
        <div class="wrap-modalcontents" id="dv322P">
          <div class="input-top">
            <div class="column w100p gap12">
              <div class="row w100p">
                <div class="left">
                  <div class="wrap-input row">
                    <label class="emphasis"> 계좌번호 </label>
                    <mo-text-field                     
                      ref="tac_no"
                      class="w130"
                      :class="lv_tac_no.error ? 'error' : ''"
                      v-model="lv_tac_no.value"
                      :disabled="lv_tac_no.disabled"
                      maxlength="7"
                      @keyup="fn_AccnoSearch"
                    />
                    <mo-button class="btn-pop-download" @click="fn_OpenMSPTS107P('00')"> </mo-button>
                    <mo-text-field class="w130" v-model="lv_cnm" disabled/>
                  </div>
                </div>
              </div>
              <div class="row w100p">
                <div class="left w70p">
                  <div class="wrap-input row">
                    <label class="emphasis"> 통장번호 </label>
                    <div class="wrap-input row">
                      <mo-dropdown class="w200"
                        ref="bnkb_seq"
                        :items="lv_bnkb_seq_items"
                        v-model="lv_bnkb_seq.value"
                        :disabled="lv_bnkb_seq.disabled"
                        @input="fn_AgntCtNoSearch"
                        placeholder=" "/>
                    </div>
                  </div>
                  <div class="wrap-input row ml-5">
                    <label class="emphasis"> 계약번호 </label>
                    <div class="wrap-input row">
                      <mo-dropdown class="w130"
                        ref="ct_no"
                        :items="lv_ct_no_items" 
                        v-model="lv_ct_no.value"
                        :disabled="lv_ct_no.disabled" 
                        @input="fn_AssiAsgnAplSearch('S')"
                        placeholder=" " />
                      <mo-text-field class="w130" v-model="lv_cs_age" disabled/>
                    </div>
                  </div>
                </div>
                <div class="right w30p">
                  <div class="wrap-button row">
                    <mo-button :disabled="lv_btn_init" @click="fn_Init()"> 초기화 </mo-button>
                    <mo-button primary class="inquiry" :disabled="lv_btn_search" @click="fn_AssiAsgnAplSearch('S')"> 조회 </mo-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="wrap-table mt-5">
            <table class="table col-type w50p col2080">
              <tbody>
                <tr>
                  <th>
                    <span class="emphasis">조력자지정 여부</span>
                  </th>
                  <td>
                    <div class="row">                      
                        <mo-radio-wrapper
                          ref="assi_yn"
                          :items="rdo_assi_yn" 
                          v-model="lv_assi_yn.value" 
                          :disabled="lv_assi_yn.disabled" 
                          @input="fn_ChkAssiYn" 
                        />
                    </div>                  
                  </td>
                </tr>
                <tr>
                  <th>
                    <span class="emphasis">{{lv_assi_yn.value === 'Y' ? '조력자 지정 사유' : '조력자 미지정 사유' }}</span>
                  </th>
                  <td>
                    <div class="wrap-input row">
                      <mo-dropdown
                        ref="cob_assi_yn_rsn"
                        :items="cmb_assiYnRsn"
                        v-model="lv_cob_assi_yn_rsn.value"
                        :disabled="lv_cob_assi_yn_rsn.disabled"
                        @input="fn_ChkAssiYnRsnCd"
                        placeholder="선택하세요" 
                        class="w271" 
                      />
                      <mo-text-field
                        ref="ed_assi_yn_rsn"
                        class="input-long input-target" 
                        v-model="lv_ed_assi_yn_rsn.value"
                        :disabled="lv_ed_assi_yn_rsn.disabled"
                        @keyup="fn_ChkAssiYnRsn()"
                      />
                      <p class="txt-length">현재 <em class="colorB"> {{ lv_ed_rsn_length }} </em> / 200 Byte</p>
                    </div>
                  </td>
                </tr>              
              </tbody>
            </table>
          </div>

          <div class="wrap-table-title">
            <h2 class="table-title"> 조력자 정보 </h2>
          </div>
          <div class="wrap-table">
            <table class="table col-type w50p col2030">
              <tbody>
                <tr>
                  <th>
                    <span :class="lv_assi_yn.emphasis? 'emphasis' : ''">조력자명</span>
                  </th>
                  <td>
                    <div class="wrap-input row">
                      <mo-text-field
                        ref="assi_nm"
                        class="input-long"
                        :class="lv_assi_nm.error ? 'error' : ''" 
                        v-model="lv_assi_nm.value"
                        :disabled="lv_assi_nm.disabled"
                      />
                    </div>
                  </td>
                  <th>
                    <span :class="lv_assi_yn.emphasis? 'emphasis' : ''">연락처</span>
                  </th>
                  <td>
                    <div class="wrap-input row">
                      <mo-text-field 
                        ref="cel_no"
                        class="input-long"
                        :class="lv_assi_cel_no.error ? 'error' : ''"
                        v-model="lv_assi_cel_no.value" 
                        :disabled="lv_assi_cel_no.disabled"                      
                        type = "number"
                        maxlength="11"
                      />                    
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span :class="lv_assi_yn.emphasis? 'emphasis' : ''">관계</span>
                  </th>
                  <td>
                    <div class="wrap-input row">
                      <mo-text-field
                        ref="assi_rel"
                        class="input-long"
                        :class="lv_assi_rel.error ? 'error' : ''" 
                        v-model="lv_assi_rel.value"
                        :disabled="lv_assi_rel.disabled"
                      />
                    </div>
                  </td>
                  <td colspan="2"></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="wrap-table-title">
            <h2 class="table-title"> 조력자 개인정보 수집에 대한 확인 여부 </h2>
          </div>
          <div class="wrap-table">
            <table class="table col-type w50p col2030">
              <tbody>
                <tr>
                  <td colspan="4">
                    <p class="ml-4 text-xs">고객님의 금융투자상품 투자와 관련하여 투자자보호를 위해 조력자에 대한 성명 및 연락처 정보 수집에 대한 동의</p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span :class="lv_assi_yn.emphasis? 'emphasis' : ''">녹취일자</span>
                  </th>
                  <td>
                    <div class="wrap-input row">
                      <mo-date-picker                      
                        class="input-long"
                        :class="lv_persn_info_rec_date.error ? 'error' : ''" 
                        v-model="lv_persn_info_rec_date.value"
                        :disabled="lv_persn_info_rec_date.disabled"
                        :bottom="false" 
                      />
                    </div>
                  </td>
                  <th>
                    <span :class="lv_assi_yn.emphasis? 'emphasis' : ''">녹취시간</span>
                  </th>
                  <td>
                    <div class="wrap-input row">
                      <mo-dropdown                      
                        :items="cmb_time_hour" 
                        :class="lv_persn_info_rec_time.error ? 'error' : ''"
                        v-model="lv_persn_info_rec_time.value1"
                        :disabled="lv_persn_info_rec_time.disabled"
                        placeholder="-선택-" 
                      /> <span class="mr-2">시</span> 
                      <mo-dropdown                      
                        :items="cmb_time_min"
                        :class="lv_persn_info_rec_time.error ? 'error' : ''"
                        v-model="lv_persn_info_rec_time.value2"
                        :disabled="lv_persn_info_rec_time.disabled"
                        placeholder="-선택-" 
                      /> <span class="mr-2">분</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="wrap-table-title">
            <h2 class="table-title"> 조력자 상품가입에 대한 사실 안내 여부 </h2>
          </div>
          <div class="wrap-table">
            <table class="table col-type w50p col2030">
              <tbody>
                <tr>
                  <td colspan="4">
                    <p class="ml-4 text-xs">상품가입에 대한 사실 안내</p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span :class="lv_assi_yn.emphasis? 'emphasis' : ''">녹취일자</span>
                  </th>
                  <td>
                    <div class="wrap-input row">
                      <mo-date-picker 
                        ref="gd_ent_rec_date"
                        class="input-long"
                        :class="lv_gd_ent_rec_date.error ? 'error' : ''" 
                        v-model="lv_gd_ent_rec_date.value"
                        :disabled="lv_gd_ent_rec_date.disabled"
                        :bottom="false" 
                      />
                    </div>
                  </td>
                  <th>
                    <span :class="lv_assi_yn.emphasis? 'emphasis' : ''" >녹취시간</span>
                  </th>
                  <td>
                    <div class="wrap-input row">
                      <mo-dropdown
                        ref="gd_ent_rec_time1" 
                        :items="cmb_time_hour" 
                        :class="lv_gd_ent_rec_time.error ? 'error' : ''"
                        v-model="lv_gd_ent_rec_time.value1"
                        :disabled="lv_gd_ent_rec_time.disabled"
                        placeholder="-선택-" 
                      /> <span class="mr-2">시</span> 
                      <mo-dropdown
                        ref="gd_ent_rec_time2" 
                        :items="cmb_time_min" 
                        :class="lv_gd_ent_rec_time.error ? 'error' : ''"
                        v-model="lv_gd_ent_rec_time.value2"
                        :disabled="lv_gd_ent_rec_time.disabled"
                        placeholder="-선택-" 
                      /> <span class="mr-2">분</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    </template>
    <template slot="action">
        <ur-box-container>
          <div class="wrap-button button-page-bottom row">
            <div class="left">
              <mo-button point size="large" @click="fn_Close()"> 닫기 </mo-button>
            </div>
            <mo-button point size="large" @click="fn_Cancle()" :disabled="lv_btn_cancle">취소</mo-button>
            <mo-button point size="large" @click="fn_Update()" :disabled="lv_btn_update">수정</mo-button>
            <mo-button primary size="large" @click="fn_OpenModal()" :disabled="lv_btn_confirm"> 확인 </mo-button>
          </div>
        </ur-box-container>
    </template>

    <!-- alert modal popup -->
    <ts-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></ts-alert-popup>

    <msp-ts-107p
      ref="popup107"
      :popup107Obj="pPopup107Obj"
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup107-callback="fn_Popup107Back"
    ></msp-ts-107p>
  </mo-modal>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
  import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'    
  import TSHeader from '~/src/ui/ts/comm/TSHeader'          // header 영역 (공통)
  import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'  // Alert 팝업 (공통)
  import TSInfoUtil from '~/src/ui/ts/comm/TSInfoUtil'// 신탁 공통 정보 유틸
  import MSPTS107P from '~/src/ui/ts/MSPTS107P' //고객조회
  import moment from 'moment'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPTS322P",
    screenId: "MSPTS322P",
    components: {
      'ts-header': TSHeader,
      'ts-alert-popup': TSAlertPopup,      
      'msp-ts-107p': MSPTS107P,
    },
    props: {
      popupObj: {type:Object, default:null},
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      
      //this.fn_SetCommCode()
      this.fn_Init()

    },
    mounted() {
      this.$bizUtil.insSrnLog("MSPTS322P");
    },

    data() {
      return {
        lv_ed_rsn_length: 0,

        // 공통 객체
        pHeaderObj: {          
          title: '조력자지정 신청 및 조회',
        },
        pAlertPopupObj: {},        

        // 팝업 객체        
        pPopup107Obj: {}, // 고객조회
        
        // 조회패널 - 조회조건        
        lv_tac_no: {},                 // 종합계좌번호
        lv_bnkb_seq: {},               // 통장일련번호
        lv_ct_no: {},                  // 계약일련번호
        lv_cs_id: '',                  // 고객ID

        // 조회패널 - 조회표시
        lv_bnkb_seq_items: [],         // 통장번호 목록
        lv_ct_no_items: [],            // 계약번호 목록                
        lv_cs_age: '',                 // 고객나이
        lv_cnm: '',                    // 고객명

        // 입력패널 - 표시
        // 조력자 정보
        lv_assi_yn: {},                    // 조력자지정 여부
        lv_assi_nm: {},                    // 조력자명
        lv_assi_cel_no: {},                // 연락처
        lv_assi_rel: {},                   // 관계

        // 조력자 개인정보 수집에 대한 확인 여부
        lv_persn_info_rec_date: {},          // 개인정보 녹취일자
        lv_persn_info_rec_time: {},          // 개인정보 녹취시간

        // 조력자 상품가입에 대한 사실 안내 여부
        lv_gd_ent_rec_date: {},              // 상품가입 녹취일자
        lv_gd_ent_rec_time: {},              // 상품가입 녹취시간                
        lv_cob_assi_yn_rsn: {},              // 조력자 지정/미지정 사유 (콤보박스)
        lv_ed_assi_yn_rsn: {},              // 조력자 지정/미지정 사유 (에디트박스)
        lv_ed_assi_yn_rsn_tmp: '',          // 조력자 지정/미지정 사유 (에디트박스) 200 바이트 이하

        // 버튼
        lv_btn_search: true,          // 조회
        lv_btn_init: true,            // 초기화

        lv_btn_cancle: true,          // 취소        
        lv_btn_update: true,          // 수정        
        lv_btn_confirm: true,         // 확인

        // 컨트롤
        lv_stateFlag: '',             // 확인, 수정, 취소
        lv_isInsert: false,            // 조회 시 데이터 없을 때 입력        

        // 공통코드
        // cmb_assiYnRsn: [],           // 조력자 지정/미지정 사유
        cmb_assiYRsn: [],            // 조력자 지정 사유
        cmb_assiNRsn: [],            // 조력자 미지정 사유
        cmb_agedStndAge: [],         // 고령기준연령

        lv_tfabnb_list: [],    // 대리인 리스트 조회 응답값
        lv_tfac27000_list: [], // 계약번호 리스트 조회 응답값        
        lv_tfcori_list: [],    // 고객기본정보 응답값

        gdC: '',        // 신탁상품
        trstTypC: '',   // 신탁유형
        gdTypDtlC: '',  // 상품유형

        pPopup107Type: '',  // 고객정보 팝업 시 선택 타입

        // Contents Model 
        eaiCommObj: TSCommUtil.gfn_eaiCommObj(),

        lv_transaction: {}, // 리포트 전달 객체

        // 전달 파라미터
        lv_tac_param: {},

        //return param
        lv_rtn_param: {"assiYn":""},
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      modal() {
        return this.$refs.modal;
      },
      rdo_assi_yn() {
        let rtn= [];
        rtn.push({value: 'Y', text: '예'});
        rtn.push({value: 'N', text: '아니오'});
        return rtn;
      },      
      cmb_assiYnRsn() {
        return (TSCommUtil.gfn_isNull(this.lv_assi_yn.value) ||  this.lv_assi_yn.value === 'N') ? this.cmb_assiNRsn : this.cmb_assiYRsn
      },
      cmb_time_hour() {
        let rtn = []
        let tmpHour = ''        
        rtn.push({value: '99', text: '- 선택 -'})
        for (let i = 9 ; i <= 17 ; i++) {          
          tmpHour = String(i).length === 1 ? '0' + String(i) : String(i)
          rtn.push({value: tmpHour, text: tmpHour})
        }
        return rtn
      },
      cmb_time_min() {
        let rtn = []
        let tmpMin = ''
        rtn.push({value: '99', text: '- 선택 -'})
        for (let i = 0 ; i <= 50 ; i = i + 10) {
          tmpMin = String(i).length === 1 ? '0' + String(i) : String(i)
          rtn.push({value: tmpMin, text: tmpMin})
        }
        return rtn
      },
      lv_isProcess() {
        return this.getStore('tsStore').getters.getState.isProcess
      },
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_display(mode){
        //mode: true(display), false(none display)
        let el = document.getElementById("dv322P");
        if (el == null) return;
        if(mode){
          el.style.display = "";
        }else{
          el.style.display = "none";
        }
      },
    /******************************************************************************
     * Function명 : fn_Open
     * 설명       : 모달 일시에 전체 팝업 오픈
     ******************************************************************************/
      fn_Open() {        
        this.fn_SetCommCode();

        if( !TSCommUtil.gfn_isNull(this.popupObj.params) ) {
          this.fn_Init()

          this.$nextTick(()=>{
            if( !TSCommUtil.gfn_isNull(this.popupObj.params.tacno)) {              
              this.lv_tac_no.value = this.popupObj.params.tacno
              this.lv_tac_param.ctno = !TSCommUtil.gfn_isNull(this.popupObj.params.ctno) ? this.popupObj.params.ctno : ''
              this.fn_AccnoSearch()

              this.lv_tac_param.bnkbSeq = !TSCommUtil.gfn_isNull(this.popupObj.params.bnkbSeq) ? this.popupObj.params.bnkbSeq : ''
              this.lv_tac_param.accSeq = !TSCommUtil.gfn_isNull(this.popupObj.params.accSeq) ? this.popupObj.params.accSeq : ''
            }
          })

          this.$refs.modal.open()
        }
      },
      /******************************************************************************
       * Function명 : fn_Popup_CallBack
       * 설명       : popup 컴포넌트에서 callback
       * 타입(type) : 0: 우편번호 / 1: MDG/CDD정보비교 / 2: 고객정보 / 3: 직원번호(직원검색) / 4: 마케팅 동의여부 / 5: 고객ID선택
       *              7: FC 조회 / 8: cdi 고객조회 / 9: FATCA 
       ******************************************************************************/
      fn_Popup_CallBack(type, pData) {
        console.log(pData)
        let t_popupObj = {}

        switch (type) {
          case 0:
            break
        }
      },     
      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
       ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
        if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
          TSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)              
        }
        this.$refs.alertPopup.fn_Open()
      },
      /******************************************************************************
       * Function명 : fn_SetCommCode, fn_CommCodeCallBack
       * 설명       : 공통코드 세팅
       ******************************************************************************/
      fn_SetCommCode() {
        if (this.cmb_agedStndAge != null && this.cmb_agedStndAge.length > 0) return;

        this.fn_display(false);
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.trnstId = 'txTSSTS90S1' // selectTFCP11000A
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600046_S'        
        this.eaiCommObj.params = {'TUKEMK':[
                                    {cId:"5186"},    // cmb_agedStndAge 고령기준연령                                     
                                    {cId:"5189"},    // cmb_assiYRsn 조력자 지정사유
                                    {cId:"5190"}     // cmb_assiNRsn 조력자 미지정사유
                                ]}
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CommCodeCallBack, this.fn_error)
      },
      fn_CommCodeCallBack ( res ) {
        this.cmb_agedStndAge = []
        this.cmb_assiYRsn = []
        this.cmb_assiNRsn = []
        for ( let index in res.data.tukemk ) {
            let item = res.data.tukemk[index]
            switch (item.cid) 
            {
              case "5186": 
                this.cmb_agedStndAge.push({value: item.c , text: item.cnm})
                break
              case "5189":
                this.cmb_assiYRsn.push({value: item.c , text: item.cnm})
                break
              case "5190":
                this.cmb_assiNRsn.push({value: item.c , text: item.cnm})
                break              
            }
        }
        this.fn_display(true);
      },
      /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 화면 초기화
       ******************************************************************************/
      fn_Init(type = 'init') {
        
        // 검색조건
        if(type === 'init') {
          this.lv_tac_no                   = TSCommUtil.gfn_dataSet(1, '', false, '', 'tac_no')         // 종합계좌번호
        }
        this.lv_bnkb_seq                 = TSCommUtil.gfn_dataSet(1, '', false, '', 'bnkb_seq')       // 통장일련번호
        this.lv_ct_no                    = TSCommUtil.gfn_dataSet(1, '', false, '', 'ct_no')          // 계약일련번호
        this.lv_cnm                      = '' // 고객명
        this.lv_cs_age                   = '' // 나이
        
        this.lv_btn_search          = true                          // 조회
        this.lv_btn_init            = true                          // 초기화

        this.lv_btn_confirm         = true                          // 확인        
        this.lv_btn_update          = true                          // 수정        
        this.lv_btn_cancle          = true                          // 취소
        this.lv_isInsert             = false                         // 조회 시 입력
        this.lv_cs_id               = ''  // 고객번호

        this.lv_bnkb_seq_items = []
        this.lv_ct_no_items = []

        this.fn_InitData()
      },

      /******************************************************************************
       * Function명 : fn_InitData
       * 설명       : 조회 전 데이터 초기화
       * 파라미터   : init - 초기화 시 / search - 조회 시 / input - 입력 시 (조회 시 결과 값 없을 경우 자동 설정) / assiYn - 조력자 여부 선택 시 
       ******************************************************************************/
      fn_InitData(type = 'init') {
        let isFlag = true
        let strToday = ''
        let valType = 1
        switch (type) {
           case 'init': // 초기화
            this.lv_btn_confirm         = true                          // 확인        
            this.lv_btn_update          = true                         // 수정        
            this.lv_btn_cancle          = true                          // 취소

            this.lv_assi_yn             = TSCommUtil.gfn_dataSet(1, '', isFlag, '', 'assi_yn')                // 조력자지정 여부
            this.lv_stateFlag           = ''
            break
          case 'search': // 조회
            this.lv_btn_confirm         = true                          // 확인        
            this.lv_btn_update          = false                         // 수정        
            this.lv_btn_cancle          = true                          // 취소
            this.lv_isInsert             = false
            
            this.lv_assi_yn              = TSCommUtil.gfn_dataSet(1, '', isFlag, '', 'assi_yn')                // 조력자지정 여부
            break
          case 'input':  // 입력
            this.lv_btn_confirm         = false                          // 확인 활성화       
            this.lv_btn_update          = true                           // 수정 비활성화        
            this.lv_btn_cancle          = false                          // 취소 활성화
            this.lv_isInsert             = true

            this.lv_assi_yn             = TSCommUtil.gfn_dataSet(1, '', isFlag, '', 'assi_yn')                // 조력자지정 여부
            break          
          case 'assiYn': // 조력자 선택
            if (this.lv_assi_yn.value === 'Y') {
              isFlag = false
              valType = 0
              strToday = moment(new Date()).format('YYYY-MM-DD')              
            }
            break
        }

        this.lv_cob_assi_yn_rsn             = TSCommUtil.gfn_dataSet(1, '', isFlag, '', 'cob_assi_yn_rsn')        // 조력자 지정/미지정 사유 콤보박스
        this.lv_ed_assi_yn_rsn              = TSCommUtil.gfn_dataSet(1, '', true, '', 'ed_assi_yn_rsn')         // 조력자 지정/미지정 사유 에디트박스
        this.lv_ed_assi_yn_rsn_tmp          = ''                                                                  // 조력자 지정/미지정 사유 에디트박스 (200 바이트 이하)

        this.lv_assi_nm                     = TSCommUtil.gfn_dataSet(1, '', isFlag, '', 'agnt_nm')           // 조력자명
        this.lv_assi_cel_no                 = TSCommUtil.gfn_dataSet(1, '', isFlag, '', 'assi_cel_no')       // 조력자명
        this.lv_assi_rel                    = TSCommUtil.gfn_dataSet(1, '', isFlag, '', 'assi_rel')          // 관계
        // 시간
        let tmp_time = {
          error: false,
          disabled: isFlag,          
          value1: '',
          value2: '',          
        }        
        this.lv_persn_info_rec_date            = TSCommUtil.gfn_dataSet(valType, strToday, isFlag, '', 'persn_info_rec_date')  // 개인정보 녹취일자        
        this.lv_persn_info_rec_time            = Object.assign({}, tmp_time)   // 개인정보 녹취시간
        this.lv_gd_ent_rec_date                = TSCommUtil.gfn_dataSet(valType, strToday, isFlag, '', 'gd_ent_rec_date')      // 상품가입 녹취일자                
        this.lv_gd_ent_rec_time                = Object.assign({}, tmp_time)   // 상품가입 녹취시간
      },

      

      /******************************************************************************
       * Function명 : fn_AccnoSearch
       * 설명       : 계좌번호조회
       ******************************************************************************/
      fn_AccnoSearch(event) {
        console.log('fn_AccnoSearch.....')
        if( TSCommUtil.gfn_length(this.lv_tac_no.value) == 7) {
          if( !TSCommUtil.gfn_isNum(this.lv_tac_no.value) ) {
            TSCommUtil.gfn_validate(this, '계좌번호의 형식에 맞지 않습니다.')
            this.lv_tac_no.error = true
            this.$refs['tac_no'].focus()
            return
          } else {
            this.lv_tac_no.error = false
          }

          // 비활성화

          // 서비스 호출
          this.eaiCommObj.lv_vm = this
          this.eaiCommObj.trnstId = 'txTSSTS20S3'
          this.eaiCommObj.auth = 'S'

          this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600083_S'          
          this.eaiCommObj.params = {
            tacno: this.lv_tac_no.value,
            bnkbSeq: this.lv_bnkb_seq.value,
            csId: this.lv_cs_id,            
          }
          console.log('fn_AccnoSearch - eaiCommObj >',  this.eaiCommObj)
          TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_AccnoSearchResult)
        } else {
          if (TSCommUtil.gfn_length(this.lv_tac_no.value) > 0){
            this.fn_Init('keyup')
          }
        }
      },

      /******************************************************************************
       * Function명 : fn_AccnoSearchResult
       * 설명       : 계좌번호조회 결과
       ******************************************************************************/
      fn_AccnoSearchResult(pResultData) {
        console.log('fn_AccnoSearchResult.....')

        let t_data = pResultData.data

        console.log('fn_AccnoSearchResult - pResultData > ', pResultData)
        
        this.lv_bnkb_seq_items = []
        this.lv_tfabnb_list = []

        if (t_data !== null && t_data !== '') {
          if (t_data.tfabnb.length > 0){
            this.lv_btn_search = false // 조회
            this.lv_btn_init   = false // 초기화
            this.lv_tfabnb_list = t_data.tfabnb
                      
            // 계좌번호 설정
            this.lv_tfabnb_list.forEach( item => {            
              this.lv_bnkb_seq_items.push({value: item.bnkbSeq , text: item.tacnoBnkbSeq})                        
            })

            // 고객id 설정
            this.lv_cs_id = this.lv_tfabnb_list[0].csId
            if (!this.$bizUtil.isEmpty(this.lv_cs_id)){
              this.fn_CstBasInfoDtlSearch()
            }
            

            // 콤보박스 설정
            // 계좌번호
            if (!this.$bizUtil.isEmpty(this.lv_bnkb_seq_items) && this.lv_bnkb_seq_items.length > 0) {
              if(!TSCommUtil.gfn_isNull(this.lv_tac_param.bnkbSeq)){
                this.lv_bnkb_seq.value = this.lv_tac_param.bnkbSeq
              } else {
                this.lv_bnkb_seq.value = this.lv_bnkb_seq_items[0].value
              }

              // 계약번호
              this.fn_AgntCtNoSearch()
            }
          } else if (t_data.tfabnb.length === 0){
            this.fn_Init('keyup')            
            this.lv_btn_search = false
            this.lv_btn_init = false
          }
        }       
      },
      

      /******************************************************************************
       * Function명 : fn_AgntCtNoSearch
       * 설명       : 대리인계약번호조회(통장번호 콤보 변경 시)
       ******************************************************************************/
      fn_AgntCtNoSearch() {        
        console.log('fn_AgntCtNoSearch.....')
        if( TSCommUtil.gfn_length(this.lv_tac_no.value) == 7) {
          if( !TSCommUtil.gfn_isNum(this.lv_tac_no.value) ) {
            TSCommUtil.gfn_validate(this, '계좌번호의 형식에 맞지 않습니다.')
            this.lv_tac_no.error = true
            this.$refs['tac_no'].focus()
            return
          } else {
            this.lv_tac_no.error = false
          }

          // 비활성화

          // 서비스 호출
          this.eaiCommObj.lv_vm = this
          this.eaiCommObj.trnstId = 'txTSSTS28S2'
          this.eaiCommObj.auth = 'S'

          this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600117_S'          
          this.eaiCommObj.params = {
            tacno: this.lv_tac_no.value,
            bnkbSeq: this.lv_bnkb_seq.value,            
            pageRowCnt: 0,
            stndKeyId: ''
          }
          console.log('fn_AgntCtNoSearch - eaiCommObj >',  this.eaiCommObj)
          TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_AgntCtNoSearchResult)
        }
      },

      /******************************************************************************
       * Function명 : fn_AgntCtNoSearchResult
       * 설명       : 대리인계약번호조회 결과
       ******************************************************************************/
      fn_AgntCtNoSearchResult(pResultData) {
        console.log('fn_AgntCtNoSearchResult.....')

        let t_data = pResultData.data

        console.log('fn_AgntCtNoSearchResult - pResultData > ', pResultData)
        this.lv_ct_no_items = []        
        if (t_data !== null && t_data !== '') {
          if (t_data.tftcob.length > 0) {            
            this.lv_btn_search = false            
            t_data.tftcob.forEach( item => {
              // 계좌번호 설정
              this.lv_ct_no_items.push({value: item.ctno , text: item.ctno})
            })

            // 콤보박스 설정
            // 계약번호
            if (!this.$bizUtil.isEmpty(this.lv_ct_no_items) && this.lv_ct_no_items.length > 0) {
              if(!TSCommUtil.gfn_isNull(this.lv_tac_param.accSeq)){
                this.lv_ct_no.value = this.lv_tac_param.accSeq
                this.fn_AssiAsgnAplSearch('S')
                this.lv_tac_param = Object.assign({})
              } else {
                this.lv_ct_no.value = this.lv_ct_no_items[0].value
              }
              this.lv_ct_no.disabled = false
            }
          } else if (t_data.tftcob.length === 0) {
            this.lv_ct_no.disabled = true
          }
          
          this.lv_ct_no.value = this.lv_tac_param.ctno;
        }
      },

      /******************************************************************************
       * Function명 : fn_CstBasInfoDtlSearch
       * 설명       : 고객기본정보상세조회
       ******************************************************************************/
      fn_CstBasInfoDtlSearch() {
        console.log('fn_CstBasInfoDtlSearch.....')        
        if( !this.$bizUtil.isEmpty(this.lv_cs_id)) {
          // 비활성화

          // 서비스 호출
          this.eaiCommObj.lv_vm = this
          this.eaiCommObj.trnstId = 'txTSSTS27S3'
          this.eaiCommObj.auth = 'S'

          this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600113_S'          
          this.eaiCommObj.params = {            
            agntCsId: this.lv_cs_id,
            pageRowCnt: 0,
            stndKeyId: ''            
          }
          console.log('fn_CstBasInfoDtlSearch - eaiCommObj >',  this.eaiCommObj)
          TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CstBasInfoDtlSearchResult)
        }
      },
      /******************************************************************************
       * Function명 : fn_CstBasInfoDtlSearchResult
       * 설명       : 고객기본정보상세조회 결과
       ******************************************************************************/
      fn_CstBasInfoDtlSearchResult(pResultData) {
        console.log('fn_CstBasInfoDtlSearchResult.....')

        let t_data = pResultData.data

        console.log('fn_CstBasInfoDtlSearchResult - pResultData > ', pResultData)
        
        if (t_data !== null && t_data !== '') {
          console.log('fn_CstBasInfoDtlSearchResult - t_data > ', t_data)          
          this.fn_SetCustInfo('search', t_data)
        }
      },

      /******************************************************************************
       * Function명 : fn_ChkAssiYnRsnCd
       * 설명       : 조력자 지정/미지정 사유 코드 체크
       ******************************************************************************/
      fn_ChkAssiYnRsnCd(e) {
        let tmpCd = this.lv_cob_assi_yn_rsn.value
        console.log('fn_ChkAssiYnRsnCd > ', tmpCd)
        if(!TSCommUtil.gfn_isNull(tmpCd)) {
          if (tmpCd === '99'){ // 기타입력 일때만 미지정 사유 활성화
            this.lv_ed_assi_yn_rsn.disabled = false
          } else {
            this.lv_ed_assi_yn_rsn.disabled = true
          }

          if (tmpCd === '00' || tmpCd === '99') {
            this.lv_ed_assi_yn_rsn.value = ''
            this.lv_ed_rsn_length = 0            
            return
          }

          let selectTxt = this.cmb_assiYnRsn.filter(v => v.value === tmpCd)
          if (!TSCommUtil.gfn_isNull(selectTxt) && selectTxt.length > 0) {                        
            this.lv_ed_assi_yn_rsn.value = selectTxt[0].text
          } else {            
            this.lv_ed_assi_yn_rsn.value = ''
            this.lv_ed_rsn_length = 0
          }
        } else {
          this.lv_ed_assi_yn_rsn.value = ''
          this.lv_ed_rsn_length = 0
        }        
      },      

      /******************************************************************************
       * Function명 : fn_ChkAssiYn
       * 설명       : 조력자 지정/미지정 구분
       ******************************************************************************/      
      fn_ChkAssiYn(e) {        
        if(this.lv_assi_yn.value === 'Y') {
          this.fn_InitData('assiYn') // 버튼 관련 체크 안함, 조력자정보 외 활성화, 조력자 지정/미지정 구분 비활성화
          this.lv_assi_yn.emphasis = true
        } else {
          this.fn_InitData('assiYn') // 버튼 관련 체크 안함, 조력자정보 외 비활성화, 조력자 지정/미지정 구분 비활성화
          this.lv_assi_yn.emphasis = false
        }                
        
        this.lv_assi_yn.disabled = false        
        this.lv_cob_assi_yn_rsn.disabled = false        
      },

      /******************************************************************************
       * Function명 : fn_ChkAssiYnRsn
       * 설명       : 조력자 지정/미지정 사유 체크
       ******************************************************************************/    
      fn_ChkAssiYnRsn(){
        let tmpText = this.lv_ed_assi_yn_rsn.value
        if(!TSCommUtil.gfn_isNull(tmpText)){            
          let totalByte = 0            
          for (let i=0; i< tmpText.length; i++) {
            const eachChar = tmpText.charAt(i)
            const uniChar = escape(eachChar)
            if (uniChar.length>4) {
              totalByte += 3
            } else {
              totalByte += 1
            }
          }

          this.lv_ed_rsn_length = totalByte
          if( totalByte <= 200 ) {
            this.lv_ed_assi_yn_rsn.error = false
            this.lv_ed_assi_yn_rsn_tmp = tmpText                
          } else {              
            this.lv_ed_assi_yn_rsn.value = this.lv_ed_assi_yn_rsn_tmp
            this.lv_ed_assi_yn_rsn.error = true
            this.$refs['ed_assi_yn_rsn'].focus()              
          }            
        }
      },

      /******************************************************************************
       * Function명 : fn_SetBtnEnable
       * 설명       : 버튼 설정
       * 파라미터(type) : 버튼 타입 (U: 수정)
       ******************************************************************************/
      fn_SetBtnEnable (type) {
        switch(type) {
          case 'C':            
            this.lv_stateFlag   = 'C'  // 취소
            this.lv_btn_search  = false // 조회 (활성)
            this.lv_btn_init    = false // 초기화 (활성)
            this.lv_btn_update  = false  // 수정 (활성)            
            this.lv_btn_cancle  = true // 취소 (비활성)
            this.lv_btn_confirm  = true // 확인 (비활성)
            break            
          case 'U':            
            this.lv_stateFlag   = 'U'  // 수정
            this.lv_btn_search  = true // 조회 (비활성)
            this.lv_btn_init    = true // 초기화 (비활성)
            this.lv_btn_update  = true  // 수정 (비활성화)            
            this.lv_btn_cancle  = false // 취소 (활성)
            this.lv_btn_confirm  = false // 확인 (활성)
            break
        }
      },

      /******************************************************************************
       * Function명 : fn_Update
       * 설명       : 수정 이벤트
       ******************************************************************************/
      fn_Update() {        
        this.fn_SetBtnEnable('U')
        this.lv_assi_yn.disabled = false
        this.lv_cob_assi_yn_rsn.disabled = false
        if(this.lv_cob_assi_yn_rsn.value === '99') {
          this.lv_ed_assi_yn_rsn.disabled = false
        } else {
          this.lv_ed_assi_yn_rsn.disabled = true
        }
        

        if (this.lv_assi_yn.value === 'Y') { // 조력자지정여부
          this.lv_assi_nm.disabled = false
          this.lv_assi_cel_no.disabled  = false          
          this.lv_assi_rel.disabled  = false
          this.lv_persn_info_rec_date.disabled  = false
          this.lv_persn_info_rec_time.disabled  = false
          this.lv_gd_ent_rec_date.disabled  = false
          this.lv_gd_ent_rec_time.disabled  = false
        }
      },
      
      /******************************************************************************
       * Function명 : fn_SetInput
       * 설명       : 입력 설정 (조회 후 결과 값이 없을 때)
       ******************************************************************************/
      fn_SetInput() {                    
        this.lv_assi_yn.disabled = false
        this.lv_cob_assi_yn_rsn.disabled = false        
      },

      /******************************************************************************
       * Function명 : fn_Cancle
       * 설명       : 취소 이벤트
       ******************************************************************************/
      fn_Cancle() {
        if (!this.lv_isInsert) {
          this.fn_AssiAsgnAplSearch('S') // 조력자신청 재조회        
        } else {
          this.fn_InitData()
        }
      },

      /******************************************************************************
       * Function명 : fn_SetParam
       * 설명       : 파라미터 설정
       ******************************************************************************/
      fn_SetParam(type) {
        let param = {}
        let tfcarhLst = []
        const tfcarh = {
              tacno: this.lv_tac_no.value,
              bnkbSeq: this.lv_bnkb_seq.value,
              ctno: this.lv_ct_no.value,
              assiYn: '',
              assiYnRsn: '',
              assiNm: '',
              assiCelno: '',              
              assiRel: '',              
              persnInfoRecDate: '',
              persnInfoRecTime: '',
              gdEntRecDate: '',
              gdEntRecTime: '',
              lastChngDt: '',
              lastChngMnTmno: '',
              lastChngMnUsid: '',              
              pageRowCnt: 0,
              stndKeyId: '',
            }        
        switch(type) {
          case 'U': // 업데이트 시
            // ds_search 객체
            param.tacno   = this.lv_tac_no.value
            param.bnkbSeq = this.lv_bnkb_seq.value
            param.ctno    = this.lv_ct_no.value
            param.assiYn = this.lv_assi_yn.value
            param.assiYnRsn = this.lv_ed_assi_yn_rsn.value
            param.assiNm = this.lv_assi_nm.value
            param.assiCelno = String(this.lv_assi_cel_no.value)
            param.assiRel = this.lv_assi_rel.value
            param.persnInfoRecDate = this.lv_persn_info_rec_date.value.replace(/-/g, '')
            param.persnInfoRecTime = this.lv_persn_info_rec_time.value1 + this.lv_persn_info_rec_time.value2
            param.gdEntRecDate = this.lv_gd_ent_rec_date.value.replace(/-/g, '')
            param.gdEntRecTime = this.lv_gd_ent_rec_time.value1 + this.lv_gd_ent_rec_time.value2
            
            tfcarhLst.push(tfcarh)
            param.tfcarh = tfcarhLst
            param.pageRowCnt = '0'
            param.stndKeyId = ''            
            break
        }
        return param
      },

      /******************************************************************************
       * Function명 : fn_Validate
       * 설명       : 등록/수정 시 입력값 검증
       ******************************************************************************/
      fn_Validate() {
        let desc = (this.lv_assi_yn.value === 'Y') ? '조력자 지정 사유' : '조력자 미지정 사유'
        let today = moment(new Date()).format('YYYYMMDD')
        if( TSCommUtil.gfn_isNull(this.lv_assi_yn.value) ) {
          TSCommUtil.gfn_validate(this, '조력자 지정 여부를 입력해주세요.')
          this.lv_assi_yn.error = true
          this.$refs['assi_yn'].focus()
          return
        }

        if( TSCommUtil.gfn_isNull(this.lv_ed_assi_yn_rsn.value) ) {          
          TSCommUtil.gfn_validate(this, desc + '를 입력해주세요.')
          this.lv_ed_assi_yn_rsn.error = true
          this.$refs['ed_assi_yn_rsn'].focus()
          return
        } else {
          if( this.lv_ed_rsn_length > 200 ) {
            TSCommUtil.gfn_validate(this, desc + ' 입력 길이 초과입니다. (최대 200byte)')
            this.lv_ed_assi_yn_rsn.error = true
            this.$refs['ed_assi_yn_rsn'].focus()
            return
          }        
        }
        

        if (this.lv_assi_yn.value === 'Y') {
          if( TSCommUtil.gfn_isNull(this.lv_assi_nm.value) ) {
            TSCommUtil.gfn_validate(this, '조력자명을 입력해주세요.')
            this.lv_assi_nm.error = true
            this.$refs['assi_nm'].focus()
            return
          }          

          if (TSCommUtil.gfn_isNull(this.lv_assi_cel_no.value) || TSCommUtil.gfn_trim(this.lv_assi_cel_no.value).length < 9) {
            TSCommUtil.gfn_validate(this, '연락처를 입력해주세요.')
            this.lv_assi_cel_no.error = true            
            this.$refs['assi_cel_no'].focus()
            return
          }

          if( TSCommUtil.gfn_isNull(this.lv_assi_rel.value) ) {
            TSCommUtil.gfn_validate(this, '조력자 관계를 입력해주세요.')
            this.lv_assi_rel.error = true
            this.$refs['assi_rel'].focus()
            return
          }

          if( TSCommUtil.gfn_isNull(this.lv_persn_info_rec_date.value) ) {
            TSCommUtil.gfn_validate(this, '녹취일자를 선택해주세요.')
            this.lv_persn_info_rec_date.error = true            
            return
          } else {
            let tmpDate = this.lv_persn_info_rec_date.value.replace(/-/g, '')
            if (tmpDate > today) {              
              TSCommUtil.gfn_validate(this, '녹취일자는 미래일자일 수 없습니다.')
              this.lv_persn_info_rec_date.error = true              
              return
            }            
          }

          if( TSCommUtil.gfn_isNull(this.lv_persn_info_rec_time.value1) || this.lv_persn_info_rec_time.value1 === '99' ) {
            TSCommUtil.gfn_validate(this, '조력자 개인정보 수집 녹취시간을 선택해주세요.')
            this.lv_persn_info_rec_time.error = true            
            return
          }

          if( TSCommUtil.gfn_isNull(this.lv_persn_info_rec_time.value2) || this.lv_persn_info_rec_time.value2 === '99' ) {
            TSCommUtil.gfn_validate(this, '조력자 개인정보 수집 녹취시간을 선택해주세요.')
            this.lv_persn_info_rec_time.error = true            
            return
          }

          if( TSCommUtil.gfn_isNull(this.lv_gd_ent_rec_date.value) ) {
            TSCommUtil.gfn_validate(this, '녹취일자를 선택해주세요.')
            this.lv_gd_ent_rec_date.error = true            
            return
          } else {
            let tmpDate = this.lv_gd_ent_rec_date.value.replace(/-/g, '')
            if (tmpDate > today) {              
              TSCommUtil.gfn_validate(this, '녹취일자는 미래일자일 수 없습니다.')
              this.lv_gd_ent_rec_date.error = true              
              return
            }            
          }

          if( TSCommUtil.gfn_isNull(this.lv_gd_ent_rec_time.value1) || this.lv_gd_ent_rec_time.value1 === '99' ) {
            TSCommUtil.gfn_validate(this, '조력자 상품가입 안내 녹취시간을 선택해주세요.')
            this.lv_gd_ent_rec_time.error = true            
            return
          }

          if( TSCommUtil.gfn_isNull(this.lv_gd_ent_rec_time.value2) || this.lv_gd_ent_rec_time.value2 === '99' ) {
            TSCommUtil.gfn_validate(this, '조력자 상품가입 안내 녹취시간을 선택해주세요.')
            this.lv_gd_ent_rec_time.error = true            
            return
          }
        }
        
        return true
      },

      /******************************************************************************
       * Function명 : fn_Confirm
       * 설명       : 입력 시 validate 체크
       * 파라미터(type) : 수정 / 입력 구분
       ******************************************************************************/
      fn_Confirm(type) {
        if (!this.fn_Validate()) {
          return false
        }

        this.lv_stateFlag = type
        this.fn_Transaction()
      },

      /******************************************************************************
       * Function명 : fn_AssiAsgnAplSearch, fn_AssiAsgnAplSearchResult
       * 설명       : 조력자지정신청조회 (selectTFAC28000)       
       ******************************************************************************/
      fn_AssiAsgnAplSearch(state = '') {
        if( TSCommUtil.gfn_length(this.lv_tac_no.value) == 7 ) {
          if( !TSCommUtil.gfn_isNum(this.lv_tac_no.value) ) {
            TSCommUtil.gfn_validate(this, '계좌번호의 형식에 맞지 않습니다.')
            this.lv_tac_no.error = true
            this.$refs['tac_no'].focus()
            return
          } else {
            this.lv_tac_no.error = false
          }

          if( TSCommUtil.gfn_length(this.lv_bnkb_seq.value)  == 0 || TSCommUtil.gfn_length(this.lv_ct_no.value)  == 0 ) {            
            this.fn_AlertPopup(0,{content: '계좌번호를 입력하세요'})
            return
          } 

          this.lv_stateFlag = 'S'

          // 서비스 호출
          this.eaiCommObj.lv_vm = this
          this.eaiCommObj.trnstId = 'txTSSTS28S1'
          this.eaiCommObj.auth = 'S'

          this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600116_S'          
          this.eaiCommObj.params = {
            tacno: this.lv_tac_no.value,
            bnkbSeq: this.lv_bnkb_seq.value,
            ctno: this.lv_ct_no.value,
            pageRowCnt: '0',
            stndKeyId: '',
          }
          console.log('fn_AssiAsgnAplSearch - eaiCommObj >',  this.eaiCommObj)
          TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_AssiAsgnAplSearchResult, this.fn_error)
        } else if (TSCommUtil.gfn_length(this.lv_tac_no.value) == 0)  {
          this.lv_btn_cancle = true
          this.lv_btn_confirm = true
          this.fn_AlertPopup(0, {content: '계좌번호를 입력하세요'})          
        }
      },

      /******************************************************************************
       * Function명 : fn_AssiAsgnAplSearchResult
       * 설명       : 조회 시 호출 결과
       ******************************************************************************/
      fn_AssiAsgnAplSearchResult(pResultData) {
        // 오류 확인
        let t_data = pResultData.data
        if (t_data !== null && t_data !== '') {
          if (TSCommUtil.gfn_trim(t_data.errorCode) !== '') {
            return false
          }          

          if (!TSCommUtil.gfn_isNull(t_data.tacno) && TSCommUtil.gfn_trim(t_data.tacno).length === 7) { 
            
            this.fn_InitData('search')

            // 조력자 지정/미지정 여부 및 사유
            if (!TSCommUtil.gfn_isNull(t_data.assiYn)) {
              this.lv_assi_yn.value = t_data.assiYn
              this.lv_assi_yn.disabled = true
            }

            if (!TSCommUtil.gfn_isNull(t_data.assiYnRsn)) {
              let tmpAssiYnRsnCd = this.cmb_assiYnRsn.filter(v => v.text === TSCommUtil.gfn_trim(t_data.assiYnRsn))
              if(!TSCommUtil.gfn_isNull(tmpAssiYnRsnCd) && tmpAssiYnRsnCd.length > 0) {
                this.lv_cob_assi_yn_rsn.value = this.cmb_assiYnRsn.filter(v => v.text === TSCommUtil.gfn_trim(t_data.assiYnRsn))[0].value
              } else {
                this.lv_cob_assi_yn_rsn.value = '99'
              }
              this.lv_cob_assi_yn_rsn.disabled = true
            }

            if (!TSCommUtil.gfn_isNull(t_data.assiYnRsn)) {
              this.lv_ed_assi_yn_rsn.value = t_data.assiYnRsn
              this.lv_ed_assi_yn_rsn.disabled = true
            }

            if (t_data.assiYn === 'Y') {
              // 조력자 정보
              if (!TSCommUtil.gfn_isNull(t_data.assiNm)) {
                this.lv_assi_nm.value = t_data.assiNm
                this.lv_assi_nm.disabled = true
              }              

              if (!TSCommUtil.gfn_isNull(t_data.assiCelno)) {              
                this.lv_assi_cel_no.value = t_data.assiCelno
                this.lv_assi_cel_no.disabled = true
              }

              if (!TSCommUtil.gfn_isNull(t_data.assiRel)) {
                this.lv_assi_rel.value = t_data.assiRel
                this.lv_assi_rel.disabled = true
              }

              // 개인정보
              if (!TSCommUtil.gfn_isNull(t_data.persnInfoRecDate)) {
                this.lv_persn_info_rec_date.value = TSCommUtil.gfn_dateReplace(1, t_data.persnInfoRecDate)
                this.lv_persn_info_rec_date.disabled = true
              }

              if (!TSCommUtil.gfn_isNull(t_data.persnInfoRecTime)) {
                this.lv_persn_info_rec_time.value1 = t_data.persnInfoRecTime.substr(0,2)
                this.lv_persn_info_rec_time.value2 = t_data.persnInfoRecTime.substr(2,2)
                this.lv_persn_info_rec_time.disabled = true
              }

              // 상품가입
              if (!TSCommUtil.gfn_isNull(t_data.gdEntRecDate)) {
                this.lv_gd_ent_rec_date.value = TSCommUtil.gfn_dateReplace(1, t_data.gdEntRecDate)
                this.lv_gd_ent_rec_date.disabled = true
              }

              if (!TSCommUtil.gfn_isNull(t_data.gdEntRecTime)) {
                this.lv_gd_ent_rec_time.value1 = t_data.gdEntRecTime.substr(0,2)
                this.lv_gd_ent_rec_time.value2 = t_data.gdEntRecTime.substr(2,2)
                this.lv_gd_ent_rec_time.disabled = true
              }              
            }

            this.lv_btn_init = false
            this.lv_btn_search = false
          } else if (TSCommUtil.gfn_isNull(t_data.tacno) || TSCommUtil.gfn_trim(t_data.tacno).length !== 7) {
            this.fn_InitData('input')
            this.fn_SetInput()
            this.lv_btn_init = false
            this.lv_btn_search = false
          } else {
            this.fn_InitData()
          }
        }
      },

      /******************************************************************************
       * Function명 : fn_Transaction, fn_TransactionResult
       * 설명       : 입력 수정 처리시 호출
       ******************************************************************************/
      fn_Transaction() {        
        console.log('fn_Transaction.....')
        if( TSCommUtil.gfn_length(this.lv_tac_no.value) == 7 ){
          if( !TSCommUtil.gfn_isNum(this.lv_tac_no.value) ) {
            TSCommUtil.gfn_validate(this, '계좌번호의 형식에 맞지 않습니다.')
            this.lv_tac_no.error = true
            this.$refs['tac_no'].focus()
            return
          } else {
            this.lv_tac_no.error = false
          }

          // 서비스 호출
          this.eaiCommObj.lv_vm = this          
          this.eaiCommObj.auth = 'S'

          this.eaiCommObj.commHeaderVO.eaiId = this.lv_isInsert ? 'C392_F10600115_S' : 'C392_F10600118_S'
          this.eaiCommObj.params = this.fn_SetParam('U')
          console.log('fn_Transaction - eaiCommObj >',  this.eaiCommObj)
          TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_TransactionResult, this.fn_error)
        }
      },

      /******************************************************************************
       * Function명 : fn_TransactionResult
       * 설명       : 입력 수정 시 호출 결과
       ******************************************************************************/
      fn_TransactionResult(pResultData) {
        // 오류 확인
        let lv_Vm = this
        let t_data = pResultData.data
        if (t_data !== null && t_data !== '') {
          if (TSCommUtil.gfn_trim(t_data.errorCode) !== '') {
            let t_popupObj = {
              confirm: false,          
              content: ['입력한 내용이 정상적으로 수정되지 않았습니다.', '관리자에게 문의하세요.'],
              contentTitle: ''
            }        
            this.fn_AlertPopup(7, t_popupObj)            
            return false
          }          

          if (!TSCommUtil.gfn_isNull(t_data.tacno) && TSCommUtil.gfn_trim(t_data.tacno).length === 7) { 
            
            this.fn_InitData('search')           

            // 조력자 지정/미지정 여부 및 사유
            if (!TSCommUtil.gfn_isNull(t_data.assiYn)) {
              this.lv_assi_yn.value = t_data.assiYn              
              this.lv_assi_yn.disabled = true
            }

            if (!TSCommUtil.gfn_isNull(t_data.assiYnRsn)) {
              let tmpAssiYnRsnCd = this.cmb_assiYnRsn.filter(v => v.text === TSCommUtil.gfn_trim(t_data.assiYnRsn))
              if(!TSCommUtil.gfn_isNull(tmpAssiYnRsnCd) && tmpAssiYnRsnCd.length > 0) {
                this.lv_cob_assi_yn_rsn.value = this.cmb_assiYnRsn.filter(v => v.text === TSCommUtil.gfn_trim(t_data.assiYnRsn))[0].value
              } else {
                this.lv_cob_assi_yn_rsn.value = '99'
              }
              this.lv_cob_assi_yn_rsn.disabled = true
            }

            if (!TSCommUtil.gfn_isNull(t_data.assiYnRsn)) {
              this.lv_ed_assi_yn_rsn.value = t_data.assiYnRsn
              this.lv_ed_assi_yn_rsn.disabled = true
            }

            if (t_data.assiYn === 'Y') {

              // 조력자 정보
              if (!TSCommUtil.gfn_isNull(t_data.assiNm)) {
                this.lv_assi_nm.value = t_data.assiNm
                this.lv_assi_nm.disabled = true
              }

              if (!TSCommUtil.gfn_isNull(t_data.assiCelno)) {              
                this.lv_assi_cel_no.value = t_data.assiCelno
                this.lv_assi_cel_no.disabled = true
              }

              if (!TSCommUtil.gfn_isNull(t_data.assiRel)) {
                this.lv_assi_rel.value = t_data.assiRel
                this.lv_assi_rel.disabled = true
              }

              // 개인정보
              if (!TSCommUtil.gfn_isNull(t_data.persnInfoRecDate)) {
                this.lv_persn_info_rec_date.value = TSCommUtil.gfn_dateReplace(1, t_data.persnInfoRecDate)
                this.lv_persn_info_rec_date.disabled = true
              }

              if (!TSCommUtil.gfn_isNull(t_data.persnInfoRecTime)) {
                this.lv_persn_info_rec_time.value1 = t_data.persnInfoRecTime.substr(0,2)
                this.lv_persn_info_rec_time.value2 = t_data.persnInfoRecTime.substr(2,2)
                this.lv_persn_info_rec_time.disabled = true
              }

              // 상품가입
              if (!TSCommUtil.gfn_isNull(t_data.gdEntRecDate)) {
                this.lv_gd_ent_rec_date.value = TSCommUtil.gfn_dateReplace(1, t_data.gdEntRecDate)
                this.lv_gd_ent_rec_date.disabled = true
              }

              if (!TSCommUtil.gfn_isNull(t_data.gdEntRecTime)) {
                this.lv_gd_ent_rec_time.value1 = t_data.gdEntRecTime.substr(0,2)
                this.lv_gd_ent_rec_time.value2 = t_data.gdEntRecTime.substr(2,2)
                this.lv_gd_ent_rec_time.disabled = true
              }

              this.lv_rtn_param.assiYn = "Y"
            }else{
              this.lv_rtn_param.assiYn = "N"
            }

            this.lv_btn_init = false
            this.lv_btn_search = false

            let tmpContent = []
            if (this.lv_stateFlag === 'U') {
              tmpContent.push('정상적으로 수정되었습니다.')
              this.fn_AlertPopup(0,{content: tmpContent})
            } else if (this.lv_stateFlag === 'I'){
              tmpContent.push('조력자 지정 완료')
              tmpContent.push('등록이 완료되었습니다.')
              this.fn_AlertPopup(0,{content: tmpContent})
            }

            let t_type = ''            
            if ( lv_Vm.lv_isProcess ) { 
              t_type = 7 // 프로세스 진행 중일 때
            } else {    
              t_type = 9
            }
            lv_Vm.lv_transaction = t_data
            let t_popupRptObj = {
              confirm: true,
              confirmFunc: lv_Vm.fn_ReportPrint,
              confirmData: {
                tacno: lv_Vm.lv_tac_no.value,
                bnkbSeq: lv_Vm.lv_bnkb_seq.value,
                accSeq: lv_Vm.lv_ct_no.value,
              },
              content: tmpContent,
              contentTitle: '',
            }
            lv_Vm.fn_AlertPopup(t_type, t_popupRptObj)
          } else {
            this.fn_InitData()
          }
        }
      },

      /******************************************************************************
       * Function명 : fn_ReportPrint
       * 설명       : 전자서식 호출
       * 파라미터   : 리턴 파라미터
       ******************************************************************************/
      async fn_ReportPrint(pParam, nextFunc) {
        console.log('fn_ReportPrint....')
        let formList = []    
        if( TSCommUtil.gfn_length(this.lv_tac_no.value) == 7) {
          if( !TSCommUtil.gfn_isNum(this.lv_tac_no.value) ) {
            TSCommUtil.gfn_validate(this, '계좌번호의 형식에 맞지 않습니다.')
            this.lv_tac_no.error = true
            this.$refs['tac_no'].focus()
            return
          } else {
            this.lv_tac_no.error = false
          }

          // 전자서명동의서
          let TS000001 = {formId: 'TS000001'}          
          let searchData = {
            tacno: this.lv_tac_no.value,    //계좌번호
            bnkbSeq: this.lv_bnkb_seq.value,//통장일련번호
            //accSeq: this.tacno
            ctno: this.lv_ct_no.value       //계약번호
          }

          TS000001.params = {
            reportParam: JSON.stringify(searchData),
            reportUrl: 'reportTFAC20000',
          }
          formList.push(TS000001)

          
          let TS000043 = {formId: 'TS000043'}

          let data = this.lv_transaction
          TS000043.mappingData = {
            cnm: this.lv_cnm,
            assiYn: data.assiYn === 'Y' ? '1' : '2',
            assiYnRsn: data.assiYnRsn,
            assiNm: data.assiNm,
            assiRel: data.assiRel,
            assiCelno: data.assiCelno,
            persnInfoRecTime: data.persnInfoRecTime,
            gdEntRecTime: data.gdEntRecTime,            
          }

          formList.push(TS000043)
          
          TSInfoUtil.commReportInfo(this, formList, nextFunc)
        }
      },
      
      /*********************************************************
      * Function명: fn_OpenModal
      * 설명: 모달팝업 오픈
      *********************************************************/
      fn_OpenModal() {
        if(!this.fn_Validate()) {
          return false
        }
        if (this.lv_isInsert){
          this.fn_Confirm("I");
        }else{
          this.fn_Confirm("U");
        }
      },

      fn_Close(){
        //lv_rtn_param.assiYn
        this.$emit("ts-popup322p-callback", {"assiYn":this.fn_ChkAssiYn});
        this.modal.close();
      },

      /*********************************************************
      * Function명: fn_OpenMSPTS107P
      * 설명: 고객조회 팝업호출
      *********************************************************/
      fn_OpenMSPTS107P (type) {
        //this.$refs.popup110.fn_Init();
        let lv_vm = this
        this.pPopup107Type = type        
        let properties = {
          pPage : 'MSPTS322P',
          pGdC : lv_vm.gdC,              // 신탁상품
          pTrstTypeC : lv_vm.trstTypC,   // 신탁유형
          pGdTypDtlC : lv_vm.gdTypDtlC,  // 상품유형
          pUrl : '2000',                 // 
          pProcTc : '2000',              //
        }

        this.popup107 = this.$refs.popup107.fn_Open(properties)
      },
      
      /*********************************************************
      * Function명: fn_Popup107Back
      * 설명: 고객조회 팝업호출 콜백
      *********************************************************/
      fn_Popup107Back(rtnData){        
        //조회조건 고객팝업
        if(this.pPopup107Type === '00'){          
          this.fn_Init()
          this.fn_SetCustInfo('popup', rtnData)
          this.fn_AccnoSearch()
        //고객정보 입력
        }
      },

      /*********************************************************
      * Function명: fn_SetCustInfo
      * 설명: 고객정보 설정
      * 파라미터 : 고객정보객체
      *********************************************************/
      fn_SetCustInfo(type, rtnData) {
        if (type === 'popup') {
          this.lv_tac_no.value = rtnData.tacno  // 계좌번호
        }

        this.lv_cnm = rtnData.cnm // 고객명
        this.lv_cs_id = rtnData.csId // 고객번호

        // 만 나이 계산
        let rtn = ''
        let rcno = rtnData.rcno
        let rcnoKindTc = rtnData.rcnoKindTc
        if(!TSCommUtil.gfn_isNull(rcno) && !TSCommUtil.gfn_isNull(rcnoKindTc)) {
          var date = rcno.substr(0,6)	
          var sex = ''
          
          if ( rcno.substr(6,1) == "-" ) {
            sex = rcno.substr(7,1)
          }
          else {
            sex = rcno.substr(6,1)
          }

          if (rcnoKindTc == '01' || rcnoKindTc == '04' || rcnoKindTc == '05' || rcnoKindTc == '06') {
            rtn = TSCommUtil.gfn_getLifeAge(date, sex)
          }          
        }
        
        this.lv_cs_age =  rtn !== '' ? rtn : rtnData.age
        this.lv_cs_age += '세'

        if(TSCommUtil.gfn_isNull(rtnData.tacno)){
          this.lv_btn_search = false
          this.lv_btn_init = false          
        }
      },
      
    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      'lv_assi_yn.value': {
        handler(after, before) {
          if( !TSCommUtil.gfn_isNull(after) ){            
            if(after){
              if(after === 'Y') {
                this.lv_assi_yn.emphasis = true
              } else {
                this.lv_assi_yn.emphasis = false                
              }

            }            
          }
        },
        deep: true
      }
    },
  }
</script>
