/*
 * 업무구분: 사원조회
 * 화 면 명: MSPTS103P
 * 화면설명: 사원조회
 * 작 성 일: 2023.03.06
 * 작 성 자: 김경태
 * 파 일 명: TPUP95110 
 */

/******************************************************************************************
 * Vue 메인 templates 설정 영역
 ******************************************************************************************/
<template>
  <mo-modal class="fts-modal large" ref="modal" title="사원조회">
    <div class="wrap-modalcontents">
      <div class="input-top">
        <div class="left">
          <div class="wrap-input row">
            <label> 사원번호 </label>
            <mo-decimal-field v-model="userId" numeric mask="#####" clearable placeholder="입력하세요" class="w130"/>
          </div>
          <div class="wrap-input row">
            <label> 성명 </label>
            <mo-text-field v-model="userNm" clearable placeholder="입력하세요" class="w130"/>
          </div>
        </div>
        <div class="right">
          <div class="wrap-button row">
            <mo-button @click="fn_Clear"> 초기화 </mo-button>
            <mo-button primary @click="fn_Search" class="btn-inquiry"> 조회 </mo-button>
          </div>
        </div>
      </div>
      <div class="wrap-table mt16 h-scroll">
        <table class="table row-type aC">
          <thead>
            <tr>
              <th class="w40"> </th>
              <th> 사원번호 </th>
              <th> 성명 </th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="(data, idx) in tuuext" :key="idx">
                <td @click="fn_RowSelected (idx.toString ())">
                  <mo-radio 
                  v-model="lv_SelectedItem" 
                  :id="idx" 
                  :value="idx.toString ()" 
                  @click="fn_RowSelected (idx.toString ())"
                />
                </td>
                <td @click="fn_RowSelected (idx.toString ())">{{data.usid}}</td>
                <td @click="fn_RowSelected (idx.toString ())">{{data.userNm}}</td>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_Close">닫기</mo-button>
        <mo-button primary size="large" @click="fn_Confirm">확인</mo-button>
      </div>
    </template>
    <!-- (공통)팝업-메시지처리용 -->
    <ts-alert-popup 
      ref="alertPopup_103p"
      :popupObj="pAlertPopupObj_103p"
    ></ts-alert-popup>
  </mo-modal>
</template>

<script>
/************************************************************************************************
 * 공통 라이브러리 INCLUDE 영역
 ************************************************************************************************/
import TSAlertPopup from "~/src/ui/ts/comm/TSAlertPopup"
import TSCommUtil from "~/src/ui/ts/comm/TSCommUtil"
import TSServiceUtil from "~/src/ui/ts/comm/TSServiceUtil"

export default {
  /******************************************************************************************
   * Vue 인스턴스 기본속성(화면명, ID 등) 정의
   ******************************************************************************************/
  name: "MSPTS103P", // 현재 화면명
  screenId: "MSPTS103P", // 현재 화면 ID
  /******************************************************************************************
   * Components 설정 영역
   ******************************************************************************************/
  components: {
    "ts-alert-popup": TSAlertPopup, // (공통)팝업 메시지 처리용
  },
  /******************************************************************************************
   * Props 설정 영역
   ******************************************************************************************/
  props: {
    pPage: String,      // 부모 페이지명
    pGdC: String,       // 신탁상품
    pTrstTypeC: String, // 신탁유형
    pGdTypDtlC: String, // 상품유형
    pUrl: String,
    pProcTc: String,
    popupObj: {
      type: Object,
      default: null,
    },
  },
  /******************************************************************************************
   * Data 설정 영역
   ******************************************************************************************/
  data () {
    return {
      // ------
      // 개발/검증용 로깅출력 여부, added by anarchi (2023.08.7)
      // (※ 개발시: true, 운영이관시: false 설정할 것)
      // ------
      isLoggable: true,
      userId: "",
      userNm: "",
      selectByBrcd:"N",
      brcd:"",
      lv_Params: {},
      lv_ReturnVal: {}, // 리턴값
      lv_ReturnStr: [], // 베이스 화면으로 리턴할 조회조건 텍스트
      lv_SelectedItem: "",
      eaiCommObj: {
        lv_vm: "",
        trnstId: "",
        auth: "",
        commHeaderVO: {
          eaiId: "",
          fahrTrnId: "S",
          requestSystemCode: "F1391",
          reqSrvcNm: "",
          reqMthdNm: "",
          targetSystemCode: "",
          resVONm: "",
          reqVONm: "",
        },
        params: {},
        response: [],
        error_msg: "",
      },
      tuuext: [],
      pAlertPopupObj_103p: {},
      rowSelect: {},
    };
  },
  /******************************************************************************************
   * Computed 설정 영역
   ******************************************************************************************/
  computed: {
    modal () {
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::modal ]___[ 시작 ]");
      return this.$refs.modal;
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::modal ]___[ 종료 ]");
    },
  },
    /** watch 정의 영역 */
  /******************************************************************************************
   * Watch 설정 영역
   ******************************************************************************************/
  watch: {
    // 
  },
  /******************************************************************************************
   * [Step-2][Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Created 설정 영역
   ******************************************************************************************/
  created () {
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::created ]___[ 시작 ]");
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::created ]___[ 종료 ]");
  },
  /******************************************************************************************
   * [Step-4][Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Mounted 설정 영역
   ******************************************************************************************/
  mounted () {
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::mounted ]___[ 시작 ]");
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog ("MSPTS103P");
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::mounted ]___[ 종료 ]");
  },
  /******************************************************************************************
   * Data 설정 영역
   ******************************************************************************************/
  methods: {
    // ======================================================
    // (업무:초기화) fn_Init:: 
    // ======================================================
    fn_Init () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Init ]___[ 시작 ]");
      this.modal.open ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Init ]___[ 종료 ]");
    },
    // ======================================================
    // (화면요소 제어) fn_Open:: 
    // ======================================================
    fn_Open (param) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Open ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [사원조회 팝업-(호출)]_[(수신)-파라미터]", " param → ", param);
      if (this.isLoggable) console.log ("+++++++++");
      this.fn_Clear ();
      this.lv_Params = param;
      if (!_.isEmpty (param.selectByBrcd)) {
        this.selectByBrcd = param.selectByBrcd;
      }
      if (!_.isEmpty (param.brcd)) {
        this.brcd = param.brcd;
      }
      this.modal.open ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Open ]___[ 종료 ]");
    },
    // ======================================================
    // (화면흐름 제어) fn_Close:: 
    // ======================================================
    fn_Close () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Close ]___[ 시작 ]");
      this.modal.close ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Close ]___[ 종료 ]");
    },
    // ======================================================
    // (업무:초기화) fn_Clear:: 입력값 모두 초기화 (초기 값으로 셋팅)
    // ======================================================
    fn_Clear () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Clear ]___[ 시작 ]");
      this.rowSelect = {};
      this.lv_SelectedItem = "";
      this.userId = "";
      this.userNm = "";
      this.tuuext = [];
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Clear ]___[ 종료 ]");
    },
    // ======================================================
    // (업무:비동기 서비스 호출) fn_Search:: 사원정보 조회
    // ======================================================
    fn_Search () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Search ]___[ 시작 ]");
      if (this.selectByBrcd === "Y") {
        if (_.isEmpty (this.brcd)) {
          let t_popupObj = {
            confirm: false,
            content: "소속 부서정보가 없습니다.",
          };
          // this.$emit ("ts-popup103-callback", 0, t_popupObj);
          this.$refs.alertPopup_103p.fn_OpenA (t_popupObj);
          return;
        }
        // ---------
        // EAI(PO) 전송 파라미터 설정
        // ---------
        let pParams = {
          usid: this.userId,
          userNm: this.userNm,
          brcd: this.brcd,
        };
        // ---------
        // □ 원격트랜잭션서비스(PO) 호출
        // 'C392_F10600209_S' |
        // 'UF10600209' // [NEXT 사랑On 신탁]사원목록조회-selectTPUP95110AList
        // ---------
        this.eaiCommObj.lv_vm = this;
        this.eaiCommObj.auth = "S";
        // this.eaiCommObj.trnstId = "txTSSTS92S5";
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600209_S";
        this.eaiCommObj.params = pParams;
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶ [사원목록조회_A]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
        if (this.isLoggable) console.log ("+++++++++");
        // TSServiceUtil.invoke (this.eaiCommObj, this.fn_SearchResultA)
        // TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_transactionResult);
        TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_SearchResultA);
      }
      else {
        if ((this.userId.length + this.userNm.length) < 1) {
          let t_popupObj = {
            confirm: false,
            // content: "사원번호,사원명 둘 중 하나는 2글자 이상 입력해 주세요.",
            content: ["사원번호, 사원명 둘중 하나는 2글자 이상 입력해 주세요.",],
          };
          // this.$emit ("ts-popup103-callback", 0, t_popupObj);
          this.$refs.alertPopup_103p.fn_OpenA (t_popupObj);
          return;
        }
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // if ((this.userId.length > 0 && this.userId.length) < 2) {
        //   let t_popupObj = {
        //     confirm: false,
        //     content: "사원번호를 2글자 이상 입력해 주세요.",
        //   };
        //   // this.$emit ("ts-popup103-callback", 0, t_popupObj);
        //   this.$refs.alertPopup_103p.fn_OpenA (t_popupObj);
        //   return;
        // }
        // else if ((this.userNm.length > 0 && this.userNm.length) < 2) {
        //   let t_popupObj = {
        //     confirm: false,
        //     content: "사원명을 2글자 이상 입력해 주세요.",
        //   };
        //   // this.$emit ("ts-popup103-callback", 0, t_popupObj);
        //   this.$refs.alertPopup_103p.fn_OpenA (t_popupObj);
        //   return;
        // }
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // ---------
        // EAI(PO) 전송 파라미터 설정
        // ---------
        let pParams = {
          usid: this.userId,
          userNm: this.userNm,
          brcd: this.brcd,
        };
        // ---------
        // □ 원격트랜잭션서비스(PO) 호출
        // 'C392_F10600210_S' |
        // 'UF10600210' // [NEXT 사랑On 신탁]사원목록조회-selectTPUP95110List
        // ---------
        this.eaiCommObj.lv_vm = this;
        this.eaiCommObj.auth = "S";
        // this.eaiCommObj.trnstId = "txTSSTS92S6";
        this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600210_S";
        this.eaiCommObj.params = pParams;
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶ [사원목록조회]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
        if (this.isLoggable) console.log ("+++++++++");
        TSServiceUtil.invokeInDirect (this.eaiCommObj, function (response) {
          if (this.isLoggable) console.log ("+++++++++");
          if (this.isLoggable) console.log ("+ ▶▷ [사원목록조회]_[EAI(PO)_(", response.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " response → ", response);
          if (this.isLoggable) console.log ("+++++++++");
          if (response && response.data) {
            this.tuuext = response.data.tuuext;
          }
        }, function (error) {
          if (this.isLoggable) console.log ("+ ▶ [사원목록조회]_[EAI(PO) error", error);
          return;
        })
        // TSServiceUtil.invoke (this.eaiCommObj, this.fn_SearchResult)
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Search ]___[ 종료 ]");
    },
    // ======================================================
    // (업무:비동기 서비스 호출--콜백) fn_SearchResult:: 사원(부서)정보 조회 후처리
    // ======================================================
    fn_SearchResult (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SearchResult ]___[ 시작 ]");
      this.tuuext = [];
      this.tuuext = res.data.body.tuuext;
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SearchResult ]___[ 종료 ]");
    },
    // ======================================================
    // (업무:비동기 서비스 호출--콜백) fn_SearchResultA:: 사원(부서)정보 조회 후처리 (임시)
    // ======================================================
    fn_SearchResultA (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SearchResultA ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶▷ [사원목록조회_A]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " res → ", res);
      if (this.isLoggable) console.log ("+++++++++");
      this.tuuext = [];
      this.tuuext = res.data.body.tuuext;
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SearchResultA ]___[ 종료 ]");
    },
    // ======================================================
    // (화면흐름 제어) fn_Confirm:: 화면 닫기
    // ======================================================
    fn_Confirm () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Confirm ]___[ 시작 ]");
      if (_.isEmpty (this.rowSelect)) {
        this.fn_Close ();
      }
      else {
        this.$emit ("ts-popup103-callback", this.rowSelect);
        this.fn_Close ();
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Confirm ]___[ 종료 ]");
    },
    // ======================================================
    // (이벤트 핸들러) fn_RowSelected:: 조회된 사원(부서)목록 중 항목별 선택 시
    // ======================================================
    fn_RowSelected (idx) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_RowSelected ]___[ 시작 ]");
      this.lv_SelectedItem = idx;
      let findIdx = this.tuuext.findIndex (obj => obj.chnlCustId === this.lv_SelectedItem);
      if (findIdx !== -1) {
        Object.assign (this.lv_SelectedItem, this.tuuext[findIdx]);
      }
      this.rowSelect = this.tuuext[idx];
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_RowSelected ]___[ 종료 ]");
    },
  },
}
</script>
