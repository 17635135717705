/*
 * 업무구분: 운용방법
 * 화 면 명: MSPTS312P
 * 화면설명: 운용방법
 * 작 성 일: 2023.03.06
 * 작 성 자: 김경태
 */
<template>
  <mo-modal class="fts-modal large" ref="modal" title="운용방법">
    <div class="wrap-modalcontents">
      <div class="input-top">
        <div class="left">
          <div class="wrap-input row">
            <label> 코드 </label>
            <mo-text-field v-model="c" class="w130" clearable placeholder="입력하세요" />
          </div>
          <div class="wrap-input row">
            <label> 운용방법명 </label>
            <mo-text-field v-model="cnm" clearable placeholder="입력하세요" />
          </div>
        </div>
        <div class="right">
          <div class="wrap-button row">
            <mo-button @click="fn_Search()" primary> 조회 </mo-button>
          </div>
        </div>
      </div>
      <div class="wrap-table-title row">
        <h2 class="table-title"> 조회내역 </h2>
      </div>
      <div class="wrap-table">
        <table class="table row-type">
          <thead>
            <tr>
              <th class="w40">선택</th>
              <th>코드</th>
              <th>운용방법명</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, idx) in tucoinLst" :key="idx" @click="fn_RowSelected(idx)">
              <td><mo-radio v-model='lv_SelectedItem' :value="idx.toString()" @click="fn_RowSelected(idx)"/></td>
              <td>{{ data.c}}</td>
              <td class="aL">{{ data.cnm}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_Close">닫기</mo-button>
        <mo-button primary size="large" @click="fn_Confirm">확인</mo-button>
      </div>
    </template>
  </mo-modal>
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
import tsConstants from '@/config/constants/tsConstants'
export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPTS312P',
  // 현재 화면 ID
  screenId: 'MSPTS312P',
  // 컴포넌트 선언
  components: {
    
  },
  // 화면명
  props: {
    popup312Obj: {type:Object, default:null},
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      // 공통 변수
      isInit: false, // 초기화 여부
      c:'',
      cnm:'',
      tucoinLst:[],
      lv_Params:{},
      lv_SelectedItem:"",
      lv_ReturnVal: {}, // 리턴값
      lv_ReturnStr: [], // 베이스 화면으로 리턴할 조회조건 텍스트
      eaiCommObj: TSCommUtil.gfn_eaiCommObj(),
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    modal() {
      return this.$refs.modal
    },
  },
  
  /** watch 정의 영역 */
  watch: {},

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPTS106P')

  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    fn_Init () {
      this.modal.open()
    },
    fn_Open(param){
      this.lv_Params = param
      this.tucoinLst = []
      this.modal.open()
      this.fn_Search()
    },
    fn_Close(){
      this.modal.close()
    },
    /******************************************************************************
     * Function명 : fn_Clear
     * 설명       : 입력값 모두 초기화 (초기 값으로 셋팅)
     ******************************************************************************/
    fn_Clear () {

    },
    /******************************************************************************
     * Function명 : fn_Search
     * 설명       : 조회 후 팝업 닫음
     ******************************************************************************/
    fn_Search () {
      let pParams312 = {
        cId: '5024',
        c: this.c,
        cNm: this.cnm,
        rmk500: this.c,
      }
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600236_S'
      this.eaiCommObj.params = _.cloneDeep(pParams312);
      TSServiceUtil.invokeInDirect(this.eaiCommObj, function (res) {
        if (res && res.data) {
          this.tucoinLst = res.data.tucoin
        }
      },function (error) {return},'UF10600236')
    },
    /******************************************************************************
     * Function명 : fn_SearchResult
     * 설명       : 조회 콜백
     ******************************************************************************/
    fn_SearchResult (res) {
      this.tucoinLst = res.data.tucoin
    },
    /******************************************************************************
     * Function명 : fn_Confirm
     * 설명       : 확인
     ******************************************************************************/
    fn_Confirm () {
      if(_.isEmpty(this.rowSelect)){
        this.fn_Close()
      }else{
        this.rowSelect.pRow = this.lv_Params.pRow
        this.$emit('ts-popup312-callback', this.rowSelect)
        this.fn_Close()
      }
    },
    /******************************************************************************
     * Function명 : fn_RowSelected
     * 설명       : 조회내역 선택
     ******************************************************************************/
    fn_RowSelected(idx){
      this.lv_SelectedItem = idx+"";
      this.rowSelect = this.tucoinLst[idx]
    }
  }
}
</script>
