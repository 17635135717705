/*
* 업무구분: 신탁>영업>계약승인알림
* 화 면 명: MSPTS319P[TPUP96100.xfdl]
* 화면설명: 계약승인알림
* 작 성 일: 2023.03.29
* 작 성 자: 이종근
*/
<template>
    <!-- popup -->
    <mo-modal class="fts-modal small type02 noClose" ref="modal" title="계약승인알림">
      <template>
        <!-- <div class="wrap-modalcontents"> -->
          <div class="wrap-input column">
            <p class="text" v-html="processMsg"></p>
          </div>
        <!-- </div> -->
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large" @click="fn_goPage ('MSPTS210M')" :disabled="disRealDeposit"> 실시간입금 </mo-button>
          <mo-button primary size="large" @click="fn_goPage ('MSPTS209M')"> 가상계좌 </mo-button>
        </div>
      </template>
    </mo-modal> 
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import moment from 'moment'
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'

export default {
  /************************************************************************************************
   * Vue 파일 속성 정의 영역
   ************************************************************************************************/
  // 현재 화면명
  name: 'MSPTS319P',
  // 현재 화면 ID
  screenId: 'MSPTS319P',
  // 컴포넌트 선언
  components: {
  },
  // 화면명
  props: {
    pPage: String,     
    pMsgCntn: String,  
    popupObj: {type:Object, default:null},
  },
  /******************************************************************************************
   * Data 설정 영역
   ******************************************************************************************/
  data () {
    console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::MSPTS319P ]__[ DATA-MODEL:::data ]___[ 시작 ]");
    return {
      msgCntn : '',
      aprvBusnTc : '',
      tacno : '',
      bnkbSeq : '',
      accSeq : '',
      aprvBusnTc : '',
      msgCntt1 : '',
      msgCntt2 : '',
      msgCntt3 : '',
      processMsg : '',

      disRealDeposit: false,
    };
    console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::MSPTS319P ]__[ DATA-MODEL:::data ]___[ 종료 ]");
  },
  /******************************************************************************************
   * Computed 설정 영역
   ******************************************************************************************/
  computed: {
    modal () {
      return this.$refs.modal;
    },
  },
  /******************************************************************************************
   * Watch 설정 영역
   ******************************************************************************************/
  watch: {
    //
  },
  /******************************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Created 설정 영역
   ******************************************************************************************/
  created () {
    console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::MSPTS319P ]__[ LIFECYCLE-HOOK:::created ]___[ 시작 ]");
    console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::MSPTS319P ]__[ LIFECYCLE-HOOK:::created ]___[ 종료 ]");
  },
  /******************************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Mounted 설정 영역
   ******************************************************************************************/
  mounted () {
    console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::MSPTS319P ]__[ LIFECYCLE-HOOK:::mounted ]___[ 시작 ]");
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog ('MSPTS319P')
    console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::MSPTS319P ]__[ LIFECYCLE-HOOK:::mounted ]___[ 종료 ]");
  },
  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    // ======================================================
    // (데이터 제어) fn_Init:: 
    // ======================================================
    fn_Init () {
      console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS319P ]__[ METHODS:::fn_Init ]___[ 시작 ]");
      console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS319P ]__[ METHODS:::fn_Init ]___[ 종료 ]");
      this.modal.open ()
    },
    // ======================================================
    // (화면흐름 제어) fn_Open:: 
    // ======================================================
    fn_Open (param) {
      console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS319P ]__[ METHODS:::fn_Open ]___[ 시작 ]");
      this.disRealDeposit = false;  //실시간입금
      let trstTypC = "";
      if (param.trstTypC != undefined && param.trstTypC != null) trstTypC = param.trstTypC;
      //002.유가증권,003.부동산신탁 : 실시간입금 없음.
      if (trstTypC == "002" || trstTypC == "003"){
        this.disRealDeposit = true;
      }
      this.msgCntn = param.pMsgCntn;
      let t_accn_no = param.pMsgCntn.replace (/[^0-9]/g, '');
      this.aprvBusnTc = t_accn_no.substr (0, 4);
      this.tacno = t_accn_no.substr (4, 7);
      this.bnkbSeq = t_accn_no.substr (11, 3);
      this.accSeq = t_accn_no.substr (14, 3);
      if (this.aprvBusnTc == "1011") {
        this.msgCntt2 = "신규계약";
      }
      else if (this.aprvBusnTc == "1031") { // 추가입금
        this.msgCntt2 = "추가입금";
      }
      else if (this.aprvBusnTc == "4011") { // 해지
        this.msgCntt2 = "계약해지";
      }
      this.msgCntt1 = "계약[" + this.tacno + "-" + this.bnkbSeq + "-" + this.accSeq + "] 건";
      this.msgCntt2 = '<br />' + this.msgCntt2 + " 승인처리가 완료 되었습니다.";
      this.msgCntt3 = "<br /><br />입금방법을 선택해 주세요.";
      this.processMsg = this.msgCntt1 + this.msgCntt2 + this.msgCntt3;
      this.modal.open ();
      // this.fn_Clear ();
      console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS319P ]__[ METHODS:::fn_Open ]___[ 종료 ]");
    },
    // ======================================================
    // (화면흐름 제어) fn_Close:: 
    // ======================================================
    fn_Close () {
      console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS319P ]__[ METHODS:::fn_Close ]___[ 시작 ]");
      this.modal.close ();
      console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS319P ]__[ METHODS:::fn_Close ]___[ 종료 ]");
    },
    // ======================================================
    // (데이터 제어) fn_Clear:: 입력값 모두 초기화 (초기 값으로 셋팅)
    // ======================================================
    fn_Clear () {
      console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS319P ]__[ METHODS:::fn_Clear ]___[ 시작 ]");
      console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS319P ]__[ METHODS:::fn_Clear ]___[ 종료 ]");
    },
    // ======================================================
    // (화면흐름 제어) fn_goPage:: 
    // ======================================================
    fn_goPage (name) {
      console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS319P ]__[ METHODS:::fn_goPage ]___[ 시작 ]");
      let params = {
        tacno: this.tacno,
        bnkbSeq: this.bnkbSeq,
        accSeq: this.accSeq,
        name: name,
      };
      this.$emit ('ts-popup319-callback', params);
      this.fn_Close ();
      console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS319P ]__[ METHODS:::fn_goPage ]___[ 종료 ]");
    },    
  },
}
</script>
