/*
 * 업무구분: 상품검색
 * 화 면 명: MSPTS110P
 * 화면설명: 상품검색
 * 작 성 일: 2023.02.15
 * 작 성 자: 김경태
 */
<template>
  <mo-modal class="fts-modal large" ref="modal" title="상품조회">
    <div class="wrap-modalcontents" id="dv110P">
      <div class="input-top">
        <div class="column w100p gap12">
          <div class="row w100p">
            <div class="left w100p mr0">
              <div class="wrap-input row">
                <label> 상품코드 </label>
                <mo-text-field class="form-input-name" v-model="gdC" clearable placeholder="입력하세요" />
              </div>
              <div class="wrap-input row">
                <label> 신탁유형 </label>
                <mo-dropdown :items="dsCmb5014_110p" v-model="trstTypC" dsdtl="trstTypC" ref="dropdown1" @input="cmb5014_onitemchanged()" :disabled="disTrstTypC" placeholder="선택하세요"/>
              </div>
              <div class="wrap-input row">
                <label> 상품유형 </label>
                <mo-dropdown :items="cmbGdTypDtlC" v-model="gdTypDtlC" ref="dropdown2" @input="cmbGdTypDtlC_onitemchanged()" :disabled="disGdTypDtlC" placeholder="선택하세요" />
              </div>
            </div>
          </div>
          <div class="row w100p">
            <div class="left w100p">
              <div class="wrap-input row">
                <label> 상품명 </label>
                <mo-text-field class="form-input-name w100p" v-model="gdNm" clearable placeholder="입력하세요" />
              </div>
            </div>
            <div class="right">
              <div class="wrap-button row">
                <mo-button class="btn-clear" @click="fn_Clear()"> 초기화 </mo-button>
                <mo-button primary class="btn-inquiry" @click="fn_Search()"> 조회 </mo-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrap-table-title">
        <h3 class="table-title"> 조회내역 </h3>
      </div>
      <div class="wrap-table">
        <table class="table row-type">
          <thead>
            <tr>
              <th scope="col">선택</th>
              <th scope="col">상품코드</th>
              <th scope="col">상품명</th>
            </tr>
          </thead>
          <tbody>
              <tr :key="idx" v-for="(data, idx) in tpup95330List" @click="fn_RowSelected(idx)">
                <td><mo-radio :id="idx" v-model='lv_SelectedItem' :value="idx.toString()" @click="fn_RowSelected(idx)" /></td>
                <td>{{ data.gdC}}</td>
                <td>{{ data.gdNm}}</td>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button primary size="large" @click="fn_Confirm()">확인</mo-button>
      </div>
    </template>
  </mo-modal>
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import moment from 'moment'
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPTS110P',
  // 현재 화면 ID
  screenId: 'MSPTS110P',
  // 컴포넌트 선언
  components: {
    
  },
  // 화면명
  props: {
    pPage: String,      // 부모 페이지명
    pGdC: String,       // 신탁상품
    pTrstTypC: String, // 신탁유형
    pGdTypDtlC: String, // 상품유형
    pUrl: String,
    pProcTc: String,
    popupObj: {type:Object, default:null},
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      // 공통 변수
      page: "",
      gdC: "",
      gdNm: "",
      trstTypC: "",
      gdTypDtlC: "",
      procTc: "",
      url: "",
      
      cmbGdTypDtlC: [],

      disTrstTypC: false,
      disGdTypDtlC:false,

      dsCmb5014_110p: [],
      dsCmb5010: [],
      dsCmb5011: [],
      dsCmb5012: [],
      dsCmb5013: [],
      dsCmb5059: [],
      dsCmb5173: [],
      dsCmb5174: [],
      dsCmb5175: [],

      lv_SelectedItem: '',
      cmbGdTypDtlC: [],
      eaiCommObj: TSCommUtil.gfn_eaiCommObj(),
      tpup95330List:[]
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    modal() {
      return this.$refs.modal
    },
  },
  
  /** watch 정의 영역 */
  watch: {},

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    // 공통코드 조회
    this.fn_ComCode()
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPTS110P')

  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    fn_display(mode){
      //mode: true(display), false(none display)
      let el = document.getElementById("dv110P");
      if (el == null) return;
      if(mode){
        el.style.display = "";
      }else{
        el.style.display = "none";
      }
    },
    /***************************************************************************************
      code        : 호출할 공통코드
      dsName      : 호출된 공통코드를 담을 Dataset
      selecttype  : ComboBox 최상단에 추가할 문구( A: -전체-, S:-선택-, N: 빈칸)
      
      selecttype은 추후 변경될 수 있음.
      this.fn_commonAfterOnload를 화면에 선언하면 gfn_setCommonCode처리후 호출됨.
    *****************************************************************************************/
    fn_ComCode (){
      if (this.dsCmb5014_110p != null && this.dsCmb5014_110p.length > 0) return;

      this.fn_display(false);
      this.eaiCommObj.lv_vm = this
      // this.eaiCommObj.trnstId = 'txTSSTS90S1' // selectTFCP11000A
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600046_S'
      this.eaiCommObj.params = {'TUKEMK':[
                                  {cId:"5014"},
                                  {cId:"5010"},
                                  {cId:"5011"},
                                  {cId:"5012"},
                                  {cId:"5013"},
                                  {cId:"5059"},
                                  {cId:"5173"},
                                  {cId:"5174"},
                                  {cId:"5175"}
                              ]}
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_comCodeCallBack)
    }, 
    fn_comCodeCallBack ( res ) {
      for ( let index in res.data.tukemk ) {
          let item = res.data.tukemk[index]
          switch (item.cid) 
          {
            case "5014":
              this.dsCmb5014_110p.push({value: item.c , text: item.cnm})
              break
            case "5010":
              this.dsCmb5010.push({value: item.c , text: item.cnm})
              break
            case "5011":
              this.dsCmb5011.push({value: item.c , text: item.cnm})
              break
            case "5012":
              this.dsCmb5012.push({value: item.c , text: item.cnm})
              break
            case "5013":
              this.dsCmb5013.push({value: item.c , text: item.cnm})
              break
            case "5059":
              this.dsCmb5059.push({value: item.c , text: item.cnm})
              break
            case "5173":
              this.dsCmb5173.push({value: item.c , text: item.cnm})
              break
            case "5174":
              this.dsCmb5174.push({value: item.c , text: item.cnm})
              break
            case "5175":
              this.dsCmb5175.push({value: item.c , text: item.cnm})
              break
          }
      }
      this.fn_display(true);
    },
    fn_Init () {
      this.gdC = ''
      this.gdNm = ''
      //this.trstTypC = ''
      this.gdTypDtlC = ''
      //this.procTc = ''
      this.url = ''
      this.tpup95330List = []
      this.rowSelect = {}
      this.lv_SelectedItem = ''
    },
    fn_Open(params){
      this.fn_Init();
      this.$nextTick(()=>{
        this.page = params.pPage==undefined||params.pPage==null?"":params.pPage;
        this.gdC = params.pGdC==undefined||params.pGdC==null?"":params.pGdC;
        this.trstTypC = params.pTrstTypC==undefined||params.pTrstTypC==null?"":params.pTrstTypC;
        this.cmb5014_onitemchanged();
        this.gdTypDtlC = params.pGdTypDtlC==undefined||params.pGdTypDtlC==null?"":params.pGdTypDtlC;
        this.url = params.pUrl==undefined||params.pUrl==null?"":params.pUrl;
        this.procTc = params.pProcTc==undefined||params.pProcTc==null?"":params.pProcTc;

        this.fn_Search();
      })
      this.modal.open()
    },
    /******************************************************************************
     * Function명 : fn_Close
     * 설명       : 닫기
     ******************************************************************************/
    fn_Close(){
      this.modal.close()
      this.closeDropdown()
    },
    closeDropdown() {
      this.$refs.dropdown1.close()
      this.$refs.dropdown2.close()
    },
    /******************************************************************************
     * Function명 : fn_Clear
     * 설명       : 입력값 모두 초기화 (초기 값으로 셋팅)
     ******************************************************************************/
    fn_Clear () {
      this.fn_Init();
    },
    cmb5014_onitemchanged(){
      this.tpup95330List = []
      this.rowSelect = {}

      let trstTypC = this.trstTypC;
      if( trstTypC == "001") {        //금전신탁일때
        this.cmbGdTypDtlC = _.cloneDeep(this.dsCmb5010);
      } else if(trstTypC == "002") {
        this.cmbGdTypDtlC = _.cloneDeep(this.dsCmb5011);
      } else if(trstTypC == "003") {
        this.cmbGdTypDtlC = _.cloneDeep(this.dsCmb5012);
      } else if(trstTypC == "004") {
        this.cmbGdTypDtlC = _.cloneDeep(this.dsCmb5013);
      } else if(trstTypC == "007") {  //치매신탁 20210805
        this.cmbGdTypDtlC = _.cloneDeep(this.dsCmb5059);
      } else if(trstTypC == "008") { //유언대용신탁(금전)유형코드 20230126
        this.cmbGdTypDtlC = _.cloneDeep(this.dsCmb5173);
      } else if(trstTypC == "009") { //유언대용신탁(유가증권)유형코드  20230126
        this.cmbGdTypDtlC = _.cloneDeep(this.dsCmb5174);
      } else if(trstTypC == "010") { //유언대용신탁(부동산)유형코드  20230126
        this.cmbGdTypDtlC = _.cloneDeep(this.dsCmb5175);
      }
    },
    cmbGdTypDtlC_onitemchanged(){
      this.tpup95330List = []
      this.rowSelect = {}
    },
    /******************************************************************************
     * Function명 : fn_Search
     * 설명       : 조회
     ******************************************************************************/
    fn_Search () {
      const lv_Vm = this
      this.tpup95330List = []
      this.rowSelect = {}

      let pParams = {
        gdC: lv_Vm.gdC,
        gdNm: lv_Vm.gdNm,
        trstTypC: lv_Vm.trstTypC,
        gdTypDtlC: lv_Vm.gdTypDtlC,
        procTc: lv_Vm.procTc,
        URL: lv_Vm.pUrl
      }
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600216_S'
      this.eaiCommObj.params = pParams

      console.log('fn_Search pParams ===============> ',pParams)

      TSServiceUtil.invokeInDirect(this.eaiCommObj, function (res) {
        if (res && res.data) {
          this.tpup95330List = res.data.tpup95330
        }
      },function (error) {return},'UF10600216')
    },
    /******************************************************************************
     * Function명 : fn_Confirm
     * 설명       : 확인
     ******************************************************************************/
    fn_Confirm () {
      if(_.isEmpty(this.rowSelect)){
        this.fn_Close()
      }else{
        this.$emit('ts-popup110-callback', this.rowSelect)
        this.fn_Close()
      }
    },
    /******************************************************************************
     * Function명 : fn_RowSelected
     * 설명       : 조회내역 선택
     ******************************************************************************/
    fn_RowSelected(idx){
      this.lv_SelectedItem = idx+""
      this.rowSelect = this.tpup95330List[idx]
    }
  }
}
</script>
